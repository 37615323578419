export class VehicleTypeUtils {
  static niuIdentifier = 'niu';
  static yadeaIdentifier = 'yadea';

  static CAPACITIES: { [key: string]: number } = {
    NIU: 15,
    Yadea: 14
  };

  static PICKUP_CAPACITIES = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 15, 20, 25, 29, 30];
  static STANDARD_CAPACITY = 5;
}
