import { QUERY_KEYS } from '@/config/QueryKeys';
import type { Vehicle } from '@badgermoleV2/api';
import { getVehicleReports } from '@badgermoleV2/vehicles';
import { useQuery } from '@tanstack/react-query';
import { subDays } from 'date-fns';

const page = 1;
const pageSize = 5;

export const usePersistedReports = (vehicle: Vehicle | undefined) => {
  const { data, refetch } = useQuery({
    queryKey: [QUERY_KEYS.vehicle.latestReports, vehicle],
    enabled: false,
    queryFn: async ({ signal }) =>
      await getVehicleReports(
        page,
        pageSize,
        undefined,
        subDays(new Date(), 100),
        new Date(),
        undefined,
        vehicle!.vin,
        vehicle!.licencePlate,
        undefined,
        undefined,
        true,
        undefined,
        { signal }
      )
  });

  return { data: data?.items ?? [], refetch };
};
