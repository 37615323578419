import type { CheckupCategory, CheckupItem, CheckupList } from '@/utils/WorkshopUtils';
import { WorkshopUtils } from '@/utils/WorkshopUtils';
import type { PricingLevelMapEntry, WorkshopTicket } from '@badgermoleV2/api';
import {
  Alert,
  Card,
  CardContent,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography
} from '@mui/material';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type WorkshopTicketCheckupListProps = {
  readonly repairTicket: WorkshopTicket;
  readonly vehicleTypes: PricingLevelMapEntry[];
  readonly open: boolean;
  readonly handleOk: () => void;
  readonly handleCancel: () => void;
  readonly loading: boolean;
};

export const WorkshopTicketCheckupList = ({
  repairTicket,
  vehicleTypes,
  open,
  handleOk,
  handleCancel,
  loading
}: WorkshopTicketCheckupListProps) => {
  const { t } = useTranslation();
  const [checkupList, setCheckupList] = useState<CheckupList>(
    vehicleTypes
      .find((vt) => vt.vehicleTypeId === repairTicket.vehicle.vehicleTypeId)
      ?.model.toLowerCase()
      .startsWith('niu')
      ? WorkshopUtils.checkupListNiu
      : WorkshopUtils.checkupListYadea
  );

  const toggleCheckupListItem = (categoryKey: string, item: CheckupItem) => {
    const deepCopy = { ...checkupList } as CheckupList;
    const category = deepCopy.categories.find(
      (category: CheckupCategory) => category.title === categoryKey
    )!;
    const newCategoryItems = category.items.map((categoryItem) =>
      categoryItem.item === item.item
        ? { ...categoryItem, checked: !categoryItem.checked }
        : categoryItem
    );
    const newCategories = deepCopy.categories.map((category: CheckupCategory) =>
      category.title === categoryKey ? { ...category, items: newCategoryItems } : category
    );
    setCheckupList({ ...deepCopy, categories: newCategories });
  };

  const checkAllInCategory = (categoryKey: string) => {
    const deepCopy = { ...checkupList } as CheckupList;
    const category = deepCopy.categories.find(
      (category: CheckupCategory) => category.title === categoryKey
    )!;
    const newCategoryItems = category.items.map((categoryItem) => ({
      ...categoryItem,
      checked: true
    }));
    const newCategories = deepCopy.categories.map((category: CheckupCategory) =>
      category.title === categoryKey ? { ...category, items: newCategoryItems } : category
    );
    setCheckupList({ ...deepCopy, categories: newCategories });
  };

  const allChecked = checkupList.categories.every((category) =>
    category.items.every((item) => item.checked)
  );

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'xl'}
      open={open}
      onClose={(_, reason) => {
        if (reason === 'backdropClick') return;
        handleCancel();
      }}
      aria-labelledby="form-dialog-title"
      data-testid={'checkupListDialog'}
    >
      <DialogTitle>{t('external-workshop:checkupList:title')}</DialogTitle>
      <DialogContent>
        <Grid container spacing={1}>
          {checkupList.categories.map((category: CheckupCategory) => {
            return (
              <Grid item lg={4} xs={12} key={`CheckboxCategory${category.title}`}>
                <Card key={`CheckBoxGroup${category.title}`} sx={{ width: '100%' }}>
                  <CardContent>
                    <Typography sx={{ fontSize: 'large', fontWeight: 'bold' }}>
                      {t(
                        `external-workshop:checkupList:${checkupList.vehicleTypeIdentifier}:${category.title}:title`
                      )}
                    </Typography>
                    <Button
                      variant={'text'}
                      color={'secondary'}
                      sx={{ margin: 0, padding: 0 }}
                      onClick={() => {
                        checkAllInCategory(category.title);
                      }}
                    >
                      {t(`external-workshop:checkupList:checkAll`)}
                    </Button>
                    <FormGroup sx={{ marginTop: 2 }}>
                      {category.items.map((item) => (
                        <FormControlLabel
                          sx={{ marginTop: 1.1 }}
                          key={`CheckBoxItem${item.item}`}
                          control={<Checkbox checked={item.checked} />}
                          label={t(
                            `external-workshop:checkupList:${checkupList.vehicleTypeIdentifier}:${category.title}:items:${item.item}`
                          )}
                          onChange={() => {
                            toggleCheckupListItem(category.title, item);
                          }}
                        />
                      ))}
                    </FormGroup>
                  </CardContent>
                </Card>
              </Grid>
            );
          })}
        </Grid>
        <Alert sx={{ marginTop: 2 }} severity={'info'}>
          {t(`external-workshop:checkupList:confirmMessage`)}
        </Alert>
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loading}
          onClick={() => {
            handleCancel();
          }}
          color="error"
          variant="outlined"
        >
          {t(`external-workshop:checkupList:cancel`)}
        </Button>
        <Button
          onClick={() => {
            handleOk();
          }}
          color="success"
          variant="outlined"
          disabled={!allChecked || loading}
        >
          {t(`external-workshop:checkupList:ok`)}
          {loading && <CircularProgress color={'secondary'} size={'1rem'} sx={{ marginLeft: 2 }} />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
