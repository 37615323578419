import { TableCell, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type EditableTableCellProps = {
  readonly onChange: (_: string) => void;
  readonly type?: 'number' | 'text';
  readonly placeHolder?: string;
  readonly helperText?: string;
  readonly initialValue?: string;
  readonly rows?: number;
};
export const EditableTableCell = ({
  type = 'text',
  initialValue = '',
  helperText = '',
  placeHolder = '',
  rows = 1,
  onChange
}: EditableTableCellProps) => {
  const [currentValue, setCurrentValue] = useState(initialValue);
  const { t } = useTranslation();
  const changed = initialValue !== currentValue;

  useEffect(() => {
    setCurrentValue(initialValue); // needed for updating when change is discarded
  }, [initialValue]);

  return (
    <TableCell align="left">
      <TextField
        variant="standard"
        size="small"
        type={type}
        fullWidth
        multiline
        value={currentValue}
        label={changed ? `${t('glossar:current')}: ${initialValue}` : ''}
        helperText={helperText}
        placeholder={placeHolder}
        InputProps={{ disableUnderline: true, rows }}
        InputLabelProps={{ style: { color: 'gray' } }}
        onChange={(e) => {
          onChange(e.target.value);
          setCurrentValue(e.target.value);
        }}
      />
    </TableCell>
  );
};
