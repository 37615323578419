import { Stack, Typography } from '@mui/material';

type WorkshopTicketInfoLabelProps = {
  readonly title: string;
  readonly label: string;
  readonly dataTestId: string;
};

export const WorkshopTicketInfoLabel = ({
  title,
  label,
  dataTestId
}: WorkshopTicketInfoLabelProps) => {
  return (
    <Stack>
      <Typography sx={{ fontSize: 'small', fontWeight: 'bold' }}>{title}</Typography>
      <Typography sx={{ fontSize: 'default' }} data-testid={dataTestId}>
        {label}
      </Typography>
    </Stack>
  );
};
