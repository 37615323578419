import { SimpleDataGrid } from '@/components/common/DataGrid/SimpleDataGrid';
import { useRepairTicket } from '@/hooks/Workshop/useRepairTicket';
import AirlineStopsIcon from '@mui/icons-material/AirlineStops';
import BatteryCharging60Icon from '@mui/icons-material/BatteryCharging60';
import CellTowerIcon from '@mui/icons-material/CellTower';
import ElectricMeterIcon from '@mui/icons-material/ElectricMeter';
import ElectricMopedIcon from '@mui/icons-material/ElectricMoped';
import HMobiledataIcon from '@mui/icons-material/HMobiledata';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MoneyIcon from '@mui/icons-material/Money';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import PanToolIcon from '@mui/icons-material/PanTool';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import RefreshIcon from '@mui/icons-material/Refresh';
import ScreenLockRotationIcon from '@mui/icons-material/ScreenLockRotation';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@mui/material';
import { useTranslation } from 'react-i18next';

type VehicleDataDialogProps = {
  open: boolean;
  issueKey: string;
  setShowVehicleData: (v: boolean) => void;
};

export const VehicleDataDialog = ({
  open,
  issueKey,
  setShowVehicleData
}: VehicleDataDialogProps) => {
  const { vehicleLiveData, isLiveDataLoading, refetchLiveData } = useRepairTicket(issueKey, open);
  const { t } = useTranslation();

  const formatDataForUsers = (value: string | undefined): string => {
    if (value && !isNaN(+value)) {
      return value;
    }
    return t(`common:vehicleLiveData:${value}`);
  };

  return (
    <Dialog open={open} onClose={() => setShowVehicleData(false)} maxWidth="xs" fullWidth>
      <DialogTitle>
        {t('common:vehicleLiveData:title')}
        {isLiveDataLoading ? (
          <CircularProgress color="info" size={'1.6rem'} sx={{ marginLeft: '1rem' }} />
        ) : (
          <CellTowerIcon sx={{ marginLeft: '1rem' }} />
        )}
      </DialogTitle>
      <DialogContent>
        <SimpleDataGrid
          isLoading={isLiveDataLoading}
          icon={<BatteryCharging60Icon />}
          description={t('common:vehicleLiveData:batteryLevel')}
          value={formatDataForUsers(vehicleLiveData?.battery_level?.toString())}
        />
        <SimpleDataGrid
          isLoading={isLiveDataLoading}
          icon={<ElectricMeterIcon />}
          description={t('common:vehicleLiveData:voltage')}
          value={formatDataForUsers(vehicleLiveData?.board_voltage?.toString())}
        />
        <SimpleDataGrid
          isLoading={isLiveDataLoading}
          icon={<NotificationImportantIcon />}
          description={t('common:vehicleLiveData:alarmOn')}
          value={formatDataForUsers(vehicleLiveData?.alarm_active?.toString())}
        />
        <SimpleDataGrid
          isLoading={isLiveDataLoading}
          icon={<PowerSettingsNewIcon />}
          description={t('common:vehicleLiveData:ignitionOff')}
          value={formatDataForUsers(vehicleLiveData?.ignition_off?.toString())}
        />
        <SimpleDataGrid
          isLoading={isLiveDataLoading}
          icon={<PanToolIcon />}
          description={t('common:vehicleLiveData:immobilizerOn')}
          value={formatDataForUsers(vehicleLiveData?.immobilized?.toString())}
        />
        <SimpleDataGrid
          isLoading={isLiveDataLoading}
          icon={<ElectricMopedIcon />}
          description={t('common:vehicleLiveData:locked')}
          value={formatDataForUsers(vehicleLiveData?.locked?.toString())}
        />
        <SimpleDataGrid
          isLoading={isLiveDataLoading}
          icon={<HMobiledataIcon />}
          description={t('common:vehicleLiveData:onKickstand')}
          value={formatDataForUsers(vehicleLiveData?.centerstand_out?.toString())}
        />
        <SimpleDataGrid
          isLoading={isLiveDataLoading}
          icon={<Inventory2Icon />}
          description={t('common:vehicleLiveData:helmetBoxClosed')}
          value={formatDataForUsers(vehicleLiveData?.helmetBox?.closed?.toString())}
        />
        <SimpleDataGrid
          isLoading={isLiveDataLoading}
          icon={<ScreenLockRotationIcon />}
          description={t('common:vehicleLiveData:helmetBoxLocked')}
          value={formatDataForUsers(vehicleLiveData?.helmetBox?.locked?.toString())}
        />
        <SimpleDataGrid
          isLoading={isLiveDataLoading}
          icon={<AirlineStopsIcon />}
          description={t('common:vehicleLiveData:saddleClosed')}
          value={formatDataForUsers(vehicleLiveData?.saddle_closed?.toString())}
        />
        <SimpleDataGrid
          isLoading={isLiveDataLoading}
          icon={<LocationOnIcon />}
          description={t('common:vehicleLiveData:location')}
          value={
            vehicleLiveData
              ? `https://www.google.com/maps/search/?api=1&query=${vehicleLiveData?.location?.lat},${vehicleLiveData?.location?.lon}`
              : 'null'
          }
          display={
            vehicleLiveData
              ? vehicleLiveData.location!.lat + ' ' + vehicleLiveData.location!.lon
              : t('common:vehicleLiveData:undefined')
          }
        />
        <SimpleDataGrid
          isLoading={isLiveDataLoading}
          icon={<MoneyIcon />}
          description={t('common:vehicleLiveData:mileage')}
          value={formatDataForUsers(vehicleLiveData?.mileage?.toString())}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => refetchLiveData()}>
          {t('common:buttons:refresh')} <RefreshIcon />
        </Button>
        <Button variant="outlined" onClick={() => setShowVehicleData(false)}>
          {t('common:buttons:close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
