import { QUERY_KEYS } from '@/config/QueryKeys';
import type { GpsPosition1 } from '@badgermoleV2/api';
import { getEstimatedDrivingTime } from '@badgermoleV2/missions';
import { useQuery } from '@tanstack/react-query';

export function useEstimatedDrivingTime(
  stepId: number,
  destination: GpsPosition1,
  location: GeolocationPosition,
  enabled: boolean
) {
  const { data, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.tour.estimatedDrivingTime, stepId, destination, location],
    queryFn: async ({ signal }) =>
      await getEstimatedDrivingTime(
        location.coords.latitude,
        location.coords.longitude,
        destination.lat,
        destination.lon,
        { signal }
      ),
    enabled: enabled
  });

  return { estimatedDrivingTime: data, edtLoading: isFetching };
}
