import { Button, Chip, Stack, TableCell, TableRow } from '@mui/material';
import { EditableTableCell } from '@/components/EditableTableCell/EditableTableCell';
import { useState } from 'react';
import type { ProblemListTodo } from '@badgermoleV2/api';
import { WorkshopUtils } from '@/utils/WorkshopUtils';
import type { OverridableStringUnion } from '@mui/types';
import type { ChipPropsColorOverrides } from '@mui/material/Chip/Chip';
import { useTranslation } from 'react-i18next';

type WorkshopTicketTodoRowProps = {
  readonly todo: ProblemListTodo;
  readonly changeTodoStatus: (todo: ProblemListTodo, status: string) => Promise<void>;
  readonly changeTodoComment: (todo: ProblemListTodo, comment: string) => Promise<void>;
};

export const WorkshopTicketTodoRow = ({
  todo,
  changeTodoStatus,
  changeTodoComment
}: WorkshopTicketTodoRowProps) => {
  const [loading, setLoading] = useState(false);
  const [ticketComment, setTicketComment] = useState(todo.comment ?? '');
  const hasCommentChanged = ticketComment !== (todo.comment ?? '');
  const { t } = useTranslation();
  const statusColor = (
    status: string
  ): OverridableStringUnion<
    'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning',
    ChipPropsColorOverrides
  > => {
    if (status == todo.status.toString()) {
      if (status == 'Done') {
        return 'success';
      } else if (status == 'Failed') {
        return 'error';
      } else {
        return 'secondary';
      }
    } else {
      return 'default';
    }
  };
  return (
    <TableRow key={`TodoRow${todo.todoId}`}>
      {!hasCommentChanged ? (
        <TableCell key={`TodoRow${todo.todoId}Content`} align="left">
          {todo.content}
        </TableCell>
      ) : (
        <TableCell>
          <Stack gap={1}>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => {
                changeTodoComment(todo, ticketComment ?? '');
              }}
              color="success"
            >
              {t('common:buttons.save')}
            </Button>
            <Button
              variant="outlined"
              fullWidth
              onClick={() => setTicketComment(todo.comment ?? '')}
            >
              {t('common:buttons.discard')}
            </Button>
          </Stack>
        </TableCell>
      )}
      <EditableTableCell
        key={`TodoRow${todo.todoId}Comment`}
        helperText=""
        rows={3}
        initialValue={ticketComment}
        onChange={setTicketComment}
        placeHolder=""
        type="text"
      />
      <TableCell key={`TodoRow${todo.todoId}Status`} align="left">
        {WorkshopUtils.ticketStatusList.map((status) => (
          <Chip
            disabled={loading}
            key={`StatusChip${status}`}
            sx={{ marginRight: '4px', marginBottom: '4px' }}
            label={
              status === todo.status.toString() ? (
                <span style={{ textDecorationLine: 'underline', fontWeight: 'bold' }}>
                  {status}
                </span>
              ) : (
                <span>{status}</span>
              )
            }
            color={statusColor(status)}
            onClick={async () => {
              setLoading(true);
              changeTodoStatus(todo, status).finally(() => {
                setLoading(false);
              });
            }}
          />
        ))}
      </TableCell>
    </TableRow>
  );
};
