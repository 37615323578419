import { QUERY_KEYS } from '@/config/QueryKeys';
import type { AvailableProblem, ProblemListTodoBody } from '@badgermoleV2/api';
import type { ProblemListTodoResolveBody, TriggerActionBody } from '@badgermoleV2/api';
import {
  addWorkshopTicketTodoItem,
  completeWorkshopTicket,
  failWorkshopTicket,
  getVehicleLiveStatusByRepairTicket,
  getWorkshopTicketByIssueKey,
  getWorkshopTicketProblemList,
  perfromHardwareInteraction,
  resolveWorkshopTicketTodoItem
} from '@badgermoleV2/workshop';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

type CreateRepairTicketTodoBody = {
  problem: string;
  status: string;
  comment: string;
};

export const useRepairTicket = (jiraIssueKey?: string, liveDataOpen: boolean = false) => {
  const queryClient = useQueryClient();

  const invalidateQueries = () => {
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.workshop.repairTicket, jiraIssueKey] });
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.workshop.problemList, jiraIssueKey] });
  };

  const { data: repairTicket } = useQuery({
    queryKey: [QUERY_KEYS.workshop.repairTicket, jiraIssueKey],
    queryFn: async ({ signal }) => await getWorkshopTicketByIssueKey(jiraIssueKey!, { signal }),
    enabled: jiraIssueKey !== undefined
  });

  const {
    data: vehicleLiveData,
    isFetching: isLiveDataLoading,
    refetch: refetchLiveData
  } = useQuery({
    queryKey: [QUERY_KEYS.workshop.liveData, jiraIssueKey],
    enabled: repairTicket !== undefined && liveDataOpen,
    queryFn: async ({ signal }) =>
      await getVehicleLiveStatusByRepairTicket(jiraIssueKey!, { signal })
  });

  const { data: problemList = [] } = useQuery({
    queryKey: [QUERY_KEYS.workshop.problemList, jiraIssueKey],
    enabled: jiraIssueKey !== undefined,
    queryFn: async ({ signal }) => await getWorkshopTicketProblemList(jiraIssueKey!, { signal })
  });

  const { mutate: failRepairTicket, isSuccess: isFailRepairSuccess } = useMutation({
    mutationFn: async (comment: string) => await failWorkshopTicket(jiraIssueKey!, comment),
    onSettled: invalidateQueries
  });

  const { mutate: completeRepairTicket, isSuccess: isCompleteRepairSuccess } = useMutation({
    mutationFn: async () => await completeWorkshopTicket(jiraIssueKey!),
    onSettled: invalidateQueries
  });

  const { mutate: createRepairTicketTodo } = useMutation({
    mutationFn: async ({ problem, status, comment }: CreateRepairTicketTodoBody) =>
      await addWorkshopTicketTodoItem(jiraIssueKey!, {
        status: status as unknown as object,
        problem: { name: problem } as AvailableProblem,
        comment
      } satisfies ProblemListTodoBody),
    onSettled: invalidateQueries
  });

  const { mutate: updateRepairTicket } = useMutation({
    mutationFn: async (body: ProblemListTodoResolveBody) => {
      await resolveWorkshopTicketTodoItem(jiraIssueKey!, body);
    },
    onSettled: invalidateQueries
  });

  const { mutate: sendVehicleInteraction, isPending: isVehicleInteractionPending } = useMutation({
    mutationFn: async (action: TriggerActionBody) =>
      await perfromHardwareInteraction(jiraIssueKey!, action['action'])
  });

  return {
    repairTicket,
    isResolveTicketSucces: isFailRepairSuccess || isCompleteRepairSuccess,
    sendVehicleInteraction,
    isVehicleInteractionPending,
    problemList,
    vehicleLiveData,
    isLiveDataLoading,
    refetchLiveData,
    updateRepairTicket,
    failRepairTicket,
    completeRepairTicket,
    createRepairTicketTodo
  };
};
