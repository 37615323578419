import { useUserProfile } from '@/hooks/Authentication/useUserProfile';
import { useUserLocation } from '@/hooks/useUserLocation';
import { useStore } from '@/store';
import { TourUtils } from '@/utils/TourUtils';
import { VehicleTypeUtils } from '@/utils/VehicleTypeUtils';
import type {
  Mission,
  PricingLevelMapEntry,
  ServiceHub,
  ServicePhone,
  ServiceVehicle,
  TourParameterOptionsBundle,
  TourParametersBody
} from '@badgermoleV2/api';
import { MissionType } from '@badgermoleV2/api';
import { Box, Button, MenuItem, TextField } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

type TourParameterSelectionProps = {
  mission: Mission;
  bundle: TourParameterOptionsBundle;
  vehicleTypes: PricingLevelMapEntry[];
  onStart: (tourBody: TourParametersBody) => Promise<void>;
};

export const TourParameterSelection = ({
  mission,
  bundle,
  vehicleTypes,
  onStart
}: TourParameterSelectionProps) => {
  const OCXXEnabled = useStore((s) => s.OCXXEnabled);
  const { user } = useUserProfile();
  const { t } = useTranslation();
  const { location } = useUserLocation('current');
  const isPickupMission = mission.missionType === MissionType.Pickup;
  const availableVehicleTypes = Array.from(
    new Set(vehicleTypes.map((vt) => vt.model.split(' ')[0]))
  );
  const availableCapacities = isPickupMission
    ? VehicleTypeUtils.PICKUP_CAPACITIES
    : [VehicleTypeUtils.CAPACITIES['Yadea'], VehicleTypeUtils.CAPACITIES['NIU']];

  const [selectedVehicleModel, setSelectedVehicleModel] = useState<string>(
    bundle.location.projectKey === 'OCB' ? 'NIU' : 'Yadea'
  );
  const [selectedEndHub, setSelectedEndHub] = useState<ServiceHub>();
  const [selectedStartHub, setSelectedStartHub] = useState<ServiceHub>();
  const [selectedServicePhone, setSelectedServicePhone] = useState<ServicePhone>();
  const [selectedServiceVehicle, setSelectedServiceVehicle] = useState<ServiceVehicle>();
  const [serviceCapacity, setServiceCapacity] = useState(
    isPickupMission
      ? VehicleTypeUtils.STANDARD_CAPACITY
      : bundle.location.projectKey === 'OCB'
        ? availableCapacities[1]
        : availableCapacities[0]
  );

  const tourStartDisabled = (): boolean => {
    if (isPickupMission) {
      return (
        selectedVehicleModel === '' ||
        selectedEndHub === undefined ||
        selectedServicePhone === undefined ||
        selectedServiceVehicle === undefined
      );
    } else {
      return (
        selectedVehicleModel === '' ||
        selectedStartHub === undefined ||
        selectedEndHub === undefined ||
        selectedServicePhone === undefined ||
        selectedServiceVehicle === undefined
      );
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1.5rem',
        margin: '1rem',
        marginBottom: '70px'
      }}
    >
      <TextField
        disabled
        label="City"
        data-testid={`nonDynamicSelectProject${mission.filterSet?.serviceLocation.projectKey.replaceAll(
          ' ',
          ''
        )}`}
        value={`${mission.filterSet?.serviceLocation.name} (${mission.filterSet?.serviceLocation.projectKey})`}
      />

      <TextField
        disabled
        label="Start location"
        data-testid="nonDynamicSelectStartLocation"
        value={`Current location: ${location?.coords.latitude},${location?.coords.longitude}`}
      />
      <TextField
        select
        required={!isPickupMission}
        data-testid="customSelectVehicleTypes"
        value={selectedVehicleModel}
        label="Vehicle Model"
        onChange={(e) => {
          setSelectedVehicleModel(e.target.value);
          if (!isPickupMission) setServiceCapacity(VehicleTypeUtils.CAPACITIES[e.target.value]);
        }}
      >
        {availableVehicleTypes.map((vehicleType) => (
          <MenuItem
            key={vehicleType}
            value={vehicleType}
            data-testid={`customSelectVehicleTypesMenuItem${vehicleType.replace(' ', '')}`}
          >
            {vehicleType}
          </MenuItem>
        ))}
      </TextField>
      {!isPickupMission && (
        <TextField
          select
          required
          data-testid="customSelectStartHub"
          value={selectedStartHub ? selectedStartHub.name : ''}
          label="Start Hub"
          onChange={(e) => {
            setSelectedStartHub(bundle.serviceHubs!.find((hub) => hub.name === e.target.value)!);
            setSelectedEndHub(bundle.serviceHubs!.find((hub) => hub.name === e.target.value)!);
          }}
        >
          {bundle.serviceHubs?.map((hub) => (
            <MenuItem
              key={hub.name}
              value={hub.name}
              data-testid={`customSelectStartHubMenuItem${hub.name.replaceAll(' ', '')}`}
            >
              {hub.name}
            </MenuItem>
          ))}
        </TextField>
      )}
      <TextField
        select
        required
        data-testid="customSelectEndHub"
        value={selectedEndHub ? selectedEndHub.name : ''}
        label="End Hub"
        onChange={(e) => {
          setSelectedEndHub(bundle.serviceHubs!.find((hub) => hub.name === e.target.value)!);
        }}
      >
        {bundle.serviceHubs?.map((hub) => (
          <MenuItem
            key={hub.name}
            value={hub.name}
            data-testid={`customSelectEndHubMenuItem${hub.name.replaceAll(' ', '')}`}
          >
            {hub.name}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        select
        required
        data-testid="customSelectServiceVehicle"
        value={selectedServiceVehicle ? selectedServiceVehicle.label : ''}
        label="Service Vehicle"
        onChange={(e) => {
          setSelectedServiceVehicle(
            bundle.serviceVehicle!.find((vehicle) => vehicle.label === e.target.value)!
          );
        }}
      >
        {bundle.serviceVehicle?.map((vehicle) => (
          <MenuItem
            key={vehicle.label}
            value={vehicle.label}
            data-testid={`customSelectServiceVehicleMenuItem${vehicle.label.replaceAll(' ', '')}`}
          >
            {vehicle.label}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        select
        label="Capacity"
        data-testid="customSelectCapacity"
        value={serviceCapacity}
        disabled={!isPickupMission}
        onChange={(e) => {
          setServiceCapacity(+e.target.value);
        }}
      >
        {availableCapacities.map((cap) => (
          <MenuItem key={cap} value={cap} data-testid={`customSelectCapacityMenuItem${cap}`}>
            {cap}
          </MenuItem>
        ))}
      </TextField>

      <TextField
        select
        required
        data-testid="customSelectServicePhone"
        value={selectedServicePhone ? selectedServicePhone.label : ''}
        label="Service Phone"
        onChange={(e) => {
          setSelectedServicePhone(
            bundle.servicePhones!.find((phone) => phone.label === e.target.value)!
          );
        }}
      >
        {bundle.servicePhones?.map((phone) => (
          <MenuItem
            key={phone.label}
            value={phone.label}
            data-testid={`customSelectServicePhoneMenuItem${phone.label.replaceAll(' ', '')}`}
          >
            {phone.label}
          </MenuItem>
        ))}
      </TextField>

      <Button
        fullWidth
        data-testid="startTourButton"
        variant="contained"
        color={'success'}
        disabled={tourStartDisabled()}
        onClick={async () => {
          const body = {
            capacity: serviceCapacity,
            endHubId: selectedEndHub!.hubId!,
            missionType: mission.missionType,
            phoneId: selectedServicePhone!.servicePhoneId!,
            serviceVehicleId: selectedServiceVehicle!.serviceVehicleId!,
            startLocation: { lat: location!.coords.latitude, lon: location!.coords.longitude },
            assignees: [user!.username],
            debug: OCXXEnabled,
            startHubId: isPickupMission ? selectedEndHub!.hubId : selectedStartHub!.hubId,
            vehicleTypeIds: isPickupMission
              ? undefined
              : vehicleTypes
                  .filter((vt) => vt.model.startsWith(selectedVehicleModel))
                  .map((vt) => vt.vehicleTypeId)
          } satisfies TourParametersBody;
          await onStart(body);
        }}
      >
        {t(
          `${TourUtils.chooseTourTypeOutput(
            mission.missionType as unknown as MissionType,
            'pickup-tour',
            'service-tour',
            'relocate'
          )}:start`
        )}
      </Button>
    </Box>
  );
};
