import { LoginScreen } from '@/screens/LoginScreen';
import { OverviewScreen } from '@/screens/OverviewScreen';
import { ExtendTourScreen, FeedbackScreen, PrepareTourScreen } from '@/screens/TourScreens/General';
import { RunningPickupScreen } from '@/screens/TourScreens/Pickup/RunningPickupScreen';
import { RunningPickupPostStepsScreen } from '@/screens/TourScreens/Pickup/RunningPickupPostStepsScreen';
import { RelocateScreen } from '@/screens/TourScreens/Relocate';
import { ServiceTourScreen } from '@/screens/TourScreens/Service/ServiceTourScreen';
import { UmparkenScreen } from '@/screens/UmparkenScreen';
import { VehicleReportScreen } from '@/screens/VehicleReport/VehicleReportScreen';
import { WorkshopDetailScreen } from '@/screens/Workshop/WorkshopDetailScreen';
import { WorkshopScreen } from '@/screens/Workshop/WorkshopScreen';
import { MissionType } from '@badgermoleV2/api';
import { Navigate, createBrowserRouter, type RouteObject } from 'react-router-dom';
import { Layout } from './Layout';
import { ROUTES } from './routes';
import { ProtectedRoute } from './ProtectedRoutes';
import { LoginReturnScreen } from '@/screens/LoginScreen/LoginReturnScreen';
import { LogoutRedirectScreen } from '@/screens/LoginScreen/LogoutRedirectScreen';
import * as Sentry from '@sentry/react';

const tourRoutes: (
  tourType: typeof MissionType.Custom | typeof MissionType.Pickup | typeof MissionType.Swap
) => RouteObject[] = (tourType) => [
  {
    path: ROUTES.TOUR.PREPARE.replace('/', ''),
    element: (
      <ProtectedRoute
        component={<PrepareTourScreen key={`prepare-${tourType}`} missionType={tourType} />}
      />
    )
  },
  {
    path: ROUTES.TOUR.RUN.replace('/', ''),
    element: (
      <ProtectedRoute
        component={
          tourType === MissionType.Pickup ? (
            <RunningPickupScreen />
          ) : (
            <ServiceTourScreen key={tourType} missionType={tourType} />
          )
        }
      />
    )
  },
  {
    path: ROUTES.TOUR.EXTEND.replace('/', ''),
    element: (
      <ProtectedRoute component={<ExtendTourScreen key={tourType} missionType={tourType} />} />
    )
  },
  {
    path: ROUTES.TOUR.FEEDBACK.replace('/', ''),
    element: <ProtectedRoute component={<FeedbackScreen key={tourType} missionType={tourType} />} />
  }
];

const routes: RouteObject[] = [
  {
    path: '/',
    element: <Layout />,
    children: [
      {
        path: ROUTES.DEFAULT,
        element: <LoginScreen />
      },
      { path: ROUTES.LOGIN_RETURN, element: <LoginReturnScreen /> },
      { path: ROUTES.LOGOUT_REDIRECT, element: <LogoutRedirectScreen /> },
      {
        path: ROUTES.OVERVIEW,
        element: <ProtectedRoute component={<OverviewScreen />} />
      },
      {
        path: ROUTES.UMPARKEN,
        element: <ProtectedRoute component={<UmparkenScreen />} />
      },
      {
        path: ROUTES.REPORTS,
        element: <ProtectedRoute component={<VehicleReportScreen />} />
      },
      {
        path: ROUTES.PICKUP.ROOT,
        children: tourRoutes(MissionType.Pickup).concat([
          {
            path: ROUTES.TOUR.POST_STEPS.replace('/', ''),
            element: <ProtectedRoute component={<RunningPickupPostStepsScreen />} />
          }
        ])
      },
      {
        path: ROUTES.SERVICE.ROOT,
        children: tourRoutes(MissionType.Swap)
      },
      {
        path: ROUTES.CUSTOM.ROOT,
        children: tourRoutes(MissionType.Custom)
      },
      {
        path: ROUTES.RELOCATE + ROUTES.TOUR.RUN,
        element: <ProtectedRoute component={<RelocateScreen />} />
      },
      {
        path: ROUTES.WORKSHOP.ROOT,
        element: <ProtectedRoute component={<WorkshopScreen />} />
      },
      {
        path: ROUTES.WORKSHOP.DETAIL,
        element: <ProtectedRoute component={<WorkshopDetailScreen />} />
      },
      {
        path: '*',
        element: <Navigate to={ROUTES.DEFAULT} replace={true} />
      }
    ]
  }
];

const createRouter =
  import.meta.env.VITE_SENTRY_ENABLED === 'true'
    ? Sentry.wrapCreateBrowserRouter(createBrowserRouter)
    : createBrowserRouter;

export const router = createRouter(routes);
