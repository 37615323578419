import { ROUTES } from '@/App/routes/routes';
import { ConfirmDialog } from '@/components/Dialogs/ConfirmDialog';
import { VehicleDataDialog } from '@/components/Dialogs/VehicleDataDialog';
import { useRepairTicket } from '@/hooks/Workshop/useRepairTicket';
import { DateTimeUtils } from '@badgermoleV2/DateTimeUtils';
import type { PricingLevelMapEntry, WorkshopTicket } from '@badgermoleV2/api';
import { TriggerAction } from '@badgermoleV2/api';
import CellTowerIcon from '@mui/icons-material/CellTower';
import { Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { WorkshopTicketCheckupList } from './WorkshopTicketCheckupList';
import { WorkshopTicketInfoLabel } from './WorkshopTicketInfoLabel';

type WorkshopTicketCardProps = {
  readonly workshopTicket: WorkshopTicket;
  readonly iconSrc: string;
  readonly vehicleTypes: PricingLevelMapEntry[];
  readonly showSelectButton?: boolean;
  readonly showCompleteButton?: boolean;
};

export const WorkshopTicketCard = ({
  workshopTicket,
  iconSrc,
  vehicleTypes,
  showCompleteButton,
  showSelectButton
}: WorkshopTicketCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { completeRepairTicket, failRepairTicket, isResolveTicketSucces, sendVehicleInteraction } =
    useRepairTicket(workshopTicket.jiraIssue.issueKey);
  const [confirmActivateDialogOpen, setConfirmActivateDialogOpen] = useState(false);
  const [completeLoading, setCompleteLoading] = useState(false);
  const [confirmFailDialogOpen, setConfirmFailDialogOpen] = useState(false);
  const [showVehicleData, setShowVehicleData] = useState(false);
  const completeButtonDisabled = workshopTicket.todos!.some(
    (todo) => todo!.status.toString() !== 'Done' && todo!.status.toString() !== 'CannotReproduce'
  );
  const failButtonDisabled =
    workshopTicket.todos!.some((todo) => todo!.status.toString() === 'Open') ||
    workshopTicket.todos!.every((todo) => todo!.status.toString() === 'Done');

  const availableTriggerActions = [
    TriggerAction.Lock,
    TriggerAction.Unlock,
    TriggerAction.UnlockSaddle
  ];

  if (isResolveTicketSucces) {
    navigate(ROUTES.WORKSHOP.ROOT);
  }

  return (
    <Paper sx={{ width: '100%' }}>
      <Grid container spacing={2} sx={{ padding: 2 }} data-testid={'repairTicketCard'}>
        <VehicleDataDialog
          open={showVehicleData}
          issueKey={workshopTicket.jiraIssue.issueKey}
          setShowVehicleData={setShowVehicleData}
        />
        <WorkshopTicketCheckupList
          vehicleTypes={vehicleTypes}
          repairTicket={workshopTicket}
          open={confirmActivateDialogOpen}
          handleCancel={() => setConfirmActivateDialogOpen(false)}
          handleOk={async () => {
            setCompleteLoading(true);
            completeRepairTicket();
          }}
          loading={completeLoading}
        />
        <ConfirmDialog
          open={confirmFailDialogOpen}
          cancelTitle={t(`external-workshop:repairTicket:cancelCompleteRepair`)}
          handleCancel={() => setConfirmFailDialogOpen(false)}
          handleOk={(comment: string | undefined) => {
            failRepairTicket(comment!);
          }}
          okTitle={t(`external-workshop:repairTicket:failRepair`)}
          hint={t(`external-workshop:repairTicket:questionToFailRepair`)}
          warning={t(`external-workshop:repairTicket:ticketWarning`)}
          okMessage={t(`external-workshop:repairTicket:failRepair`)}
          placeholder={t('external-workshop:repairTicket:addComment')}
          withComment={true}
          successAction={false}
        />

        <Grid item xs={6} lg={3}>
          <img
            src={iconSrc}
            height={'90px'}
            alt={'vehicle-type-image'}
            data-testid={'vehicle-type-image'}
          />
        </Grid>

        <Grid item xs={6} lg={3}>
          <Typography
            data-testid={`licensePlate`}
            display={'inline'}
            fontSize={'x-large'}
            fontWeight={'bold'}
          >
            {workshopTicket.vehicle.licencePlate}
          </Typography>
          <Stack spacing={1}>
            {showSelectButton && (
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  const navigateTo = `${ROUTES.WORKSHOP.ROOT}/${workshopTicket.jiraIssue.issueKey}`;
                  navigate(navigateTo);
                }}
                data-testid={'selectButton'}
              >
                {t(`common:buttons:select`)}
              </Button>
            )}
            <Stack alignItems={'start'} spacing={1}>
              {availableTriggerActions.map((triggerAction) => (
                <Button
                  variant="outlined"
                  key={`triggerActionButton${triggerAction.toString()}`}
                  fullWidth
                  disabled={completeLoading}
                  color={'secondary'}
                  onClick={() =>
                    sendVehicleInteraction({
                      action: triggerAction
                    })
                  }
                >
                  {t(`external-workshop:repairTicket:triggerActions:${triggerAction.toString()}`)}
                </Button>
              ))}
            </Stack>
          </Stack>
        </Grid>

        <Grid item xs={6} lg={3}>
          <Stack direction={'column'} spacing={1}>
            <WorkshopTicketInfoLabel
              dataTestId={'ticketNumber'}
              title={t(`external-workshop:repairTicket:number`)}
              label={workshopTicket.jiraIssue.issueKey}
            />
            <WorkshopTicketInfoLabel
              dataTestId={'cloudboxxId'}
              title={t(`external-workshop:repairTicket:cloudboxxId`)}
              label={workshopTicket.vehicle.hardwareId ?? ''}
            />
            <WorkshopTicketInfoLabel
              dataTestId={'vin'}
              title={t(`external-workshop:repairTicket:vin`)}
              label={workshopTicket.jiraIssue.vin}
            />
            <WorkshopTicketInfoLabel
              dataTestId={'mileage'}
              title={t(`external-workshop:repairTicket:mileage`)}
              label={
                workshopTicket.vehicle.mileage
                  ? `${workshopTicket.vehicle.mileage.toString()} km`
                  : '-'
              }
            />
          </Stack>
        </Grid>

        <Grid item xs={6} lg={3}>
          <Stack direction={'column'} spacing={1}>
            <WorkshopTicketInfoLabel
              dataTestId={'priority'}
              title={t(`external-workshop:repairTicket:priority`)}
              label={workshopTicket.jiraIssue.priority?.toString() ?? '-'}
            />

            <WorkshopTicketInfoLabel
              dataTestId={'lastUpdatedAt'}
              label={
                workshopTicket.jiraIssue.updated
                  ? DateTimeUtils.formatToLocale(workshopTicket.jiraIssue.updated)
                  : ''
              }
              title={t(`external-workshop:repairTicket:updated`)}
            />

            <WorkshopTicketInfoLabel
              dataTestId={'reasonForRepair'}
              title={t(`external-workshop:repairTicket:type`)}
              label={workshopTicket.jiraIssue.reasonForRepair ?? ''}
            />
            <WorkshopTicketInfoLabel
              dataTestId={'assignedWorkshop'}
              title={t(`external-workshop:repairTicket:workshop`)}
              label={workshopTicket.jiraIssue.workshop ?? '-'}
            />
            <Button
              variant="outlined"
              color="secondary"
              fullWidth
              onClick={async () => {
                setShowVehicleData(true);
              }}
            >
              <CellTowerIcon />
              {t('common:vehicleData:buttonTitle')}
            </Button>
          </Stack>
        </Grid>

        {showCompleteButton && (
          <>
            <Grid item xs={12} lg={4}>
              <Stack direction={'column'}>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={() => {
                    setConfirmFailDialogOpen(true);
                  }}
                  disabled={failButtonDisabled}
                >
                  {t(`external-workshop:repairTicket:failRepair`)}
                </Button>
                {failButtonDisabled && (
                  <Typography variant="caption">
                    {t('external-workshop:repairTicket.failRepairHint')}
                  </Typography>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Button
                fullWidth={true}
                variant="contained"
                color="success"
                size="small"
                onClick={() => {
                  setConfirmActivateDialogOpen(true);
                }}
                disabled={completeButtonDisabled}
              >
                {t(`external-workshop:repairTicket:completeRepair`)}
              </Button>
              {completeButtonDisabled && (
                <Typography variant="caption">
                  {t('external-workshop:repairTicket.completeAllTicketsHint')}
                </Typography>
              )}
            </Grid>
          </>
        )}
      </Grid>
    </Paper>
  );
};
