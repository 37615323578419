import { ShortenedChip } from '@/components/Chips/ShortenedChip';
import { BusinessMap } from '@/components/Map';
import { DisplayUserPosition } from '@/components/Map/DisplayUserPosition';
import { VehicleMarker } from '@/components/Map/VehicleMarker';
import { ProblemSolutionActions } from '@/components/Tour/Step/ProblemSolutionActions';
import { VehicleInteractions } from '@/components/Tour/Step/VehicleInteractions';
import { ReportButton } from '@/components/common/ReportButton/ReportButton';
import { useEstimatedDrivingTime } from '@/hooks/Tour/General/useEstimatedDrivingTime';
import { useUserLocation } from '@/hooks/useUserLocation';
import { useStore } from '@/store';
import { NavigatorUtils } from '@/utils/NavigatorUtils';
import { StepUtils } from '@/utils/StepUtils';
import type {
  LocalizedDescription,
  Step,
  Tour,
  TransitionAction,
  TransitionActionField
} from '@badgermoleV2/api';
import { MissionType, StepCategory, StepState, StepType } from '@badgermoleV2/api';
import AssistantDirectionIcon from '@mui/icons-material/AssistantDirection';
import Battery0BarIcon from '@mui/icons-material/Battery0Bar';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Typography
} from '@mui/material';
import { orange } from '@mui/material/colors';
import type { LatLngExpression } from 'leaflet';
import { latLng } from 'leaflet';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type CurrentStepProps = {
  readonly step: Step;
  readonly tour: Tour;
  readonly specialTasksInJob?: LocalizedDescription[];
  readonly selectedCompleteAction?: TransitionActionField;
  readonly completeActionOptions?: TransitionActionField[];
  readonly onCompleteActionSelect?: (_: TransitionActionField) => void;
  readonly doneAction: (step: Step) => void;
  readonly failedAction: (step: Step, cancelAction: TransitionAction, reason: string) => void;
  readonly refreshAction?: (step: Step) => void;
  readonly disableAllButtons?: boolean;
};

export const CurrentStep = ({
  step,
  tour,
  specialTasksInJob,
  selectedCompleteAction = undefined,
  completeActionOptions = [],
  onCompleteActionSelect = () => {},
  doneAction,
  failedAction,
  refreshAction,
  disableAllButtons
}: CurrentStepProps) => {
  const { t, i18n } = useTranslation();
  const { location } = useUserLocation('watch');

  const [PROXIMITY_CHECK_ENABLED, PROXIMITY_THRESHOLD] = useStore((s) => [
    s.PROXIMITY_CHECK_ENABLED,
    s.PROXIMITY_THRESHOLD
  ]);
  const [proximityCheck, setProximityCheck] = useState(
    () => PROXIMITY_CHECK_ENABLED && step.stepCategory !== 'DriveToScooter'
  );

  const isDrivingToStep =
    !(step.stepType === StepType.AtStep && step.stepCategory === StepCategory.DoSomething) &&
    !(
      step.stepType === StepType.PostStep &&
      step.stepCategory === StepCategory.DoSomethingWithSelection
    );

  const { edtLoading, estimatedDrivingTime } = useEstimatedDrivingTime(
    step.stepId!,
    step.gpsPosition!,
    location!,
    isDrivingToStep && !!location && !!step.gpsPosition
  );

  useEffect(() => {
    if (!location) return;
    if (step.gpsPosition) {
      const userLocation = latLng(location.coords.latitude, location.coords.longitude);
      const vehicleLocation = latLng(step.gpsPosition!.lat, step.gpsPosition!.lon);
      setProximityCheck(
        NavigatorUtils.proximityCheck(userLocation, vehicleLocation, PROXIMITY_THRESHOLD)
      );
    } else {
      setProximityCheck(true);
    }
  }, [location, step.stepCategory, step.gpsPosition, PROXIMITY_THRESHOLD]);

  return (
    <Paper sx={{ padding: '.75rem', margin: '.25rem', background: 'rgba(0, 0, 0, 0.06)' }}>
      <Stack spacing={1}>
        <Stack
          direction={'row'}
          justifyContent="center"
          alignItems="center"
          spacing={2}
          data-testid={`currentVehicle${step.vehicle?.licencePlate}`}
        >
          <Typography
            fontSize="x-large"
            fontWeight="bold"
            sx={{ textAlign: 'center' }}
            data-testid={`currentStep${step.stepDescription['en'].replaceAll(' ', '')}`}
          >
            {step.stepDescription[i18n.language as keyof Step['stepDescription']]}
          </Typography>
          {isDrivingToStep && (
            <Chip
              label={
                estimatedDrivingTime ? (
                  `~${Math.round(estimatedDrivingTime.estimatedDrivingSeconds / 60)}min`
                ) : edtLoading ? (
                  <CircularProgress size={'1rem'} />
                ) : (
                  '-'
                )
              }
            />
          )}
          {step.vehicle && tour.tourType === MissionType.Swap && (
            <Chip
              icon={<Battery0BarIcon fontSize={'small'} />}
              label={`${step.vehicle.batteryLevel} %`}
            />
          )}
        </Stack>
        {(tour.tourType === MissionType.Swap || tour.tourType === MissionType.Custom) &&
          specialTasksInJob && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', rowGap: 1, columnGap: 1 }}>
              {specialTasksInJob.map((task) => (
                <ShortenedChip
                  key={'SpecialTaskInJobChip' + task['en']}
                  color={orange[500]}
                  label={task[i18n.language as keyof LocalizedDescription]}
                />
              ))}
            </Box>
          )}
        {step.gpsPosition && (
          <>
            <BusinessMap style={{ width: '100%', height: '200px' }}>
              <DisplayUserPosition />
              <VehicleMarker
                position={[step.gpsPosition.lat, step.gpsPosition.lon] as LatLngExpression}
                description={step.stepDescription[i18n.language as keyof Step['stepDescription']]}
              />
            </BusinessMap>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginTop: '.5rem'
              }}
            >
              {refreshAction && (
                <Button
                  variant={'outlined'}
                  disabled={false}
                  size={'small'}
                  color={'secondary'}
                  onClick={() => {
                    refreshAction(step);
                  }}
                >
                  <GpsFixedIcon fontSize={'small'} sx={{ marginRight: '.25rem' }} />
                  {t('common:buttons.refresh')}
                </Button>
              )}

              {step.stepType !== StepType.PostStep && StepUtils.getGpsLocation(step) && (
                <Button
                  disabled={disableAllButtons}
                  variant={'outlined'}
                  size={'small'}
                  color={'secondary'}
                  onClick={() => {
                    window.open(
                      NavigatorUtils.createMapsUrl(StepUtils.getGpsLocation(step)),
                      '_blank'
                    );
                  }}
                >
                  <AssistantDirectionIcon fontSize="inherit" sx={{ marginRight: '.25rem' }} />
                  {t('common:buttons.navigate')}
                </Button>
              )}
            </Box>
          </>
        )}
        <Stack spacing={2}>
          <VehicleInteractions step={step} tour={tour} disableAllButtons={disableAllButtons} />
          <ProblemSolutionActions
            step={step}
            cancelActions={step.cancelActions}
            failedAction={(step, cancelAction, reason) => {
              failedAction(step, cancelAction, reason);
            }}
            disableAllButtons={disableAllButtons}
          />
          {step.vehicle && step.cancelActions && step.cancelActions.length !== 0 && (
            <Box>
              <ReportButton vehicle={step.vehicle} />
            </Box>
          )}
        </Stack>
        {completeActionOptions &&
          completeActionOptions.length > 0 &&
          step.state !== StepState.Completed && (
            <Box>
              <FormControl variant="outlined" disabled={disableAllButtons} fullWidth>
                <InputLabel>Options</InputLabel>
                <Select
                  value={selectedCompleteAction?.value}
                  onChange={(e) => {
                    const value = e.target.value;
                    const selectedAction = completeActionOptions.filter(
                      (t) => t.value === value
                    )[0];
                    onCompleteActionSelect(selectedAction);
                  }}
                  label="Complete options"
                >
                  {completeActionOptions?.map((triggerAction) => (
                    <MenuItem
                      key={`CurrentStepMenuItem${triggerAction.value.replace(' ', '')}`}
                      value={triggerAction.value}
                    >
                      {triggerAction.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          )}
        <Box
          sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%' }}
        >
          {PROXIMITY_CHECK_ENABLED && !proximityCheck && (
            <Typography color="red">{t('pickup-tour:outOfProximity')}</Typography>
          )}
          <Button
            aria-label="Submit"
            data-testid={`completeAction${step.completeAction.toString().replaceAll(' ', '')}`}
            fullWidth
            variant="contained"
            color="success"
            onClick={() => {
              doneAction(step);
            }}
            disabled={
              disableAllButtons ||
              step.state === StepState.Completed ||
              (PROXIMITY_CHECK_ENABLED && !proximityCheck)
            }
          >
            {t('pickup-tour:completeActions:' + step.completeAction.toString())}
          </Button>
        </Box>
      </Stack>
    </Paper>
  );
};
