import type { User } from '@badgermoleV2/api';

export interface CheckupItem {
  item: string;
  checked: boolean;
}

export interface CheckupCategory {
  title: string;
  items: CheckupItem[];
}

export interface CheckupList {
  vehicleTypeIdentifier: string;
  categories: CheckupCategory[];
}

export class WorkshopUtils {
  static repairsNotAvailable = (user: User | undefined): boolean => {
    return !user || !(user.groups ?? []).some((group) => group.includes('workshop_external'));
  };

  static isOnlyExternal = (user: User | undefined): boolean => {
    return !user || (user.groups ?? []).every((group) => group.includes('workshop_external'));
  };
  static ticketStatusList = ['Open', 'Done', 'Failed', 'WaitingForSparePart', 'CannotReproduce'];

  static checkupListNiu = {
    vehicleTypeIdentifier: 'niu',
    categories: [
      {
        title: 'chassisAndElectronics',
        items: [
          { item: 'Axles', checked: false },
          { item: 'Throttle', checked: false },
          { item: 'PhoneHolder', checked: false },
          { item: 'SteeringBearing', checked: false },
          { item: 'Body', checked: false },
          { item: 'Mirrors', checked: false },
          { item: 'Switches', checked: false },
          { item: 'TirePressure', checked: false },
          { item: 'TireTreadDepth', checked: false },
          { item: 'Stand', checked: false },
          { item: 'LightsIndicatorsHorn', checked: false },
          { item: 'GreenButton', checked: false },
          { item: 'Display', checked: false },
          { item: 'SeatLock', checked: false },
          { item: 'HelmetBoxLock', checked: false },
          { item: 'HelmetBoxSignal', checked: false }
        ]
      },
      {
        title: 'brakeSystem',
        items: [
          { item: 'BrakePadsAndDisk', checked: false },
          { item: 'BrakeFluid', checked: false },
          { item: 'BrakeCaliper', checked: false }
        ]
      },
      {
        title: 'overall',
        items: [
          { item: 'TestRide', checked: false },
          { item: 'CleanBody', checked: false },
          { item: 'CleanBox', checked: false },
          { item: 'Position', checked: false }
        ]
      }
    ]
  } as CheckupList;

  static checkupListYadea = {
    vehicleTypeIdentifier: 'yadea',
    categories: [
      {
        title: 'chassisAndElectronics',
        items: [
          { item: 'Axles', checked: false },
          { item: 'Throttle', checked: false },
          { item: 'PhoneHolder', checked: false },
          { item: 'SteeringBearing', checked: false },
          { item: 'Body', checked: false },
          { item: 'Mirrors', checked: false },
          { item: 'Switches', checked: false },
          { item: 'TirePressure', checked: false },
          { item: 'TireTreadDepth', checked: false },
          { item: 'Stand', checked: false },
          { item: 'LightsIndicatorsHorn', checked: false },
          { item: 'PButton', checked: false },
          { item: 'Display', checked: false },
          { item: 'SeatLock', checked: false },
          { item: 'SideCables', checked: false },
          { item: 'HelmetBoxLock', checked: false },
          { item: 'HelmetBoxSignal', checked: false }
        ]
      },
      {
        title: 'brakeSystem',
        items: [
          { item: 'BrakePadsAndDisk', checked: false },
          { item: 'BrakeFluid', checked: false },
          { item: 'BrakeCaliper', checked: false }
        ]
      },
      {
        title: 'overall',
        items: [
          { item: 'TestRide', checked: false },
          { item: 'CleanBody', checked: false },
          { item: 'CleanBox', checked: false },
          { item: 'Position', checked: false }
        ]
      }
    ]
  } as CheckupList;
}
