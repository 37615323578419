import { Grid, Link, Typography } from '@mui/material';
import type { ReactNode } from 'react';

type SimpleDataGridProps = {
  readonly icon: ReactNode;
  readonly description: string;
  readonly value: string;
  readonly display?: string;
  readonly isLoading: boolean;
};

export const SimpleDataGrid = ({
  icon,
  description,
  value,
  display,
  isLoading
}: SimpleDataGridProps) => {
  return (
    <Grid
      container
      justifyContent="flex-start"
      alignItems="center"
      borderBottom={1}
      borderColor="lightgray"
      marginTop={'0.3rem'}
    >
      <Grid item xs={1}>
        {icon}
      </Grid>
      <Grid item xs={5}>
        <Typography>{description}</Typography>
      </Grid>
      {isLoading ? (
        <Typography>...</Typography>
      ) : (
        <Grid item xs="auto">
          {value.includes('https') ? (
            <Link rel="noopener noreferrer" target="_blank" href={value}>
              {display}
            </Link>
          ) : (
            <Typography>{value}</Typography>
          )}
        </Grid>
      )}
    </Grid>
  );
};
