import { FormControl, InputLabel, MenuItem, Select, type SxProps, type Theme } from '@mui/material';

type GenericSelectProps<Type> = {
  items: Type[];
  selectedItem: Type | undefined;
  onSelectItem: (item: Type) => void;
  getValueFromItem: (item: Type | undefined) => string;
  labelForItem: (item: Type) => string;
  title: string;
  disabled?: boolean;
  required?: boolean;
  customStyling?: (item: Type) => SxProps<Theme>;
  color?: string;
};

export const GenericSelect = <ItemType,>({
  title,
  required,
  getValueFromItem: idForItem,
  onSelectItem,
  labelForItem,
  disabled,
  items,
  selectedItem,
  customStyling
}: GenericSelectProps<ItemType>) => (
  <FormControl required={required} fullWidth>
    <InputLabel id={`${title}-select-label`} color={'secondary'}>
      {title}
    </InputLabel>
    <Select
      color={'secondary'}
      disabled={disabled}
      required={required}
      labelId={`${title}-select-label`}
      id={`${title}-select`}
      value={idForItem(selectedItem)}
      label={title}
      onChange={(e) => {
        onSelectItem(items.filter((i) => idForItem(i) === e.target.value)[0]);
      }}
    >
      {items.map((i) => (
        <MenuItem
          color={'secondary'}
          key={`${title}-select-item-${idForItem(i)}`}
          value={idForItem(i)}
          sx={customStyling ? customStyling(i) : {}}
        >
          {labelForItem(i)}
        </MenuItem>
      ))}
    </Select>
  </FormControl>
);
