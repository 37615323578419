import { SimpleCreateWorkshopTodoDialog } from '@/components/Dialogs/SimpleCreateWorkshopTodoDialog';
import { useVehicleTypes } from '@/hooks/Cache/useVehicleTypes';
import { useRepairTicket } from '@/hooks/Workshop/useRepairTicket';
import type { AvailableProblem, ProblemListTodo } from '@badgermoleV2/api';
import { Button, Grid, LinearProgress, Stack } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { WorkshopTicketCard } from './WorkshopTicketCard';
import { WorkshopTicketTodoList } from './WorkshopTicketTodoList';

export const WorkshopDetailScreen = () => {
  const [t] = useTranslation();
  const { jiraIssueKey } = useParams();
  const { repairTicket, problemList, createRepairTicketTodo, updateRepairTicket } =
    useRepairTicket(jiraIssueKey);

  const { vehicleTypes } = useVehicleTypes();

  const [createTodoDialogOpen, setCreateTodoDialogOpen] = useState(false);

  const createTodo = async (content: AvailableProblem, comment: string | undefined) => {
    if (!repairTicket) return;
    createRepairTicketTodo({ problem: content.name, status: 'Open', comment: comment ?? '' });
    setCreateTodoDialogOpen(false);
  };

  // TODO: can be refactored down into row components
  const changeTodoStatus = async (todo: ProblemListTodo, status: string): Promise<void> => {
    if (!repairTicket) return;
    updateRepairTicket({ ...todo, status: status as unknown as object });
  };

  const changeTodoComment = async (todo: ProblemListTodo, comment: string): Promise<void> => {
    if (!repairTicket) return;
    updateRepairTicket({ ...todo, comment });
  };

  if (!repairTicket) return <LinearProgress />;

  return (
    <Grid container>
      <Grid item sm={0} lg={3} />
      <Grid item sm={12} lg={6}>
        {createTodoDialogOpen && (
          <SimpleCreateWorkshopTodoDialog
            workshopTicket={repairTicket}
            onCreate={createTodo}
            onClose={() => {
              setCreateTodoDialogOpen(false);
            }}
            availableProblemList={problemList}
          />
        )}

        <Stack spacing={2}>
          <WorkshopTicketCard
            workshopTicket={repairTicket}
            vehicleTypes={vehicleTypes}
            iconSrc={`/${
              vehicleTypes
                .find((vt) => vt.vehicleTypeId === repairTicket.vehicle.vehicleTypeId)
                ?.model.split(' ')[0] ?? 'legacy'
            }.png`}
            showCompleteButton
          />

          <WorkshopTicketTodoList
            workshopTicket={repairTicket}
            changeTodoStatus={changeTodoStatus}
            changeTodoComment={changeTodoComment}
          />

          <Button
            sx={{ marginTop: '8px' }}
            variant={'outlined'}
            onClick={() => {
              setCreateTodoDialogOpen(!createTodoDialogOpen);
            }}
          >
            {t('external-workshop:repairTicket:createNew:newTodo')}
          </Button>
        </Stack>
      </Grid>
      <Grid item sm={0} lg={3} />
    </Grid>
  );
};
