import { useStore } from '@/store';
import type { OIDCToken, User } from '@badgermoleV2/api';
import { jwtDecode } from 'jwt-decode';
import { useCallback } from 'react';
import * as uuid from 'uuid';

export const useOidcAuthorization = () => {
  const [oidcCode, setOidcCode] = useStore((s) => [s.oidcCode, s.setOidcCode]);

  const createOidcCode = () => {
    if (!oidcCode) {
      const code = uuid.v4();
      setOidcCode(code);
      return code;
    }
    return oidcCode;
  };

  const validateOidcToken = useCallback(
    (oidcToken: OIDCToken, oidcCode: string | null): boolean => {
      if (!oidcCode) return false;
      const decoded = jwtDecode(oidcToken.accessToken);
      if (typeof decoded === 'object' && decoded !== null && 'nonce' in decoded) {
        return decoded.nonce === oidcCode;
      }
      return false;
    },
    []
  );

  type ResourceAccess = {
    'badgermole-staging': {
      roles: string[];
    };
  };

  type Payload = {
    preferred_username: string;
    email: string;
    resource_access: ResourceAccess;
  };

  // also check for ocxx group for debug purposes
  const getUserByToken = useCallback((oidcToken: OIDCToken): User | null => {
    const decoded = jwtDecode<Payload>(oidcToken.accessToken);
    if (typeof decoded !== 'object' || decoded === null) return null;

    return {
      email: decoded.email,
      username: decoded.preferred_username,
      connectedToJira: true,
      groups: [],
      token: 'deprecated'
    } satisfies User;
  }, []);

  return { validateOidcToken, createOidcCode, getUserByToken };
};
