import { QUERY_KEYS } from '@/config/QueryKeys';
import i18n from '@/utils/i18n';
import type { Query, QueryKey } from '@tanstack/react-query';
import { type AxiosError } from 'axios';
import * as Sentry from '@sentry/react';
import toast from 'react-hot-toast';
import { DateTimeUtils } from '@badgermoleV2/DateTimeUtils';

const errorCodes = [400, 401, 403, 404, 412, 500, 502, 504];

const getMessageByStatus = (code: number | undefined) => {
  if (code && errorCodes.includes(code)) return i18n.t(`error:${code}`);
  else return i18n.t('error:generic');
};

export const QueryErrorHandler = (
  error: Error,
  query: Query<unknown, unknown, unknown, QueryKey>
) => {
  const time = DateTimeUtils.currentDateWithSeconds();
  const axiosError = error as AxiosError;

  if (import.meta.env.PROD) {
    // Display dumb down error message for prod to not reveal backend messages to users
    const code =
      axiosError.response &&
      typeof axiosError.response.status === 'number' &&
      !isNaN(axiosError.response.status)
        ? axiosError.response.status
        : undefined;
    toast.error(`[${time}]\n${getMessageByStatus(code)}`);
    Sentry.captureException(axiosError);
    return;
  }

  // This works because we know that all our calls to badgermole go through axios,
  // so we can safely cast the error to AxiosError.
  // TODO: This can easily be reworked into a global error handler function, when backend is ready.
  if (query.queryKey.includes(QUERY_KEYS.oidc.getToken))
    return toast.error(`Session could not be verified. Please log in again`);
  const prefix =
    query.queryKey.length > 1
      ? `[${query.queryKey.at(0)},${query.queryKey.at(1)}]`
      : query.queryKey.at(0);
  const message =
    axiosError.response &&
    axiosError.response.data &&
    typeof axiosError.response.data === 'object' &&
    'message' in axiosError.response.data
      ? (axiosError.response.data.message as string)
      : axiosError.message;

  toast.error(`${prefix}: ${message}`);
};

export const MutationErrorHandler = (error: Error) => {
  const time = DateTimeUtils.formatToLocaleWithSeconds(new Date().toISOString());
  const axiosError = error as AxiosError;

  if (import.meta.env.PROD || import.meta.env.mode === 'production') {
    // Display dumb down error message for prod to not reveal backend messages to users
    const code =
      axiosError.response &&
      typeof axiosError.response.status === 'number' &&
      !isNaN(axiosError.response.status)
        ? axiosError.response.status
        : undefined;
    toast.error(`[${time}]\n${getMessageByStatus(code)}`);
    Sentry.captureException(error);
    return;
  }

  const message =
    axiosError.response &&
    axiosError.response.data &&
    typeof axiosError.response.data === 'object' &&
    'message' in axiosError.response.data
      ? (axiosError.response.data.message as string)
      : axiosError.message;
  toast.error(message);
};
