/* tslint:disable */
/* eslint-disable */
/**
 * Badgermole (staging)
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: abebc76f
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction
} from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * @type Action
 * @export
 */
export type Action = object;

/**
 *
 * @export
 * @interface ActionNotAllowed
 */
export interface ActionNotAllowed {
  /**
   *
   * @type {string}
   * @memberof ActionNotAllowed
   */
  msg: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ActionResult = {
  Success: 'Success',
  Error: 'Error',
  NothingToDo: 'NothingToDo'
} as const;

export type ActionResult = (typeof ActionResult)[keyof typeof ActionResult];

/**
 *
 * @export
 * @enum {string}
 */

export const AssignmentState = {
  Unassigned: 'Unassigned',
  Considered: 'Considered',
  InProgress: 'InProgress',
  PreStepsCompleted: 'PreStepsCompleted',
  AtStepsCompleted: 'AtStepsCompleted',
  PostStepsCompleted: 'PostStepsCompleted',
  Failed: 'Failed',
  Canceled: 'Canceled',
  Skipped: 'Skipped'
} as const;

export type AssignmentState = (typeof AssignmentState)[keyof typeof AssignmentState];

/**
 *
 * @export
 * @enum {string}
 */

export const AssignmentType = {
  Pickup: 'Pickup',
  Swap: 'Swap',
  Custom: 'Custom',
  DriveBack: 'DriveBack',
  Relocate: 'Relocate'
} as const;

export type AssignmentType = (typeof AssignmentType)[keyof typeof AssignmentType];

/**
 *
 * @export
 * @interface AvailableProblem
 */
export interface AvailableProblem {
  /**
   *
   * @type {string}
   * @memberof AvailableProblem
   */
  name: string;
}
/**
 *
 * @export
 * @interface Base64Image
 */
export interface Base64Image {
  /**
   *
   * @type {string}
   * @memberof Base64Image
   */
  imageType: Base64ImageImageTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Base64Image
   */
  base64EncodedImage: string;
}

export const Base64ImageImageTypeEnum = {
  Png: 'png',
  Jpeg: 'jpeg'
} as const;

export type Base64ImageImageTypeEnum =
  (typeof Base64ImageImageTypeEnum)[keyof typeof Base64ImageImageTypeEnum];

/**
 *
 * @export
 * @interface BaseTerritory
 */
export interface BaseTerritory {
  /**
   *
   * @type {number}
   * @memberof BaseTerritory
   */
  territoryId: number;
  /**
   *
   * @type {Array<Polygon>}
   * @memberof BaseTerritory
   */
  polygons?: Array<Polygon>;
  /**
   *
   * @type {string}
   * @memberof BaseTerritory
   */
  marker?: string;
}
/**
 *
 * @export
 * @interface BuildInformation
 */
export interface BuildInformation {
  /**
   *
   * @type {string}
   * @memberof BuildInformation
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof BuildInformation
   */
  environment: string;
  /**
   *
   * @type {string}
   * @memberof BuildInformation
   */
  version: string;
  /**
   *
   * @type {string}
   * @memberof BuildInformation
   */
  scalaVersion: string;
  /**
   *
   * @type {string}
   * @memberof BuildInformation
   */
  sbtVersion: string;
}
/**
 *
 * @export
 * @interface BulkCreateAssetsFileValidation
 */
export interface BulkCreateAssetsFileValidation {
  /**
   *
   * @type {HeaderValidation}
   * @memberof BulkCreateAssetsFileValidation
   */
  headerValidation: HeaderValidation;
  /**
   *
   * @type {Array<BulkCreateRow>}
   * @memberof BulkCreateAssetsFileValidation
   */
  assetsToBeCreated?: Array<BulkCreateRow>;
  /**
   *
   * @type {string}
   * @memberof BulkCreateAssetsFileValidation
   */
  error?: string;
}
/**
 *
 * @export
 * @interface BulkCreateRow
 */
export interface BulkCreateRow {
  /**
   *
   * @type {string}
   * @memberof BulkCreateRow
   */
  vin: string;
  /**
   *
   * @type {string}
   * @memberof BulkCreateRow
   */
  hardwareId: string;
  /**
   *
   * @type {string}
   * @memberof BulkCreateRow
   */
  deliveryDate: string;
  /**
   *
   * @type {string}
   * @memberof BulkCreateRow
   */
  endWarranty: string;
}
/**
 *
 * @export
 * @interface BulkCreateRowResult
 */
export interface BulkCreateRowResult {
  /**
   *
   * @type {string}
   * @memberof BulkCreateRowResult
   */
  vin: string;
  /**
   *
   * @type {string}
   * @memberof BulkCreateRowResult
   */
  hardwareId: string;
  /**
   *
   * @type {string}
   * @memberof BulkCreateRowResult
   */
  assetUrl?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof BulkCreateRowResult
   */
  errors?: Array<string>;
}
/**
 *
 * @export
 * @interface BulkCreateStatus
 */
export interface BulkCreateStatus {
  /**
   *
   * @type {string}
   * @memberof BulkCreateStatus
   */
  startedAt: string;
  /**
   *
   * @type {string}
   * @memberof BulkCreateStatus
   */
  startedBy: string;
  /**
   *
   * @type {BulkCreateStatusType}
   * @memberof BulkCreateStatus
   */
  status: BulkCreateStatusType;
  /**
   *
   * @type {number}
   * @memberof BulkCreateStatus
   */
  progress: number;
  /**
   *
   * @type {Array<BulkCreateRowResult>}
   * @memberof BulkCreateStatus
   */
  results?: Array<BulkCreateRowResult>;
}
/**
 * @type BulkCreateStatusType
 * @export
 */
export type BulkCreateStatusType = object;

/**
 *
 * @export
 * @interface BulkEditAssetsFileValidation
 */
export interface BulkEditAssetsFileValidation {
  /**
   *
   * @type {HeaderValidation}
   * @memberof BulkEditAssetsFileValidation
   */
  headerValidation: HeaderValidation;
  /**
   *
   * @type {Array<BulkEditRow>}
   * @memberof BulkEditAssetsFileValidation
   */
  vehiclesToBeEdited?: Array<BulkEditRow>;
  /**
   *
   * @type {string}
   * @memberof BulkEditAssetsFileValidation
   */
  error?: string;
}
/**
 *
 * @export
 * @interface BulkEditRow
 */
export interface BulkEditRow {
  /**
   *
   * @type {number}
   * @memberof BulkEditRow
   */
  vehicleId: number;
}
/**
 *
 * @export
 * @interface BulkEditRowResult
 */
export interface BulkEditRowResult {
  /**
   *
   * @type {number}
   * @memberof BulkEditRowResult
   */
  vehicleId: number;
  /**
   *
   * @type {Array<string>}
   * @memberof BulkEditRowResult
   */
  errors?: Array<string>;
}
/**
 *
 * @export
 * @interface BulkEditStartBody
 */
export interface BulkEditStartBody {
  /**
   *
   * @type {BulkEditAssetsFileValidation}
   * @memberof BulkEditStartBody
   */
  validation: BulkEditAssetsFileValidation;
  /**
   *
   * @type {string}
   * @memberof BulkEditStartBody
   */
  updateBodyJson: string;
}
/**
 *
 * @export
 * @interface BusinessLocation
 */
export interface BusinessLocation {
  /**
   *
   * @type {number}
   * @memberof BusinessLocation
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof BusinessLocation
   */
  label: string;
  /**
   *
   * @type {BusinessLocationCenter}
   * @memberof BusinessLocation
   */
  cityCenter: BusinessLocationCenter;
  /**
   *
   * @type {BaseTerritory}
   * @memberof BusinessLocation
   */
  businessTerritory: BaseTerritory;
  /**
   *
   * @type {Array<BaseTerritory>}
   * @memberof BusinessLocation
   */
  noParkingTerritories?: Array<BaseTerritory>;
  /**
   *
   * @type {Array<BaseTerritory>}
   * @memberof BusinessLocation
   */
  incentiveTerritories?: Array<BaseTerritory>;
  /**
   *
   * @type {Array<MobilityHub>}
   * @memberof BusinessLocation
   */
  mobilityHubs?: Array<MobilityHub>;
}
/**
 *
 * @export
 * @interface BusinessLocationCenter
 */
export interface BusinessLocationCenter {
  /**
   *
   * @type {number}
   * @memberof BusinessLocationCenter
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof BusinessLocationCenter
   */
  lon: number;
}
/**
 *
 * @export
 * @interface Comment
 */
export interface Comment {
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  authorUsername: string;
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof Comment
   */
  message: string;
}
/**
 *
 * @export
 * @interface CompiledTour
 */
export interface CompiledTour {
  /**
   *
   * @type {Tour}
   * @memberof CompiledTour
   */
  tour: Tour;
  /**
   *
   * @type {Array<Step>}
   * @memberof CompiledTour
   */
  compiledSteps?: Array<Step>;
}
/**
 *
 * @export
 * @interface CompleteChecksResponse
 */
export interface CompleteChecksResponse {
  /**
   *
   * @type {boolean}
   * @memberof CompleteChecksResponse
   */
  success: boolean;
  /**
   *
   * @type {Array<LocalizedDescription>}
   * @memberof CompleteChecksResponse
   */
  errors?: Array<LocalizedDescription>;
}
/**
 *
 * @export
 * @interface CompleteTourBody
 */
export interface CompleteTourBody {
  /**
   *
   * @type {number}
   * @memberof CompleteTourBody
   */
  serviceVehicleMileage: number;
}
/**
 *
 * @export
 * @interface ConditionSet
 */
export interface ConditionSet {
  /**
   *
   * @type {Array<number>}
   * @memberof ConditionSet
   */
  scooters?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof ConditionSet
   */
  scooterTypeIds?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof ConditionSet
   */
  locationIds?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof ConditionSet
   */
  availability?: number;
  /**
   *
   * @type {number}
   * @memberof ConditionSet
   */
  averageBatteryLevel?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof ConditionSet
   */
  geofencePolygon?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof ConditionSet
   */
  numScootersGeofenceThreshold?: number;
  /**
   *
   * @type {number}
   * @memberof ConditionSet
   */
  fuelPercentileRank?: number;
  /**
   *
   * @type {string}
   * @memberof ConditionSet
   */
  drawStrategy?: string;
}
/**
 *
 * @export
 * @interface Coordinate
 */
export interface Coordinate {
  /**
   *
   * @type {number}
   * @memberof Coordinate
   */
  index: number;
  /**
   *
   * @type {number}
   * @memberof Coordinate
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof Coordinate
   */
  lon: number;
}
/**
 *
 * @export
 * @interface CorridorPolygon
 */
export interface CorridorPolygon {
  /**
   *
   * @type {GpsPosition}
   * @memberof CorridorPolygon
   */
  topLeft: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof CorridorPolygon
   */
  topRight: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof CorridorPolygon
   */
  bottomLeft: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof CorridorPolygon
   */
  bottomRight: GpsPosition;
}
/**
 *
 * @export
 * @interface CreateRuleInstanceBody
 */
export interface CreateRuleInstanceBody {
  /**
   *
   * @type {number}
   * @memberof CreateRuleInstanceBody
   */
  ruleDefinitionId: number;
  /**
   *
   * @type {string}
   * @memberof CreateRuleInstanceBody
   */
  startTime: string;
  /**
   *
   * @type {string}
   * @memberof CreateRuleInstanceBody
   */
  endTime: string;
  /**
   *
   * @type {string}
   * @memberof CreateRuleInstanceBody
   */
  approvalPeriodEndTime?: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateRuleInstanceBody
   */
  enable: boolean;
  /**
   *
   * @type {ConditionSet}
   * @memberof CreateRuleInstanceBody
   */
  conditionFactSet: ConditionSet;
}
/**
 *
 * @export
 * @interface EndReservationBody
 */
export interface EndReservationBody {
  /**
   *
   * @type {number}
   * @memberof EndReservationBody
   */
  reservationId: number;
  /**
   *
   * @type {number}
   * @memberof EndReservationBody
   */
  carId: number;
  /**
   *
   * @type {number}
   * @memberof EndReservationBody
   */
  usageTime?: number;
  /**
   *
   * @type {number}
   * @memberof EndReservationBody
   */
  locationId: number;
}
/**
 *
 * @export
 * @interface EstimatedDrivingTime
 */
export interface EstimatedDrivingTime {
  /**
   *
   * @type {GpsPosition}
   * @memberof EstimatedDrivingTime
   */
  start: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof EstimatedDrivingTime
   */
  end: GpsPosition;
  /**
   *
   * @type {number}
   * @memberof EstimatedDrivingTime
   */
  estimatedDrivingSeconds: number;
}
/**
 *
 * @export
 * @interface EvaluationEvent
 */
export interface EvaluationEvent {
  /**
   *
   * @type {number}
   * @memberof EvaluationEvent
   */
  evaluationEventId: number;
  /**
   *
   * @type {string}
   * @memberof EvaluationEvent
   */
  timestamp: string;
  /**
   *
   * @type {number}
   * @memberof EvaluationEvent
   */
  ruleInstanceId: number;
  /**
   *
   * @type {boolean}
   * @memberof EvaluationEvent
   */
  conditionsApply: boolean;
  /**
   *
   * @type {Array<number>}
   * @memberof EvaluationEvent
   */
  scooters?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof EvaluationEvent
   */
  calculatedAvailability?: number;
  /**
   *
   * @type {number}
   * @memberof EvaluationEvent
   */
  calculatedAverageBatteryLevel?: number;
  /**
   *
   * @type {number}
   * @memberof EvaluationEvent
   */
  calculatedNumScootersInGeofence?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof EvaluationEvent
   */
  overruledByRuleDefinitionIds?: Array<number>;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ExperienceLevel = {
  Newbie: 'Newbie',
  Intermediate: 'Intermediate',
  Advanced: 'Advanced'
} as const;

export type ExperienceLevel = (typeof ExperienceLevel)[keyof typeof ExperienceLevel];

/**
 *
 * @export
 * @interface ExtendByOneBody
 */
export interface ExtendByOneBody {
  /**
   *
   * @type {number}
   * @memberof ExtendByOneBody
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof ExtendByOneBody
   */
  lon: number;
}
/**
 *
 * @export
 * @interface FailStepBody
 */
export interface FailStepBody {
  /**
   *
   * @type {TransitionAction}
   * @memberof FailStepBody
   */
  cancelAction: TransitionAction;
  /**
   *
   * @type {string}
   * @memberof FailStepBody
   */
  reason: string;
}

/**
 *
 * @export
 * @interface FilterSet
 */
export interface FilterSet {
  /**
   *
   * @type {number}
   * @memberof FilterSet
   */
  filterSetId?: number;
  /**
   *
   * @type {ServiceLocation}
   * @memberof FilterSet
   */
  serviceLocation: ServiceLocation;
  /**
   *
   * @type {ServiceHub}
   * @memberof FilterSet
   */
  startHub?: ServiceHub;
  /**
   *
   * @type {Array<number>}
   * @memberof FilterSet
   */
  vehicleTypes?: Array<number>;
  /**
   *
   * @type {Array<SteeringTile>}
   * @memberof FilterSet
   */
  mustGoTiles?: Array<SteeringTile>;
  /**
   *
   * @type {Array<SteeringTile>}
   * @memberof FilterSet
   */
  noGoTiles?: Array<SteeringTile>;
  /**
   *
   * @type {number}
   * @memberof FilterSet
   */
  requiredLevel?: number;
  /**
   *
   * @type {number}
   * @memberof FilterSet
   */
  optionalLevel?: number;
  /**
   *
   * @type {number}
   * @memberof FilterSet
   */
  distanceWeight?: number;
  /**
   *
   * @type {number}
   * @memberof FilterSet
   */
  densityWeight?: number;
}
/**
 *
 * @export
 * @interface FilterSetBody
 */
export interface FilterSetBody {
  /**
   *
   * @type {number}
   * @memberof FilterSetBody
   */
  filterSetId?: number;
  /**
   *
   * @type {number}
   * @memberof FilterSetBody
   */
  serviceLocationId: number;
  /**
   *
   * @type {number}
   * @memberof FilterSetBody
   */
  startHubId?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof FilterSetBody
   */
  vehicleTypes?: Array<number>;
  /**
   *
   * @type {Array<SteeringTileBody>}
   * @memberof FilterSetBody
   */
  mustGoTiles?: Array<SteeringTileBody>;
  /**
   *
   * @type { Array<SteeringTileBody>}
   * @memberof FilterSetBody
   */
  noGoTiles?: Array<SteeringTileBody>;
  /**
   *
   * @type {number}
   * @memberof FilterSetBody
   */
  requiredLevel?: number;
  /**
   *
   * @type {number}
   * @memberof FilterSetBody
   */
  optionalLevel?: number;
  /**
   *
   * @type {number}
   * @memberof FilterSetBody
   */
  distanceWeight?: number;
  /**
   *
   * @type {number}
   * @memberof FilterSetBody
   */
  densityWeight?: number;
}
/**
 *
 * @export
 * @interface Forbidden
 */
export interface Forbidden {
  /**
   *
   * @type {string}
   * @memberof Forbidden
   */
  message: string;
}
/**
 *
 * @export
 * @interface GenericTourEvent
 */
export interface GenericTourEvent {
  /**
   *
   * @type {number}
   * @memberof GenericTourEvent
   */
  tourId: number;
  /**
   *
   * @type {number}
   * @memberof GenericTourEvent
   */
  jobId?: number;
  /**
   *
   * @type {number}
   * @memberof GenericTourEvent
   */
  stepId?: number;
  /**
   *
   * @type {string}
   * @memberof GenericTourEvent
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof GenericTourEvent
   */
  eventTimestamp: string;
  /**
   *
   * @type {LocalizedDescription}
   * @memberof GenericTourEvent
   */
  description: LocalizedDescription;
  /**
   *
   * @type {GenericTourEventType}
   * @memberof GenericTourEvent
   */
  eventType: GenericTourEventType;
  /**
   *
   * @type {string}
   * @memberof GenericTourEvent
   */
  eventSubType: string;
  /**
   *
   * @type {ActionResult}
   * @memberof GenericTourEvent
   */
  eventOutcome: ActionResult;
  /**
   *
   * @type {string}
   * @memberof GenericTourEvent
   */
  eventError?: string;
  /**
   *
   * @type {string}
   * @memberof GenericTourEvent
   */
  eventPayload?: string;
}

/**
 *
 * @export
 * @enum {string}
 */

export const GenericTourEventType = {
  TourEvent: 'TourEvent',
  TransitionEvent: 'TransitionEvent',
  TriggerEvent: 'TriggerEvent'
} as const;

export type GenericTourEventType = (typeof GenericTourEventType)[keyof typeof GenericTourEventType];

/**
 *
 * @export
 * @interface GpsPosition
 */
export interface GpsPosition {
  /**
   *
   * @type {number}
   * @memberof GpsPosition
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof GpsPosition
   */
  lon: number;
}
/**
 *
 * @export
 * @interface GpsPosition1
 */
export interface GpsPosition1 {
  /**
   *
   * @type {number}
   * @memberof GpsPosition1
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof GpsPosition1
   */
  lon: number;
}
/**
 *
 * @export
 * @interface Grid
 */
export interface Grid {
  /**
   *
   * @type {number}
   * @memberof Grid
   */
  locationId: number;
  /**
   *
   * @type {string}
   * @memberof Grid
   */
  createdAt: string;
  /**
   *
   * @type {Array<Tile>}
   * @memberof Grid
   */
  tiles?: Array<Tile>;
  /**
   *
   * @type {Array<Line>}
   * @memberof Grid
   */
  lines?: Array<Line>;
}
/**
 *
 * @export
 * @interface HeaderValidation
 */
export interface HeaderValidation {
  /**
   *
   * @type {boolean}
   * @memberof HeaderValidation
   */
  validated: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof HeaderValidation
   */
  expectedHeaders?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof HeaderValidation
   */
  foundHeaders?: Array<string>;
}
/**
 *
 * @export
 * @interface Health
 */
export interface Health {
  /**
   *
   * @type {boolean}
   * @memberof Health
   */
  iAmHealthy: boolean;
  /**
   *
   * @type {BuildInformation}
   * @memberof Health
   */
  buildInfo: BuildInformation;
}
/**
 *
 * @export
 * @interface HelmetBox
 */
export interface HelmetBox {
  /**
   *
   * @type {boolean}
   * @memberof HelmetBox
   */
  locked?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof HelmetBox
   */
  closed?: boolean;
}
/**
 *
 * @export
 * @interface InputComment
 */
export interface InputComment {
  /**
   *
   * @type {string}
   * @memberof InputComment
   */
  message: string;
}
/**
 *
 * @export
 * @interface InputServiceAsset
 */
export interface InputServiceAsset {
  /**
   *
   * @type {string}
   * @memberof InputServiceAsset
   */
  vin: string;
  /**
   *
   * @type {number}
   * @memberof InputServiceAsset
   */
  businessLocationId: number;
  /**
   *
   * @type {boolean}
   * @memberof InputServiceAsset
   */
  isDeleted: boolean;
}
/**
 *
 * @export
 * @interface InputServiceTask
 */
export interface InputServiceTask {
  /**
   *
   * @type {LocalizedDescription}
   * @memberof InputServiceTask
   */
  title: LocalizedDescription;
  /**
   *
   * @type {AssignmentType}
   * @memberof InputServiceTask
   */
  taskType: AssignmentType;
  /**
   *
   * @type {AssignmentState}
   * @memberof InputServiceTask
   */
  state: AssignmentState;
  /**
   *
   * @type {number}
   * @memberof InputServiceTask
   */
  priority?: number;
  /**
   *
   * @type {string}
   * @memberof InputServiceTask
   */
  failReason?: string;
  /**
   *
   * @type {string}
   * @memberof InputServiceTask
   */
  cancelReason?: string;
  /**
   *
   * @type {number}
   * @memberof InputServiceTask
   */
  businessLocationId: number;
  /**
   *
   * @type {string}
   * @memberof InputServiceTask
   */
  serviceAssetVin: string;
}

/**
 *
 * @export
 * @interface JiraBulkCreateSubtaskBody
 */
export interface JiraBulkCreateSubtaskBody {
  /**
   *
   * @type {Array<string>}
   * @memberof JiraBulkCreateSubtaskBody
   */
  vins?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof JiraBulkCreateSubtaskBody
   */
  subtaskDescription: string;
  /**
   *
   * @type {string}
   * @memberof JiraBulkCreateSubtaskBody
   */
  confirmationMailTo: string;
}
/**
 *
 * @export
 * @interface JiraIssue
 */
export interface JiraIssue {
  /**
   *
   * @type {number}
   * @memberof JiraIssue
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  summary: string;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  issueType: string;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  statusName: string;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  resolution: string;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  issueKey: string;
  /**
   *
   * @type {number}
   * @memberof JiraIssue
   */
  priority?: number;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  vin: string;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  updated?: string;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  workshop?: string;
  /**
   *
   * @type {string}
   * @memberof JiraIssue
   */
  reasonForRepair?: string;
}
/**
 *
 * @export
 * @interface JiraLoginBody
 */
export interface JiraLoginBody {
  /**
   *
   * @type {string}
   * @memberof JiraLoginBody
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof JiraLoginBody
   */
  password: string;
}
/**
 * @type JiraPriorities
 * @export
 */
export type JiraPriorities = object;

/**
 *
 * @export
 * @interface JiraPriorityBody
 */
export interface JiraPriorityBody {
  /**
   *
   * @type {JiraPriorities}
   * @memberof JiraPriorityBody
   */
  priority: JiraPriorities;
}
/**
 *
 * @export
 * @interface Job
 */
export interface Job {
  /**
   *
   * @type {number}
   * @memberof Job
   */
  jobId?: number;
  /**
   *
   * @type {LocalizedDescription}
   * @memberof Job
   */
  summary: LocalizedDescription;
  /**
   *
   * @type {AssignmentType}
   * @memberof Job
   */
  jobType: AssignmentType;
  /**
   *
   * @type {AssignmentState}
   * @memberof Job
   */
  state: AssignmentState;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  inheritedState?: string;
  /**
   *
   * @type {Array<Step>}
   * @memberof Job
   */
  preSteps?: Array<Step>;
  /**
   *
   * @type {Array<Step>}
   * @memberof Job
   */
  atSteps?: Array<Step>;
  /**
   *
   * @type {Array<Step>}
   * @memberof Job
   */
  postSteps?: Array<Step>;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  lastUpdatedAt?: string;
  /**
   *
   * @type {number}
   * @memberof Job
   */
  scooterId?: number;
  /**
   *
   * @type {GpsPosition}
   * @memberof Job
   */
  location?: GpsPosition;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  jiraIssueKey?: string;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  jiraIssueLink?: string;
  /**
   *
   * @type {number}
   * @memberof Job
   */
  createdByServiceTask?: number;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  failReason?: string;
  /**
   *
   * @type {string}
   * @memberof Job
   */
  cancelReason?: string;
  /**
   *
   * @type {number}
   * @memberof Job
   */
  positionInCompiledList?: number;
  /**
   *
   * @type {number}
   * @memberof Job
   */
  priority?: number;
  /**
   *
   * @type {ServiceSourceType}
   * @memberof Job
   */
  serviceSourceType?: ServiceSourceType;
  /**
   *
   * @type {number}
   * @memberof Job
   */
  replacedByJobId?: number;
  /**
   *
   * @type {number}
   * @memberof Job
   */
  preEnergyLevel?: number;
  /**
   *
   * @type {number}
   * @memberof Job
   */
  postEnergyLevel?: number;
}

/**
 *
 * @export
 * @interface Line
 */
export interface Line {
  /**
   *
   * @type {GpsPosition}
   * @memberof Line
   */
  left: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof Line
   */
  right: GpsPosition;
}
/**
 *
 * @export
 * @interface LiveData
 */
export interface LiveData {
  /**
   *
   * @type {number}
   * @memberof LiveData
   */
  battery_level: number;
  /**
   *
   * @type {number}
   * @memberof LiveData
   */
  board_voltage: number;
  /**
   *
   * @type {number}
   * @memberof LiveData
   */
  mileage: number;
  /**
   *
   * @type {boolean}
   * @memberof LiveData
   */
  locked: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LiveData
   */
  ignition_off: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LiveData
   */
  immobilized: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LiveData
   */
  centerstand_out?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LiveData
   */
  keyfob_in?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LiveData
   */
  saddle_closed?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof LiveData
   */
  alarm_active?: boolean;
  /**
   *
   * @type {HelmetBox}
   * @memberof LiveData
   */
  helmetBox?: HelmetBox;
  /**
   *
   * @type {TelematicsPosition}
   * @memberof LiveData
   */
  location?: TelematicsPosition;
}
/**
 *
 * @export
 * @interface LocalizedDescription
 */
export interface LocalizedDescription {
  /**
   *
   * @type {string}
   * @memberof LocalizedDescription
   */
  de: string;
  /**
   *
   * @type {string}
   * @memberof LocalizedDescription
   */
  en: string;
}
/**
 * @type LogTaskChangeType
 * @export
 */
export type LogTaskChangeType = object;

/**
 *
 * @export
 * @interface MismatchingJiraToken
 */
export interface MismatchingJiraToken {
  /**
   *
   * @type {string}
   * @memberof MismatchingJiraToken
   */
  message: string;
  /**
   *
   * @type {string}
   * @memberof MismatchingJiraToken
   */
  code: string;
}
/**
 *
 * @export
 * @interface Mission
 */
export interface Mission {
  /**
   *
   * @type {ServiceEmployee}
   * @memberof Mission
   */
  createdBy: ServiceEmployee;
  /**
   *
   * @type {string}
   * @memberof Mission
   */
  createdAt: string;
  /**
   *
   * @type {MissionType}
   * @memberof Mission
   */
  missionType: MissionType;
  /**
   *
   * @type {number}
   * @memberof Mission
   */
  priority: number;
  /**
   *
   * @type {Timespan}
   * @memberof Mission
   */
  validTimespan: Timespan;
  /**
   *
   * @type {UserTargetGroup}
   * @memberof Mission
   */
  offeredTo: UserTargetGroup;
  /**
   *
   * @type {Array<Job>}
   * @memberof Mission
   */
  jobs?: Array<Job>;
  /**
   *
   * @type {FilterSet}
   * @memberof Mission
   */
  filterSet?: FilterSet;
  /**
   *
   * @type {number}
   * @memberof Mission
   */
  maxNumberOfTours: number;
  /**
   *
   * @type {boolean}
   * @memberof Mission
   */
  enabled: boolean;
  /**
   *
   * @type {string}
   * @memberof Mission
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof Mission
   */
  missionId?: number;
  /**
   *
   * @type {string}
   * @memberof Mission
   */
  deletedAt?: string;
}

/**
 *
 * @export
 * @interface MissionBody
 */
export interface MissionBody {
  /**
   *
   * @type {number}
   * @memberof MissionBody
   */
  missionId?: number;
  /**
   *
   * @type {MissionType}
   * @memberof MissionBody
   */
  missionType: MissionType;
  /**
   *
   * @type {number}
   * @memberof MissionBody
   */
  priority: number;
  /**
   *
   * @type {UserTargetGroupBody}
   * @memberof MissionBody
   */
  offerTo: UserTargetGroupBody;
  /**
   *
   * @type {Array<Job>}
   * @memberof MissionBody
   */
  jobs?: Array<Job>;
  /**
   *
   * @type {FilterSetBody}
   * @memberof MissionBody
   */
  filterSet?: FilterSetBody;
  /**
   *
   * @type {Timespan}
   * @memberof MissionBody
   */
  validTimespan: Timespan;
  /**
   *
   * @type {number}
   * @memberof MissionBody
   */
  maxNumberOfTours: number;
  /**
   *
   * @type {boolean}
   * @memberof MissionBody
   */
  enabled: boolean;
  /**
   *
   * @type {string}
   * @memberof MissionBody
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof MissionBody
   */
  deletedAt?: string;
}

/**
 *
 * @export
 * @interface MissionOverview
 */
export interface MissionOverview {
  /**
   *
   * @type {Array<MissionWithTourStats>}
   * @memberof MissionOverview
   */
  missions?: Array<MissionWithTourStats>;
  /**
   *
   * @type {number}
   * @memberof MissionOverview
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof MissionOverview
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof MissionOverview
   */
  totalPages: number;
  /**
   *
   * @type {number}
   * @memberof MissionOverview
   */
  totalMissions: number;
}
/**
 *
 * @export
 * @interface MissionTourStats
 */
export interface MissionTourStats {
  /**
   *
   * @type {number}
   * @memberof MissionTourStats
   */
  missionId: number;
  /**
   *
   * @type {number}
   * @memberof MissionTourStats
   */
  totalNumberOfTours: number;
  /**
   *
   * @type {number}
   * @memberof MissionTourStats
   */
  totalNumberOfCompletedTours: number;
  /**
   *
   * @type {number}
   * @memberof MissionTourStats
   */
  totalNumberOfFailedTours: number;
  /**
   *
   * @type {number}
   * @memberof MissionTourStats
   */
  totalNumberOfOpenTours: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export const MissionType = {
  Pickup: 'Pickup',
  Swap: 'Swap',
  Custom: 'Custom',
  Relocate: 'Relocate'
} as const;

export type MissionType = (typeof MissionType)[keyof typeof MissionType];

/**
 *
 * @export
 * @interface MissionWithTourStats
 */
export interface MissionWithTourStats {
  /**
   *
   * @type {Mission}
   * @memberof MissionWithTourStats
   */
  mission: Mission;
  /**
   *
   * @type {MissionTourStats}
   * @memberof MissionWithTourStats
   */
  missionTourStats: MissionTourStats;
}
/**
 *
 * @export
 * @interface MobilityHub
 */
export interface MobilityHub {
  /**
   *
   * @type {string}
   * @memberof MobilityHub
   */
  provider: string;
  /**
   *
   * @type {number}
   * @memberof MobilityHub
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof MobilityHub
   */
  lon: number;
}
/**
 *
 * @export
 * @interface NoJobsAvailable
 */
export interface NoJobsAvailable {
  /**
   *
   * @type {string}
   * @memberof NoJobsAvailable
   */
  message: string;
}
/**
 *
 * @export
 * @interface NotFound
 */
export interface NotFound {
  /**
   *
   * @type {string}
   * @memberof NotFound
   */
  message: string;
}
/**
 *
 * @export
 * @interface OIDCToken
 */
export interface OIDCToken {
  /**
   *
   * @type {string}
   * @memberof OIDCToken
   */
  accessToken: string;
  /**
   *
   * @type {string}
   * @memberof OIDCToken
   */
  refreshToken: string;
  /**
   *
   * @type {string}
   * @memberof OIDCToken
   */
  idToken: string;
  /**
   *
   * @type {string}
   * @memberof OIDCToken
   */
  tokenType: string;
  /**
   *
   * @type {string}
   * @memberof OIDCToken
   */
  scope: string;
  /**
   *
   * @type {string}
   * @memberof OIDCToken
   */
  sessionState: string;
  /**
   *
   * @type {number}
   * @memberof OIDCToken
   */
  expiresIn: number;
  /**
   *
   * @type {number}
   * @memberof OIDCToken
   */
  refreshExpiresIn: number;
  /**
   *
   * @type {number}
   * @memberof OIDCToken
   */
  notBeforePolicy: number;
}
/**
 *
 * @export
 * @interface OpenTourFound
 */
export interface OpenTourFound {
  /**
   *
   * @type {string}
   * @memberof OpenTourFound
   */
  msg: string;
}
/**
 *
 * @export
 * @interface PaginatedParentResultTourMission
 */
export interface PaginatedParentResultTourMission {
  /**
   *
   * @type {Array<Tour>}
   * @memberof PaginatedParentResultTourMission
   */
  items?: Array<Tour>;
  /**
   *
   * @type {Array<Mission>}
   * @memberof PaginatedParentResultTourMission
   */
  parents?: Array<Mission>;
  /**
   *
   * @type {number}
   * @memberof PaginatedParentResultTourMission
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedParentResultTourMission
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedParentResultTourMission
   */
  totalItems: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedParentResultTourMission
   */
  totalPages: number;
}
/**
 *
 * @export
 * @interface PaginatedResultPersistedVehicleReport
 */
export interface PaginatedResultPersistedVehicleReport {
  /**
   *
   * @type {Array<PersistedVehicleReport>}
   * @memberof PaginatedResultPersistedVehicleReport
   */
  items?: Array<PersistedVehicleReport>;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultPersistedVehicleReport
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultPersistedVehicleReport
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultPersistedVehicleReport
   */
  totalItems: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultPersistedVehicleReport
   */
  totalPages: number;
}
/**
 *
 * @export
 * @interface PaginatedResultTour
 */
export interface PaginatedResultTour {
  /**
   *
   * @type {Array<Tour>}
   * @memberof PaginatedResultTour
   */
  items?: Array<Tour>;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultTour
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultTour
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultTour
   */
  totalItems: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultTour
   */
  totalPages: number;
}
/**
 *
 * @export
 * @interface PaginatedResultWorkshopEvent
 */
export interface PaginatedResultWorkshopEvent {
  /**
   *
   * @type {Array<WorkshopEvent>}
   * @memberof PaginatedResultWorkshopEvent
   */
  items?: Array<WorkshopEvent>;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultWorkshopEvent
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultWorkshopEvent
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultWorkshopEvent
   */
  totalItems: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedResultWorkshopEvent
   */
  totalPages: number;
}
/**
 *
 * @export
 * @interface PaginatedTourFilterBody
 */
export interface PaginatedTourFilterBody {
  /**
   *
   * @type {number}
   * @memberof PaginatedTourFilterBody
   */
  locationId: number;
  /**
   *
   * @type {MissionType}
   * @memberof PaginatedTourFilterBody
   */
  tourType: MissionType;
  /**
   *
   * @type {boolean}
   * @memberof PaginatedTourFilterBody
   */
  includeFinished: boolean;
  /**
   *
   * @type {number}
   * @memberof PaginatedTourFilterBody
   */
  page: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedTourFilterBody
   */
  pageSize: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedTourFilterBody
   */
  tourId?: number;
  /**
   *
   * @type {number}
   * @memberof PaginatedTourFilterBody
   */
  batteryLevel?: number;
  /**
   *
   * @type {string}
   * @memberof PaginatedTourFilterBody
   */
  assignee?: string;
  /**
   *
   * @type {string}
   * @memberof PaginatedTourFilterBody
   */
  vehicleLicencePlate?: string;
  /**
   *
   * @type {string}
   * @memberof PaginatedTourFilterBody
   */
  fromDate?: string;
  /**
   *
   * @type {string}
   * @memberof PaginatedTourFilterBody
   */
  toDate?: string;
  /**
   *
   * @type {string}
   * @memberof PaginatedTourFilterBody
   */
  serviceVehicleLicencePlate?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof PaginatedTourFilterBody
   */
  vehicleTypeIds?: Array<number>;
  /**
   *
   * @type {number}
   * @memberof PaginatedTourFilterBody
   */
  missionId?: number;
}

/**
 *
 * @export
 * @interface PersistedVehicleReport
 */
export interface PersistedVehicleReport {
  /**
   *
   * @type {number}
   * @memberof PersistedVehicleReport
   */
  vehicleReportId: number;
  /**
   *
   * @type {string}
   * @memberof PersistedVehicleReport
   */
  reporter: string;
  /**
   *
   * @type {string}
   * @memberof PersistedVehicleReport
   */
  vin?: string;
  /**
   *
   * @type {string}
   * @memberof PersistedVehicleReport
   */
  licencePlate?: string;
  /**
   *
   * @type {number}
   * @memberof PersistedVehicleReport
   */
  vehicleId?: number;
  /**
   *
   * @type {string}
   * @memberof PersistedVehicleReport
   */
  reportedTime: string;
  /**
   *
   * @type {string}
   * @memberof PersistedVehicleReport
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof PersistedVehicleReport
   */
  resolvedBy?: string;
  /**
   *
   * @type {string}
   * @memberof PersistedVehicleReport
   */
  resolvedAt?: string;
  /**
   *
   * @type {string}
   * @memberof PersistedVehicleReport
   */
  resolvedThrough?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof PersistedVehicleReport
   */
  incidents?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PersistedVehicleReport
   */
  imageUrls?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof PersistedVehicleReport
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof PersistedVehicleReport
   */
  lon: number;
  /**
   *
   * @type {number}
   * @memberof PersistedVehicleReport
   */
  locationId: number;
  /**
   *
   * @type {number}
   * @memberof PersistedVehicleReport
   */
  reportedDuringTourId?: number;
}
/**
 *
 * @export
 * @interface Polygon
 */
export interface Polygon {
  /**
   *
   * @type {number}
   * @memberof Polygon
   */
  polygonId: number;
  /**
   *
   * @type {boolean}
   * @memberof Polygon
   */
  isCutout: boolean;
  /**
   *
   * @type {Array<Coordinate>}
   * @memberof Polygon
   */
  coordinates?: Array<Coordinate>;
}
/**
 *
 * @export
 * @interface PreconditionCheckResult
 */
export interface PreconditionCheckResult {
  /**
   *
   * @type {boolean}
   * @memberof PreconditionCheckResult
   */
  success: boolean;
  /**
   *
   * @type {Array<string>}
   * @memberof PreconditionCheckResult
   */
  reasons?: Array<string>;
  /**
   *
   * @type {Array<string>}
   * @memberof PreconditionCheckResult
   */
  issuesToBeCanceled?: Array<string>;
}
/**
 *
 * @export
 * @interface PreconditionFailed
 */
export interface PreconditionFailed {
  /**
   *
   * @type {string}
   * @memberof PreconditionFailed
   */
  message: string;
}
/**
 *
 * @export
 * @interface PriceChangeEvent
 */
export interface PriceChangeEvent {
  /**
   *
   * @type {string}
   * @memberof PriceChangeEvent
   */
  pricingLevel: string;
  /**
   *
   * @type {string}
   * @memberof PriceChangeEvent
   */
  priceChangeTime: string;
  /**
   *
   * @type {number}
   * @memberof PriceChangeEvent
   */
  triggeredByRuleInstanceId?: number;
  /**
   *
   * @type {string}
   * @memberof PriceChangeEvent
   */
  triggerReason?: string;
  /**
   *
   * @type {Array<number>}
   * @memberof PriceChangeEvent
   */
  requestedScooters?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof PriceChangeEvent
   */
  affectedScooters?: Array<number>;
  /**
   *
   * @type {Array<number>}
   * @memberof PriceChangeEvent
   */
  failedScooters?: Array<number>;
}
/**
 *
 * @export
 * @interface PricingLevelMapEntry
 */
export interface PricingLevelMapEntry {
  /**
   *
   * @type {string}
   * @memberof PricingLevelMapEntry
   */
  model: PricingLevelMapEntryModelEnum;
  /**
   *
   * @type {string}
   * @memberof PricingLevelMapEntry
   */
  pricingLevel: PricingLevelMapEntryPricingLevelEnum;
  /**
   *
   * @type {number}
   * @memberof PricingLevelMapEntry
   */
  vehicleTypeId: number;
}

export const PricingLevelMapEntryModelEnum = {
  YadeaG5LHamburg: 'Yadea G5L Hamburg',
  YadeaG5LMnchen: 'Yadea G5L München',
  NiuN1SpBerlin: 'NIU N1SP Berlin',
  NiuN1SpGoTo: 'NIU N1SP GoTo',
  NiuN1SpGreen: 'NIU N1SP Green',
  Unknown: 'Unknown'
} as const;

export type PricingLevelMapEntryModelEnum =
  (typeof PricingLevelMapEntryModelEnum)[keyof typeof PricingLevelMapEntryModelEnum];
export const PricingLevelMapEntryPricingLevelEnum = {
  Default: 'default',
  Discounted: 'discounted',
  Overpriced: 'overpriced',
  Special: 'special',
  Unknown: 'unknown'
} as const;

export type PricingLevelMapEntryPricingLevelEnum =
  (typeof PricingLevelMapEntryPricingLevelEnum)[keyof typeof PricingLevelMapEntryPricingLevelEnum];

/**
 *
 * @export
 * @interface ProblemListTodo
 */
export interface ProblemListTodo {
  /**
   *
   * @type {number}
   * @memberof ProblemListTodo
   */
  todoId: number;
  /**
   *
   * @type {string}
   * @memberof ProblemListTodo
   */
  content: string;
  /**
   *
   * @type {TodoStatusName}
   * @memberof ProblemListTodo
   */
  status: TodoStatusName;
  /**
   *
   * @type {string}
   * @memberof ProblemListTodo
   */
  comment?: string;
}
/**
 *
 * @export
 * @interface ProblemListTodoBody
 */
export interface ProblemListTodoBody {
  /**
   *
   * @type {TodoStatusName}
   * @memberof ProblemListTodoBody
   */
  status: TodoStatusName;
  /**
   *
   * @type {AvailableProblem}
   * @memberof ProblemListTodoBody
   */
  problem: AvailableProblem;
  /**
   *
   * @type {string}
   * @memberof ProblemListTodoBody
   */
  comment?: string;
}
/**
 *
 * @export
 * @interface ProblemListTodoResolveBody
 */
export interface ProblemListTodoResolveBody {
  /**
   *
   * @type {number}
   * @memberof ProblemListTodoResolveBody
   */
  todoId: number;
  /**
   *
   * @type {TodoStatusName}
   * @memberof ProblemListTodoResolveBody
   */
  status: TodoStatusName;
  /**
   *
   * @type {string}
   * @memberof ProblemListTodoResolveBody
   */
  comment?: string;
}
/**
 *
 * @export
 * @interface Project
 */
export interface Project {
  /**
   *
   * @type {string}
   * @memberof Project
   */
  key: string;
  /**
   *
   * @type {string}
   * @memberof Project
   */
  name: string;
}
/**
 *
 * @export
 * @interface ReasonBody
 */
export interface ReasonBody {
  /**
   *
   * @type {string}
   * @memberof ReasonBody
   */
  reason: string;
}
/**
 *
 * @export
 * @interface RepairTicketFailBody
 */
export interface RepairTicketFailBody {
  /**
   *
   * @type {string}
   * @memberof RepairTicketFailBody
   */
  reason: string;
}
/**
 *
 * @export
 * @interface RequestBlocked
 */
export interface RequestBlocked {
  /**
   *
   * @type {string}
   * @memberof RequestBlocked
   */
  message: string;
}
/**
 *
 * @export
 * @interface RouteOptimizationActivity
 */
export interface RouteOptimizationActivity {
  /**
   *
   * @type {string}
   * @memberof RouteOptimizationActivity
   */
  type: string;
  /**
   *
   * @type {string}
   * @memberof RouteOptimizationActivity
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof RouteOptimizationActivity
   */
  location_id: string;
  /**
   *
   * @type {RouteOptimizationAddress}
   * @memberof RouteOptimizationActivity
   */
  address: RouteOptimizationAddress;
  /**
   *
   * @type {number}
   * @memberof RouteOptimizationActivity
   */
  distance: number;
  /**
   *
   * @type {number}
   * @memberof RouteOptimizationActivity
   */
  driving_time: number;
}
/**
 *
 * @export
 * @interface RouteOptimizationAddress
 */
export interface RouteOptimizationAddress {
  /**
   *
   * @type {string}
   * @memberof RouteOptimizationAddress
   */
  location_id: string;
  /**
   *
   * @type {number}
   * @memberof RouteOptimizationAddress
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof RouteOptimizationAddress
   */
  lon: number;
}
/**
 *
 * @export
 * @interface RouteOptimizationPoint
 */
export interface RouteOptimizationPoint {
  /**
   *
   * @type {string}
   * @memberof RouteOptimizationPoint
   */
  type: string;
  /**
   *
   * @type {Array<Array<number>>}
   * @memberof RouteOptimizationPoint
   */
  coordinates?: Array<Array<number>>;
}
/**
 *
 * @export
 * @interface RouteOptimizationResult
 */
export interface RouteOptimizationResult {
  /**
   *
   * @type {RouteOptimizationSolution}
   * @memberof RouteOptimizationResult
   */
  solution: RouteOptimizationSolution;
}
/**
 *
 * @export
 * @interface RouteOptimizationRoute
 */
export interface RouteOptimizationRoute {
  /**
   *
   * @type {Array<RouteOptimizationActivity>}
   * @memberof RouteOptimizationRoute
   */
  activities?: Array<RouteOptimizationActivity>;
  /**
   *
   * @type {Array<RouteOptimizationPoint>}
   * @memberof RouteOptimizationRoute
   */
  points?: Array<RouteOptimizationPoint>;
}
/**
 *
 * @export
 * @interface RouteOptimizationSolution
 */
export interface RouteOptimizationSolution {
  /**
   *
   * @type {Array<RouteOptimizationRoute>}
   * @memberof RouteOptimizationSolution
   */
  routes?: Array<RouteOptimizationRoute>;
}
/**
 *
 * @export
 * @interface RuleDefinition
 */
export interface RuleDefinition {
  /**
   *
   * @type {number}
   * @memberof RuleDefinition
   */
  ruleDefinitionId: number;
  /**
   *
   * @type {string}
   * @memberof RuleDefinition
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof RuleDefinition
   */
  pricingLevel: string;
  /**
   *
   * @type {string}
   * @memberof RuleDefinition
   */
  recurrence: string;
  /**
   *
   * @type {number}
   * @memberof RuleDefinition
   */
  defaultDuration: number;
  /**
   *
   * @type {number}
   * @memberof RuleDefinition
   */
  evaluationInterval: number;
  /**
   *
   * @type {number}
   * @memberof RuleDefinition
   */
  cooldownPeriod: number;
  /**
   *
   * @type {number}
   * @memberof RuleDefinition
   */
  priority: number;
  /**
   *
   * @type {number}
   * @memberof RuleDefinition
   */
  locationId: number;
}
/**
 *
 * @export
 * @interface RuleDefinitionWithInstances
 */
export interface RuleDefinitionWithInstances {
  /**
   *
   * @type {RuleDefinition}
   * @memberof RuleDefinitionWithInstances
   */
  ruleDefinition: RuleDefinition;
  /**
   *
   * @type {Array<RuleInstanceWithConditionSet>}
   * @memberof RuleDefinitionWithInstances
   */
  runningInstances?: Array<RuleInstanceWithConditionSet>;
}
/**
 *
 * @export
 * @interface RuleInstance
 */
export interface RuleInstance {
  /**
   *
   * @type {number}
   * @memberof RuleInstance
   */
  ruleInstanceId: number;
  /**
   *
   * @type {number}
   * @memberof RuleInstance
   */
  ruleDefinitionId: number;
  /**
   *
   * @type {number}
   * @memberof RuleInstance
   */
  conditionFactSetId: number;
  /**
   *
   * @type {string}
   * @memberof RuleInstance
   */
  startTime: string;
  /**
   *
   * @type {string}
   * @memberof RuleInstance
   */
  endTime: string;
  /**
   *
   * @type {string}
   * @memberof RuleInstance
   */
  approvalPeriodEndTime?: string;
  /**
   *
   * @type {boolean}
   * @memberof RuleInstance
   */
  isEnabled: boolean;
  /**
   *
   * @type {boolean}
   * @memberof RuleInstance
   */
  running: boolean;
  /**
   *
   * @type {string}
   * @memberof RuleInstance
   */
  createdBy: string;
  /**
   *
   * @type {string}
   * @memberof RuleInstance
   */
  approvedBy?: string;
  /**
   *
   * @type {string}
   * @memberof RuleInstance
   */
  pausedBy?: string;
  /**
   *
   * @type {string}
   * @memberof RuleInstance
   */
  pausedAt?: string;
  /**
   *
   * @type {string}
   * @memberof RuleInstance
   */
  terminatedBy?: string;
  /**
   *
   * @type {string}
   * @memberof RuleInstance
   */
  terminatedAt?: string;
}
/**
 *
 * @export
 * @interface RuleInstanceWithConditionSet
 */
export interface RuleInstanceWithConditionSet {
  /**
   *
   * @type {RuleInstance}
   * @memberof RuleInstanceWithConditionSet
   */
  ruleInstance: RuleInstance;
  /**
   *
   * @type {ConditionSet}
   * @memberof RuleInstanceWithConditionSet
   */
  conditionSet: ConditionSet;
}
/**
 *
 * @export
 * @interface ServiceAsset
 */
export interface ServiceAsset {
  /**
   *
   * @type {string}
   * @memberof ServiceAsset
   */
  vin: string;
  /**
   *
   * @type {ServiceLocation}
   * @memberof ServiceAsset
   */
  businessLocation: ServiceLocation;
  /**
   *
   * @type {string}
   * @memberof ServiceAsset
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof ServiceAsset
   */
  updatedAt: string;
  /**
   *
   * @type {boolean}
   * @memberof ServiceAsset
   */
  isDeleted: boolean;
}
/**
 *
 * @export
 * @interface ServiceAssetFilters
 */
export interface ServiceAssetFilters {
  /**
   *
   * @type {number}
   * @memberof ServiceAssetFilters
   */
  businessLocationId: number;
  /**
   *
   * @type {Array<number>}
   * @memberof ServiceAssetFilters
   */
  assetStateIds?: Array<number>;
  /**
   *
   * @type {boolean}
   * @memberof ServiceAssetFilters
   */
  ignoreAssetsWithOpenTasks?: boolean;
  /**
   *
   * @type {string}
   * @memberof ServiceAssetFilters
   */
  licencePlate?: string;
}
/**
 *
 * @export
 * @interface ServiceAssetInfo
 */
export interface ServiceAssetInfo {
  /**
   *
   * @type {number}
   * @memberof ServiceAssetInfo
   */
  assetStateId?: number;
  /**
   *
   * @type {string}
   * @memberof ServiceAssetInfo
   */
  licencePlate?: string;
  /**
   *
   * @type {ServiceAsset}
   * @memberof ServiceAssetInfo
   */
  asset: ServiceAsset;
}
/**
 *
 * @export
 * @interface ServiceEmployee
 */
export interface ServiceEmployee {
  /**
   *
   * @type {string}
   * @memberof ServiceEmployee
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof ServiceEmployee
   */
  email: string;
  /**
   *
   * @type {ExperienceLevel}
   * @memberof ServiceEmployee
   */
  experienceLevel: ExperienceLevel;
}

/**
 *
 * @export
 * @interface ServiceHub
 */
export interface ServiceHub {
  /**
   *
   * @type {number}
   * @memberof ServiceHub
   */
  hubId?: number;
  /**
   *
   * @type {number}
   * @memberof ServiceHub
   */
  serviceLocationId: number;
  /**
   *
   * @type {string}
   * @memberof ServiceHub
   */
  name: string;
  /**
   *
   * @type {GpsPosition}
   * @memberof ServiceHub
   */
  gpsPosition: GpsPosition;
  /**
   *
   * @type {boolean}
   * @memberof ServiceHub
   */
  isActive: boolean;
  /**
   *
   * @type {string}
   * @memberof ServiceHub
   */
  deletedAt?: string;
  /**
   *
   * @type {boolean}
   * @memberof ServiceHub
   */
  isServicePoint: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ServiceHub
   */
  isWorkshop: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ServiceHub
   */
  isExternalWorkshop: boolean;
  /**
   *
   * @type {string}
   * @memberof ServiceHub
   */
  jiraGroupInCharge?: string;
}
/**
 *
 * @export
 * @interface ServiceLocation
 */
export interface ServiceLocation {
  /**
   *
   * @type {number}
   * @memberof ServiceLocation
   */
  locationId: number;
  /**
   *
   * @type {string}
   * @memberof ServiceLocation
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof ServiceLocation
   */
  abbreviation?: string;
  /**
   *
   * @type {string}
   * @memberof ServiceLocation
   */
  projectKey: string;
  /**
   *
   * @type {GpsPosition}
   * @memberof ServiceLocation
   */
  center: GpsPosition;
  /**
   *
   * @type {number}
   * @memberof ServiceLocation
   */
  widthInMeters: number;
  /**
   *
   * @type {number}
   * @memberof ServiceLocation
   */
  heightInMeters: number;
  /**
   *
   * @type {number}
   * @memberof ServiceLocation
   */
  tileSizeInMeters: number;
  /**
   *
   * @type {string}
   * @memberof ServiceLocation
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof ServiceLocation
   */
  updatedAt: string;
  /**
   *
   * @type {boolean}
   * @memberof ServiceLocation
   */
  isDeleted: boolean;
}
/**
 *
 * @export
 * @interface ServiceOverview
 */
export interface ServiceOverview {
  /**
   *
   * @type {number}
   * @memberof ServiceOverview
   */
  locationId: number;
  /**
   *
   * @type {MissionType}
   * @memberof ServiceOverview
   */
  missionType: MissionType;
  /**
   *
   * @type {Array<Vehicle>}
   * @memberof ServiceOverview
   */
  availableVehicles?: Array<Vehicle>;
  /**
   *
   * @type {Grid}
   * @memberof ServiceOverview
   */
  grid: Grid;
}

/**
 *
 * @export
 * @interface ServiceParkingZone
 */
export interface ServiceParkingZone {
  /**
   *
   * @type {number}
   * @memberof ServiceParkingZone
   */
  parkingZoneId?: number;
  /**
   *
   * @type {number}
   * @memberof ServiceParkingZone
   */
  hubId: number;
  /**
   *
   * @type {string}
   * @memberof ServiceParkingZone
   */
  label: string;
  /**
   *
   * @type {GpsPosition}
   * @memberof ServiceParkingZone
   */
  gpsPosition: GpsPosition;
  /**
   *
   * @type {boolean}
   * @memberof ServiceParkingZone
   */
  isActive: boolean;
  /**
   *
   * @type {boolean}
   * @memberof ServiceParkingZone
   */
  isDropoff: boolean;
  /**
   *
   * @type {string}
   * @memberof ServiceParkingZone
   */
  deletedAt?: string;
}
/**
 *
 * @export
 * @interface ServicePhone
 */
export interface ServicePhone {
  /**
   *
   * @type {number}
   * @memberof ServicePhone
   */
  servicePhoneId?: number;
  /**
   *
   * @type {number}
   * @memberof ServicePhone
   */
  serviceLocationId: number;
  /**
   *
   * @type {string}
   * @memberof ServicePhone
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof ServicePhone
   */
  phoneNumber: string;
  /**
   *
   * @type {boolean}
   * @memberof ServicePhone
   */
  enabled: boolean;
  /**
   *
   * @type {number}
   * @memberof ServicePhone
   */
  inUseByTourId?: number;
  /**
   *
   * @type {string}
   * @memberof ServicePhone
   */
  deletedAt?: string;
}
/**
 *
 * @export
 * @enum {string}
 */

export const ServiceSourceType = {
  Required: 'Required',
  Optional: 'Optional'
} as const;

export type ServiceSourceType = (typeof ServiceSourceType)[keyof typeof ServiceSourceType];

/**
 *
 * @export
 * @interface ServiceTask
 */
export interface ServiceTask {
  /**
   *
   * @type {number}
   * @memberof ServiceTask
   */
  taskId: number;
  /**
   *
   * @type {LocalizedDescription}
   * @memberof ServiceTask
   */
  title: LocalizedDescription;
  /**
   *
   * @type {AssignmentType}
   * @memberof ServiceTask
   */
  taskType: AssignmentType;
  /**
   *
   * @type {AssignmentState}
   * @memberof ServiceTask
   */
  state: AssignmentState;
  /**
   *
   * @type {number}
   * @memberof ServiceTask
   */
  priority?: number;
  /**
   *
   * @type {string}
   * @memberof ServiceTask
   */
  failReason?: string;
  /**
   *
   * @type {string}
   * @memberof ServiceTask
   */
  cancelReason?: string;
  /**
   *
   * @type {string}
   * @memberof ServiceTask
   */
  createdAt: string;
  /**
   *
   * @type {string}
   * @memberof ServiceTask
   */
  updatedAt: string;
  /**
   *
   * @type {string}
   * @memberof ServiceTask
   */
  createdBy: string;
  /**
   *
   * @type {string}
   * @memberof ServiceTask
   */
  updatedBy: string;
  /**
   *
   * @type {Array<Comment>}
   * @memberof ServiceTask
   */
  comments?: Array<Comment>;
  /**
   *
   * @type {ServiceLocation}
   * @memberof ServiceTask
   */
  businessLocation: ServiceLocation;
  /**
   *
   * @type {ServiceAsset}
   * @memberof ServiceTask
   */
  serviceAsset: ServiceAsset;
}

/**
 *
 * @export
 * @interface ServiceVehicle
 */
export interface ServiceVehicle {
  /**
   *
   * @type {number}
   * @memberof ServiceVehicle
   */
  serviceVehicleId?: number;
  /**
   *
   * @type {number}
   * @memberof ServiceVehicle
   */
  serviceLocationId: number;
  /**
   *
   * @type {string}
   * @memberof ServiceVehicle
   */
  label: string;
  /**
   *
   * @type {string}
   * @memberof ServiceVehicle
   */
  licencePlate: string;
  /**
   *
   * @type {number}
   * @memberof ServiceVehicle
   */
  mileage: number;
  /**
   *
   * @type {boolean}
   * @memberof ServiceVehicle
   */
  enabled: boolean;
  /**
   *
   * @type {number}
   * @memberof ServiceVehicle
   */
  inUseByTourId?: number;
  /**
   *
   * @type {string}
   * @memberof ServiceVehicle
   */
  deletedAt?: string;
}
/**
 *
 * @export
 * @interface ServiceVehiclesFilterSet
 */
export interface ServiceVehiclesFilterSet {
  /**
   *
   * @type {MissionType}
   * @memberof ServiceVehiclesFilterSet
   */
  missionType: MissionType;
  /**
   *
   * @type {number}
   * @memberof ServiceVehiclesFilterSet
   */
  locationId: number;
  /**
   *
   * @type {number}
   * @memberof ServiceVehiclesFilterSet
   */
  requiredLevel?: number;
  /**
   *
   * @type {number}
   * @memberof ServiceVehiclesFilterSet
   */
  optionalLevel?: number;
  /**
   *
   * @type {Array<number>}
   * @memberof ServiceVehiclesFilterSet
   */
  vehicleTypeIds?: Array<number>;
}

/**
 *
 * @export
 * @interface StatisticsOverview
 */
export interface StatisticsOverview {
  /**
   *
   * @type {number}
   * @memberof StatisticsOverview
   */
  totalNumber: number;
  /**
   *
   * @type {number}
   * @memberof StatisticsOverview
   */
  cityId: number;
  /**
   *
   * @type {Array<StatusInfo>}
   * @memberof StatisticsOverview
   */
  numberPerStatus?: Array<StatusInfo>;
}
/**
 *
 * @export
 * @interface StatusInfo
 */
export interface StatusInfo {
  /**
   *
   * @type {number}
   * @memberof StatusInfo
   */
  statusId: number;
  /**
   *
   * @type {VehicleStatus}
   * @memberof StatusInfo
   */
  statusName: VehicleStatus;
  /**
   *
   * @type {number}
   * @memberof StatusInfo
   */
  percent: number;
  /**
   *
   * @type {number}
   * @memberof StatusInfo
   */
  amount: number;
}
/**
 *
 * @export
 * @interface SteeringTile
 */
export interface SteeringTile {
  /**
   *
   * @type {number}
   * @memberof SteeringTile
   */
  steeringTileId?: number;
  /**
   *
   * @type {string}
   * @memberof SteeringTile
   */
  tileId: string;
  /**
   *
   * @type {SteeringTileType}
   * @memberof SteeringTile
   */
  steeringType: SteeringTileType;
  /**
   *
   * @type {GpsPosition}
   * @memberof SteeringTile
   */
  topLeftCorner: GpsPosition;
  /**
   *
   * @type {number}
   * @memberof SteeringTile
   */
  size: number;
  /**
   *
   * @type {number}
   * @memberof SteeringTile
   */
  index: number;
  /**
   *
   * @type {number}
   * @memberof SteeringTile
   */
  usedByTourId?: number;
}

/**
 *
 * @export
 * @interface SteeringTileBody
 */
export interface SteeringTileBody {
  /**
   *
   * @type {number}
   * @memberof SteeringTileBody
   */
  steeringTileId?: number;
  /**
   *
   * @type {SteeringTileType}
   * @memberof SteeringTileBody
   */
  steeringType: SteeringTileType;
  /**
   *
   * @type {string}
   * @memberof SteeringTileBody
   */
  tileId: string;
  /**
   *
   * @type {number}
   * @memberof SteeringTileBody
   */
  topLeftCornerLat: number;
  /**
   *
   * @type {number}
   * @memberof SteeringTileBody
   */
  topLeftCornerLon: number;
  /**
   *
   * @type {number}
   * @memberof SteeringTileBody
   */
  index: number;
}

/**
 *
 * @export
 * @enum {string}
 */

export const SteeringTileType = {
  NoGo: 'NoGo',
  MustGo: 'MustGo'
} as const;

export type SteeringTileType = (typeof SteeringTileType)[keyof typeof SteeringTileType];

/**
 *
 * @export
 * @interface Step
 */
export interface Step {
  /**
   *
   * @type {number}
   * @memberof Step
   */
  stepId?: number;
  /**
   *
   * @type {StepType}
   * @memberof Step
   */
  stepType: StepType;
  /**
   *
   * @type {StepCategory}
   * @memberof Step
   */
  stepCategory: StepCategory;
  /**
   *
   * @type {LocalizedDescription}
   * @memberof Step
   */
  stepDescription: LocalizedDescription;
  /**
   *
   * @type {boolean}
   * @memberof Step
   */
  isDoneOrFailed: boolean;
  /**
   *
   * @type {StepState}
   * @memberof Step
   */
  state: StepState;
  /**
   *
   * @type {string}
   * @memberof Step
   */
  jiraIssueKey?: string;
  /**
   *
   * @type {string}
   * @memberof Step
   */
  jiraIssueLink?: string;
  /**
   *
   * @type {TransitionAction}
   * @memberof Step
   */
  startAction: TransitionAction;
  /**
   *
   * @type {Array<TriggerAction>}
   * @memberof Step
   */
  autoTriggerActionsOnStart?: Array<TriggerAction>;
  /**
   *
   * @type {TransitionAction}
   * @memberof Step
   */
  completeAction: TransitionAction;
  /**
   *
   * @type {TriggerAction}
   * @memberof Step
   */
  autoTriggerActionsOnComplete?: TriggerAction;
  /**
   *
   * @type {TriggerAction[]}
   * @memberof Step
   */
  triggerActions?: TriggerAction[];
  /**
   *
   * @type {Array<TransitionAction>}
   * @memberof Step
   */
  cancelActions?: Array<TransitionAction>;
  /**
   *
   * @type {string}
   * @memberof Step
   */
  startedAt?: string;
  /**
   *
   * @type {string}
   * @memberof Step
   */
  completedAt?: string;
  /**
   *
   * @type {string}
   * @memberof Step
   */
  failedAt?: string;
  /**
   *
   * @type {string}
   * @memberof Step
   */
  failReason?: string;
  /**
   *
   * @type {string}
   * @memberof Step
   */
  canceledAt?: string;
  /**
   *
   * @type {string}
   * @memberof Step
   */
  cancelReason?: string;
  /**
   *
   * @type {Vehicle}
   * @memberof Step
   */
  vehicle?: Vehicle;
  /**
   *
   * @type {GpsPosition}
   * @memberof Step
   */
  gpsPosition?: GpsPosition;
  /**
   *
   * @type {number}
   * @memberof Step
   */
  positionInCompiledList?: number;
  /**
   *
   * @type {string}
   * @memberof Step
   */
  resolution?: string;
}

/**
 *
 * @export
 * @enum {string}
 */

export const StepCategory = {
  DoSomething: 'DoSomething',
  DoSomethingWithActionButtons: 'DoSomethingWithActionButtons',
  DoSomethingWithSelection: 'DoSomethingWithSelection',
  DriveToScooter: 'DriveToScooter',
  DriveToArbitraryPlace: 'DriveToArbitraryPlace',
  StandardService: 'StandardService',
  SecurityCheck: 'SecurityCheck',
  ReplaceHelmets: 'ReplaceHelmets'
} as const;

export type StepCategory = (typeof StepCategory)[keyof typeof StepCategory];

/**
 *
 * @export
 * @enum {string}
 */

export const StepState = {
  Completed: 'Completed',
  InProgress: 'InProgress',
  Failed: 'Failed',
  Canceled: 'Canceled',
  Considered: 'Considered'
} as const;

export type StepState = (typeof StepState)[keyof typeof StepState];

/**
 *
 * @export
 * @enum {string}
 */

export const StepType = {
  PreStep: 'PreStep',
  AtStep: 'AtStep',
  PostStep: 'PostStep'
} as const;

export type StepType = (typeof StepType)[keyof typeof StepType];

/**
 *
 * @export
 * @interface TaskLog
 */
export interface TaskLog {
  /**
   *
   * @type {number}
   * @memberof TaskLog
   */
  id: number;
  /**
   *
   * @type {number}
   * @memberof TaskLog
   */
  taskId: number;
  /**
   *
   * @type {LogTaskChangeType}
   * @memberof TaskLog
   */
  taskChange: LogTaskChangeType;
  /**
   *
   * @type {Action}
   * @memberof TaskLog
   */
  loggedAction: Action;
  /**
   *
   * @type {string}
   * @memberof TaskLog
   */
  at: string;
  /**
   *
   * @type {string}
   * @memberof TaskLog
   */
  by: string;
  /**
   *
   * @type {string}
   * @memberof TaskLog
   */
  summary: string;
}
/**
 *
 * @export
 * @interface TelematicsPosition
 */
export interface TelematicsPosition {
  /**
   *
   * @type {number}
   * @memberof TelematicsPosition
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof TelematicsPosition
   */
  lon: number;
}
/**
 *
 * @export
 * @interface Tile
 */
export interface Tile {
  /**
   *
   * @type {string}
   * @memberof Tile
   */
  tileId: string;
  /**
   *
   * @type {number}
   * @memberof Tile
   */
  x: number;
  /**
   *
   * @type {number}
   * @memberof Tile
   */
  y: number;
  /**
   *
   * @type {GpsPosition}
   * @memberof Tile
   */
  topLeftCorner: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof Tile
   */
  topRightCorner: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof Tile
   */
  bottomLeftCorner: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof Tile
   */
  bottomRightCorner: GpsPosition;
  /**
   *
   * @type {number}
   * @memberof Tile
   */
  numberOfVehicles: number;
  /**
   *
   * @type {number}
   * @memberof Tile
   */
  normalizedDensity: number;
}
/**
 *
 * @export
 * @interface Timespan
 */
export interface Timespan {
  /**
   *
   * @type {string}
   * @memberof Timespan
   */
  from: string;
  /**
   *
   * @type {string}
   * @memberof Timespan
   */
  to?: string;
}
/**
 * @type TodoStatusName
 * @export
 */
export type TodoStatusName = object;

/**
 *
 * @export
 * @interface Tour
 */
export interface Tour {
  /**
   *
   * @type {number}
   * @memberof Tour
   */
  missionId?: number;
  /**
   *
   * @type {MissionType}
   * @memberof Tour
   */
  tourType: MissionType;
  /**
   *
   * @type {TourParameters}
   * @memberof Tour
   */
  tourParameters: TourParameters;
  /**
   *
   * @type {Array<Job>}
   * @memberof Tour
   */
  jobs?: Array<Job>;
  /**
   *
   * @type {string}
   * @memberof Tour
   */
  requestedAt: string;
  /**
   *
   * @type {string}
   * @memberof Tour
   */
  startedAt?: string;
  /**
   *
   * @type {string}
   * @memberof Tour
   */
  completedAt?: string;
  /**
   *
   * @type {string}
   * @memberof Tour
   */
  failedAt?: string;
  /**
   *
   * @type {string}
   * @memberof Tour
   */
  failReason?: string;
  /**
   *
   * @type {number}
   * @memberof Tour
   */
  tourId?: number;
  /**
   *
   * @type {Array<SteeringTile>}
   * @memberof Tour
   */
  usedSteeringTiles?: Array<SteeringTile>;
  /**
   *
   * @type {RouteOptimizationResult}
   * @memberof Tour
   */
  routeOptimizationResult?: RouteOptimizationResult;
  /**
   *
   * @type {TourState}
   * @memberof Tour
   */
  state: TourState;
}

/**
 *
 * @export
 * @interface TourCorridor
 */
export interface TourCorridor {
  /**
   *
   * @type {Array<TourCorridorPolygon>}
   * @memberof TourCorridor
   */
  polygons?: Array<TourCorridorPolygon>;
  /**
   *
   * @type {Array<TourCorridorCircle>}
   * @memberof TourCorridor
   */
  circles?: Array<TourCorridorCircle>;
  /**
   *
   * @type {CorridorPolygon}
   * @memberof TourCorridor
   */
  boundingBox: CorridorPolygon;
}
/**
 *
 * @export
 * @interface TourCorridorAnchor
 */
export interface TourCorridorAnchor {
  /**
   *
   * @type {Job}
   * @memberof TourCorridorAnchor
   */
  job?: Job;
  /**
   *
   * @type {ServiceHub}
   * @memberof TourCorridorAnchor
   */
  hub?: ServiceHub;
}
/**
 *
 * @export
 * @interface TourCorridorCircle
 */
export interface TourCorridorCircle {
  /**
   *
   * @type {GpsPosition}
   * @memberof TourCorridorCircle
   */
  center: GpsPosition;
  /**
   *
   * @type {number}
   * @memberof TourCorridorCircle
   */
  radius: number;
  /**
   *
   * @type {TourCorridorAnchor}
   * @memberof TourCorridorCircle
   */
  anchor: TourCorridorAnchor;
}
/**
 *
 * @export
 * @interface TourCorridorPolygon
 */
export interface TourCorridorPolygon {
  /**
   *
   * @type {GpsPosition}
   * @memberof TourCorridorPolygon
   */
  topLeft: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof TourCorridorPolygon
   */
  topRight: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof TourCorridorPolygon
   */
  bottomLeft: GpsPosition;
  /**
   *
   * @type {GpsPosition}
   * @memberof TourCorridorPolygon
   */
  bottomRight: GpsPosition;
  /**
   *
   * @type {TourCorridorAnchor}
   * @memberof TourCorridorPolygon
   */
  leftAnchor: TourCorridorAnchor;
  /**
   *
   * @type {TourCorridorAnchor}
   * @memberof TourCorridorPolygon
   */
  rightAnchor: TourCorridorAnchor;
}
/**
 *
 * @export
 * @interface TourInteractionNotAllowed
 */
export interface TourInteractionNotAllowed {
  /**
   *
   * @type {string}
   * @memberof TourInteractionNotAllowed
   */
  msg: string;
}
/**
 *
 * @export
 * @interface TourParameterOptionsBundle
 */
export interface TourParameterOptionsBundle {
  /**
   *
   * @type {ServiceLocation}
   * @memberof TourParameterOptionsBundle
   */
  location: ServiceLocation;
  /**
   *
   * @type {Array<ServiceEmployee>}
   * @memberof TourParameterOptionsBundle
   */
  serviceEmployees?: Array<ServiceEmployee>;
  /**
   *
   * @type {Array<ServicePhone>}
   * @memberof TourParameterOptionsBundle
   */
  servicePhones?: Array<ServicePhone>;
  /**
   *
   * @type {Array<ServiceVehicle>}
   * @memberof TourParameterOptionsBundle
   */
  serviceVehicle?: Array<ServiceVehicle>;
  /**
   *
   * @type {Array<ServiceHub>}
   * @memberof TourParameterOptionsBundle
   */
  serviceHubs?: Array<ServiceHub>;
}
/**
 *
 * @export
 * @interface TourParameters
 */
export interface TourParameters {
  /**
   *
   * @type {number}
   * @memberof TourParameters
   */
  tourId?: number;
  /**
   *
   * @type {number}
   * @memberof TourParameters
   */
  tourParameterId?: number;
  /**
   *
   * @type {Array<ServiceEmployee>}
   * @memberof TourParameters
   */
  assignees?: Array<ServiceEmployee>;
  /**
   *
   * @type {ServiceVehicle}
   * @memberof TourParameters
   */
  serviceVehicle: ServiceVehicle;
  /**
   *
   * @type {number}
   * @memberof TourParameters
   */
  capacity: number;
  /**
   *
   * @type {ServicePhone}
   * @memberof TourParameters
   */
  phone: ServicePhone;
  /**
   *
   * @type {GpsPosition}
   * @memberof TourParameters
   */
  startLocation: GpsPosition;
  /**
   *
   * @type {ServiceHub}
   * @memberof TourParameters
   */
  startHub?: ServiceHub;
  /**
   *
   * @type {ServiceHub}
   * @memberof TourParameters
   */
  endHub: ServiceHub;
  /**
   *
   * @type {Array<VehicleType>}
   * @memberof TourParameters
   */
  vehicleTypes?: Array<VehicleType>;
}
/**
 *
 * @export
 * @interface TourParametersBody
 */
export interface TourParametersBody {
  /**
   *
   * @type {Array<string>}
   * @memberof TourParametersBody
   */
  assignees?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof TourParametersBody
   */
  serviceVehicleId: number;
  /**
   *
   * @type {number}
   * @memberof TourParametersBody
   */
  phoneId: number;
  /**
   *
   * @type {number}
   * @memberof TourParametersBody
   */
  endHubId: number;
  /**
   *
   * @type {number}
   * @memberof TourParametersBody
   */
  startHubId?: number;
  /**
   *
   * @type {number}
   * @memberof TourParametersBody
   */
  capacity: number;
  /**
   *
   * @type {GpsPosition}
   * @memberof TourParametersBody
   */
  startLocation: GpsPosition;
  /**
   *
   * @type {Array<number>}
   * @memberof TourParametersBody
   */
  vehicleTypeIds?: Array<number>;
  /**
   *
   * @type {MissionType}
   * @memberof TourParametersBody
   */
  missionType: MissionType;
  /**
   *
   * @type {boolean}
   * @memberof TourParametersBody
   */
  debug?: boolean;
}

/**
 *
 * @export
 * @interface TourResponse
 */
export interface TourResponse {
  /**
   *
   * @type {boolean}
   * @memberof TourResponse
   */
  success: boolean;
  /**
   *
   * @type {string}
   * @memberof TourResponse
   */
  error?: string;
  /**
   *
   * @type {CompiledTour}
   * @memberof TourResponse
   */
  updatedTour: CompiledTour;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TourState = {
  Requested: 'Requested',
  InProgress: 'InProgress',
  Completed: 'Completed',
  Failed: 'Failed'
} as const;

export type TourState = (typeof TourState)[keyof typeof TourState];

/**
 *
 * @export
 * @interface TourStats
 */
export interface TourStats {
  /**
   *
   * @type {number}
   * @memberof TourStats
   */
  tourStatsId?: number;
  /**
   *
   * @type {number}
   * @memberof TourStats
   */
  tourId: number;
  /**
   *
   * @type {number}
   * @memberof TourStats
   */
  mileageBefore: number;
  /**
   *
   * @type {number}
   * @memberof TourStats
   */
  mileageAfter: number;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TransitionAction = {
  JiraTransitionActivatePickupTour: 'JiraTransitionActivatePickupTour',
  JiraTransitionApproachingScooter: 'JiraTransitionApproachingScooter',
  JiraTransitionScooterFound: 'JiraTransitionScooterFound',
  JiraTransitionPickedUp: 'JiraTransitionPickedUp',
  JiraTransitionDroppedOff: 'JiraTransitionDroppedOff',
  JiraTransitionScooterInaccessible: 'JiraTransitionScooterInaccessible',
  JiraTransitionScooterNotFound: 'JiraTransitionScooterNotFound',
  JiraTransitionCancelTour: 'JiraTransitionCancelTour',
  JiraTransitionSkipScooter: 'JiraTransitionSkipScooter',
  JiraTransitionPickupFailed: 'JiraTransitionPickupFailed',
  JiraTransitionClearingDropOff: 'JiraTransitionClearingDropOff',
  ServiceOnStreetTaskListRequestTour: 'ServiceOnStreetTaskListRequestTour',
  ServiceOnStreetTaskListActivateTour: 'ServiceOnStreetTaskListActivateTour',
  ServiceOnStreetTaskListCancelTour: 'ServiceOnStreetTaskListCancelTour',
  ServiceOnStreetTaskListCancelTourRequest: 'ServiceOnStreetTaskListCancelTourRequest',
  ServiceOnStreetTaskListApproachingOptional: 'ServiceOnStreetTaskListApproachingOptional',
  ServiceOnStreetTaskListCancelApproachingOptional:
    'ServiceOnStreetTaskListCancelApproachingOptional',
  ServiceOnStreetTaskListApproachingScooter: 'ServiceOnStreetTaskListApproachingScooter',
  ServiceOnStreetTaskListPerformingService: 'ServiceOnStreetTaskListPerformingService',
  ServiceOnStreetTaskListScooterNotFound: 'ServiceOnStreetTaskListScooterNotFound',
  ServiceOnStreetTaskListScooterInaccessible: 'ServiceOnStreetTaskListScooterInaccessible',
  ServiceOnStreetTaskListServiceSucceeded: 'ServiceOnStreetTaskListServiceSucceeded',
  ServiceOnStreetTaskListServiceFailed: 'ServiceOnStreetTaskListServiceFailed',
  ServiceTaskStartProgress: 'ServiceTaskStartProgress',
  ServiceTaskClose: 'ServiceTaskClose',
  ServiceTaskFail: 'ServiceTaskFail',
  ServiceTaskCancel: 'ServiceTaskCancel',
  BatterySwapTaskStartProgress: 'BatterySwapTaskStartProgress',
  BatterySwapTaskClose: 'BatterySwapTaskClose',
  BatterySwapTaskFail: 'BatterySwapTaskFail',
  BatterySwapTaskCancel: 'BatterySwapTaskCancel',
  RelocateActivate: 'RelocateActivate',
  RepairModeActivate: 'RepairModeActivate',
  Nothing: 'Nothing'
} as const;

export type TransitionAction = (typeof TransitionAction)[keyof typeof TransitionAction];

/**
 *
 * @export
 * @interface TransitionActionBody
 */
export interface TransitionActionBody {
  /**
   *
   * @type {Array<TransitionActionField>}
   * @memberof TransitionActionBody
   */
  fields?: Array<TransitionActionField>;
  /**
   *
   * @type {string}
   * @memberof TransitionActionBody
   */
  resolution?: string;
}
/**
 *
 * @export
 * @interface TransitionActionField
 */
export interface TransitionActionField {
  /**
   *
   * @type {string}
   * @memberof TransitionActionField
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof TransitionActionField
   */
  value: string;
  /**
   *
   * @type {Array<string>}
   * @memberof TransitionActionField
   */
  encodedValue?: Array<string>;
}
/**
 *
 * @export
 * @enum {string}
 */

export const TriggerAction = {
  DisableAlarm: 'DisableAlarm',
  EnableAlarm: 'EnableAlarm',
  Lock: 'Lock',
  Unlock: 'Unlock',
  UnlockSaddle: 'UnlockSaddle',
  Nothing: 'Nothing'
} as const;

export type TriggerAction = (typeof TriggerAction)[keyof typeof TriggerAction];

/**
 *
 * @export
 * @interface TriggerActionBody
 */
export interface TriggerActionBody {
  /**
   *
   * @type {TriggerAction}
   * @memberof TriggerActionBody
   */
  action: TriggerAction;
}

/**
 *
 * @export
 * @interface TriggerActionBody1
 */
export interface TriggerActionBody1 {
  /**
   *
   * @type {TriggerAction}
   * @memberof TriggerActionBody1
   */
  requestedTriggerAction: TriggerAction;
}

/**
 *
 * @export
 * @interface TriggerActionNotAllowed
 */
export interface TriggerActionNotAllowed {
  /**
   *
   * @type {string}
   * @memberof TriggerActionNotAllowed
   */
  message: string;
}
/**
 *
 * @export
 * @interface TriggerEvent
 */
export interface TriggerEvent {
  /**
   *
   * @type {number}
   * @memberof TriggerEvent
   */
  tourId: number;
  /**
   *
   * @type {number}
   * @memberof TriggerEvent
   */
  stepId: number;
  /**
   *
   * @type {string}
   * @memberof TriggerEvent
   */
  username: string;
  /**
   *
   * @type {TriggerAction}
   * @memberof TriggerEvent
   */
  triggerAction: TriggerAction;
  /**
   *
   * @type {string}
   * @memberof TriggerEvent
   */
  triggeredAt: string;
  /**
   *
   * @type {ActionResult}
   * @memberof TriggerEvent
   */
  triggerResult: ActionResult;
  /**
   *
   * @type {string}
   * @memberof TriggerEvent
   */
  error?: string;
}

/**
 *
 * @export
 * @interface Unauthorized
 */
export interface Unauthorized {
  /**
   *
   * @type {string}
   * @memberof Unauthorized
   */
  message: string;
}
/**
 *
 * @export
 * @interface Unknown
 */
export interface Unknown {
  /**
   *
   * @type {string}
   * @memberof Unknown
   */
  message: string;
}
/**
 *
 * @export
 * @interface User
 */
export interface User {
  /**
   *
   * @type {string}
   * @memberof User
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  email: string;
  /**
   *
   * @type {Array<string>}
   * @memberof User
   */
  groups?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof User
   */
  token: string;
  /**
   *
   * @type {string}
   * @memberof User
   */
  redirectUrl?: string;
  /**
   *
   * @type {boolean}
   * @memberof User
   */
  connectedToJira: boolean;
}
/**
 *
 * @export
 * @interface UserProfile
 */
export interface UserProfile {
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  email: string;
  /**
   *
   * @type {boolean}
   * @memberof UserProfile
   */
  emailVerified: boolean;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  givenName: string;
  /**
   *
   * @type {Array<string>}
   * @memberof UserProfile
   */
  groups?: Array<string>;
  /**
   *
   * @type {ExperienceLevel}
   * @memberof UserProfile
   */
  experienceLevel: ExperienceLevel;
  /**
   *
   * @type {string}
   * @memberof UserProfile
   */
  accessToken: string;
}

/**
 *
 * @export
 * @interface UserTargetGroup
 */
export interface UserTargetGroup {
  /**
   *
   * @type {number}
   * @memberof UserTargetGroup
   */
  targetGroupId?: number;
  /**
   *
   * @type {ServiceLocation}
   * @memberof UserTargetGroup
   */
  serviceLocation: ServiceLocation;
  /**
   *
   * @type {ExperienceLevel}
   * @memberof UserTargetGroup
   */
  experienceLevel?: ExperienceLevel;
  /**
   *
   * @type {Array<ServiceEmployee>}
   * @memberof UserTargetGroup
   */
  employees?: Array<ServiceEmployee>;
}

/**
 *
 * @export
 * @interface UserTargetGroupBody
 */
export interface UserTargetGroupBody {
  /**
   *
   * @type {number}
   * @memberof UserTargetGroupBody
   */
  targetGroupId?: number;
  /**
   *
   * @type {number}
   * @memberof UserTargetGroupBody
   */
  serviceLocationId: number;
  /**
   *
   * @type {ExperienceLevel}
   * @memberof UserTargetGroupBody
   */
  experienceLevel?: ExperienceLevel;
  /**
   *
   * @type {Array<string>}
   * @memberof UserTargetGroupBody
   */
  employees?: Array<string>;
}

/**
 *
 * @export
 * @interface Vehicle
 */
export interface Vehicle {
  /**
   *
   * @type {number}
   * @memberof Vehicle
   */
  vehicleId: number;
  /**
   *
   * @type {number}
   * @memberof Vehicle
   */
  vehicleTypeId: number;
  /**
   *
   * @type {number}
   * @memberof Vehicle
   */
  vehicleStateId: number;
  /**
   *
   * @type {boolean}
   * @memberof Vehicle
   */
  isReserved: boolean;
  /**
   *
   * @type {string}
   * @memberof Vehicle
   */
  licencePlate: string;
  /**
   *
   * @type {number}
   * @memberof Vehicle
   */
  lat: number;
  /**
   *
   * @type {number}
   * @memberof Vehicle
   */
  lon: number;
  /**
   *
   * @type {number}
   * @memberof Vehicle
   */
  batteryLevel: number;
  /**
   *
   * @type {number}
   * @memberof Vehicle
   */
  locationId: number;
  /**
   *
   * @type {number}
   * @memberof Vehicle
   */
  mileage?: number;
  /**
   *
   * @type {Array<string>}
   * @memberof Vehicle
   */
  visibleDamages?: Array<string>;
  /**
   *
   * @type {string}
   * @memberof Vehicle
   */
  hardwareId?: string;
  /**
   *
   * @type {string}
   * @memberof Vehicle
   */
  vin?: string;
  /**
   *
   * @type {JiraIssue}
   * @memberof Vehicle
   */
  repairTicket?: JiraIssue;
  /**
   *
   * @type {JiraIssue}
   * @memberof Vehicle
   */
  serviceTicket?: JiraIssue;
  /**
   *
   * @type {JiraIssue}
   * @memberof Vehicle
   */
  pickupTicket?: JiraIssue;
  /**
   *
   * @type {JiraIssue}
   * @memberof Vehicle
   */
  relocateTicket?: JiraIssue;
  /**
   *
   * @type {ServiceTask}
   * @memberof Vehicle
   */
  pickupTask?: ServiceTask;
  /**
   *
   * @type {string}
   * @memberof Vehicle
   */
  assetUrl?: string;
  /**
   *
   * @type {ServiceSourceType}
   * @memberof Vehicle
   */
  serviceSourceType?: ServiceSourceType;
}

/**
 *
 * @export
 * @interface VehicleIdentifier
 */
export interface VehicleIdentifier {
  /**
   *
   * @type {string}
   * @memberof VehicleIdentifier
   */
  vin?: string;
  /**
   *
   * @type {string}
   * @memberof VehicleIdentifier
   */
  licencePlate?: string;
  /**
   *
   * @type {number}
   * @memberof VehicleIdentifier
   */
  vehicleId?: number;
}
/**
 *
 * @export
 * @interface VehicleReportBody
 */
export interface VehicleReportBody {
  /**
   *
   * @type {VehicleIdentifier}
   * @memberof VehicleReportBody
   */
  vehicleIdentifier: VehicleIdentifier;
  /**
   *
   * @type {Array<string>}
   * @memberof VehicleReportBody
   */
  incidents?: Array<VehicleReportBodyIncidentsEnum>;
  /**
   *
   * @type {string}
   * @memberof VehicleReportBody
   */
  description?: string;
  /**
   *
   * @type {Array<Base64Image>}
   * @memberof VehicleReportBody
   */
  images?: Array<Base64Image>;
  /**
   *
   * @type {GpsPosition1}
   * @memberof VehicleReportBody
   */
  position: GpsPosition1;
  /**
   *
   * @type {number}
   * @memberof VehicleReportBody
   */
  reportedDuringTourId?: number;
}

export const VehicleReportBodyIncidentsEnum = {
  Damage: 'Damage',
  BadlyParked: 'BadlyParked',
  BatteryStolen: 'BatteryStolen',
  PersonalItemsFound: 'PersonalItemsFound',
  MissingHelmet: 'MissingHelmet',
  WorkshopDocumentation: 'WorkshopDocumentation'
} as const;

export type VehicleReportBodyIncidentsEnum =
  (typeof VehicleReportBodyIncidentsEnum)[keyof typeof VehicleReportBodyIncidentsEnum];

/**
 *
 * @export
 * @interface VehicleReportResolveBody
 */
export interface VehicleReportResolveBody {
  /**
   *
   * @type {string}
   * @memberof VehicleReportResolveBody
   */
  resolutionType: VehicleReportResolveBodyResolutionTypeEnum;
  /**
   *
   * @type {string}
   * @memberof VehicleReportResolveBody
   */
  resolvedThrough?: string;
}

export const VehicleReportResolveBodyResolutionTypeEnum = {
  JiraRepairTicket: 'jira-repair-ticket',
  WunderfleetDamage: 'wunderfleet-damage',
  NoActionRequired: 'no-action-required'
} as const;

export type VehicleReportResolveBodyResolutionTypeEnum =
  (typeof VehicleReportResolveBodyResolutionTypeEnum)[keyof typeof VehicleReportResolveBodyResolutionTypeEnum];

/**
 * @type VehicleStatus
 * @export
 */
export type VehicleStatus = object;

/**
 *
 * @export
 * @interface VehicleType
 */
export interface VehicleType {
  /**
   *
   * @type {number}
   * @memberof VehicleType
   */
  vehicleTypeId: number;
  /**
   *
   * @type {string}
   * @memberof VehicleType
   */
  manufacturer: string;
  /**
   *
   * @type {string}
   * @memberof VehicleType
   */
  model: string;
  /**
   *
   * @type {string}
   * @memberof VehicleType
   */
  pricingLevel: string;
}
/**
 *
 * @export
 * @interface WorkshopEvent
 */
export interface WorkshopEvent {
  /**
   *
   * @type {string}
   * @memberof WorkshopEvent
   */
  username: string;
  /**
   *
   * @type {string}
   * @memberof WorkshopEvent
   */
  timestamp: string;
  /**
   *
   * @type {string}
   * @memberof WorkshopEvent
   */
  jiraIssueKey: string;
  /**
   *
   * @type {string}
   * @memberof WorkshopEvent
   */
  workshopGroup?: string;
  /**
   *
   * @type {string}
   * @memberof WorkshopEvent
   */
  vin: string;
  /**
   *
   * @type {WorkshopEventType}
   * @memberof WorkshopEvent
   */
  eventType: WorkshopEventType;
  /**
   *
   * @type {string}
   * @memberof WorkshopEvent
   */
  eventPayload: string;
  /**
   *
   * @type {string}
   * @memberof WorkshopEvent
   */
  error?: string;
}
/**
 * @type WorkshopEventType
 * @export
 */
export type WorkshopEventType = object;

/**
 *
 * @export
 * @interface WorkshopTicket
 */
export interface WorkshopTicket {
  /**
   *
   * @type {JiraIssue}
   * @memberof WorkshopTicket
   */
  jiraIssue: JiraIssue;
  /**
   *
   * @type {Vehicle}
   * @memberof WorkshopTicket
   */
  vehicle: Vehicle;
  /**
   *
   * @type {Array<ProblemListTodo>}
   * @memberof WorkshopTicket
   */
  todos?: Array<ProblemListTodo>;
}
/**
 *
 * @export
 * @interface WunderBulkEditStatus
 */
export interface WunderBulkEditStatus {
  /**
   *
   * @type {string}
   * @memberof WunderBulkEditStatus
   */
  startedAt: string;
  /**
   *
   * @type {string}
   * @memberof WunderBulkEditStatus
   */
  startedBy: string;
  /**
   *
   * @type {WunderBulkEditStatusType}
   * @memberof WunderBulkEditStatus
   */
  status: WunderBulkEditStatusType;
  /**
   *
   * @type {number}
   * @memberof WunderBulkEditStatus
   */
  progress: number;
  /**
   *
   * @type {Array<BulkEditRowResult>}
   * @memberof WunderBulkEditStatus
   */
  results?: Array<BulkEditRowResult>;
}
/**
 * @type WunderBulkEditStatusType
 * @export
 */
export type WunderBulkEditStatusType = object;

/**
 * AuthApi - axios parameter creator
 * @export
 */
export const AuthApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Login with Jira credentials
     * @summary Login with Jira credentials
     * @param {JiraLoginBody} jiraLoginBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login: async (
      jiraLoginBody: JiraLoginBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'jiraLoginBody' is not null or undefined
      assertParamExists('login', 'jiraLoginBody', jiraLoginBody);
      const localVarPath = `/auth/login`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        jiraLoginBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Logout, invalidates the given token
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/logout`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get logged in user
     * @summary Get logged in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    myself: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/auth/me`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Gets called by Jira after an OAuth 1.0a flow has been confirmed by the user
     * @summary Gets called by Jira after an OAuth 1.0a flow has been confirmed by the user
     * @param {string} oauthToken
     * @param {string} oauthVerifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oAuthCallback: async (
      oauthToken: string,
      oauthVerifier: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'oauthToken' is not null or undefined
      assertParamExists('oAuthCallback', 'oauthToken', oauthToken);
      // verify required parameter 'oauthVerifier' is not null or undefined
      assertParamExists('oAuthCallback', 'oauthVerifier', oauthVerifier);
      const localVarPath = `/auth/jira-oauth-callback/token-verification`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (oauthToken !== undefined) {
        localVarQueryParameter['oauth_token'] = oauthToken;
      }

      if (oauthVerifier !== undefined) {
        localVarQueryParameter['oauth_verifier'] = oauthVerifier;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthApiAxiosParamCreator(configuration);
  return {
    /**
     * Login with Jira credentials
     * @summary Login with Jira credentials
     * @param {JiraLoginBody} jiraLoginBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async login(
      jiraLoginBody: JiraLoginBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.login(jiraLoginBody, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['AuthApi.login']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Logout, invalidates the given token
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async logout(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.logout(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['AuthApi.logout']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get logged in user
     * @summary Get logged in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async myself(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.myself(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['AuthApi.myself']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Gets called by Jira after an OAuth 1.0a flow has been confirmed by the user
     * @summary Gets called by Jira after an OAuth 1.0a flow has been confirmed by the user
     * @param {string} oauthToken
     * @param {string} oauthVerifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async oAuthCallback(
      oauthToken: string,
      oauthVerifier: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.oAuthCallback(
        oauthToken,
        oauthVerifier,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['AuthApi.oAuthCallback']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AuthApiFp(configuration);
  return {
    /**
     * Login with Jira credentials
     * @summary Login with Jira credentials
     * @param {JiraLoginBody} jiraLoginBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    login(jiraLoginBody: JiraLoginBody, options?: any): AxiosPromise<User> {
      return localVarFp.login(jiraLoginBody, options).then((request) => request(axios, basePath));
    },
    /**
     * Logout, invalidates the given token
     * @summary Logout
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    logout(options?: any): AxiosPromise<void> {
      return localVarFp.logout(options).then((request) => request(axios, basePath));
    },
    /**
     * Get logged in user
     * @summary Get logged in user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    myself(options?: any): AxiosPromise<User> {
      return localVarFp.myself(options).then((request) => request(axios, basePath));
    },
    /**
     * Gets called by Jira after an OAuth 1.0a flow has been confirmed by the user
     * @summary Gets called by Jira after an OAuth 1.0a flow has been confirmed by the user
     * @param {string} oauthToken
     * @param {string} oauthVerifier
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    oAuthCallback(oauthToken: string, oauthVerifier: string, options?: any): AxiosPromise<string> {
      return localVarFp
        .oAuthCallback(oauthToken, oauthVerifier, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
  /**
   * Login with Jira credentials
   * @summary Login with Jira credentials
   * @param {JiraLoginBody} jiraLoginBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public login(jiraLoginBody: JiraLoginBody, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .login(jiraLoginBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Logout, invalidates the given token
   * @summary Logout
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public logout(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .logout(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get logged in user
   * @summary Get logged in user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public myself(options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .myself(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets called by Jira after an OAuth 1.0a flow has been confirmed by the user
   * @summary Gets called by Jira after an OAuth 1.0a flow has been confirmed by the user
   * @param {string} oauthToken
   * @param {string} oauthVerifier
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthApi
   */
  public oAuthCallback(oauthToken: string, oauthVerifier: string, options?: AxiosRequestConfig) {
    return AuthApiFp(this.configuration)
      .oAuthCallback(oauthToken, oauthVerifier, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CacheApi - axios parameter creator
 * @export
 */
export const CacheApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Refresh VehicleType and PricingLevel cache
     * @summary Refresh VehicleType and PricingLevel cache
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1RefreshCache: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cache/refresh`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Gets the pricing level cache
     * @summary Gets the pricing level cache
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1RetrievePricingLevelMapCache: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/cache/pricing-level/retrieve`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * CacheApi - functional programming interface
 * @export
 */
export const CacheApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CacheApiAxiosParamCreator(configuration);
  return {
    /**
     * Refresh VehicleType and PricingLevel cache
     * @summary Refresh VehicleType and PricingLevel cache
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1RefreshCache(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1RefreshCache(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['CacheApi.v1RefreshCache']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Gets the pricing level cache
     * @summary Gets the pricing level cache
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1RetrievePricingLevelMapCache(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PricingLevelMapEntry>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.v1RetrievePricingLevelMapCache(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['CacheApi.v1RetrievePricingLevelMapCache']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * CacheApi - factory interface
 * @export
 */
export const CacheApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CacheApiFp(configuration);
  return {
    /**
     * Refresh VehicleType and PricingLevel cache
     * @summary Refresh VehicleType and PricingLevel cache
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1RefreshCache(options?: any): AxiosPromise<void> {
      return localVarFp.v1RefreshCache(options).then((request) => request(axios, basePath));
    },
    /**
     * Gets the pricing level cache
     * @summary Gets the pricing level cache
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1RetrievePricingLevelMapCache(options?: any): AxiosPromise<Array<PricingLevelMapEntry>> {
      return localVarFp
        .v1RetrievePricingLevelMapCache(options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * CacheApi - object-oriented interface
 * @export
 * @class CacheApi
 * @extends {BaseAPI}
 */
export class CacheApi extends BaseAPI {
  /**
   * Refresh VehicleType and PricingLevel cache
   * @summary Refresh VehicleType and PricingLevel cache
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CacheApi
   */
  public v1RefreshCache(options?: AxiosRequestConfig) {
    return CacheApiFp(this.configuration)
      .v1RefreshCache(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets the pricing level cache
   * @summary Gets the pricing level cache
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CacheApi
   */
  public v1RetrievePricingLevelMapCache(options?: AxiosRequestConfig) {
    return CacheApiFp(this.configuration)
      .v1RetrievePricingLevelMapCache(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CacheV2Api - axios parameter creator
 * @export
 */
export const CacheV2ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Refresh VehicleType and PricingLevel cache
     * @summary Refresh VehicleType and PricingLevel cache
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshCache: async (
      accessToken: string,
      idToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('refreshCache', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('refreshCache', 'idToken', idToken);
      const localVarPath = `/v2/cache/refresh`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Gets the pricing level cache
     * @summary Gets the pricing level cache
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retrievePricingLevelMapCache: async (
      accessToken: string,
      idToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('retrievePricingLevelMapCache', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('retrievePricingLevelMapCache', 'idToken', idToken);
      const localVarPath = `/v2/cache/pricing-level/retrieve`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * CacheV2Api - functional programming interface
 * @export
 */
export const CacheV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CacheV2ApiAxiosParamCreator(configuration);
  return {
    /**
     * Refresh VehicleType and PricingLevel cache
     * @summary Refresh VehicleType and PricingLevel cache
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshCache(
      accessToken: string,
      idToken: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.refreshCache(
        accessToken,
        idToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['CacheV2Api.refreshCache']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Gets the pricing level cache
     * @summary Gets the pricing level cache
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async retrievePricingLevelMapCache(
      accessToken: string,
      idToken: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PricingLevelMapEntry>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.retrievePricingLevelMapCache(
        accessToken,
        idToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['CacheV2Api.retrievePricingLevelMapCache']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * CacheV2Api - factory interface
 * @export
 */
export const CacheV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = CacheV2ApiFp(configuration);
  return {
    /**
     * Refresh VehicleType and PricingLevel cache
     * @summary Refresh VehicleType and PricingLevel cache
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshCache(accessToken: string, idToken: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .refreshCache(accessToken, idToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets the pricing level cache
     * @summary Gets the pricing level cache
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    retrievePricingLevelMapCache(
      accessToken: string,
      idToken: string,
      options?: any
    ): AxiosPromise<Array<PricingLevelMapEntry>> {
      return localVarFp
        .retrievePricingLevelMapCache(accessToken, idToken, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * CacheV2Api - object-oriented interface
 * @export
 * @class CacheV2Api
 * @extends {BaseAPI}
 */
export class CacheV2Api extends BaseAPI {
  /**
   * Refresh VehicleType and PricingLevel cache
   * @summary Refresh VehicleType and PricingLevel cache
   * @param {string} accessToken
   * @param {string} idToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CacheV2Api
   */
  public refreshCache(accessToken: string, idToken: string, options?: AxiosRequestConfig) {
    return CacheV2ApiFp(this.configuration)
      .refreshCache(accessToken, idToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets the pricing level cache
   * @summary Gets the pricing level cache
   * @param {string} accessToken
   * @param {string} idToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CacheV2Api
   */
  public retrievePricingLevelMapCache(
    accessToken: string,
    idToken: string,
    options?: AxiosRequestConfig
  ) {
    return CacheV2ApiFp(this.configuration)
      .retrievePricingLevelMapCache(accessToken, idToken, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DynamicPricingV2Api - axios parameter creator
 * @export
 */
export const DynamicPricingV2ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Create an instance of a given rule definition with a condition set attached
     * @summary Create an instance of a given rule definition with a condition set attached
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} ruleDefinitionId
     * @param {CreateRuleInstanceBody} createRuleInstanceBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRuleInstance: async (
      accessToken: string,
      idToken: string,
      ruleDefinitionId: number,
      createRuleInstanceBody: CreateRuleInstanceBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('createRuleInstance', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('createRuleInstance', 'idToken', idToken);
      // verify required parameter 'ruleDefinitionId' is not null or undefined
      assertParamExists('createRuleInstance', 'ruleDefinitionId', ruleDefinitionId);
      // verify required parameter 'createRuleInstanceBody' is not null or undefined
      assertParamExists('createRuleInstance', 'createRuleInstanceBody', createRuleInstanceBody);
      const localVarPath =
        `/v2/dynamic-pricing/rule-definitions/{ruleDefinitionId}/instances`.replace(
          `{${'ruleDefinitionId'}}`,
          encodeURIComponent(String(ruleDefinitionId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createRuleInstanceBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get all rule instances for all rule definitions
     * @summary Get all rule instances for all rule definitions
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {number} limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllRuleInstancesForAllRuleDefinitions: async (
      accessToken: string,
      idToken: string,
      locationId: number,
      limit: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getAllRuleInstancesForAllRuleDefinitions', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getAllRuleInstancesForAllRuleDefinitions', 'idToken', idToken);
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('getAllRuleInstancesForAllRuleDefinitions', 'locationId', locationId);
      // verify required parameter 'limit' is not null or undefined
      assertParamExists('getAllRuleInstancesForAllRuleDefinitions', 'limit', limit);
      const localVarPath = `/v2/dynamic-pricing/rule-definitions/*/rule-instances`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (limit !== undefined) {
        localVarQueryParameter['limit'] = limit;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get enabled and scheduled rule instances for a given rule definition
     * @summary Get enabled and scheduled rule instances for a given rule definition
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} ruleDefinitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllScheduledRuleInstances: async (
      accessToken: string,
      idToken: string,
      ruleDefinitionId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getAllScheduledRuleInstances', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getAllScheduledRuleInstances', 'idToken', idToken);
      // verify required parameter 'ruleDefinitionId' is not null or undefined
      assertParamExists('getAllScheduledRuleInstances', 'ruleDefinitionId', ruleDefinitionId);
      const localVarPath =
        `/v2/dynamic-pricing/rule-definitions/{ruleDefinitionId}/scheduled-rule-instances`.replace(
          `{${'ruleDefinitionId'}}`,
          encodeURIComponent(String(ruleDefinitionId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Gets the last N evaluation events
     * @summary Gets the last N evaluation events
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} ruleDefinitionId
     * @param {number} ruleInstanceId
     * @param {number} lastN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLastNEvaluationEvents: async (
      accessToken: string,
      idToken: string,
      ruleDefinitionId: number,
      ruleInstanceId: number,
      lastN: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getLastNEvaluationEvents', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getLastNEvaluationEvents', 'idToken', idToken);
      // verify required parameter 'ruleDefinitionId' is not null or undefined
      assertParamExists('getLastNEvaluationEvents', 'ruleDefinitionId', ruleDefinitionId);
      // verify required parameter 'ruleInstanceId' is not null or undefined
      assertParamExists('getLastNEvaluationEvents', 'ruleInstanceId', ruleInstanceId);
      // verify required parameter 'lastN' is not null or undefined
      assertParamExists('getLastNEvaluationEvents', 'lastN', lastN);
      const localVarPath =
        `/v2/dynamic-pricing/rule-definitions/{ruleDefinitionId}/rule-instances/{ruleInstanceId}/evaluation-events`
          .replace(`{${'ruleDefinitionId'}}`, encodeURIComponent(String(ruleDefinitionId)))
          .replace(`{${'ruleInstanceId'}}`, encodeURIComponent(String(ruleInstanceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (lastN !== undefined) {
        localVarQueryParameter['lastN'] = lastN;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Gets the last N price change events
     * @summary Gets the last N price change events
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} ruleDefinitionId
     * @param {number} ruleInstanceId
     * @param {number} lastN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLastNPriceChangeEvents: async (
      accessToken: string,
      idToken: string,
      ruleDefinitionId: number,
      ruleInstanceId: number,
      lastN: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getLastNPriceChangeEvents', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getLastNPriceChangeEvents', 'idToken', idToken);
      // verify required parameter 'ruleDefinitionId' is not null or undefined
      assertParamExists('getLastNPriceChangeEvents', 'ruleDefinitionId', ruleDefinitionId);
      // verify required parameter 'ruleInstanceId' is not null or undefined
      assertParamExists('getLastNPriceChangeEvents', 'ruleInstanceId', ruleInstanceId);
      // verify required parameter 'lastN' is not null or undefined
      assertParamExists('getLastNPriceChangeEvents', 'lastN', lastN);
      const localVarPath =
        `/v2/dynamic-pricing/rule-definitions/{ruleDefinitionId}/rule-instances/{ruleInstanceId}/price-change-events`
          .replace(`{${'ruleDefinitionId'}}`, encodeURIComponent(String(ruleDefinitionId)))
          .replace(`{${'ruleInstanceId'}}`, encodeURIComponent(String(ruleInstanceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (lastN !== undefined) {
        localVarQueryParameter['lastN'] = lastN;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Gets a list of all existing rule definitions in the specified location
     * @summary Gets a list of all existing rule definitions in the specified location
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRuleDefinitions: async (
      accessToken: string,
      idToken: string,
      locationId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getRuleDefinitions', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getRuleDefinitions', 'idToken', idToken);
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('getRuleDefinitions', 'locationId', locationId);
      const localVarPath = `/v2/dynamic-pricing/rule-definitions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get all rule instances for a given rule definition
     * @summary Get all rule instances for a given rule definition
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} ruleDefinitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRuleInstances: async (
      accessToken: string,
      idToken: string,
      ruleDefinitionId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getRuleInstances', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getRuleInstances', 'idToken', idToken);
      // verify required parameter 'ruleDefinitionId' is not null or undefined
      assertParamExists('getRuleInstances', 'ruleDefinitionId', ruleDefinitionId);
      const localVarPath =
        `/v2/dynamic-pricing/rule-definitions/{ruleDefinitionId}/instances`.replace(
          `{${'ruleDefinitionId'}}`,
          encodeURIComponent(String(ruleDefinitionId))
        );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Reset the pricing level for ended reservations, this is called by an AWS Step Function / Lambda function triggered by WunderFleet webhook events via the \'webhooks\' project
     * @summary Reset the pricing level for ended reservations
     * @param {EndReservationBody} endReservationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleEndReservation: async (
      endReservationBody: EndReservationBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'endReservationBody' is not null or undefined
      assertParamExists('handleEndReservation', 'endReservationBody', endReservationBody);
      const localVarPath = `/dynamic-pricing/end-reservation`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth1 required
      await setApiKeyToObject(localVarHeaderParameter, 'Authentication', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        endReservationBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Pauses the scheduling of the given rule instance, rule can be restarted later on
     * @summary Pauses the scheduling of the given rule instance, rule can be restarted later on
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} ruleDefinitionId
     * @param {number} ruleInstanceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pauseScheduledRuleInstance: async (
      accessToken: string,
      idToken: string,
      ruleDefinitionId: number,
      ruleInstanceId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('pauseScheduledRuleInstance', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('pauseScheduledRuleInstance', 'idToken', idToken);
      // verify required parameter 'ruleDefinitionId' is not null or undefined
      assertParamExists('pauseScheduledRuleInstance', 'ruleDefinitionId', ruleDefinitionId);
      // verify required parameter 'ruleInstanceId' is not null or undefined
      assertParamExists('pauseScheduledRuleInstance', 'ruleInstanceId', ruleInstanceId);
      const localVarPath =
        `/v2/dynamic-pricing/rule-definitions/{ruleDefinitionId}/rule-instances/{ruleInstanceId}/pause-scheduling`
          .replace(`{${'ruleDefinitionId'}}`, encodeURIComponent(String(ruleDefinitionId)))
          .replace(`{${'ruleInstanceId'}}`, encodeURIComponent(String(ruleInstanceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Starts the scheduling of the given rule instance
     * @summary Starts the scheduling of the given rule instance
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} ruleDefinitionId
     * @param {number} ruleInstanceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startScheduledRuleInstance: async (
      accessToken: string,
      idToken: string,
      ruleDefinitionId: number,
      ruleInstanceId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('startScheduledRuleInstance', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('startScheduledRuleInstance', 'idToken', idToken);
      // verify required parameter 'ruleDefinitionId' is not null or undefined
      assertParamExists('startScheduledRuleInstance', 'ruleDefinitionId', ruleDefinitionId);
      // verify required parameter 'ruleInstanceId' is not null or undefined
      assertParamExists('startScheduledRuleInstance', 'ruleInstanceId', ruleInstanceId);
      const localVarPath =
        `/v2/dynamic-pricing/rule-definitions/{ruleDefinitionId}/rule-instances/{ruleInstanceId}/start-scheduling`
          .replace(`{${'ruleDefinitionId'}}`, encodeURIComponent(String(ruleDefinitionId)))
          .replace(`{${'ruleInstanceId'}}`, encodeURIComponent(String(ruleInstanceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Terminates the scheduling of the given rule instance, rule cannot be restarted after this
     * @summary Terminates the scheduling of the given rule instance, rule cannot be restarted after this
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} ruleDefinitionId
     * @param {number} ruleInstanceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopScheduledRuleInstance: async (
      accessToken: string,
      idToken: string,
      ruleDefinitionId: number,
      ruleInstanceId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('stopScheduledRuleInstance', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('stopScheduledRuleInstance', 'idToken', idToken);
      // verify required parameter 'ruleDefinitionId' is not null or undefined
      assertParamExists('stopScheduledRuleInstance', 'ruleDefinitionId', ruleDefinitionId);
      // verify required parameter 'ruleInstanceId' is not null or undefined
      assertParamExists('stopScheduledRuleInstance', 'ruleInstanceId', ruleInstanceId);
      const localVarPath =
        `/v2/dynamic-pricing/rule-definitions/{ruleDefinitionId}/rule-instances/{ruleInstanceId}/stop-scheduling`
          .replace(`{${'ruleDefinitionId'}}`, encodeURIComponent(String(ruleDefinitionId)))
          .replace(`{${'ruleInstanceId'}}`, encodeURIComponent(String(ruleInstanceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * DynamicPricingV2Api - functional programming interface
 * @export
 */
export const DynamicPricingV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DynamicPricingV2ApiAxiosParamCreator(configuration);
  return {
    /**
     * Create an instance of a given rule definition with a condition set attached
     * @summary Create an instance of a given rule definition with a condition set attached
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} ruleDefinitionId
     * @param {CreateRuleInstanceBody} createRuleInstanceBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createRuleInstance(
      accessToken: string,
      idToken: string,
      ruleDefinitionId: number,
      createRuleInstanceBody: CreateRuleInstanceBody,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<RuleInstanceWithConditionSet>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createRuleInstance(
        accessToken,
        idToken,
        ruleDefinitionId,
        createRuleInstanceBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DynamicPricingV2Api.createRuleInstance']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get all rule instances for all rule definitions
     * @summary Get all rule instances for all rule definitions
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {number} limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllRuleInstancesForAllRuleDefinitions(
      accessToken: string,
      idToken: string,
      locationId: number,
      limit: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RuleDefinitionWithInstances>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getAllRuleInstancesForAllRuleDefinitions(
          accessToken,
          idToken,
          locationId,
          limit,
          options
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DynamicPricingV2Api.getAllRuleInstancesForAllRuleDefinitions']?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get enabled and scheduled rule instances for a given rule definition
     * @summary Get enabled and scheduled rule instances for a given rule definition
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} ruleDefinitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllScheduledRuleInstances(
      accessToken: string,
      idToken: string,
      ruleDefinitionId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RuleInstance>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllScheduledRuleInstances(
        accessToken,
        idToken,
        ruleDefinitionId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DynamicPricingV2Api.getAllScheduledRuleInstances']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Gets the last N evaluation events
     * @summary Gets the last N evaluation events
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} ruleDefinitionId
     * @param {number} ruleInstanceId
     * @param {number} lastN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLastNEvaluationEvents(
      accessToken: string,
      idToken: string,
      ruleDefinitionId: number,
      ruleInstanceId: number,
      lastN: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EvaluationEvent>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLastNEvaluationEvents(
        accessToken,
        idToken,
        ruleDefinitionId,
        ruleInstanceId,
        lastN,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DynamicPricingV2Api.getLastNEvaluationEvents']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Gets the last N price change events
     * @summary Gets the last N price change events
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} ruleDefinitionId
     * @param {number} ruleInstanceId
     * @param {number} lastN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLastNPriceChangeEvents(
      accessToken: string,
      idToken: string,
      ruleDefinitionId: number,
      ruleInstanceId: number,
      lastN: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PriceChangeEvent>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLastNPriceChangeEvents(
        accessToken,
        idToken,
        ruleDefinitionId,
        ruleInstanceId,
        lastN,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DynamicPricingV2Api.getLastNPriceChangeEvents']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Gets a list of all existing rule definitions in the specified location
     * @summary Gets a list of all existing rule definitions in the specified location
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRuleDefinitions(
      accessToken: string,
      idToken: string,
      locationId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RuleDefinition>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRuleDefinitions(
        accessToken,
        idToken,
        locationId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DynamicPricingV2Api.getRuleDefinitions']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get all rule instances for a given rule definition
     * @summary Get all rule instances for a given rule definition
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} ruleDefinitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRuleInstances(
      accessToken: string,
      idToken: string,
      ruleDefinitionId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<RuleInstance>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRuleInstances(
        accessToken,
        idToken,
        ruleDefinitionId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DynamicPricingV2Api.getRuleInstances']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Reset the pricing level for ended reservations, this is called by an AWS Step Function / Lambda function triggered by WunderFleet webhook events via the \'webhooks\' project
     * @summary Reset the pricing level for ended reservations
     * @param {EndReservationBody} endReservationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async handleEndReservation(
      endReservationBody: EndReservationBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.handleEndReservation(
        endReservationBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DynamicPricingV2Api.handleEndReservation']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Pauses the scheduling of the given rule instance, rule can be restarted later on
     * @summary Pauses the scheduling of the given rule instance, rule can be restarted later on
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} ruleDefinitionId
     * @param {number} ruleInstanceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async pauseScheduledRuleInstance(
      accessToken: string,
      idToken: string,
      ruleDefinitionId: number,
      ruleInstanceId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.pauseScheduledRuleInstance(
        accessToken,
        idToken,
        ruleDefinitionId,
        ruleInstanceId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DynamicPricingV2Api.pauseScheduledRuleInstance']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Starts the scheduling of the given rule instance
     * @summary Starts the scheduling of the given rule instance
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} ruleDefinitionId
     * @param {number} ruleInstanceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async startScheduledRuleInstance(
      accessToken: string,
      idToken: string,
      ruleDefinitionId: number,
      ruleInstanceId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.startScheduledRuleInstance(
        accessToken,
        idToken,
        ruleDefinitionId,
        ruleInstanceId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DynamicPricingV2Api.startScheduledRuleInstance']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Terminates the scheduling of the given rule instance, rule cannot be restarted after this
     * @summary Terminates the scheduling of the given rule instance, rule cannot be restarted after this
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} ruleDefinitionId
     * @param {number} ruleInstanceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async stopScheduledRuleInstance(
      accessToken: string,
      idToken: string,
      ruleDefinitionId: number,
      ruleInstanceId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.stopScheduledRuleInstance(
        accessToken,
        idToken,
        ruleDefinitionId,
        ruleInstanceId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['DynamicPricingV2Api.stopScheduledRuleInstance']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * DynamicPricingV2Api - factory interface
 * @export
 */
export const DynamicPricingV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = DynamicPricingV2ApiFp(configuration);
  return {
    /**
     * Create an instance of a given rule definition with a condition set attached
     * @summary Create an instance of a given rule definition with a condition set attached
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} ruleDefinitionId
     * @param {CreateRuleInstanceBody} createRuleInstanceBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRuleInstance(
      accessToken: string,
      idToken: string,
      ruleDefinitionId: number,
      createRuleInstanceBody: CreateRuleInstanceBody,
      options?: any
    ): AxiosPromise<RuleInstanceWithConditionSet> {
      return localVarFp
        .createRuleInstance(accessToken, idToken, ruleDefinitionId, createRuleInstanceBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all rule instances for all rule definitions
     * @summary Get all rule instances for all rule definitions
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {number} limit
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllRuleInstancesForAllRuleDefinitions(
      accessToken: string,
      idToken: string,
      locationId: number,
      limit: number,
      options?: any
    ): AxiosPromise<Array<RuleDefinitionWithInstances>> {
      return localVarFp
        .getAllRuleInstancesForAllRuleDefinitions(accessToken, idToken, locationId, limit, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get enabled and scheduled rule instances for a given rule definition
     * @summary Get enabled and scheduled rule instances for a given rule definition
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} ruleDefinitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllScheduledRuleInstances(
      accessToken: string,
      idToken: string,
      ruleDefinitionId: number,
      options?: any
    ): AxiosPromise<Array<RuleInstance>> {
      return localVarFp
        .getAllScheduledRuleInstances(accessToken, idToken, ruleDefinitionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets the last N evaluation events
     * @summary Gets the last N evaluation events
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} ruleDefinitionId
     * @param {number} ruleInstanceId
     * @param {number} lastN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLastNEvaluationEvents(
      accessToken: string,
      idToken: string,
      ruleDefinitionId: number,
      ruleInstanceId: number,
      lastN: number,
      options?: any
    ): AxiosPromise<Array<EvaluationEvent>> {
      return localVarFp
        .getLastNEvaluationEvents(
          accessToken,
          idToken,
          ruleDefinitionId,
          ruleInstanceId,
          lastN,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets the last N price change events
     * @summary Gets the last N price change events
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} ruleDefinitionId
     * @param {number} ruleInstanceId
     * @param {number} lastN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLastNPriceChangeEvents(
      accessToken: string,
      idToken: string,
      ruleDefinitionId: number,
      ruleInstanceId: number,
      lastN: number,
      options?: any
    ): AxiosPromise<Array<PriceChangeEvent>> {
      return localVarFp
        .getLastNPriceChangeEvents(
          accessToken,
          idToken,
          ruleDefinitionId,
          ruleInstanceId,
          lastN,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets a list of all existing rule definitions in the specified location
     * @summary Gets a list of all existing rule definitions in the specified location
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRuleDefinitions(
      accessToken: string,
      idToken: string,
      locationId: number,
      options?: any
    ): AxiosPromise<Array<RuleDefinition>> {
      return localVarFp
        .getRuleDefinitions(accessToken, idToken, locationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all rule instances for a given rule definition
     * @summary Get all rule instances for a given rule definition
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} ruleDefinitionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRuleInstances(
      accessToken: string,
      idToken: string,
      ruleDefinitionId: number,
      options?: any
    ): AxiosPromise<Array<RuleInstance>> {
      return localVarFp
        .getRuleInstances(accessToken, idToken, ruleDefinitionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Reset the pricing level for ended reservations, this is called by an AWS Step Function / Lambda function triggered by WunderFleet webhook events via the \'webhooks\' project
     * @summary Reset the pricing level for ended reservations
     * @param {EndReservationBody} endReservationBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    handleEndReservation(
      endReservationBody: EndReservationBody,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .handleEndReservation(endReservationBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Pauses the scheduling of the given rule instance, rule can be restarted later on
     * @summary Pauses the scheduling of the given rule instance, rule can be restarted later on
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} ruleDefinitionId
     * @param {number} ruleInstanceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    pauseScheduledRuleInstance(
      accessToken: string,
      idToken: string,
      ruleDefinitionId: number,
      ruleInstanceId: number,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .pauseScheduledRuleInstance(accessToken, idToken, ruleDefinitionId, ruleInstanceId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Starts the scheduling of the given rule instance
     * @summary Starts the scheduling of the given rule instance
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} ruleDefinitionId
     * @param {number} ruleInstanceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startScheduledRuleInstance(
      accessToken: string,
      idToken: string,
      ruleDefinitionId: number,
      ruleInstanceId: number,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .startScheduledRuleInstance(accessToken, idToken, ruleDefinitionId, ruleInstanceId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Terminates the scheduling of the given rule instance, rule cannot be restarted after this
     * @summary Terminates the scheduling of the given rule instance, rule cannot be restarted after this
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} ruleDefinitionId
     * @param {number} ruleInstanceId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    stopScheduledRuleInstance(
      accessToken: string,
      idToken: string,
      ruleDefinitionId: number,
      ruleInstanceId: number,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .stopScheduledRuleInstance(accessToken, idToken, ruleDefinitionId, ruleInstanceId, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * DynamicPricingV2Api - object-oriented interface
 * @export
 * @class DynamicPricingV2Api
 * @extends {BaseAPI}
 */
export class DynamicPricingV2Api extends BaseAPI {
  /**
   * Create an instance of a given rule definition with a condition set attached
   * @summary Create an instance of a given rule definition with a condition set attached
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} ruleDefinitionId
   * @param {CreateRuleInstanceBody} createRuleInstanceBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DynamicPricingV2Api
   */
  public createRuleInstance(
    accessToken: string,
    idToken: string,
    ruleDefinitionId: number,
    createRuleInstanceBody: CreateRuleInstanceBody,
    options?: AxiosRequestConfig
  ) {
    return DynamicPricingV2ApiFp(this.configuration)
      .createRuleInstance(accessToken, idToken, ruleDefinitionId, createRuleInstanceBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all rule instances for all rule definitions
   * @summary Get all rule instances for all rule definitions
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} locationId
   * @param {number} limit
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DynamicPricingV2Api
   */
  public getAllRuleInstancesForAllRuleDefinitions(
    accessToken: string,
    idToken: string,
    locationId: number,
    limit: number,
    options?: AxiosRequestConfig
  ) {
    return DynamicPricingV2ApiFp(this.configuration)
      .getAllRuleInstancesForAllRuleDefinitions(accessToken, idToken, locationId, limit, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get enabled and scheduled rule instances for a given rule definition
   * @summary Get enabled and scheduled rule instances for a given rule definition
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} ruleDefinitionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DynamicPricingV2Api
   */
  public getAllScheduledRuleInstances(
    accessToken: string,
    idToken: string,
    ruleDefinitionId: number,
    options?: AxiosRequestConfig
  ) {
    return DynamicPricingV2ApiFp(this.configuration)
      .getAllScheduledRuleInstances(accessToken, idToken, ruleDefinitionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets the last N evaluation events
   * @summary Gets the last N evaluation events
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} ruleDefinitionId
   * @param {number} ruleInstanceId
   * @param {number} lastN
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DynamicPricingV2Api
   */
  public getLastNEvaluationEvents(
    accessToken: string,
    idToken: string,
    ruleDefinitionId: number,
    ruleInstanceId: number,
    lastN: number,
    options?: AxiosRequestConfig
  ) {
    return DynamicPricingV2ApiFp(this.configuration)
      .getLastNEvaluationEvents(
        accessToken,
        idToken,
        ruleDefinitionId,
        ruleInstanceId,
        lastN,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets the last N price change events
   * @summary Gets the last N price change events
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} ruleDefinitionId
   * @param {number} ruleInstanceId
   * @param {number} lastN
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DynamicPricingV2Api
   */
  public getLastNPriceChangeEvents(
    accessToken: string,
    idToken: string,
    ruleDefinitionId: number,
    ruleInstanceId: number,
    lastN: number,
    options?: AxiosRequestConfig
  ) {
    return DynamicPricingV2ApiFp(this.configuration)
      .getLastNPriceChangeEvents(
        accessToken,
        idToken,
        ruleDefinitionId,
        ruleInstanceId,
        lastN,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets a list of all existing rule definitions in the specified location
   * @summary Gets a list of all existing rule definitions in the specified location
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} locationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DynamicPricingV2Api
   */
  public getRuleDefinitions(
    accessToken: string,
    idToken: string,
    locationId: number,
    options?: AxiosRequestConfig
  ) {
    return DynamicPricingV2ApiFp(this.configuration)
      .getRuleDefinitions(accessToken, idToken, locationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all rule instances for a given rule definition
   * @summary Get all rule instances for a given rule definition
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} ruleDefinitionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DynamicPricingV2Api
   */
  public getRuleInstances(
    accessToken: string,
    idToken: string,
    ruleDefinitionId: number,
    options?: AxiosRequestConfig
  ) {
    return DynamicPricingV2ApiFp(this.configuration)
      .getRuleInstances(accessToken, idToken, ruleDefinitionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Reset the pricing level for ended reservations, this is called by an AWS Step Function / Lambda function triggered by WunderFleet webhook events via the \'webhooks\' project
   * @summary Reset the pricing level for ended reservations
   * @param {EndReservationBody} endReservationBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DynamicPricingV2Api
   */
  public handleEndReservation(
    endReservationBody: EndReservationBody,
    options?: AxiosRequestConfig
  ) {
    return DynamicPricingV2ApiFp(this.configuration)
      .handleEndReservation(endReservationBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Pauses the scheduling of the given rule instance, rule can be restarted later on
   * @summary Pauses the scheduling of the given rule instance, rule can be restarted later on
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} ruleDefinitionId
   * @param {number} ruleInstanceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DynamicPricingV2Api
   */
  public pauseScheduledRuleInstance(
    accessToken: string,
    idToken: string,
    ruleDefinitionId: number,
    ruleInstanceId: number,
    options?: AxiosRequestConfig
  ) {
    return DynamicPricingV2ApiFp(this.configuration)
      .pauseScheduledRuleInstance(accessToken, idToken, ruleDefinitionId, ruleInstanceId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Starts the scheduling of the given rule instance
   * @summary Starts the scheduling of the given rule instance
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} ruleDefinitionId
   * @param {number} ruleInstanceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DynamicPricingV2Api
   */
  public startScheduledRuleInstance(
    accessToken: string,
    idToken: string,
    ruleDefinitionId: number,
    ruleInstanceId: number,
    options?: AxiosRequestConfig
  ) {
    return DynamicPricingV2ApiFp(this.configuration)
      .startScheduledRuleInstance(accessToken, idToken, ruleDefinitionId, ruleInstanceId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Terminates the scheduling of the given rule instance, rule cannot be restarted after this
   * @summary Terminates the scheduling of the given rule instance, rule cannot be restarted after this
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} ruleDefinitionId
   * @param {number} ruleInstanceId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DynamicPricingV2Api
   */
  public stopScheduledRuleInstance(
    accessToken: string,
    idToken: string,
    ruleDefinitionId: number,
    ruleInstanceId: number,
    options?: AxiosRequestConfig
  ) {
    return DynamicPricingV2ApiFp(this.configuration)
      .stopScheduledRuleInstance(accessToken, idToken, ruleDefinitionId, ruleInstanceId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * HealthV2Api - axios parameter creator
 * @export
 */
export const HealthV2ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get API Health
     * @summary Get API Health
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkHealth: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/health`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * HealthV2Api - functional programming interface
 * @export
 */
export const HealthV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = HealthV2ApiAxiosParamCreator(configuration);
  return {
    /**
     * Get API Health
     * @summary Get API Health
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async checkHealth(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Health>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.checkHealth(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['HealthV2Api.checkHealth']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * HealthV2Api - factory interface
 * @export
 */
export const HealthV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = HealthV2ApiFp(configuration);
  return {
    /**
     * Get API Health
     * @summary Get API Health
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    checkHealth(options?: any): AxiosPromise<Health> {
      return localVarFp.checkHealth(options).then((request) => request(axios, basePath));
    }
  };
};

/**
 * HealthV2Api - object-oriented interface
 * @export
 * @class HealthV2Api
 * @extends {BaseAPI}
 */
export class HealthV2Api extends BaseAPI {
  /**
   * Get API Health
   * @summary Get API Health
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthV2Api
   */
  public checkHealth(options?: AxiosRequestConfig) {
    return HealthV2ApiFp(this.configuration)
      .checkHealth(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * JiraBulkActionsV2Api - axios parameter creator
 * @export
 */
export const JiraBulkActionsV2ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Aborts the previously submitted bulk create
     * @summary Aborts the previously submitted bulk create
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkCreateAssetsAbort: async (
      accessToken: string,
      idToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('bulkCreateAssetsAbort', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('bulkCreateAssetsAbort', 'idToken', idToken);
      const localVarPath = `/v2/jira/assets/bulk-create/abort`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Clear status report of previously submitted bulk create, download report
     * @summary Clear status report of previously submitted bulk create, download report
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkCreateAssetsComplete: async (
      accessToken: string,
      idToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('bulkCreateAssetsComplete', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('bulkCreateAssetsComplete', 'idToken', idToken);
      const localVarPath = `/v2/jira/assets/bulk-create/complete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Starts bulk creation of previously validated assets file
     * @summary Starts bulk creation of previously validated assets file
     * @param {string} accessToken
     * @param {string} idToken
     * @param {BulkCreateAssetsFileValidation} bulkCreateAssetsFileValidation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkCreateAssetsStart: async (
      accessToken: string,
      idToken: string,
      bulkCreateAssetsFileValidation: BulkCreateAssetsFileValidation,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('bulkCreateAssetsStart', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('bulkCreateAssetsStart', 'idToken', idToken);
      // verify required parameter 'bulkCreateAssetsFileValidation' is not null or undefined
      assertParamExists(
        'bulkCreateAssetsStart',
        'bulkCreateAssetsFileValidation',
        bulkCreateAssetsFileValidation
      );
      const localVarPath = `/v2/jira/assets/bulk-create/start`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bulkCreateAssetsFileValidation,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get status report of previously submitted bulk create
     * @summary Get status report of previously submitted bulk create
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkCreateAssetsStatus: async (
      accessToken: string,
      idToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('bulkCreateAssetsStatus', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('bulkCreateAssetsStatus', 'idToken', idToken);
      const localVarPath = `/v2/jira/assets/bulk-create/status`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Validates an Excel file to be used for creating multiple Jira assets
     * @summary Validates an Excel file to be used for creating multiple Jira assets
     * @param {string} accessToken
     * @param {string} idToken
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkCreateAssetsValidateFile: async (
      accessToken: string,
      idToken: string,
      file: File,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('bulkCreateAssetsValidateFile', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('bulkCreateAssetsValidateFile', 'idToken', idToken);
      // verify required parameter 'file' is not null or undefined
      assertParamExists('bulkCreateAssetsValidateFile', 'file', file);
      const localVarPath = `/v2/jira/assets/bulk-create/validate-file`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * This route requires credentials of an admin user or a user in the group oc_bulkoperations
     * @summary Creates a service on street subtask for each of the provided vehicles identified via VIN
     * @param {string} accessToken
     * @param {string} idToken
     * @param {JiraBulkCreateSubtaskBody} jiraBulkCreateSubtaskBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkCreateServiceOnStreetSubtask: async (
      accessToken: string,
      idToken: string,
      jiraBulkCreateSubtaskBody: JiraBulkCreateSubtaskBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('bulkCreateServiceOnStreetSubtask', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('bulkCreateServiceOnStreetSubtask', 'idToken', idToken);
      // verify required parameter 'jiraBulkCreateSubtaskBody' is not null or undefined
      assertParamExists(
        'bulkCreateServiceOnStreetSubtask',
        'jiraBulkCreateSubtaskBody',
        jiraBulkCreateSubtaskBody
      );
      const localVarPath = `/v2/jira/service-on-street-task-list/subtasks/bulk-create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        jiraBulkCreateSubtaskBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * JiraBulkActionsV2Api - functional programming interface
 * @export
 */
export const JiraBulkActionsV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = JiraBulkActionsV2ApiAxiosParamCreator(configuration);
  return {
    /**
     * Aborts the previously submitted bulk create
     * @summary Aborts the previously submitted bulk create
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bulkCreateAssetsAbort(
      accessToken: string,
      idToken: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bulkCreateAssetsAbort(
        accessToken,
        idToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['JiraBulkActionsV2Api.bulkCreateAssetsAbort']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Clear status report of previously submitted bulk create, download report
     * @summary Clear status report of previously submitted bulk create, download report
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bulkCreateAssetsComplete(
      accessToken: string,
      idToken: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bulkCreateAssetsComplete(
        accessToken,
        idToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['JiraBulkActionsV2Api.bulkCreateAssetsComplete']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Starts bulk creation of previously validated assets file
     * @summary Starts bulk creation of previously validated assets file
     * @param {string} accessToken
     * @param {string} idToken
     * @param {BulkCreateAssetsFileValidation} bulkCreateAssetsFileValidation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bulkCreateAssetsStart(
      accessToken: string,
      idToken: string,
      bulkCreateAssetsFileValidation: BulkCreateAssetsFileValidation,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bulkCreateAssetsStart(
        accessToken,
        idToken,
        bulkCreateAssetsFileValidation,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['JiraBulkActionsV2Api.bulkCreateAssetsStart']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get status report of previously submitted bulk create
     * @summary Get status report of previously submitted bulk create
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bulkCreateAssetsStatus(
      accessToken: string,
      idToken: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkCreateStatus>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bulkCreateAssetsStatus(
        accessToken,
        idToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['JiraBulkActionsV2Api.bulkCreateAssetsStatus']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Validates an Excel file to be used for creating multiple Jira assets
     * @summary Validates an Excel file to be used for creating multiple Jira assets
     * @param {string} accessToken
     * @param {string} idToken
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bulkCreateAssetsValidateFile(
      accessToken: string,
      idToken: string,
      file: File,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkCreateAssetsFileValidation>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bulkCreateAssetsValidateFile(
        accessToken,
        idToken,
        file,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['JiraBulkActionsV2Api.bulkCreateAssetsValidateFile']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * This route requires credentials of an admin user or a user in the group oc_bulkoperations
     * @summary Creates a service on street subtask for each of the provided vehicles identified via VIN
     * @param {string} accessToken
     * @param {string} idToken
     * @param {JiraBulkCreateSubtaskBody} jiraBulkCreateSubtaskBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bulkCreateServiceOnStreetSubtask(
      accessToken: string,
      idToken: string,
      jiraBulkCreateSubtaskBody: JiraBulkCreateSubtaskBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bulkCreateServiceOnStreetSubtask(
        accessToken,
        idToken,
        jiraBulkCreateSubtaskBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['JiraBulkActionsV2Api.bulkCreateServiceOnStreetSubtask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * JiraBulkActionsV2Api - factory interface
 * @export
 */
export const JiraBulkActionsV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = JiraBulkActionsV2ApiFp(configuration);
  return {
    /**
     * Aborts the previously submitted bulk create
     * @summary Aborts the previously submitted bulk create
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkCreateAssetsAbort(accessToken: string, idToken: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .bulkCreateAssetsAbort(accessToken, idToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Clear status report of previously submitted bulk create, download report
     * @summary Clear status report of previously submitted bulk create, download report
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkCreateAssetsComplete(
      accessToken: string,
      idToken: string,
      options?: any
    ): AxiosPromise<File> {
      return localVarFp
        .bulkCreateAssetsComplete(accessToken, idToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Starts bulk creation of previously validated assets file
     * @summary Starts bulk creation of previously validated assets file
     * @param {string} accessToken
     * @param {string} idToken
     * @param {BulkCreateAssetsFileValidation} bulkCreateAssetsFileValidation
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkCreateAssetsStart(
      accessToken: string,
      idToken: string,
      bulkCreateAssetsFileValidation: BulkCreateAssetsFileValidation,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .bulkCreateAssetsStart(accessToken, idToken, bulkCreateAssetsFileValidation, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get status report of previously submitted bulk create
     * @summary Get status report of previously submitted bulk create
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkCreateAssetsStatus(
      accessToken: string,
      idToken: string,
      options?: any
    ): AxiosPromise<BulkCreateStatus> {
      return localVarFp
        .bulkCreateAssetsStatus(accessToken, idToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Validates an Excel file to be used for creating multiple Jira assets
     * @summary Validates an Excel file to be used for creating multiple Jira assets
     * @param {string} accessToken
     * @param {string} idToken
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkCreateAssetsValidateFile(
      accessToken: string,
      idToken: string,
      file: File,
      options?: any
    ): AxiosPromise<BulkCreateAssetsFileValidation> {
      return localVarFp
        .bulkCreateAssetsValidateFile(accessToken, idToken, file, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This route requires credentials of an admin user or a user in the group oc_bulkoperations
     * @summary Creates a service on street subtask for each of the provided vehicles identified via VIN
     * @param {string} accessToken
     * @param {string} idToken
     * @param {JiraBulkCreateSubtaskBody} jiraBulkCreateSubtaskBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkCreateServiceOnStreetSubtask(
      accessToken: string,
      idToken: string,
      jiraBulkCreateSubtaskBody: JiraBulkCreateSubtaskBody,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .bulkCreateServiceOnStreetSubtask(accessToken, idToken, jiraBulkCreateSubtaskBody, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * JiraBulkActionsV2Api - object-oriented interface
 * @export
 * @class JiraBulkActionsV2Api
 * @extends {BaseAPI}
 */
export class JiraBulkActionsV2Api extends BaseAPI {
  /**
   * Aborts the previously submitted bulk create
   * @summary Aborts the previously submitted bulk create
   * @param {string} accessToken
   * @param {string} idToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JiraBulkActionsV2Api
   */
  public bulkCreateAssetsAbort(accessToken: string, idToken: string, options?: AxiosRequestConfig) {
    return JiraBulkActionsV2ApiFp(this.configuration)
      .bulkCreateAssetsAbort(accessToken, idToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Clear status report of previously submitted bulk create, download report
   * @summary Clear status report of previously submitted bulk create, download report
   * @param {string} accessToken
   * @param {string} idToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JiraBulkActionsV2Api
   */
  public bulkCreateAssetsComplete(
    accessToken: string,
    idToken: string,
    options?: AxiosRequestConfig
  ) {
    return JiraBulkActionsV2ApiFp(this.configuration)
      .bulkCreateAssetsComplete(accessToken, idToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Starts bulk creation of previously validated assets file
   * @summary Starts bulk creation of previously validated assets file
   * @param {string} accessToken
   * @param {string} idToken
   * @param {BulkCreateAssetsFileValidation} bulkCreateAssetsFileValidation
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JiraBulkActionsV2Api
   */
  public bulkCreateAssetsStart(
    accessToken: string,
    idToken: string,
    bulkCreateAssetsFileValidation: BulkCreateAssetsFileValidation,
    options?: AxiosRequestConfig
  ) {
    return JiraBulkActionsV2ApiFp(this.configuration)
      .bulkCreateAssetsStart(accessToken, idToken, bulkCreateAssetsFileValidation, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get status report of previously submitted bulk create
   * @summary Get status report of previously submitted bulk create
   * @param {string} accessToken
   * @param {string} idToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JiraBulkActionsV2Api
   */
  public bulkCreateAssetsStatus(
    accessToken: string,
    idToken: string,
    options?: AxiosRequestConfig
  ) {
    return JiraBulkActionsV2ApiFp(this.configuration)
      .bulkCreateAssetsStatus(accessToken, idToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Validates an Excel file to be used for creating multiple Jira assets
   * @summary Validates an Excel file to be used for creating multiple Jira assets
   * @param {string} accessToken
   * @param {string} idToken
   * @param {File} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JiraBulkActionsV2Api
   */
  public bulkCreateAssetsValidateFile(
    accessToken: string,
    idToken: string,
    file: File,
    options?: AxiosRequestConfig
  ) {
    return JiraBulkActionsV2ApiFp(this.configuration)
      .bulkCreateAssetsValidateFile(accessToken, idToken, file, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This route requires credentials of an admin user or a user in the group oc_bulkoperations
   * @summary Creates a service on street subtask for each of the provided vehicles identified via VIN
   * @param {string} accessToken
   * @param {string} idToken
   * @param {JiraBulkCreateSubtaskBody} jiraBulkCreateSubtaskBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JiraBulkActionsV2Api
   */
  public bulkCreateServiceOnStreetSubtask(
    accessToken: string,
    idToken: string,
    jiraBulkCreateSubtaskBody: JiraBulkCreateSubtaskBody,
    options?: AxiosRequestConfig
  ) {
    return JiraBulkActionsV2ApiFp(this.configuration)
      .bulkCreateServiceOnStreetSubtask(accessToken, idToken, jiraBulkCreateSubtaskBody, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * JiraV2Api - axios parameter creator
 * @export
 */
export const JiraV2ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Checks if a repair ticket can be created on the given asset
     * @summary Checks if a repair ticket can be created on the given asset
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRepairTicketConditionCheck: async (
      accessToken: string,
      idToken: string,
      vin: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('createRepairTicketConditionCheck', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('createRepairTicketConditionCheck', 'idToken', idToken);
      // verify required parameter 'vin' is not null or undefined
      assertParamExists('createRepairTicketConditionCheck', 'vin', vin);
      const localVarPath = `/v2/jira/assets/{vin}/create-repair-ticket/condition-check`.replace(
        `{${'vin'}}`,
        encodeURIComponent(String(vin))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * List Jira projects
     * @summary List Jira projects
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjects: async (
      accessToken: string,
      idToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getProjects', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getProjects', 'idToken', idToken);
      const localVarPath = `/v2/jira/projects`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Sets the priority of the given Jira ticket
     * @summary Sets the priority of the given Jira ticket
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} issueKey
     * @param {JiraPriorityBody} jiraPriorityBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setJiraIssuePriority: async (
      accessToken: string,
      idToken: string,
      issueKey: string,
      jiraPriorityBody: JiraPriorityBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('setJiraIssuePriority', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('setJiraIssuePriority', 'idToken', idToken);
      // verify required parameter 'issueKey' is not null or undefined
      assertParamExists('setJiraIssuePriority', 'issueKey', issueKey);
      // verify required parameter 'jiraPriorityBody' is not null or undefined
      assertParamExists('setJiraIssuePriority', 'jiraPriorityBody', jiraPriorityBody);
      const localVarPath = `/v2/jira/issues/{issueKey}/set-priority`.replace(
        `{${'issueKey'}}`,
        encodeURIComponent(String(issueKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        jiraPriorityBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * JiraV2Api - functional programming interface
 * @export
 */
export const JiraV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = JiraV2ApiAxiosParamCreator(configuration);
  return {
    /**
     * Checks if a repair ticket can be created on the given asset
     * @summary Checks if a repair ticket can be created on the given asset
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createRepairTicketConditionCheck(
      accessToken: string,
      idToken: string,
      vin: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PreconditionCheckResult>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createRepairTicketConditionCheck(
        accessToken,
        idToken,
        vin,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['JiraV2Api.createRepairTicketConditionCheck']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * List Jira projects
     * @summary List Jira projects
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProjects(
      accessToken: string,
      idToken: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Project>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProjects(
        accessToken,
        idToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['JiraV2Api.getProjects']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Sets the priority of the given Jira ticket
     * @summary Sets the priority of the given Jira ticket
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} issueKey
     * @param {JiraPriorityBody} jiraPriorityBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async setJiraIssuePriority(
      accessToken: string,
      idToken: string,
      issueKey: string,
      jiraPriorityBody: JiraPriorityBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.setJiraIssuePriority(
        accessToken,
        idToken,
        issueKey,
        jiraPriorityBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['JiraV2Api.setJiraIssuePriority']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * JiraV2Api - factory interface
 * @export
 */
export const JiraV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = JiraV2ApiFp(configuration);
  return {
    /**
     * Checks if a repair ticket can be created on the given asset
     * @summary Checks if a repair ticket can be created on the given asset
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createRepairTicketConditionCheck(
      accessToken: string,
      idToken: string,
      vin: string,
      options?: any
    ): AxiosPromise<PreconditionCheckResult> {
      return localVarFp
        .createRepairTicketConditionCheck(accessToken, idToken, vin, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * List Jira projects
     * @summary List Jira projects
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProjects(accessToken: string, idToken: string, options?: any): AxiosPromise<Array<Project>> {
      return localVarFp
        .getProjects(accessToken, idToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Sets the priority of the given Jira ticket
     * @summary Sets the priority of the given Jira ticket
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} issueKey
     * @param {JiraPriorityBody} jiraPriorityBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    setJiraIssuePriority(
      accessToken: string,
      idToken: string,
      issueKey: string,
      jiraPriorityBody: JiraPriorityBody,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .setJiraIssuePriority(accessToken, idToken, issueKey, jiraPriorityBody, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * JiraV2Api - object-oriented interface
 * @export
 * @class JiraV2Api
 * @extends {BaseAPI}
 */
export class JiraV2Api extends BaseAPI {
  /**
   * Checks if a repair ticket can be created on the given asset
   * @summary Checks if a repair ticket can be created on the given asset
   * @param {string} accessToken
   * @param {string} idToken
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JiraV2Api
   */
  public createRepairTicketConditionCheck(
    accessToken: string,
    idToken: string,
    vin: string,
    options?: AxiosRequestConfig
  ) {
    return JiraV2ApiFp(this.configuration)
      .createRepairTicketConditionCheck(accessToken, idToken, vin, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * List Jira projects
   * @summary List Jira projects
   * @param {string} accessToken
   * @param {string} idToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JiraV2Api
   */
  public getProjects(accessToken: string, idToken: string, options?: AxiosRequestConfig) {
    return JiraV2ApiFp(this.configuration)
      .getProjects(accessToken, idToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Sets the priority of the given Jira ticket
   * @summary Sets the priority of the given Jira ticket
   * @param {string} accessToken
   * @param {string} idToken
   * @param {string} issueKey
   * @param {JiraPriorityBody} jiraPriorityBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof JiraV2Api
   */
  public setJiraIssuePriority(
    accessToken: string,
    idToken: string,
    issueKey: string,
    jiraPriorityBody: JiraPriorityBody,
    options?: AxiosRequestConfig
  ) {
    return JiraV2ApiFp(this.configuration)
      .setJiraIssuePriority(accessToken, idToken, issueKey, jiraPriorityBody, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * OIDCAuthApi - axios parameter creator
 * @export
 */
export const OIDCAuthApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *   By directing the browser to this endpoint your user will be redirected to the identity provider (Keycloak),  where they are able to enter their credentials and authorize for this application. After successful  authentication their browser will be redirected to the specificed `redirect_url`.    For this endpoint to work properly you need to include a redirect URL at which you intend to receive the access  code as well as a non-guessable `nonce` parameter. The `state` parameter can be used to encode information  about the state of the frontend app in order to restore the state before redirecting. In the case of a untrusted  infrastructure the nonce parameter needs to be compared with the nonce parameter within the signed JWT access  token.    The access code required to obtain an access token will be present from the query parameter `code`  and the state can be received back via the query parameter `state`.
     * @summary Let\'s you receive an access code via a callback url.
     * @param {string} redirectUrl
     * @param {string} state
     * @param {string} nonce
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authorizeEndpoint: async (
      redirectUrl: string,
      state: string,
      nonce: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'redirectUrl' is not null or undefined
      assertParamExists('authorizeEndpoint', 'redirectUrl', redirectUrl);
      // verify required parameter 'state' is not null or undefined
      assertParamExists('authorizeEndpoint', 'state', state);
      // verify required parameter 'nonce' is not null or undefined
      assertParamExists('authorizeEndpoint', 'nonce', nonce);
      const localVarPath = `/oidc/authorize`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (redirectUrl !== undefined) {
        localVarQueryParameter['redirect_url'] = redirectUrl;
      }

      if (state !== undefined) {
        localVarQueryParameter['state'] = state;
      }

      if (nonce !== undefined) {
        localVarQueryParameter['nonce'] = nonce;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *   By directing the browser at this url you can end the users  session at the identity provider level.
     * @summary End the user session at the IDP.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    endUserSession: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/oidc/logout`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get active UserProfile, including groups.
     * @summary Get active UserProfile
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserProfile: async (
      accessToken: string,
      idToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getUserProfile', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getUserProfile', 'idToken', idToken);
      const localVarPath = `/oidc/user-profile`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *   With a valid refresh token you can obtain a fresh set of token via this endpoint.  A refresh token also has a limited lifetime, which is defined by the session idle time (e.g. 30min) configured   at the identity provider. Using this endpoint will reset the idle timer.
     * @summary Receive a renewed set of access, refresh and id token by providing a valid refresh token.
     * @param {string} refreshToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshTokenEndpoint: async (
      refreshToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'refreshToken' is not null or undefined
      assertParamExists('refreshTokenEndpoint', 'refreshToken', refreshToken);
      const localVarPath = `/oidc/refreshToken`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (refreshToken !== undefined) {
        localVarQueryParameter['refresh_token'] = refreshToken;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *   Using the code received via accessing the `Authorize Endpoint` you can receive a valid access,  refresh and id token via this endpoint. The access token will have a limited lifetime and will become  invalid after a given time (e.g. 5 min) which is determined by or configured at the identity provider.  You also need to send the redirect_url used to receive the access code for the identity provider  to validate your claim on this token.   You should inspect the JWT token whether it contains the correct nonce you have sent to the authorize endpoint,   if that\'s not the case the token should be discarded.
     * @summary Obtain the Access, Refresh and ID token using your access code.
     * @param {string} code
     * @param {string} redirectUrl
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenEndpoint: async (
      code: string,
      redirectUrl: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'code' is not null or undefined
      assertParamExists('tokenEndpoint', 'code', code);
      // verify required parameter 'redirectUrl' is not null or undefined
      assertParamExists('tokenEndpoint', 'redirectUrl', redirectUrl);
      const localVarPath = `/oidc/token`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (code !== undefined) {
        localVarQueryParameter['code'] = code;
      }

      if (redirectUrl !== undefined) {
        localVarQueryParameter['redirect_url'] = redirectUrl;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * OIDCAuthApi - functional programming interface
 * @export
 */
export const OIDCAuthApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = OIDCAuthApiAxiosParamCreator(configuration);
  return {
    /**
     *   By directing the browser to this endpoint your user will be redirected to the identity provider (Keycloak),  where they are able to enter their credentials and authorize for this application. After successful  authentication their browser will be redirected to the specificed `redirect_url`.    For this endpoint to work properly you need to include a redirect URL at which you intend to receive the access  code as well as a non-guessable `nonce` parameter. The `state` parameter can be used to encode information  about the state of the frontend app in order to restore the state before redirecting. In the case of a untrusted  infrastructure the nonce parameter needs to be compared with the nonce parameter within the signed JWT access  token.    The access code required to obtain an access token will be present from the query parameter `code`  and the state can be received back via the query parameter `state`.
     * @summary Let\'s you receive an access code via a callback url.
     * @param {string} redirectUrl
     * @param {string} state
     * @param {string} nonce
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authorizeEndpoint(
      redirectUrl: string,
      state: string,
      nonce: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authorizeEndpoint(
        redirectUrl,
        state,
        nonce,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['OIDCAuthApi.authorizeEndpoint']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *   By directing the browser at this url you can end the users  session at the identity provider level.
     * @summary End the user session at the IDP.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async endUserSession(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.endUserSession(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['OIDCAuthApi.endUserSession']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get active UserProfile, including groups.
     * @summary Get active UserProfile
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getUserProfile(
      accessToken: string,
      idToken: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserProfile>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getUserProfile(
        accessToken,
        idToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['OIDCAuthApi.getUserProfile']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *   With a valid refresh token you can obtain a fresh set of token via this endpoint.  A refresh token also has a limited lifetime, which is defined by the session idle time (e.g. 30min) configured   at the identity provider. Using this endpoint will reset the idle timer.
     * @summary Receive a renewed set of access, refresh and id token by providing a valid refresh token.
     * @param {string} refreshToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshTokenEndpoint(
      refreshToken: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OIDCToken>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.refreshTokenEndpoint(
        refreshToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['OIDCAuthApi.refreshTokenEndpoint']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *   Using the code received via accessing the `Authorize Endpoint` you can receive a valid access,  refresh and id token via this endpoint. The access token will have a limited lifetime and will become  invalid after a given time (e.g. 5 min) which is determined by or configured at the identity provider.  You also need to send the redirect_url used to receive the access code for the identity provider  to validate your claim on this token.   You should inspect the JWT token whether it contains the correct nonce you have sent to the authorize endpoint,   if that\'s not the case the token should be discarded.
     * @summary Obtain the Access, Refresh and ID token using your access code.
     * @param {string} code
     * @param {string} redirectUrl
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async tokenEndpoint(
      code: string,
      redirectUrl: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OIDCToken>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.tokenEndpoint(
        code,
        redirectUrl,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['OIDCAuthApi.tokenEndpoint']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * OIDCAuthApi - factory interface
 * @export
 */
export const OIDCAuthApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = OIDCAuthApiFp(configuration);
  return {
    /**
     *   By directing the browser to this endpoint your user will be redirected to the identity provider (Keycloak),  where they are able to enter their credentials and authorize for this application. After successful  authentication their browser will be redirected to the specificed `redirect_url`.    For this endpoint to work properly you need to include a redirect URL at which you intend to receive the access  code as well as a non-guessable `nonce` parameter. The `state` parameter can be used to encode information  about the state of the frontend app in order to restore the state before redirecting. In the case of a untrusted  infrastructure the nonce parameter needs to be compared with the nonce parameter within the signed JWT access  token.    The access code required to obtain an access token will be present from the query parameter `code`  and the state can be received back via the query parameter `state`.
     * @summary Let\'s you receive an access code via a callback url.
     * @param {string} redirectUrl
     * @param {string} state
     * @param {string} nonce
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authorizeEndpoint(
      redirectUrl: string,
      state: string,
      nonce: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .authorizeEndpoint(redirectUrl, state, nonce, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *   By directing the browser at this url you can end the users  session at the identity provider level.
     * @summary End the user session at the IDP.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    endUserSession(options?: any): AxiosPromise<void> {
      return localVarFp.endUserSession(options).then((request) => request(axios, basePath));
    },
    /**
     * Get active UserProfile, including groups.
     * @summary Get active UserProfile
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getUserProfile(accessToken: string, idToken: string, options?: any): AxiosPromise<UserProfile> {
      return localVarFp
        .getUserProfile(accessToken, idToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *   With a valid refresh token you can obtain a fresh set of token via this endpoint.  A refresh token also has a limited lifetime, which is defined by the session idle time (e.g. 30min) configured   at the identity provider. Using this endpoint will reset the idle timer.
     * @summary Receive a renewed set of access, refresh and id token by providing a valid refresh token.
     * @param {string} refreshToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshTokenEndpoint(refreshToken: string, options?: any): AxiosPromise<OIDCToken> {
      return localVarFp
        .refreshTokenEndpoint(refreshToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *   Using the code received via accessing the `Authorize Endpoint` you can receive a valid access,  refresh and id token via this endpoint. The access token will have a limited lifetime and will become  invalid after a given time (e.g. 5 min) which is determined by or configured at the identity provider.  You also need to send the redirect_url used to receive the access code for the identity provider  to validate your claim on this token.   You should inspect the JWT token whether it contains the correct nonce you have sent to the authorize endpoint,   if that\'s not the case the token should be discarded.
     * @summary Obtain the Access, Refresh and ID token using your access code.
     * @param {string} code
     * @param {string} redirectUrl
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    tokenEndpoint(code: string, redirectUrl: string, options?: any): AxiosPromise<OIDCToken> {
      return localVarFp
        .tokenEndpoint(code, redirectUrl, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * OIDCAuthApi - object-oriented interface
 * @export
 * @class OIDCAuthApi
 * @extends {BaseAPI}
 */
export class OIDCAuthApi extends BaseAPI {
  /**
   *   By directing the browser to this endpoint your user will be redirected to the identity provider (Keycloak),  where they are able to enter their credentials and authorize for this application. After successful  authentication their browser will be redirected to the specificed `redirect_url`.    For this endpoint to work properly you need to include a redirect URL at which you intend to receive the access  code as well as a non-guessable `nonce` parameter. The `state` parameter can be used to encode information  about the state of the frontend app in order to restore the state before redirecting. In the case of a untrusted  infrastructure the nonce parameter needs to be compared with the nonce parameter within the signed JWT access  token.    The access code required to obtain an access token will be present from the query parameter `code`  and the state can be received back via the query parameter `state`.
   * @summary Let\'s you receive an access code via a callback url.
   * @param {string} redirectUrl
   * @param {string} state
   * @param {string} nonce
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OIDCAuthApi
   */
  public authorizeEndpoint(
    redirectUrl: string,
    state: string,
    nonce: string,
    options?: AxiosRequestConfig
  ) {
    return OIDCAuthApiFp(this.configuration)
      .authorizeEndpoint(redirectUrl, state, nonce, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *   By directing the browser at this url you can end the users  session at the identity provider level.
   * @summary End the user session at the IDP.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OIDCAuthApi
   */
  public endUserSession(options?: AxiosRequestConfig) {
    return OIDCAuthApiFp(this.configuration)
      .endUserSession(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get active UserProfile, including groups.
   * @summary Get active UserProfile
   * @param {string} accessToken
   * @param {string} idToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OIDCAuthApi
   */
  public getUserProfile(accessToken: string, idToken: string, options?: AxiosRequestConfig) {
    return OIDCAuthApiFp(this.configuration)
      .getUserProfile(accessToken, idToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *   With a valid refresh token you can obtain a fresh set of token via this endpoint.  A refresh token also has a limited lifetime, which is defined by the session idle time (e.g. 30min) configured   at the identity provider. Using this endpoint will reset the idle timer.
   * @summary Receive a renewed set of access, refresh and id token by providing a valid refresh token.
   * @param {string} refreshToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OIDCAuthApi
   */
  public refreshTokenEndpoint(refreshToken: string, options?: AxiosRequestConfig) {
    return OIDCAuthApiFp(this.configuration)
      .refreshTokenEndpoint(refreshToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *   Using the code received via accessing the `Authorize Endpoint` you can receive a valid access,  refresh and id token via this endpoint. The access token will have a limited lifetime and will become  invalid after a given time (e.g. 5 min) which is determined by or configured at the identity provider.  You also need to send the redirect_url used to receive the access code for the identity provider  to validate your claim on this token.   You should inspect the JWT token whether it contains the correct nonce you have sent to the authorize endpoint,   if that\'s not the case the token should be discarded.
   * @summary Obtain the Access, Refresh and ID token using your access code.
   * @param {string} code
   * @param {string} redirectUrl
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof OIDCAuthApi
   */
  public tokenEndpoint(code: string, redirectUrl: string, options?: AxiosRequestConfig) {
    return OIDCAuthApiFp(this.configuration)
      .tokenEndpoint(code, redirectUrl, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ServiceMissionsApi - axios parameter creator
 * @export
 */
export const ServiceMissionsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Adapt a tour by adding / removing / reordering jobs
     * @summary Adapt a tour by adding / removing / reordering jobs
     * @param {number} missionId
     * @param {number} tourId
     * @param {File} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1adaptTour: async (
      missionId: number,
      tourId: number,
      body: File,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('v1adaptTour', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('v1adaptTour', 'tourId', tourId);
      // verify required parameter 'body' is not null or undefined
      assertParamExists('v1adaptTour', 'body', body);
      const localVarPath = `/missions/{missionId}/tours/{tourId}/adapt`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Bulk-starts pre and at steps
     * @summary Bulk-starts all open pre- and at-steps of the job
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1bulkStartPreAndAtSteps: async (
      missionId: number,
      tourId: number,
      jobId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('v1bulkStartPreAndAtSteps', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('v1bulkStartPreAndAtSteps', 'tourId', tourId);
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('v1bulkStartPreAndAtSteps', 'jobId', jobId);
      const localVarPath =
        `/missions/{missionId}/tours/{tourId}/jobs/{jobId}/start-pre-and-at-steps`
          .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
          .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)))
          .replace(`{${'jobId'}}`, encodeURIComponent(String(jobId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Check if step can be completed
     * @summary Check if step can be completed
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1completeChecks: async (
      missionId: number,
      tourId: number,
      stepId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('v1completeChecks', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('v1completeChecks', 'tourId', tourId);
      // verify required parameter 'stepId' is not null or undefined
      assertParamExists('v1completeChecks', 'stepId', stepId);
      const localVarPath = `/missions/{missionId}/tours/{tourId}/steps/{stepId}/complete-checks`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)))
        .replace(`{${'stepId'}}`, encodeURIComponent(String(stepId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Complete the specified step
     * @summary Complete the specified step
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {TransitionActionBody} transitionActionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1completeStep: async (
      missionId: number,
      tourId: number,
      stepId: number,
      transitionActionBody: TransitionActionBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('v1completeStep', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('v1completeStep', 'tourId', tourId);
      // verify required parameter 'stepId' is not null or undefined
      assertParamExists('v1completeStep', 'stepId', stepId);
      // verify required parameter 'transitionActionBody' is not null or undefined
      assertParamExists('v1completeStep', 'transitionActionBody', transitionActionBody);
      const localVarPath = `/missions/{missionId}/tours/{tourId}/steps/{stepId}/complete`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)))
        .replace(`{${'stepId'}}`, encodeURIComponent(String(stepId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        transitionActionBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Completes the tour
     * @summary Completes the tour
     * @param {number} missionId
     * @param {number} tourId
     * @param {CompleteTourBody} completeTourBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1completeTour: async (
      missionId: number,
      tourId: number,
      completeTourBody: CompleteTourBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('v1completeTour', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('v1completeTour', 'tourId', tourId);
      // verify required parameter 'completeTourBody' is not null or undefined
      assertParamExists('v1completeTour', 'completeTourBody', completeTourBody);
      const localVarPath = `/missions/{missionId}/tours/{tourId}/complete`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        completeTourBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Create a new mission
     * @summary Create a new mission
     * @param {MissionBody} missionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1createMission: async (
      missionBody: MissionBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'missionBody' is not null or undefined
      assertParamExists('v1createMission', 'missionBody', missionBody);
      const localVarPath = `/missions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        missionBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Calculate estimated driving time between two coordinates
     * @summary Calculate estimated driving time between two coordinates
     * @param {number} startLat
     * @param {number} startLon
     * @param {number} endLat
     * @param {number} endLon
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1estimatedDrivingTime: async (
      startLat: number,
      startLon: number,
      endLat: number,
      endLon: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'startLat' is not null or undefined
      assertParamExists('v1estimatedDrivingTime', 'startLat', startLat);
      // verify required parameter 'startLon' is not null or undefined
      assertParamExists('v1estimatedDrivingTime', 'startLon', startLon);
      // verify required parameter 'endLat' is not null or undefined
      assertParamExists('v1estimatedDrivingTime', 'endLat', endLat);
      // verify required parameter 'endLon' is not null or undefined
      assertParamExists('v1estimatedDrivingTime', 'endLon', endLon);
      const localVarPath = `/estimated-driving-time`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      if (startLat !== undefined) {
        localVarQueryParameter['startLat'] = startLat;
      }

      if (startLon !== undefined) {
        localVarQueryParameter['startLon'] = startLon;
      }

      if (endLat !== undefined) {
        localVarQueryParameter['endLat'] = endLat;
      }

      if (endLon !== undefined) {
        localVarQueryParameter['endLon'] = endLon;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Extends the tour by adding the nearest service job
     * @summary Extends the tour by adding the nearest service job
     * @param {number} missionId
     * @param {number} tourId
     * @param {ExtendByOneBody} extendByOneBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1extendByOneJob: async (
      missionId: number,
      tourId: number,
      extendByOneBody: ExtendByOneBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('v1extendByOneJob', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('v1extendByOneJob', 'tourId', tourId);
      // verify required parameter 'extendByOneBody' is not null or undefined
      assertParamExists('v1extendByOneJob', 'extendByOneBody', extendByOneBody);
      const localVarPath = `/missions/{missionId}/tours/{tourId}/extend-by-one`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        extendByOneBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Fail the specified step
     * @summary Fail the specified step
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {FailStepBody} failStepBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1failStep: async (
      missionId: number,
      tourId: number,
      stepId: number,
      failStepBody: FailStepBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('v1failStep', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('v1failStep', 'tourId', tourId);
      // verify required parameter 'stepId' is not null or undefined
      assertParamExists('v1failStep', 'stepId', stepId);
      // verify required parameter 'failStepBody' is not null or undefined
      assertParamExists('v1failStep', 'failStepBody', failStepBody);
      const localVarPath = `/missions/{missionId}/tours/{tourId}/steps/{stepId}/fail`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)))
        .replace(`{${'stepId'}}`, encodeURIComponent(String(stepId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        failStepBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Fail the tour
     * @summary Fail the tour
     * @param {number} missionId
     * @param {number} tourId
     * @param {ReasonBody} reasonBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1failTour: async (
      missionId: number,
      tourId: number,
      reasonBody: ReasonBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('v1failTour', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('v1failTour', 'tourId', tourId);
      // verify required parameter 'reasonBody' is not null or undefined
      assertParamExists('v1failTour', 'reasonBody', reasonBody);
      const localVarPath = `/missions/{missionId}/tours/{tourId}/fail`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reasonBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Forcefully fails / ends the specified job without trying any Jira transition
     * @summary Forcefully fails / ends the specified job without trying any Jira transition
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} jobId
     * @param {ReasonBody} reasonBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1forceEndJob: async (
      missionId: number,
      tourId: number,
      jobId: number,
      reasonBody: ReasonBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('v1forceEndJob', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('v1forceEndJob', 'tourId', tourId);
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('v1forceEndJob', 'jobId', jobId);
      // verify required parameter 'reasonBody' is not null or undefined
      assertParamExists('v1forceEndJob', 'reasonBody', reasonBody);
      const localVarPath = `/missions/{missionId}/tours/{tourId}/jobs/{jobId}/force-fail`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)))
        .replace(`{${'jobId'}}`, encodeURIComponent(String(jobId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reasonBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Forcefully fails / ends the specified tour without trying any Jira transitions
     * @summary Forcefully fails / ends the specified tour without trying any Jira transitions
     * @param {number} missionId
     * @param {number} tourId
     * @param {ReasonBody} reasonBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1forceEndTour: async (
      missionId: number,
      tourId: number,
      reasonBody: ReasonBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('v1forceEndTour', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('v1forceEndTour', 'tourId', tourId);
      // verify required parameter 'reasonBody' is not null or undefined
      assertParamExists('v1forceEndTour', 'reasonBody', reasonBody);
      const localVarPath = `/missions/{missionId}/tours/{tourId}/force-fail`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reasonBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get all open tours for the user
     * @summary Get all open tours for the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getAllOpenTours: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/tours/open`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get the available complete action options for the specified step
     * @summary Get the available complete action options for the specified step
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getCompleteActionOptions: async (
      missionId: number,
      tourId: number,
      stepId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('v1getCompleteActionOptions', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('v1getCompleteActionOptions', 'tourId', tourId);
      // verify required parameter 'stepId' is not null or undefined
      assertParamExists('v1getCompleteActionOptions', 'stepId', stepId);
      const localVarPath =
        `/missions/{missionId}/tours/{tourId}/steps/{stepId}/complete-action-options`
          .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
          .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)))
          .replace(`{${'stepId'}}`, encodeURIComponent(String(stepId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Gets mission detail with tour statistics
     * @summary Gets mission detail with tour statistics
     * @param {number} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getMissionWithTourStatistics: async (
      missionId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('v1getMissionWithTourStatistics', 'missionId', missionId);
      const localVarPath = `/mission`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      if (missionId !== undefined) {
        localVarQueryParameter['missionId'] = missionId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Gets missions
     * @summary Gets missions
     * @param {number} locationId
     * @param {boolean} includeExpired
     * @param {boolean} includeDisabled
     * @param {MissionType} missionType
     * @param {number} page
     * @param {number} pageSize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getMissions: async (
      locationId: number,
      includeExpired: boolean,
      includeDisabled: boolean,
      missionType: MissionType,
      page: number,
      pageSize: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('v1getMissions', 'locationId', locationId);
      // verify required parameter 'includeExpired' is not null or undefined
      assertParamExists('v1getMissions', 'includeExpired', includeExpired);
      // verify required parameter 'includeDisabled' is not null or undefined
      assertParamExists('v1getMissions', 'includeDisabled', includeDisabled);
      // verify required parameter 'missionType' is not null or undefined
      assertParamExists('v1getMissions', 'missionType', missionType);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('v1getMissions', 'page', page);
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('v1getMissions', 'pageSize', pageSize);
      const localVarPath = `/missions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (includeExpired !== undefined) {
        localVarQueryParameter['includeExpired'] = includeExpired;
      }

      if (includeDisabled !== undefined) {
        localVarQueryParameter['includeDisabled'] = includeDisabled;
      }

      if (missionType !== undefined) {
        localVarQueryParameter['missionType'] = missionType;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Gets the highest prioritized mission of the specified type for the user. Use debug=true query for testing in OCXX.
     * @summary Gets the highest prioritized mission
     * @param {number} lat
     * @param {number} lon
     * @param {MissionType} type
     * @param {boolean} [debug]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getNextMission: async (
      lat: number,
      lon: number,
      type: MissionType,
      debug?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'lat' is not null or undefined
      assertParamExists('v1getNextMission', 'lat', lat);
      // verify required parameter 'lon' is not null or undefined
      assertParamExists('v1getNextMission', 'lon', lon);
      // verify required parameter 'type' is not null or undefined
      assertParamExists('v1getNextMission', 'type', type);
      const localVarPath = `/next-mission`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      if (lat !== undefined) {
        localVarQueryParameter['lat'] = lat;
      }

      if (lon !== undefined) {
        localVarQueryParameter['lon'] = lon;
      }

      if (debug !== undefined) {
        localVarQueryParameter['debug'] = debug;
      }

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get the open tour for the specified tour type (if there is one)
     * @summary Get the open tour for the specified tour type (if there is one)
     * @param {MissionType} tourType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getOpenTour: async (
      tourType: MissionType,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'tourType' is not null or undefined
      assertParamExists('v1getOpenTour', 'tourType', tourType);
      const localVarPath = `/tour/open`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      if (tourType !== undefined) {
        localVarQueryParameter['tourType'] = tourType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get available parking zones for location
     * @summary Get available parking zones for location
     * @param {number} locationId
     * @param {boolean} [includeInactive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getParkingZones: async (
      locationId: number,
      includeInactive?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('v1getParkingZones', 'locationId', locationId);
      const localVarPath = `/service-parking-zones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (includeInactive !== undefined) {
        localVarQueryParameter['includeInactive'] = includeInactive;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get list of service employees
     * @summary Get list of service employees
     * @param {number} locationId
     * @param {MissionType} missionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getServiceEmployees: async (
      locationId: number,
      missionType: MissionType,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('v1getServiceEmployees', 'locationId', locationId);
      // verify required parameter 'missionType' is not null or undefined
      assertParamExists('v1getServiceEmployees', 'missionType', missionType);
      const localVarPath = `/service-employees`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (missionType !== undefined) {
        localVarQueryParameter['missionType'] = missionType;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get available hubs for location
     * @summary Get available hubs for location
     * @param {number} locationId
     * @param {boolean} [includeInactive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getServiceHubs: async (
      locationId: number,
      includeInactive?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('v1getServiceHubs', 'locationId', locationId);
      const localVarPath = `/service-hubs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (includeInactive !== undefined) {
        localVarQueryParameter['includeInactive'] = includeInactive;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get available service phones for location
     * @summary Get available service phones for location
     * @param {number} locationId
     * @param {boolean} [includeDisabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getServicePhones: async (
      locationId: number,
      includeDisabled?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('v1getServicePhones', 'locationId', locationId);
      const localVarPath = `/service-phones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (includeDisabled !== undefined) {
        localVarQueryParameter['includeDisabled'] = includeDisabled;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get available service vehicles for location
     * @summary Get available service vehicles for location
     * @param {number} locationId
     * @param {boolean} [includeDisabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getServiceVehicles: async (
      locationId: number,
      includeDisabled?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('v1getServiceVehicles', 'locationId', locationId);
      const localVarPath = `/service-vehicles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (includeDisabled !== undefined) {
        localVarQueryParameter['includeDisabled'] = includeDisabled;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get tour detail
     * @summary Get tour detail
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getTour: async (
      missionId: number,
      tourId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('v1getTour', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('v1getTour', 'tourId', tourId);
      const localVarPath = `/missions/{missionId}/tours/{tourId}`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get tour corridor
     * @summary Get tour corridor
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getTourCorridor: async (
      missionId: number,
      tourId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('v1getTourCorridor', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('v1getTourCorridor', 'tourId', tourId);
      const localVarPath = `/missions/{missionId}/tours/{tourId}/corridor`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get detailed tour logs
     * @summary Get detailed tour logs
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getTourLogs: async (
      missionId: number,
      tourId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('v1getTourLogs', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('v1getTourLogs', 'tourId', tourId);
      const localVarPath = `/missions/{missionId}/tours/{tourId}/logs`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get available hubs, service vehicles, service phones and service employees as a bundle
     * @summary Get available hubs, service vehicles, service phones and service employees as a bundle
     * @param {number} lat
     * @param {number} lon
     * @param {MissionType} missionType
     * @param {boolean} [debug]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getTourParameterOptions: async (
      lat: number,
      lon: number,
      missionType: MissionType,
      debug?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'lat' is not null or undefined
      assertParamExists('v1getTourParameterOptions', 'lat', lat);
      // verify required parameter 'lon' is not null or undefined
      assertParamExists('v1getTourParameterOptions', 'lon', lon);
      // verify required parameter 'missionType' is not null or undefined
      assertParamExists('v1getTourParameterOptions', 'missionType', missionType);
      const localVarPath = `/tour-parameter-options`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      if (lat !== undefined) {
        localVarQueryParameter['lat'] = lat;
      }

      if (lon !== undefined) {
        localVarQueryParameter['lon'] = lon;
      }

      if (missionType !== undefined) {
        localVarQueryParameter['missionType'] = missionType;
      }

      if (debug !== undefined) {
        localVarQueryParameter['debug'] = debug;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get tour statistics, if available
     * @summary Get tour statistics, if available
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getTourStats: async (
      missionId: number,
      tourId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('v1getTourStats', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('v1getTourStats', 'tourId', tourId);
      const localVarPath = `/missions/{missionId}/tours/{tourId}/stats`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get paginated list of recent tours
     * @summary Get paginated list of recent tours
     * @param {PaginatedTourFilterBody} paginatedTourFilterBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getToursForLocation: async (
      paginatedTourFilterBody: PaginatedTourFilterBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'paginatedTourFilterBody' is not null or undefined
      assertParamExists(
        'v1getToursForLocation',
        'paginatedTourFilterBody',
        paginatedTourFilterBody
      );
      const localVarPath = `/tours`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        paginatedTourFilterBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get list of tours for the specified mission
     * @summary Get list of tours for the specified mission
     * @param {number} missionId
     * @param {boolean} includeFinished
     * @param {number} page
     * @param {number} pageSize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getToursForMission: async (
      missionId: number,
      includeFinished: boolean,
      page: number,
      pageSize: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('v1getToursForMission', 'missionId', missionId);
      // verify required parameter 'includeFinished' is not null or undefined
      assertParamExists('v1getToursForMission', 'includeFinished', includeFinished);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('v1getToursForMission', 'page', page);
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('v1getToursForMission', 'pageSize', pageSize);
      const localVarPath = `/missions/{missionId}/tours`.replace(
        `{${'missionId'}}`,
        encodeURIComponent(String(missionId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      if (includeFinished !== undefined) {
        localVarQueryParameter['includeFinished'] = includeFinished;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get list of available vehicle types
     * @summary Get list of available vehicle types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getVehicleTypes: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/vehicle-types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get a list of vehicles with their status being in the specified service type
     * @summary Get a list of vehicles with their status being in the specified service type
     * @param {ServiceVehiclesFilterSet} serviceVehiclesFilterSet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getVehiclesAvailableForService: async (
      serviceVehiclesFilterSet: ServiceVehiclesFilterSet,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'serviceVehiclesFilterSet' is not null or undefined
      assertParamExists(
        'v1getVehiclesAvailableForService',
        'serviceVehiclesFilterSet',
        serviceVehiclesFilterSet
      );
      const localVarPath = `/vehicles/service`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        serviceVehiclesFilterSet,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Refresh the GPS location of the step
     * @summary Refresh the GPS location of the step
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1refreshStepLocation: async (
      missionId: number,
      tourId: number,
      stepId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('v1refreshStepLocation', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('v1refreshStepLocation', 'tourId', tourId);
      // verify required parameter 'stepId' is not null or undefined
      assertParamExists('v1refreshStepLocation', 'stepId', stepId);
      const localVarPath = `/missions/{missionId}/tours/{tourId}/steps/{stepId}/refresh-location`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)))
        .replace(`{${'stepId'}}`, encodeURIComponent(String(stepId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Request tour from mission
     * @summary Request tour from mission
     * @param {TourParametersBody} tourParametersBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1requestTourFromMission: async (
      tourParametersBody: TourParametersBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'tourParametersBody' is not null or undefined
      assertParamExists('v1requestTourFromMission', 'tourParametersBody', tourParametersBody);
      const localVarPath = `/request-tour`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tourParametersBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Simulate tour
     * @summary Simulate tour based on a given mission. The tour is not persisted to the database. Do not interact with this tour, only use this for simulation purposes.
     * @param {number} missionId
     * @param {TourParametersBody} tourParametersBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1simulateTourFromMission: async (
      missionId: number,
      tourParametersBody: TourParametersBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('v1simulateTourFromMission', 'missionId', missionId);
      // verify required parameter 'tourParametersBody' is not null or undefined
      assertParamExists('v1simulateTourFromMission', 'tourParametersBody', tourParametersBody);
      const localVarPath = `/missions/{missionId}/simulate-tour`.replace(
        `{${'missionId'}}`,
        encodeURIComponent(String(missionId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tourParametersBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Start working on the step
     * @summary Start working on the step
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1startStep: async (
      missionId: number,
      tourId: number,
      stepId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('v1startStep', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('v1startStep', 'tourId', tourId);
      // verify required parameter 'stepId' is not null or undefined
      assertParamExists('v1startStep', 'stepId', stepId);
      const localVarPath = `/missions/{missionId}/tours/{tourId}/steps/{stepId}/start`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)))
        .replace(`{${'stepId'}}`, encodeURIComponent(String(stepId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Trigger the specified step action
     * @summary Trigger the specified step action
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {TriggerActionBody} triggerActionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1triggerStepAction: async (
      missionId: number,
      tourId: number,
      stepId: number,
      triggerActionBody: TriggerActionBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('v1triggerStepAction', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('v1triggerStepAction', 'tourId', tourId);
      // verify required parameter 'stepId' is not null or undefined
      assertParamExists('v1triggerStepAction', 'stepId', stepId);
      // verify required parameter 'triggerActionBody' is not null or undefined
      assertParamExists('v1triggerStepAction', 'triggerActionBody', triggerActionBody);
      const localVarPath = `/missions/{missionId}/tours/{tourId}/steps/{stepId}/trigger-action`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)))
        .replace(`{${'stepId'}}`, encodeURIComponent(String(stepId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        triggerActionBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Create or update a service hub
     * @summary Create or update a service hub
     * @param {ServiceHub} serviceHub
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1upsertServiceHub: async (
      serviceHub: ServiceHub,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'serviceHub' is not null or undefined
      assertParamExists('v1upsertServiceHub', 'serviceHub', serviceHub);
      const localVarPath = `/service-hubs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        serviceHub,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Create or update a service parking zone
     * @summary Create or update a service parking zone
     * @param {ServiceParkingZone} serviceParkingZone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1upsertServiceParkingZone: async (
      serviceParkingZone: ServiceParkingZone,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'serviceParkingZone' is not null or undefined
      assertParamExists('v1upsertServiceParkingZone', 'serviceParkingZone', serviceParkingZone);
      const localVarPath = `/service-parking-zones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        serviceParkingZone,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Create or update a service phone
     * @summary Create or update a service phone
     * @param {ServicePhone} servicePhone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1upsertServicePhone: async (
      servicePhone: ServicePhone,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'servicePhone' is not null or undefined
      assertParamExists('v1upsertServicePhone', 'servicePhone', servicePhone);
      const localVarPath = `/service-phones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        servicePhone,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Create or update a service vehicle
     * @summary Create or update a service vehicle
     * @param {ServiceVehicle} serviceVehicle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1upsertServiceVehicle: async (
      serviceVehicle: ServiceVehicle,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'serviceVehicle' is not null or undefined
      assertParamExists('v1upsertServiceVehicle', 'serviceVehicle', serviceVehicle);
      const localVarPath = `/service-vehicles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        serviceVehicle,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * ServiceMissionsApi - functional programming interface
 * @export
 */
export const ServiceMissionsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ServiceMissionsApiAxiosParamCreator(configuration);
  return {
    /**
     * Adapt a tour by adding / removing / reordering jobs
     * @summary Adapt a tour by adding / removing / reordering jobs
     * @param {number} missionId
     * @param {number} tourId
     * @param {File} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1adaptTour(
      missionId: number,
      tourId: number,
      body: File,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompiledTour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1adaptTour(
        missionId,
        tourId,
        body,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceMissionsApi.v1adaptTour']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Bulk-starts pre and at steps
     * @summary Bulk-starts all open pre- and at-steps of the job
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1bulkStartPreAndAtSteps(
      missionId: number,
      tourId: number,
      jobId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1bulkStartPreAndAtSteps(
        missionId,
        tourId,
        jobId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1bulkStartPreAndAtSteps']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Check if step can be completed
     * @summary Check if step can be completed
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1completeChecks(
      missionId: number,
      tourId: number,
      stepId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompleteChecksResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1completeChecks(
        missionId,
        tourId,
        stepId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1completeChecks']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Complete the specified step
     * @summary Complete the specified step
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {TransitionActionBody} transitionActionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1completeStep(
      missionId: number,
      tourId: number,
      stepId: number,
      transitionActionBody: TransitionActionBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1completeStep(
        missionId,
        tourId,
        stepId,
        transitionActionBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1completeStep']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Completes the tour
     * @summary Completes the tour
     * @param {number} missionId
     * @param {number} tourId
     * @param {CompleteTourBody} completeTourBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1completeTour(
      missionId: number,
      tourId: number,
      completeTourBody: CompleteTourBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1completeTour(
        missionId,
        tourId,
        completeTourBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1completeTour']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Create a new mission
     * @summary Create a new mission
     * @param {MissionBody} missionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1createMission(
      missionBody: MissionBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mission>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1createMission(
        missionBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1createMission']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Calculate estimated driving time between two coordinates
     * @summary Calculate estimated driving time between two coordinates
     * @param {number} startLat
     * @param {number} startLon
     * @param {number} endLat
     * @param {number} endLon
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1estimatedDrivingTime(
      startLat: number,
      startLon: number,
      endLat: number,
      endLon: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EstimatedDrivingTime>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1estimatedDrivingTime(
        startLat,
        startLon,
        endLat,
        endLon,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1estimatedDrivingTime']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Extends the tour by adding the nearest service job
     * @summary Extends the tour by adding the nearest service job
     * @param {number} missionId
     * @param {number} tourId
     * @param {ExtendByOneBody} extendByOneBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1extendByOneJob(
      missionId: number,
      tourId: number,
      extendByOneBody: ExtendByOneBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompiledTour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1extendByOneJob(
        missionId,
        tourId,
        extendByOneBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1extendByOneJob']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Fail the specified step
     * @summary Fail the specified step
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {FailStepBody} failStepBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1failStep(
      missionId: number,
      tourId: number,
      stepId: number,
      failStepBody: FailStepBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1failStep(
        missionId,
        tourId,
        stepId,
        failStepBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceMissionsApi.v1failStep']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Fail the tour
     * @summary Fail the tour
     * @param {number} missionId
     * @param {number} tourId
     * @param {ReasonBody} reasonBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1failTour(
      missionId: number,
      tourId: number,
      reasonBody: ReasonBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1failTour(
        missionId,
        tourId,
        reasonBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceMissionsApi.v1failTour']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Forcefully fails / ends the specified job without trying any Jira transition
     * @summary Forcefully fails / ends the specified job without trying any Jira transition
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} jobId
     * @param {ReasonBody} reasonBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1forceEndJob(
      missionId: number,
      tourId: number,
      jobId: number,
      reasonBody: ReasonBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompiledTour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1forceEndJob(
        missionId,
        tourId,
        jobId,
        reasonBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1forceEndJob']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Forcefully fails / ends the specified tour without trying any Jira transitions
     * @summary Forcefully fails / ends the specified tour without trying any Jira transitions
     * @param {number} missionId
     * @param {number} tourId
     * @param {ReasonBody} reasonBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1forceEndTour(
      missionId: number,
      tourId: number,
      reasonBody: ReasonBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompiledTour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1forceEndTour(
        missionId,
        tourId,
        reasonBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1forceEndTour']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get all open tours for the user
     * @summary Get all open tours for the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getAllOpenTours(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompiledTour>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1getAllOpenTours(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1getAllOpenTours']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get the available complete action options for the specified step
     * @summary Get the available complete action options for the specified step
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getCompleteActionOptions(
      missionId: number,
      tourId: number,
      stepId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TransitionActionField>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1getCompleteActionOptions(
        missionId,
        tourId,
        stepId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1getCompleteActionOptions']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Gets mission detail with tour statistics
     * @summary Gets mission detail with tour statistics
     * @param {number} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getMissionWithTourStatistics(
      missionId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionWithTourStats>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1getMissionWithTourStatistics(
        missionId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1getMissionWithTourStatistics']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Gets missions
     * @summary Gets missions
     * @param {number} locationId
     * @param {boolean} includeExpired
     * @param {boolean} includeDisabled
     * @param {MissionType} missionType
     * @param {number} page
     * @param {number} pageSize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getMissions(
      locationId: number,
      includeExpired: boolean,
      includeDisabled: boolean,
      missionType: MissionType,
      page: number,
      pageSize: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionOverview>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1getMissions(
        locationId,
        includeExpired,
        includeDisabled,
        missionType,
        page,
        pageSize,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1getMissions']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Gets the highest prioritized mission of the specified type for the user. Use debug=true query for testing in OCXX.
     * @summary Gets the highest prioritized mission
     * @param {number} lat
     * @param {number} lon
     * @param {MissionType} type
     * @param {boolean} [debug]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getNextMission(
      lat: number,
      lon: number,
      type: MissionType,
      debug?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mission>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1getNextMission(
        lat,
        lon,
        type,
        debug,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1getNextMission']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get the open tour for the specified tour type (if there is one)
     * @summary Get the open tour for the specified tour type (if there is one)
     * @param {MissionType} tourType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getOpenTour(
      tourType: MissionType,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompiledTour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1getOpenTour(tourType, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1getOpenTour']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get available parking zones for location
     * @summary Get available parking zones for location
     * @param {number} locationId
     * @param {boolean} [includeInactive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getParkingZones(
      locationId: number,
      includeInactive?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceParkingZone>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1getParkingZones(
        locationId,
        includeInactive,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1getParkingZones']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get list of service employees
     * @summary Get list of service employees
     * @param {number} locationId
     * @param {MissionType} missionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getServiceEmployees(
      locationId: number,
      missionType: MissionType,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceEmployee>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1getServiceEmployees(
        locationId,
        missionType,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1getServiceEmployees']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get available hubs for location
     * @summary Get available hubs for location
     * @param {number} locationId
     * @param {boolean} [includeInactive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getServiceHubs(
      locationId: number,
      includeInactive?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceHub>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1getServiceHubs(
        locationId,
        includeInactive,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1getServiceHubs']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get available service phones for location
     * @summary Get available service phones for location
     * @param {number} locationId
     * @param {boolean} [includeDisabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getServicePhones(
      locationId: number,
      includeDisabled?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServicePhone>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1getServicePhones(
        locationId,
        includeDisabled,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1getServicePhones']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get available service vehicles for location
     * @summary Get available service vehicles for location
     * @param {number} locationId
     * @param {boolean} [includeDisabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getServiceVehicles(
      locationId: number,
      includeDisabled?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceVehicle>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1getServiceVehicles(
        locationId,
        includeDisabled,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1getServiceVehicles']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get tour detail
     * @summary Get tour detail
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getTour(
      missionId: number,
      tourId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1getTour(
        missionId,
        tourId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceMissionsApi.v1getTour']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get tour corridor
     * @summary Get tour corridor
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getTourCorridor(
      missionId: number,
      tourId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourCorridor>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1getTourCorridor(
        missionId,
        tourId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1getTourCorridor']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get detailed tour logs
     * @summary Get detailed tour logs
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getTourLogs(
      missionId: number,
      tourId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GenericTourEvent>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1getTourLogs(
        missionId,
        tourId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1getTourLogs']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get available hubs, service vehicles, service phones and service employees as a bundle
     * @summary Get available hubs, service vehicles, service phones and service employees as a bundle
     * @param {number} lat
     * @param {number} lon
     * @param {MissionType} missionType
     * @param {boolean} [debug]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getTourParameterOptions(
      lat: number,
      lon: number,
      missionType: MissionType,
      debug?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourParameterOptionsBundle>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1getTourParameterOptions(
        lat,
        lon,
        missionType,
        debug,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1getTourParameterOptions']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get tour statistics, if available
     * @summary Get tour statistics, if available
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getTourStats(
      missionId: number,
      tourId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourStats>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1getTourStats(
        missionId,
        tourId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1getTourStats']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get paginated list of recent tours
     * @summary Get paginated list of recent tours
     * @param {PaginatedTourFilterBody} paginatedTourFilterBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getToursForLocation(
      paginatedTourFilterBody: PaginatedTourFilterBody,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedParentResultTourMission>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1getToursForLocation(
        paginatedTourFilterBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1getToursForLocation']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get list of tours for the specified mission
     * @summary Get list of tours for the specified mission
     * @param {number} missionId
     * @param {boolean} includeFinished
     * @param {number} page
     * @param {number} pageSize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getToursForMission(
      missionId: number,
      includeFinished: boolean,
      page: number,
      pageSize: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResultTour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1getToursForMission(
        missionId,
        includeFinished,
        page,
        pageSize,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1getToursForMission']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get list of available vehicle types
     * @summary Get list of available vehicle types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getVehicleTypes(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VehicleType>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1getVehicleTypes(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1getVehicleTypes']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get a list of vehicles with their status being in the specified service type
     * @summary Get a list of vehicles with their status being in the specified service type
     * @param {ServiceVehiclesFilterSet} serviceVehiclesFilterSet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getVehiclesAvailableForService(
      serviceVehiclesFilterSet: ServiceVehiclesFilterSet,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceOverview>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1getVehiclesAvailableForService(
        serviceVehiclesFilterSet,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1getVehiclesAvailableForService']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Refresh the GPS location of the step
     * @summary Refresh the GPS location of the step
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1refreshStepLocation(
      missionId: number,
      tourId: number,
      stepId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1refreshStepLocation(
        missionId,
        tourId,
        stepId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1refreshStepLocation']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Request tour from mission
     * @summary Request tour from mission
     * @param {TourParametersBody} tourParametersBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1requestTourFromMission(
      tourParametersBody: TourParametersBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompiledTour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1requestTourFromMission(
        tourParametersBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1requestTourFromMission']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Simulate tour
     * @summary Simulate tour based on a given mission. The tour is not persisted to the database. Do not interact with this tour, only use this for simulation purposes.
     * @param {number} missionId
     * @param {TourParametersBody} tourParametersBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1simulateTourFromMission(
      missionId: number,
      tourParametersBody: TourParametersBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompiledTour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1simulateTourFromMission(
        missionId,
        tourParametersBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1simulateTourFromMission']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Start working on the step
     * @summary Start working on the step
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1startStep(
      missionId: number,
      tourId: number,
      stepId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1startStep(
        missionId,
        tourId,
        stepId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceMissionsApi.v1startStep']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Trigger the specified step action
     * @summary Trigger the specified step action
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {TriggerActionBody} triggerActionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1triggerStepAction(
      missionId: number,
      tourId: number,
      stepId: number,
      triggerActionBody: TriggerActionBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TriggerEvent>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1triggerStepAction(
        missionId,
        tourId,
        stepId,
        triggerActionBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1triggerStepAction']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Create or update a service hub
     * @summary Create or update a service hub
     * @param {ServiceHub} serviceHub
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1upsertServiceHub(
      serviceHub: ServiceHub,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceHub>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1upsertServiceHub(
        serviceHub,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1upsertServiceHub']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Create or update a service parking zone
     * @summary Create or update a service parking zone
     * @param {ServiceParkingZone} serviceParkingZone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1upsertServiceParkingZone(
      serviceParkingZone: ServiceParkingZone,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceParkingZone>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1upsertServiceParkingZone(
        serviceParkingZone,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1upsertServiceParkingZone']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Create or update a service phone
     * @summary Create or update a service phone
     * @param {ServicePhone} servicePhone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1upsertServicePhone(
      servicePhone: ServicePhone,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServicePhone>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1upsertServicePhone(
        servicePhone,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1upsertServicePhone']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Create or update a service vehicle
     * @summary Create or update a service vehicle
     * @param {ServiceVehicle} serviceVehicle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1upsertServiceVehicle(
      serviceVehicle: ServiceVehicle,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceVehicle>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1upsertServiceVehicle(
        serviceVehicle,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsApi.v1upsertServiceVehicle']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * ServiceMissionsApi - factory interface
 * @export
 */
export const ServiceMissionsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ServiceMissionsApiFp(configuration);
  return {
    /**
     * Adapt a tour by adding / removing / reordering jobs
     * @summary Adapt a tour by adding / removing / reordering jobs
     * @param {number} missionId
     * @param {number} tourId
     * @param {File} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1adaptTour(
      missionId: number,
      tourId: number,
      body: File,
      options?: any
    ): AxiosPromise<CompiledTour> {
      return localVarFp
        .v1adaptTour(missionId, tourId, body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Bulk-starts pre and at steps
     * @summary Bulk-starts all open pre- and at-steps of the job
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1bulkStartPreAndAtSteps(
      missionId: number,
      tourId: number,
      jobId: number,
      options?: any
    ): AxiosPromise<TourResponse> {
      return localVarFp
        .v1bulkStartPreAndAtSteps(missionId, tourId, jobId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Check if step can be completed
     * @summary Check if step can be completed
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1completeChecks(
      missionId: number,
      tourId: number,
      stepId: number,
      options?: any
    ): AxiosPromise<CompleteChecksResponse> {
      return localVarFp
        .v1completeChecks(missionId, tourId, stepId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Complete the specified step
     * @summary Complete the specified step
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {TransitionActionBody} transitionActionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1completeStep(
      missionId: number,
      tourId: number,
      stepId: number,
      transitionActionBody: TransitionActionBody,
      options?: any
    ): AxiosPromise<TourResponse> {
      return localVarFp
        .v1completeStep(missionId, tourId, stepId, transitionActionBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Completes the tour
     * @summary Completes the tour
     * @param {number} missionId
     * @param {number} tourId
     * @param {CompleteTourBody} completeTourBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1completeTour(
      missionId: number,
      tourId: number,
      completeTourBody: CompleteTourBody,
      options?: any
    ): AxiosPromise<TourResponse> {
      return localVarFp
        .v1completeTour(missionId, tourId, completeTourBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create a new mission
     * @summary Create a new mission
     * @param {MissionBody} missionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1createMission(missionBody: MissionBody, options?: any): AxiosPromise<Mission> {
      return localVarFp
        .v1createMission(missionBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Calculate estimated driving time between two coordinates
     * @summary Calculate estimated driving time between two coordinates
     * @param {number} startLat
     * @param {number} startLon
     * @param {number} endLat
     * @param {number} endLon
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1estimatedDrivingTime(
      startLat: number,
      startLon: number,
      endLat: number,
      endLon: number,
      options?: any
    ): AxiosPromise<EstimatedDrivingTime> {
      return localVarFp
        .v1estimatedDrivingTime(startLat, startLon, endLat, endLon, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Extends the tour by adding the nearest service job
     * @summary Extends the tour by adding the nearest service job
     * @param {number} missionId
     * @param {number} tourId
     * @param {ExtendByOneBody} extendByOneBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1extendByOneJob(
      missionId: number,
      tourId: number,
      extendByOneBody: ExtendByOneBody,
      options?: any
    ): AxiosPromise<CompiledTour> {
      return localVarFp
        .v1extendByOneJob(missionId, tourId, extendByOneBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Fail the specified step
     * @summary Fail the specified step
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {FailStepBody} failStepBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1failStep(
      missionId: number,
      tourId: number,
      stepId: number,
      failStepBody: FailStepBody,
      options?: any
    ): AxiosPromise<TourResponse> {
      return localVarFp
        .v1failStep(missionId, tourId, stepId, failStepBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Fail the tour
     * @summary Fail the tour
     * @param {number} missionId
     * @param {number} tourId
     * @param {ReasonBody} reasonBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1failTour(
      missionId: number,
      tourId: number,
      reasonBody: ReasonBody,
      options?: any
    ): AxiosPromise<TourResponse> {
      return localVarFp
        .v1failTour(missionId, tourId, reasonBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Forcefully fails / ends the specified job without trying any Jira transition
     * @summary Forcefully fails / ends the specified job without trying any Jira transition
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} jobId
     * @param {ReasonBody} reasonBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1forceEndJob(
      missionId: number,
      tourId: number,
      jobId: number,
      reasonBody: ReasonBody,
      options?: any
    ): AxiosPromise<CompiledTour> {
      return localVarFp
        .v1forceEndJob(missionId, tourId, jobId, reasonBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Forcefully fails / ends the specified tour without trying any Jira transitions
     * @summary Forcefully fails / ends the specified tour without trying any Jira transitions
     * @param {number} missionId
     * @param {number} tourId
     * @param {ReasonBody} reasonBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1forceEndTour(
      missionId: number,
      tourId: number,
      reasonBody: ReasonBody,
      options?: any
    ): AxiosPromise<CompiledTour> {
      return localVarFp
        .v1forceEndTour(missionId, tourId, reasonBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all open tours for the user
     * @summary Get all open tours for the user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getAllOpenTours(options?: any): AxiosPromise<Array<CompiledTour>> {
      return localVarFp.v1getAllOpenTours(options).then((request) => request(axios, basePath));
    },
    /**
     * Get the available complete action options for the specified step
     * @summary Get the available complete action options for the specified step
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getCompleteActionOptions(
      missionId: number,
      tourId: number,
      stepId: number,
      options?: any
    ): AxiosPromise<Array<TransitionActionField>> {
      return localVarFp
        .v1getCompleteActionOptions(missionId, tourId, stepId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets mission detail with tour statistics
     * @summary Gets mission detail with tour statistics
     * @param {number} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getMissionWithTourStatistics(
      missionId: number,
      options?: any
    ): AxiosPromise<MissionWithTourStats> {
      return localVarFp
        .v1getMissionWithTourStatistics(missionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets missions
     * @summary Gets missions
     * @param {number} locationId
     * @param {boolean} includeExpired
     * @param {boolean} includeDisabled
     * @param {MissionType} missionType
     * @param {number} page
     * @param {number} pageSize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getMissions(
      locationId: number,
      includeExpired: boolean,
      includeDisabled: boolean,
      missionType: MissionType,
      page: number,
      pageSize: number,
      options?: any
    ): AxiosPromise<MissionOverview> {
      return localVarFp
        .v1getMissions(
          locationId,
          includeExpired,
          includeDisabled,
          missionType,
          page,
          pageSize,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets the highest prioritized mission of the specified type for the user. Use debug=true query for testing in OCXX.
     * @summary Gets the highest prioritized mission
     * @param {number} lat
     * @param {number} lon
     * @param {MissionType} type
     * @param {boolean} [debug]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getNextMission(
      lat: number,
      lon: number,
      type: MissionType,
      debug?: boolean,
      options?: any
    ): AxiosPromise<Mission> {
      return localVarFp
        .v1getNextMission(lat, lon, type, debug, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get the open tour for the specified tour type (if there is one)
     * @summary Get the open tour for the specified tour type (if there is one)
     * @param {MissionType} tourType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getOpenTour(tourType: MissionType, options?: any): AxiosPromise<CompiledTour> {
      return localVarFp
        .v1getOpenTour(tourType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get available parking zones for location
     * @summary Get available parking zones for location
     * @param {number} locationId
     * @param {boolean} [includeInactive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getParkingZones(
      locationId: number,
      includeInactive?: boolean,
      options?: any
    ): AxiosPromise<Array<ServiceParkingZone>> {
      return localVarFp
        .v1getParkingZones(locationId, includeInactive, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get list of service employees
     * @summary Get list of service employees
     * @param {number} locationId
     * @param {MissionType} missionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getServiceEmployees(
      locationId: number,
      missionType: MissionType,
      options?: any
    ): AxiosPromise<Array<ServiceEmployee>> {
      return localVarFp
        .v1getServiceEmployees(locationId, missionType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get available hubs for location
     * @summary Get available hubs for location
     * @param {number} locationId
     * @param {boolean} [includeInactive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getServiceHubs(
      locationId: number,
      includeInactive?: boolean,
      options?: any
    ): AxiosPromise<Array<ServiceHub>> {
      return localVarFp
        .v1getServiceHubs(locationId, includeInactive, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get available service phones for location
     * @summary Get available service phones for location
     * @param {number} locationId
     * @param {boolean} [includeDisabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getServicePhones(
      locationId: number,
      includeDisabled?: boolean,
      options?: any
    ): AxiosPromise<Array<ServicePhone>> {
      return localVarFp
        .v1getServicePhones(locationId, includeDisabled, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get available service vehicles for location
     * @summary Get available service vehicles for location
     * @param {number} locationId
     * @param {boolean} [includeDisabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getServiceVehicles(
      locationId: number,
      includeDisabled?: boolean,
      options?: any
    ): AxiosPromise<Array<ServiceVehicle>> {
      return localVarFp
        .v1getServiceVehicles(locationId, includeDisabled, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get tour detail
     * @summary Get tour detail
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getTour(missionId: number, tourId: number, options?: any): AxiosPromise<Tour> {
      return localVarFp
        .v1getTour(missionId, tourId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get tour corridor
     * @summary Get tour corridor
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getTourCorridor(
      missionId: number,
      tourId: number,
      options?: any
    ): AxiosPromise<TourCorridor> {
      return localVarFp
        .v1getTourCorridor(missionId, tourId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get detailed tour logs
     * @summary Get detailed tour logs
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getTourLogs(
      missionId: number,
      tourId: number,
      options?: any
    ): AxiosPromise<Array<GenericTourEvent>> {
      return localVarFp
        .v1getTourLogs(missionId, tourId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get available hubs, service vehicles, service phones and service employees as a bundle
     * @summary Get available hubs, service vehicles, service phones and service employees as a bundle
     * @param {number} lat
     * @param {number} lon
     * @param {MissionType} missionType
     * @param {boolean} [debug]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getTourParameterOptions(
      lat: number,
      lon: number,
      missionType: MissionType,
      debug?: boolean,
      options?: any
    ): AxiosPromise<TourParameterOptionsBundle> {
      return localVarFp
        .v1getTourParameterOptions(lat, lon, missionType, debug, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get tour statistics, if available
     * @summary Get tour statistics, if available
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getTourStats(missionId: number, tourId: number, options?: any): AxiosPromise<TourStats> {
      return localVarFp
        .v1getTourStats(missionId, tourId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get paginated list of recent tours
     * @summary Get paginated list of recent tours
     * @param {PaginatedTourFilterBody} paginatedTourFilterBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getToursForLocation(
      paginatedTourFilterBody: PaginatedTourFilterBody,
      options?: any
    ): AxiosPromise<PaginatedParentResultTourMission> {
      return localVarFp
        .v1getToursForLocation(paginatedTourFilterBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get list of tours for the specified mission
     * @summary Get list of tours for the specified mission
     * @param {number} missionId
     * @param {boolean} includeFinished
     * @param {number} page
     * @param {number} pageSize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getToursForMission(
      missionId: number,
      includeFinished: boolean,
      page: number,
      pageSize: number,
      options?: any
    ): AxiosPromise<PaginatedResultTour> {
      return localVarFp
        .v1getToursForMission(missionId, includeFinished, page, pageSize, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get list of available vehicle types
     * @summary Get list of available vehicle types
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getVehicleTypes(options?: any): AxiosPromise<Array<VehicleType>> {
      return localVarFp.v1getVehicleTypes(options).then((request) => request(axios, basePath));
    },
    /**
     * Get a list of vehicles with their status being in the specified service type
     * @summary Get a list of vehicles with their status being in the specified service type
     * @param {ServiceVehiclesFilterSet} serviceVehiclesFilterSet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getVehiclesAvailableForService(
      serviceVehiclesFilterSet: ServiceVehiclesFilterSet,
      options?: any
    ): AxiosPromise<ServiceOverview> {
      return localVarFp
        .v1getVehiclesAvailableForService(serviceVehiclesFilterSet, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Refresh the GPS location of the step
     * @summary Refresh the GPS location of the step
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1refreshStepLocation(
      missionId: number,
      tourId: number,
      stepId: number,
      options?: any
    ): AxiosPromise<TourResponse> {
      return localVarFp
        .v1refreshStepLocation(missionId, tourId, stepId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Request tour from mission
     * @summary Request tour from mission
     * @param {TourParametersBody} tourParametersBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1requestTourFromMission(
      tourParametersBody: TourParametersBody,
      options?: any
    ): AxiosPromise<CompiledTour> {
      return localVarFp
        .v1requestTourFromMission(tourParametersBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Simulate tour
     * @summary Simulate tour based on a given mission. The tour is not persisted to the database. Do not interact with this tour, only use this for simulation purposes.
     * @param {number} missionId
     * @param {TourParametersBody} tourParametersBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1simulateTourFromMission(
      missionId: number,
      tourParametersBody: TourParametersBody,
      options?: any
    ): AxiosPromise<CompiledTour> {
      return localVarFp
        .v1simulateTourFromMission(missionId, tourParametersBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Start working on the step
     * @summary Start working on the step
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1startStep(
      missionId: number,
      tourId: number,
      stepId: number,
      options?: any
    ): AxiosPromise<TourResponse> {
      return localVarFp
        .v1startStep(missionId, tourId, stepId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Trigger the specified step action
     * @summary Trigger the specified step action
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {TriggerActionBody} triggerActionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1triggerStepAction(
      missionId: number,
      tourId: number,
      stepId: number,
      triggerActionBody: TriggerActionBody,
      options?: any
    ): AxiosPromise<TriggerEvent> {
      return localVarFp
        .v1triggerStepAction(missionId, tourId, stepId, triggerActionBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create or update a service hub
     * @summary Create or update a service hub
     * @param {ServiceHub} serviceHub
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1upsertServiceHub(serviceHub: ServiceHub, options?: any): AxiosPromise<ServiceHub> {
      return localVarFp
        .v1upsertServiceHub(serviceHub, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create or update a service parking zone
     * @summary Create or update a service parking zone
     * @param {ServiceParkingZone} serviceParkingZone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1upsertServiceParkingZone(
      serviceParkingZone: ServiceParkingZone,
      options?: any
    ): AxiosPromise<ServiceParkingZone> {
      return localVarFp
        .v1upsertServiceParkingZone(serviceParkingZone, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create or update a service phone
     * @summary Create or update a service phone
     * @param {ServicePhone} servicePhone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1upsertServicePhone(servicePhone: ServicePhone, options?: any): AxiosPromise<ServicePhone> {
      return localVarFp
        .v1upsertServicePhone(servicePhone, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create or update a service vehicle
     * @summary Create or update a service vehicle
     * @param {ServiceVehicle} serviceVehicle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1upsertServiceVehicle(
      serviceVehicle: ServiceVehicle,
      options?: any
    ): AxiosPromise<ServiceVehicle> {
      return localVarFp
        .v1upsertServiceVehicle(serviceVehicle, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * ServiceMissionsApi - object-oriented interface
 * @export
 * @class ServiceMissionsApi
 * @extends {BaseAPI}
 */
export class ServiceMissionsApi extends BaseAPI {
  /**
   * Adapt a tour by adding / removing / reordering jobs
   * @summary Adapt a tour by adding / removing / reordering jobs
   * @param {number} missionId
   * @param {number} tourId
   * @param {File} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1adaptTour(missionId: number, tourId: number, body: File, options?: AxiosRequestConfig) {
    return ServiceMissionsApiFp(this.configuration)
      .v1adaptTour(missionId, tourId, body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Bulk-starts pre and at steps
   * @summary Bulk-starts all open pre- and at-steps of the job
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1bulkStartPreAndAtSteps(
    missionId: number,
    tourId: number,
    jobId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsApiFp(this.configuration)
      .v1bulkStartPreAndAtSteps(missionId, tourId, jobId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Check if step can be completed
   * @summary Check if step can be completed
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} stepId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1completeChecks(
    missionId: number,
    tourId: number,
    stepId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsApiFp(this.configuration)
      .v1completeChecks(missionId, tourId, stepId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Complete the specified step
   * @summary Complete the specified step
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} stepId
   * @param {TransitionActionBody} transitionActionBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1completeStep(
    missionId: number,
    tourId: number,
    stepId: number,
    transitionActionBody: TransitionActionBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsApiFp(this.configuration)
      .v1completeStep(missionId, tourId, stepId, transitionActionBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Completes the tour
   * @summary Completes the tour
   * @param {number} missionId
   * @param {number} tourId
   * @param {CompleteTourBody} completeTourBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1completeTour(
    missionId: number,
    tourId: number,
    completeTourBody: CompleteTourBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsApiFp(this.configuration)
      .v1completeTour(missionId, tourId, completeTourBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a new mission
   * @summary Create a new mission
   * @param {MissionBody} missionBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1createMission(missionBody: MissionBody, options?: AxiosRequestConfig) {
    return ServiceMissionsApiFp(this.configuration)
      .v1createMission(missionBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Calculate estimated driving time between two coordinates
   * @summary Calculate estimated driving time between two coordinates
   * @param {number} startLat
   * @param {number} startLon
   * @param {number} endLat
   * @param {number} endLon
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1estimatedDrivingTime(
    startLat: number,
    startLon: number,
    endLat: number,
    endLon: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsApiFp(this.configuration)
      .v1estimatedDrivingTime(startLat, startLon, endLat, endLon, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Extends the tour by adding the nearest service job
   * @summary Extends the tour by adding the nearest service job
   * @param {number} missionId
   * @param {number} tourId
   * @param {ExtendByOneBody} extendByOneBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1extendByOneJob(
    missionId: number,
    tourId: number,
    extendByOneBody: ExtendByOneBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsApiFp(this.configuration)
      .v1extendByOneJob(missionId, tourId, extendByOneBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Fail the specified step
   * @summary Fail the specified step
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} stepId
   * @param {FailStepBody} failStepBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1failStep(
    missionId: number,
    tourId: number,
    stepId: number,
    failStepBody: FailStepBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsApiFp(this.configuration)
      .v1failStep(missionId, tourId, stepId, failStepBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Fail the tour
   * @summary Fail the tour
   * @param {number} missionId
   * @param {number} tourId
   * @param {ReasonBody} reasonBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1failTour(
    missionId: number,
    tourId: number,
    reasonBody: ReasonBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsApiFp(this.configuration)
      .v1failTour(missionId, tourId, reasonBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Forcefully fails / ends the specified job without trying any Jira transition
   * @summary Forcefully fails / ends the specified job without trying any Jira transition
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} jobId
   * @param {ReasonBody} reasonBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1forceEndJob(
    missionId: number,
    tourId: number,
    jobId: number,
    reasonBody: ReasonBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsApiFp(this.configuration)
      .v1forceEndJob(missionId, tourId, jobId, reasonBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Forcefully fails / ends the specified tour without trying any Jira transitions
   * @summary Forcefully fails / ends the specified tour without trying any Jira transitions
   * @param {number} missionId
   * @param {number} tourId
   * @param {ReasonBody} reasonBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1forceEndTour(
    missionId: number,
    tourId: number,
    reasonBody: ReasonBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsApiFp(this.configuration)
      .v1forceEndTour(missionId, tourId, reasonBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all open tours for the user
   * @summary Get all open tours for the user
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1getAllOpenTours(options?: AxiosRequestConfig) {
    return ServiceMissionsApiFp(this.configuration)
      .v1getAllOpenTours(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get the available complete action options for the specified step
   * @summary Get the available complete action options for the specified step
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} stepId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1getCompleteActionOptions(
    missionId: number,
    tourId: number,
    stepId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsApiFp(this.configuration)
      .v1getCompleteActionOptions(missionId, tourId, stepId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets mission detail with tour statistics
   * @summary Gets mission detail with tour statistics
   * @param {number} missionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1getMissionWithTourStatistics(missionId: number, options?: AxiosRequestConfig) {
    return ServiceMissionsApiFp(this.configuration)
      .v1getMissionWithTourStatistics(missionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets missions
   * @summary Gets missions
   * @param {number} locationId
   * @param {boolean} includeExpired
   * @param {boolean} includeDisabled
   * @param {MissionType} missionType
   * @param {number} page
   * @param {number} pageSize
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1getMissions(
    locationId: number,
    includeExpired: boolean,
    includeDisabled: boolean,
    missionType: MissionType,
    page: number,
    pageSize: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsApiFp(this.configuration)
      .v1getMissions(
        locationId,
        includeExpired,
        includeDisabled,
        missionType,
        page,
        pageSize,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets the highest prioritized mission of the specified type for the user. Use debug=true query for testing in OCXX.
   * @summary Gets the highest prioritized mission
   * @param {number} lat
   * @param {number} lon
   * @param {MissionType} type
   * @param {boolean} [debug]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1getNextMission(
    lat: number,
    lon: number,
    type: MissionType,
    debug?: boolean,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsApiFp(this.configuration)
      .v1getNextMission(lat, lon, type, debug, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get the open tour for the specified tour type (if there is one)
   * @summary Get the open tour for the specified tour type (if there is one)
   * @param {MissionType} tourType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1getOpenTour(tourType: MissionType, options?: AxiosRequestConfig) {
    return ServiceMissionsApiFp(this.configuration)
      .v1getOpenTour(tourType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get available parking zones for location
   * @summary Get available parking zones for location
   * @param {number} locationId
   * @param {boolean} [includeInactive]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1getParkingZones(
    locationId: number,
    includeInactive?: boolean,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsApiFp(this.configuration)
      .v1getParkingZones(locationId, includeInactive, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get list of service employees
   * @summary Get list of service employees
   * @param {number} locationId
   * @param {MissionType} missionType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1getServiceEmployees(
    locationId: number,
    missionType: MissionType,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsApiFp(this.configuration)
      .v1getServiceEmployees(locationId, missionType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get available hubs for location
   * @summary Get available hubs for location
   * @param {number} locationId
   * @param {boolean} [includeInactive]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1getServiceHubs(
    locationId: number,
    includeInactive?: boolean,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsApiFp(this.configuration)
      .v1getServiceHubs(locationId, includeInactive, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get available service phones for location
   * @summary Get available service phones for location
   * @param {number} locationId
   * @param {boolean} [includeDisabled]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1getServicePhones(
    locationId: number,
    includeDisabled?: boolean,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsApiFp(this.configuration)
      .v1getServicePhones(locationId, includeDisabled, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get available service vehicles for location
   * @summary Get available service vehicles for location
   * @param {number} locationId
   * @param {boolean} [includeDisabled]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1getServiceVehicles(
    locationId: number,
    includeDisabled?: boolean,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsApiFp(this.configuration)
      .v1getServiceVehicles(locationId, includeDisabled, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get tour detail
   * @summary Get tour detail
   * @param {number} missionId
   * @param {number} tourId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1getTour(missionId: number, tourId: number, options?: AxiosRequestConfig) {
    return ServiceMissionsApiFp(this.configuration)
      .v1getTour(missionId, tourId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get tour corridor
   * @summary Get tour corridor
   * @param {number} missionId
   * @param {number} tourId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1getTourCorridor(missionId: number, tourId: number, options?: AxiosRequestConfig) {
    return ServiceMissionsApiFp(this.configuration)
      .v1getTourCorridor(missionId, tourId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get detailed tour logs
   * @summary Get detailed tour logs
   * @param {number} missionId
   * @param {number} tourId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1getTourLogs(missionId: number, tourId: number, options?: AxiosRequestConfig) {
    return ServiceMissionsApiFp(this.configuration)
      .v1getTourLogs(missionId, tourId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get available hubs, service vehicles, service phones and service employees as a bundle
   * @summary Get available hubs, service vehicles, service phones and service employees as a bundle
   * @param {number} lat
   * @param {number} lon
   * @param {MissionType} missionType
   * @param {boolean} [debug]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1getTourParameterOptions(
    lat: number,
    lon: number,
    missionType: MissionType,
    debug?: boolean,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsApiFp(this.configuration)
      .v1getTourParameterOptions(lat, lon, missionType, debug, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get tour statistics, if available
   * @summary Get tour statistics, if available
   * @param {number} missionId
   * @param {number} tourId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1getTourStats(missionId: number, tourId: number, options?: AxiosRequestConfig) {
    return ServiceMissionsApiFp(this.configuration)
      .v1getTourStats(missionId, tourId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get paginated list of recent tours
   * @summary Get paginated list of recent tours
   * @param {PaginatedTourFilterBody} paginatedTourFilterBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1getToursForLocation(
    paginatedTourFilterBody: PaginatedTourFilterBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsApiFp(this.configuration)
      .v1getToursForLocation(paginatedTourFilterBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get list of tours for the specified mission
   * @summary Get list of tours for the specified mission
   * @param {number} missionId
   * @param {boolean} includeFinished
   * @param {number} page
   * @param {number} pageSize
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1getToursForMission(
    missionId: number,
    includeFinished: boolean,
    page: number,
    pageSize: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsApiFp(this.configuration)
      .v1getToursForMission(missionId, includeFinished, page, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get list of available vehicle types
   * @summary Get list of available vehicle types
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1getVehicleTypes(options?: AxiosRequestConfig) {
    return ServiceMissionsApiFp(this.configuration)
      .v1getVehicleTypes(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a list of vehicles with their status being in the specified service type
   * @summary Get a list of vehicles with their status being in the specified service type
   * @param {ServiceVehiclesFilterSet} serviceVehiclesFilterSet
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1getVehiclesAvailableForService(
    serviceVehiclesFilterSet: ServiceVehiclesFilterSet,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsApiFp(this.configuration)
      .v1getVehiclesAvailableForService(serviceVehiclesFilterSet, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Refresh the GPS location of the step
   * @summary Refresh the GPS location of the step
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} stepId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1refreshStepLocation(
    missionId: number,
    tourId: number,
    stepId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsApiFp(this.configuration)
      .v1refreshStepLocation(missionId, tourId, stepId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Request tour from mission
   * @summary Request tour from mission
   * @param {TourParametersBody} tourParametersBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1requestTourFromMission(
    tourParametersBody: TourParametersBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsApiFp(this.configuration)
      .v1requestTourFromMission(tourParametersBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Simulate tour
   * @summary Simulate tour based on a given mission. The tour is not persisted to the database. Do not interact with this tour, only use this for simulation purposes.
   * @param {number} missionId
   * @param {TourParametersBody} tourParametersBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1simulateTourFromMission(
    missionId: number,
    tourParametersBody: TourParametersBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsApiFp(this.configuration)
      .v1simulateTourFromMission(missionId, tourParametersBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Start working on the step
   * @summary Start working on the step
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} stepId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1startStep(
    missionId: number,
    tourId: number,
    stepId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsApiFp(this.configuration)
      .v1startStep(missionId, tourId, stepId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Trigger the specified step action
   * @summary Trigger the specified step action
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} stepId
   * @param {TriggerActionBody} triggerActionBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1triggerStepAction(
    missionId: number,
    tourId: number,
    stepId: number,
    triggerActionBody: TriggerActionBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsApiFp(this.configuration)
      .v1triggerStepAction(missionId, tourId, stepId, triggerActionBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create or update a service hub
   * @summary Create or update a service hub
   * @param {ServiceHub} serviceHub
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1upsertServiceHub(serviceHub: ServiceHub, options?: AxiosRequestConfig) {
    return ServiceMissionsApiFp(this.configuration)
      .v1upsertServiceHub(serviceHub, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create or update a service parking zone
   * @summary Create or update a service parking zone
   * @param {ServiceParkingZone} serviceParkingZone
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1upsertServiceParkingZone(
    serviceParkingZone: ServiceParkingZone,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsApiFp(this.configuration)
      .v1upsertServiceParkingZone(serviceParkingZone, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create or update a service phone
   * @summary Create or update a service phone
   * @param {ServicePhone} servicePhone
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1upsertServicePhone(servicePhone: ServicePhone, options?: AxiosRequestConfig) {
    return ServiceMissionsApiFp(this.configuration)
      .v1upsertServicePhone(servicePhone, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create or update a service vehicle
   * @summary Create or update a service vehicle
   * @param {ServiceVehicle} serviceVehicle
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsApi
   */
  public v1upsertServiceVehicle(serviceVehicle: ServiceVehicle, options?: AxiosRequestConfig) {
    return ServiceMissionsApiFp(this.configuration)
      .v1upsertServiceVehicle(serviceVehicle, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ServiceMissionsV2Api - axios parameter creator
 * @export
 */
export const ServiceMissionsV2ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Adapt a tour by adding / removing / reordering jobs
     * @summary Adapt a tour by adding / removing / reordering jobs
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {File} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adaptTour: async (
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      body: File,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('adaptTour', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('adaptTour', 'idToken', idToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('adaptTour', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('adaptTour', 'tourId', tourId);
      // verify required parameter 'body' is not null or undefined
      assertParamExists('adaptTour', 'body', body);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/adapt`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        body,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Bulk-starts pre and at steps
     * @summary Bulk-starts all open pre- and at-steps of the job
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkStartPreAndAtSteps: async (
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      jobId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('bulkStartPreAndAtSteps', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('bulkStartPreAndAtSteps', 'idToken', idToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('bulkStartPreAndAtSteps', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('bulkStartPreAndAtSteps', 'tourId', tourId);
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('bulkStartPreAndAtSteps', 'jobId', jobId);
      const localVarPath =
        `/v2/missions/{missionId}/tours/{tourId}/jobs/{jobId}/start-pre-and-at-steps`
          .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
          .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)))
          .replace(`{${'jobId'}}`, encodeURIComponent(String(jobId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Check if step can be completed
     * @summary Check if step can be completed
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    completeChecks: async (
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('completeChecks', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('completeChecks', 'idToken', idToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('completeChecks', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('completeChecks', 'tourId', tourId);
      // verify required parameter 'stepId' is not null or undefined
      assertParamExists('completeChecks', 'stepId', stepId);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/steps/{stepId}/complete-checks`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)))
        .replace(`{${'stepId'}}`, encodeURIComponent(String(stepId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Complete the specified step
     * @summary Complete the specified step
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {TransitionActionBody} transitionActionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    completeStep: async (
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      transitionActionBody: TransitionActionBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('completeStep', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('completeStep', 'idToken', idToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('completeStep', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('completeStep', 'tourId', tourId);
      // verify required parameter 'stepId' is not null or undefined
      assertParamExists('completeStep', 'stepId', stepId);
      // verify required parameter 'transitionActionBody' is not null or undefined
      assertParamExists('completeStep', 'transitionActionBody', transitionActionBody);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/steps/{stepId}/complete`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)))
        .replace(`{${'stepId'}}`, encodeURIComponent(String(stepId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        transitionActionBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Completes the tour
     * @summary Completes the tour
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {CompleteTourBody} completeTourBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    completeTour: async (
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      completeTourBody: CompleteTourBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('completeTour', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('completeTour', 'idToken', idToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('completeTour', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('completeTour', 'tourId', tourId);
      // verify required parameter 'completeTourBody' is not null or undefined
      assertParamExists('completeTour', 'completeTourBody', completeTourBody);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/complete`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        completeTourBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Create a new mission
     * @summary Create a new mission
     * @param {string} accessToken
     * @param {string} idToken
     * @param {MissionBody} missionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMission: async (
      accessToken: string,
      idToken: string,
      missionBody: MissionBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('createMission', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('createMission', 'idToken', idToken);
      // verify required parameter 'missionBody' is not null or undefined
      assertParamExists('createMission', 'missionBody', missionBody);
      const localVarPath = `/v2/missions/missions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        missionBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Calculate estimated driving time between two coordinates
     * @summary Calculate estimated driving time between two coordinates
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} startLat
     * @param {number} startLon
     * @param {number} endLat
     * @param {number} endLon
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    estimatedDrivingTime: async (
      accessToken: string,
      idToken: string,
      startLat: number,
      startLon: number,
      endLat: number,
      endLon: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('estimatedDrivingTime', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('estimatedDrivingTime', 'idToken', idToken);
      // verify required parameter 'startLat' is not null or undefined
      assertParamExists('estimatedDrivingTime', 'startLat', startLat);
      // verify required parameter 'startLon' is not null or undefined
      assertParamExists('estimatedDrivingTime', 'startLon', startLon);
      // verify required parameter 'endLat' is not null or undefined
      assertParamExists('estimatedDrivingTime', 'endLat', endLat);
      // verify required parameter 'endLon' is not null or undefined
      assertParamExists('estimatedDrivingTime', 'endLon', endLon);
      const localVarPath = `/v2/missions/estimated-driving-time`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (startLat !== undefined) {
        localVarQueryParameter['startLat'] = startLat;
      }

      if (startLon !== undefined) {
        localVarQueryParameter['startLon'] = startLon;
      }

      if (endLat !== undefined) {
        localVarQueryParameter['endLat'] = endLat;
      }

      if (endLon !== undefined) {
        localVarQueryParameter['endLon'] = endLon;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Extends the tour by adding the nearest service job
     * @summary Extends the tour by adding the nearest service job
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {ExtendByOneBody} extendByOneBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extendByOneJob: async (
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      extendByOneBody: ExtendByOneBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('extendByOneJob', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('extendByOneJob', 'idToken', idToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('extendByOneJob', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('extendByOneJob', 'tourId', tourId);
      // verify required parameter 'extendByOneBody' is not null or undefined
      assertParamExists('extendByOneJob', 'extendByOneBody', extendByOneBody);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/extend-by-one`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        extendByOneBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Fail the specified step
     * @summary Fail the specified step
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {FailStepBody} failStepBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    failStep: async (
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      failStepBody: FailStepBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('failStep', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('failStep', 'idToken', idToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('failStep', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('failStep', 'tourId', tourId);
      // verify required parameter 'stepId' is not null or undefined
      assertParamExists('failStep', 'stepId', stepId);
      // verify required parameter 'failStepBody' is not null or undefined
      assertParamExists('failStep', 'failStepBody', failStepBody);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/steps/{stepId}/fail`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)))
        .replace(`{${'stepId'}}`, encodeURIComponent(String(stepId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        failStepBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Fail the tour
     * @summary Fail the tour
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {ReasonBody} reasonBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    failTour: async (
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      reasonBody: ReasonBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('failTour', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('failTour', 'idToken', idToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('failTour', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('failTour', 'tourId', tourId);
      // verify required parameter 'reasonBody' is not null or undefined
      assertParamExists('failTour', 'reasonBody', reasonBody);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/fail`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reasonBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Forcefully fails / ends the specified job without trying any Jira transition
     * @summary Forcefully fails / ends the specified job without trying any Jira transition
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} jobId
     * @param {ReasonBody} reasonBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forceEndJob: async (
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      jobId: number,
      reasonBody: ReasonBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('forceEndJob', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('forceEndJob', 'idToken', idToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('forceEndJob', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('forceEndJob', 'tourId', tourId);
      // verify required parameter 'jobId' is not null or undefined
      assertParamExists('forceEndJob', 'jobId', jobId);
      // verify required parameter 'reasonBody' is not null or undefined
      assertParamExists('forceEndJob', 'reasonBody', reasonBody);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/jobs/{jobId}/force-fail`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)))
        .replace(`{${'jobId'}}`, encodeURIComponent(String(jobId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reasonBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Forcefully fails / ends the specified tour without trying any Jira transitions
     * @summary Forcefully fails / ends the specified tour without trying any Jira transitions
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {ReasonBody} reasonBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forceEndTour: async (
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      reasonBody: ReasonBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('forceEndTour', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('forceEndTour', 'idToken', idToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('forceEndTour', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('forceEndTour', 'tourId', tourId);
      // verify required parameter 'reasonBody' is not null or undefined
      assertParamExists('forceEndTour', 'reasonBody', reasonBody);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/force-fail`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        reasonBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get all open tours for the user
     * @summary Get all open tours for the user
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllOpenTours: async (
      accessToken: string,
      idToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getAllOpenTours', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getAllOpenTours', 'idToken', idToken);
      const localVarPath = `/v2/missions/tours/open`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get the available complete action options for the specified step
     * @summary Get the available complete action options for the specified step
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompleteActionOptions: async (
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getCompleteActionOptions', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getCompleteActionOptions', 'idToken', idToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('getCompleteActionOptions', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('getCompleteActionOptions', 'tourId', tourId);
      // verify required parameter 'stepId' is not null or undefined
      assertParamExists('getCompleteActionOptions', 'stepId', stepId);
      const localVarPath =
        `/v2/missions/{missionId}/tours/{tourId}/steps/{stepId}/complete-action-options`
          .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
          .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)))
          .replace(`{${'stepId'}}`, encodeURIComponent(String(stepId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Gets mission detail with tour statistics
     * @summary Gets mission detail with tour statistics
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMissionWithTourStatistics: async (
      accessToken: string,
      idToken: string,
      missionId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getMissionWithTourStatistics', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getMissionWithTourStatistics', 'idToken', idToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('getMissionWithTourStatistics', 'missionId', missionId);
      const localVarPath = `/v2/missions/mission`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (missionId !== undefined) {
        localVarQueryParameter['missionId'] = missionId;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Gets missions
     * @summary Gets missions
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {boolean} includeExpired
     * @param {boolean} includeDisabled
     * @param {MissionType} missionType
     * @param {number} page
     * @param {number} pageSize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMissions: async (
      accessToken: string,
      idToken: string,
      locationId: number,
      includeExpired: boolean,
      includeDisabled: boolean,
      missionType: MissionType,
      page: number,
      pageSize: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getMissions', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getMissions', 'idToken', idToken);
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('getMissions', 'locationId', locationId);
      // verify required parameter 'includeExpired' is not null or undefined
      assertParamExists('getMissions', 'includeExpired', includeExpired);
      // verify required parameter 'includeDisabled' is not null or undefined
      assertParamExists('getMissions', 'includeDisabled', includeDisabled);
      // verify required parameter 'missionType' is not null or undefined
      assertParamExists('getMissions', 'missionType', missionType);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('getMissions', 'page', page);
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('getMissions', 'pageSize', pageSize);
      const localVarPath = `/v2/missions/missions`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (includeExpired !== undefined) {
        localVarQueryParameter['includeExpired'] = includeExpired;
      }

      if (includeDisabled !== undefined) {
        localVarQueryParameter['includeDisabled'] = includeDisabled;
      }

      if (missionType !== undefined) {
        localVarQueryParameter['missionType'] = missionType;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Gets the highest prioritized mission of the specified type for the user. Use debug=true query for testing in OCXX.
     * @summary Gets the highest prioritized mission
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} lat
     * @param {number} lon
     * @param {MissionType} type
     * @param {boolean} [debug]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNextMission: async (
      accessToken: string,
      idToken: string,
      lat: number,
      lon: number,
      type: MissionType,
      debug?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getNextMission', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getNextMission', 'idToken', idToken);
      // verify required parameter 'lat' is not null or undefined
      assertParamExists('getNextMission', 'lat', lat);
      // verify required parameter 'lon' is not null or undefined
      assertParamExists('getNextMission', 'lon', lon);
      // verify required parameter 'type' is not null or undefined
      assertParamExists('getNextMission', 'type', type);
      const localVarPath = `/v2/missions/next-mission`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (lat !== undefined) {
        localVarQueryParameter['lat'] = lat;
      }

      if (lon !== undefined) {
        localVarQueryParameter['lon'] = lon;
      }

      if (debug !== undefined) {
        localVarQueryParameter['debug'] = debug;
      }

      if (type !== undefined) {
        localVarQueryParameter['type'] = type;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get the open tour for the specified tour type (if there is one)
     * @summary Get the open tour for the specified tour type (if there is one)
     * @param {string} accessToken
     * @param {string} idToken
     * @param {MissionType} tourType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOpenTour: async (
      accessToken: string,
      idToken: string,
      tourType: MissionType,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getOpenTour', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getOpenTour', 'idToken', idToken);
      // verify required parameter 'tourType' is not null or undefined
      assertParamExists('getOpenTour', 'tourType', tourType);
      const localVarPath = `/v2/missions/tour/open`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (tourType !== undefined) {
        localVarQueryParameter['tourType'] = tourType;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get available parking zones for location
     * @summary Get available parking zones for location
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {boolean} [includeInactive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getParkingZones: async (
      accessToken: string,
      idToken: string,
      locationId: number,
      includeInactive?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getParkingZones', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getParkingZones', 'idToken', idToken);
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('getParkingZones', 'locationId', locationId);
      const localVarPath = `/v2/missions/service-parking-zones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (includeInactive !== undefined) {
        localVarQueryParameter['includeInactive'] = includeInactive;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get list of service employees
     * @summary Get list of service employees
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {MissionType} missionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceEmployees: async (
      accessToken: string,
      idToken: string,
      locationId: number,
      missionType: MissionType,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServiceEmployees', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getServiceEmployees', 'idToken', idToken);
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('getServiceEmployees', 'locationId', locationId);
      // verify required parameter 'missionType' is not null or undefined
      assertParamExists('getServiceEmployees', 'missionType', missionType);
      const localVarPath = `/v2/missions/service-employees`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (missionType !== undefined) {
        localVarQueryParameter['missionType'] = missionType;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get available hubs for location
     * @summary Get available hubs for location
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {boolean} [includeInactive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceHubs: async (
      accessToken: string,
      idToken: string,
      locationId: number,
      includeInactive?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServiceHubs', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getServiceHubs', 'idToken', idToken);
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('getServiceHubs', 'locationId', locationId);
      const localVarPath = `/v2/missions/service-hubs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (includeInactive !== undefined) {
        localVarQueryParameter['includeInactive'] = includeInactive;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get available service phones for location
     * @summary Get available service phones for location
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {boolean} [includeDisabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServicePhones: async (
      accessToken: string,
      idToken: string,
      locationId: number,
      includeDisabled?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServicePhones', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getServicePhones', 'idToken', idToken);
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('getServicePhones', 'locationId', locationId);
      const localVarPath = `/v2/missions/service-phones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (includeDisabled !== undefined) {
        localVarQueryParameter['includeDisabled'] = includeDisabled;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get available service vehicles for location
     * @summary Get available service vehicles for location
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {boolean} [includeDisabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceVehicles: async (
      accessToken: string,
      idToken: string,
      locationId: number,
      includeDisabled?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServiceVehicles', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getServiceVehicles', 'idToken', idToken);
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('getServiceVehicles', 'locationId', locationId);
      const localVarPath = `/v2/missions/service-vehicles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (includeDisabled !== undefined) {
        localVarQueryParameter['includeDisabled'] = includeDisabled;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get tour detail
     * @summary Get tour detail
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTour: async (
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getTour', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getTour', 'idToken', idToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('getTour', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('getTour', 'tourId', tourId);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get tour corridor
     * @summary Get tour corridor
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTourCorridor: async (
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getTourCorridor', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getTourCorridor', 'idToken', idToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('getTourCorridor', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('getTourCorridor', 'tourId', tourId);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/corridor`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get detailed tour logs
     * @summary Get detailed tour logs
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTourLogs: async (
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getTourLogs', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getTourLogs', 'idToken', idToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('getTourLogs', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('getTourLogs', 'tourId', tourId);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/logs`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get available hubs, service vehicles, service phones and service employees as a bundle
     * @summary Get available hubs, service vehicles, service phones and service employees as a bundle
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} lat
     * @param {number} lon
     * @param {MissionType} missionType
     * @param {boolean} [debug]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTourParameterOptions: async (
      accessToken: string,
      idToken: string,
      lat: number,
      lon: number,
      missionType: MissionType,
      debug?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getTourParameterOptions', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getTourParameterOptions', 'idToken', idToken);
      // verify required parameter 'lat' is not null or undefined
      assertParamExists('getTourParameterOptions', 'lat', lat);
      // verify required parameter 'lon' is not null or undefined
      assertParamExists('getTourParameterOptions', 'lon', lon);
      // verify required parameter 'missionType' is not null or undefined
      assertParamExists('getTourParameterOptions', 'missionType', missionType);
      const localVarPath = `/v2/missions/tour-parameter-options`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (lat !== undefined) {
        localVarQueryParameter['lat'] = lat;
      }

      if (lon !== undefined) {
        localVarQueryParameter['lon'] = lon;
      }

      if (missionType !== undefined) {
        localVarQueryParameter['missionType'] = missionType;
      }

      if (debug !== undefined) {
        localVarQueryParameter['debug'] = debug;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get tour statistics, if available
     * @summary Get tour statistics, if available
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTourStats: async (
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getTourStats', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getTourStats', 'idToken', idToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('getTourStats', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('getTourStats', 'tourId', tourId);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/stats`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get paginated list of recent tours
     * @summary Get paginated list of recent tours
     * @param {string} accessToken
     * @param {string} idToken
     * @param {PaginatedTourFilterBody} paginatedTourFilterBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToursForLocation: async (
      accessToken: string,
      idToken: string,
      paginatedTourFilterBody: PaginatedTourFilterBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getToursForLocation', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getToursForLocation', 'idToken', idToken);
      // verify required parameter 'paginatedTourFilterBody' is not null or undefined
      assertParamExists('getToursForLocation', 'paginatedTourFilterBody', paginatedTourFilterBody);
      const localVarPath = `/v2/missions/tours`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        paginatedTourFilterBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get list of tours for the specified mission
     * @summary Get list of tours for the specified mission
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {boolean} includeFinished
     * @param {number} page
     * @param {number} pageSize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToursForMission: async (
      accessToken: string,
      idToken: string,
      missionId: number,
      includeFinished: boolean,
      page: number,
      pageSize: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getToursForMission', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getToursForMission', 'idToken', idToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('getToursForMission', 'missionId', missionId);
      // verify required parameter 'includeFinished' is not null or undefined
      assertParamExists('getToursForMission', 'includeFinished', includeFinished);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('getToursForMission', 'page', page);
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('getToursForMission', 'pageSize', pageSize);
      const localVarPath = `/v2/missions/{missionId}/tours`.replace(
        `{${'missionId'}}`,
        encodeURIComponent(String(missionId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (includeFinished !== undefined) {
        localVarQueryParameter['includeFinished'] = includeFinished;
      }

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get list of available vehicle types
     * @summary Get list of available vehicle types
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVehicleTypes: async (
      accessToken: string,
      idToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getVehicleTypes', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getVehicleTypes', 'idToken', idToken);
      const localVarPath = `/v2/missions/vehicle-types`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get a list of vehicles with their status being in the specified service type
     * @summary Get a list of vehicles with their status being in the specified service type
     * @param {string} accessToken
     * @param {string} idToken
     * @param {ServiceVehiclesFilterSet} serviceVehiclesFilterSet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVehiclesAvailableForService: async (
      accessToken: string,
      idToken: string,
      serviceVehiclesFilterSet: ServiceVehiclesFilterSet,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getVehiclesAvailableForService', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getVehiclesAvailableForService', 'idToken', idToken);
      // verify required parameter 'serviceVehiclesFilterSet' is not null or undefined
      assertParamExists(
        'getVehiclesAvailableForService',
        'serviceVehiclesFilterSet',
        serviceVehiclesFilterSet
      );
      const localVarPath = `/v2/missions/vehicles/service`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        serviceVehiclesFilterSet,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Refresh the GPS location of the step
     * @summary Refresh the GPS location of the step
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshStepLocation: async (
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('refreshStepLocation', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('refreshStepLocation', 'idToken', idToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('refreshStepLocation', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('refreshStepLocation', 'tourId', tourId);
      // verify required parameter 'stepId' is not null or undefined
      assertParamExists('refreshStepLocation', 'stepId', stepId);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/steps/{stepId}/refresh-location`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)))
        .replace(`{${'stepId'}}`, encodeURIComponent(String(stepId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Request tour from mission
     * @summary Request tour from mission
     * @param {string} accessToken
     * @param {string} idToken
     * @param {TourParametersBody} tourParametersBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestTourFromMission: async (
      accessToken: string,
      idToken: string,
      tourParametersBody: TourParametersBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('requestTourFromMission', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('requestTourFromMission', 'idToken', idToken);
      // verify required parameter 'tourParametersBody' is not null or undefined
      assertParamExists('requestTourFromMission', 'tourParametersBody', tourParametersBody);
      const localVarPath = `/v2/missions/request-tour`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tourParametersBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Simulate tour
     * @summary Simulate tour based on a given mission. The tour is not persisted to the database. Do not interact with this tour, only use this for simulation purposes.
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {TourParametersBody} tourParametersBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    simulateTourFromMission: async (
      accessToken: string,
      idToken: string,
      missionId: number,
      tourParametersBody: TourParametersBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('simulateTourFromMission', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('simulateTourFromMission', 'idToken', idToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('simulateTourFromMission', 'missionId', missionId);
      // verify required parameter 'tourParametersBody' is not null or undefined
      assertParamExists('simulateTourFromMission', 'tourParametersBody', tourParametersBody);
      const localVarPath = `/v2/missions/{missionId}/simulate-tour`.replace(
        `{${'missionId'}}`,
        encodeURIComponent(String(missionId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        tourParametersBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Start working on the step
     * @summary Start working on the step
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startStep: async (
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('startStep', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('startStep', 'idToken', idToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('startStep', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('startStep', 'tourId', tourId);
      // verify required parameter 'stepId' is not null or undefined
      assertParamExists('startStep', 'stepId', stepId);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/steps/{stepId}/start`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)))
        .replace(`{${'stepId'}}`, encodeURIComponent(String(stepId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Trigger the specified step action
     * @summary Trigger the specified step action
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {TriggerActionBody} triggerActionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    triggerStepAction: async (
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      triggerActionBody: TriggerActionBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('triggerStepAction', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('triggerStepAction', 'idToken', idToken);
      // verify required parameter 'missionId' is not null or undefined
      assertParamExists('triggerStepAction', 'missionId', missionId);
      // verify required parameter 'tourId' is not null or undefined
      assertParamExists('triggerStepAction', 'tourId', tourId);
      // verify required parameter 'stepId' is not null or undefined
      assertParamExists('triggerStepAction', 'stepId', stepId);
      // verify required parameter 'triggerActionBody' is not null or undefined
      assertParamExists('triggerStepAction', 'triggerActionBody', triggerActionBody);
      const localVarPath = `/v2/missions/{missionId}/tours/{tourId}/steps/{stepId}/trigger-action`
        .replace(`{${'missionId'}}`, encodeURIComponent(String(missionId)))
        .replace(`{${'tourId'}}`, encodeURIComponent(String(tourId)))
        .replace(`{${'stepId'}}`, encodeURIComponent(String(stepId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        triggerActionBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Create or update a service hub
     * @summary Create or update a service hub
     * @param {string} accessToken
     * @param {string} idToken
     * @param {ServiceHub} serviceHub
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertServiceHub: async (
      accessToken: string,
      idToken: string,
      serviceHub: ServiceHub,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('upsertServiceHub', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('upsertServiceHub', 'idToken', idToken);
      // verify required parameter 'serviceHub' is not null or undefined
      assertParamExists('upsertServiceHub', 'serviceHub', serviceHub);
      const localVarPath = `/v2/missions/service-hubs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        serviceHub,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Create or update a service parking zone
     * @summary Create or update a service parking zone
     * @param {string} accessToken
     * @param {string} idToken
     * @param {ServiceParkingZone} serviceParkingZone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertServiceParkingZone: async (
      accessToken: string,
      idToken: string,
      serviceParkingZone: ServiceParkingZone,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('upsertServiceParkingZone', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('upsertServiceParkingZone', 'idToken', idToken);
      // verify required parameter 'serviceParkingZone' is not null or undefined
      assertParamExists('upsertServiceParkingZone', 'serviceParkingZone', serviceParkingZone);
      const localVarPath = `/v2/missions/service-parking-zones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        serviceParkingZone,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Create or update a service phone
     * @summary Create or update a service phone
     * @param {string} accessToken
     * @param {string} idToken
     * @param {ServicePhone} servicePhone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertServicePhone: async (
      accessToken: string,
      idToken: string,
      servicePhone: ServicePhone,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('upsertServicePhone', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('upsertServicePhone', 'idToken', idToken);
      // verify required parameter 'servicePhone' is not null or undefined
      assertParamExists('upsertServicePhone', 'servicePhone', servicePhone);
      const localVarPath = `/v2/missions/service-phones`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        servicePhone,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Create or update a service vehicle
     * @summary Create or update a service vehicle
     * @param {string} accessToken
     * @param {string} idToken
     * @param {ServiceVehicle} serviceVehicle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertServiceVehicle: async (
      accessToken: string,
      idToken: string,
      serviceVehicle: ServiceVehicle,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('upsertServiceVehicle', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('upsertServiceVehicle', 'idToken', idToken);
      // verify required parameter 'serviceVehicle' is not null or undefined
      assertParamExists('upsertServiceVehicle', 'serviceVehicle', serviceVehicle);
      const localVarPath = `/v2/missions/service-vehicles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        serviceVehicle,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * ServiceMissionsV2Api - functional programming interface
 * @export
 */
export const ServiceMissionsV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ServiceMissionsV2ApiAxiosParamCreator(configuration);
  return {
    /**
     * Adapt a tour by adding / removing / reordering jobs
     * @summary Adapt a tour by adding / removing / reordering jobs
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {File} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adaptTour(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      body: File,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompiledTour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adaptTour(
        accessToken,
        idToken,
        missionId,
        tourId,
        body,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceMissionsV2Api.adaptTour']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Bulk-starts pre and at steps
     * @summary Bulk-starts all open pre- and at-steps of the job
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bulkStartPreAndAtSteps(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      jobId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bulkStartPreAndAtSteps(
        accessToken,
        idToken,
        missionId,
        tourId,
        jobId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.bulkStartPreAndAtSteps']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Check if step can be completed
     * @summary Check if step can be completed
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async completeChecks(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompleteChecksResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.completeChecks(
        accessToken,
        idToken,
        missionId,
        tourId,
        stepId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.completeChecks']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Complete the specified step
     * @summary Complete the specified step
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {TransitionActionBody} transitionActionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async completeStep(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      transitionActionBody: TransitionActionBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.completeStep(
        accessToken,
        idToken,
        missionId,
        tourId,
        stepId,
        transitionActionBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.completeStep']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Completes the tour
     * @summary Completes the tour
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {CompleteTourBody} completeTourBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async completeTour(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      completeTourBody: CompleteTourBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.completeTour(
        accessToken,
        idToken,
        missionId,
        tourId,
        completeTourBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.completeTour']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Create a new mission
     * @summary Create a new mission
     * @param {string} accessToken
     * @param {string} idToken
     * @param {MissionBody} missionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createMission(
      accessToken: string,
      idToken: string,
      missionBody: MissionBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mission>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createMission(
        accessToken,
        idToken,
        missionBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.createMission']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Calculate estimated driving time between two coordinates
     * @summary Calculate estimated driving time between two coordinates
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} startLat
     * @param {number} startLon
     * @param {number} endLat
     * @param {number} endLon
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async estimatedDrivingTime(
      accessToken: string,
      idToken: string,
      startLat: number,
      startLon: number,
      endLat: number,
      endLon: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EstimatedDrivingTime>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.estimatedDrivingTime(
        accessToken,
        idToken,
        startLat,
        startLon,
        endLat,
        endLon,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.estimatedDrivingTime']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Extends the tour by adding the nearest service job
     * @summary Extends the tour by adding the nearest service job
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {ExtendByOneBody} extendByOneBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async extendByOneJob(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      extendByOneBody: ExtendByOneBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompiledTour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.extendByOneJob(
        accessToken,
        idToken,
        missionId,
        tourId,
        extendByOneBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.extendByOneJob']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Fail the specified step
     * @summary Fail the specified step
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {FailStepBody} failStepBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async failStep(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      failStepBody: FailStepBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.failStep(
        accessToken,
        idToken,
        missionId,
        tourId,
        stepId,
        failStepBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceMissionsV2Api.failStep']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Fail the tour
     * @summary Fail the tour
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {ReasonBody} reasonBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async failTour(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      reasonBody: ReasonBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.failTour(
        accessToken,
        idToken,
        missionId,
        tourId,
        reasonBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceMissionsV2Api.failTour']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Forcefully fails / ends the specified job without trying any Jira transition
     * @summary Forcefully fails / ends the specified job without trying any Jira transition
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} jobId
     * @param {ReasonBody} reasonBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async forceEndJob(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      jobId: number,
      reasonBody: ReasonBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompiledTour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.forceEndJob(
        accessToken,
        idToken,
        missionId,
        tourId,
        jobId,
        reasonBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.forceEndJob']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Forcefully fails / ends the specified tour without trying any Jira transitions
     * @summary Forcefully fails / ends the specified tour without trying any Jira transitions
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {ReasonBody} reasonBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async forceEndTour(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      reasonBody: ReasonBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompiledTour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.forceEndTour(
        accessToken,
        idToken,
        missionId,
        tourId,
        reasonBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.forceEndTour']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get all open tours for the user
     * @summary Get all open tours for the user
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAllOpenTours(
      accessToken: string,
      idToken: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompiledTour>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAllOpenTours(
        accessToken,
        idToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.getAllOpenTours']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get the available complete action options for the specified step
     * @summary Get the available complete action options for the specified step
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCompleteActionOptions(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TransitionActionField>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCompleteActionOptions(
        accessToken,
        idToken,
        missionId,
        tourId,
        stepId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.getCompleteActionOptions']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Gets mission detail with tour statistics
     * @summary Gets mission detail with tour statistics
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMissionWithTourStatistics(
      accessToken: string,
      idToken: string,
      missionId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionWithTourStats>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMissionWithTourStatistics(
        accessToken,
        idToken,
        missionId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.getMissionWithTourStatistics']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Gets missions
     * @summary Gets missions
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {boolean} includeExpired
     * @param {boolean} includeDisabled
     * @param {MissionType} missionType
     * @param {number} page
     * @param {number} pageSize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMissions(
      accessToken: string,
      idToken: string,
      locationId: number,
      includeExpired: boolean,
      includeDisabled: boolean,
      missionType: MissionType,
      page: number,
      pageSize: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MissionOverview>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMissions(
        accessToken,
        idToken,
        locationId,
        includeExpired,
        includeDisabled,
        missionType,
        page,
        pageSize,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.getMissions']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Gets the highest prioritized mission of the specified type for the user. Use debug=true query for testing in OCXX.
     * @summary Gets the highest prioritized mission
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} lat
     * @param {number} lon
     * @param {MissionType} type
     * @param {boolean} [debug]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getNextMission(
      accessToken: string,
      idToken: string,
      lat: number,
      lon: number,
      type: MissionType,
      debug?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Mission>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getNextMission(
        accessToken,
        idToken,
        lat,
        lon,
        type,
        debug,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.getNextMission']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get the open tour for the specified tour type (if there is one)
     * @summary Get the open tour for the specified tour type (if there is one)
     * @param {string} accessToken
     * @param {string} idToken
     * @param {MissionType} tourType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOpenTour(
      accessToken: string,
      idToken: string,
      tourType: MissionType,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompiledTour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOpenTour(
        accessToken,
        idToken,
        tourType,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.getOpenTour']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get available parking zones for location
     * @summary Get available parking zones for location
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {boolean} [includeInactive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getParkingZones(
      accessToken: string,
      idToken: string,
      locationId: number,
      includeInactive?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceParkingZone>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getParkingZones(
        accessToken,
        idToken,
        locationId,
        includeInactive,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.getParkingZones']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get list of service employees
     * @summary Get list of service employees
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {MissionType} missionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceEmployees(
      accessToken: string,
      idToken: string,
      locationId: number,
      missionType: MissionType,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceEmployee>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceEmployees(
        accessToken,
        idToken,
        locationId,
        missionType,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.getServiceEmployees']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get available hubs for location
     * @summary Get available hubs for location
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {boolean} [includeInactive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceHubs(
      accessToken: string,
      idToken: string,
      locationId: number,
      includeInactive?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceHub>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceHubs(
        accessToken,
        idToken,
        locationId,
        includeInactive,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.getServiceHubs']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get available service phones for location
     * @summary Get available service phones for location
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {boolean} [includeDisabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServicePhones(
      accessToken: string,
      idToken: string,
      locationId: number,
      includeDisabled?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServicePhone>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServicePhones(
        accessToken,
        idToken,
        locationId,
        includeDisabled,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.getServicePhones']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get available service vehicles for location
     * @summary Get available service vehicles for location
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {boolean} [includeDisabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceVehicles(
      accessToken: string,
      idToken: string,
      locationId: number,
      includeDisabled?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceVehicle>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceVehicles(
        accessToken,
        idToken,
        locationId,
        includeDisabled,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.getServiceVehicles']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get tour detail
     * @summary Get tour detail
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTour(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTour(
        accessToken,
        idToken,
        missionId,
        tourId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceMissionsV2Api.getTour']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get tour corridor
     * @summary Get tour corridor
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTourCorridor(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourCorridor>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTourCorridor(
        accessToken,
        idToken,
        missionId,
        tourId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.getTourCorridor']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get detailed tour logs
     * @summary Get detailed tour logs
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTourLogs(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GenericTourEvent>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTourLogs(
        accessToken,
        idToken,
        missionId,
        tourId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.getTourLogs']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get available hubs, service vehicles, service phones and service employees as a bundle
     * @summary Get available hubs, service vehicles, service phones and service employees as a bundle
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} lat
     * @param {number} lon
     * @param {MissionType} missionType
     * @param {boolean} [debug]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTourParameterOptions(
      accessToken: string,
      idToken: string,
      lat: number,
      lon: number,
      missionType: MissionType,
      debug?: boolean,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourParameterOptionsBundle>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTourParameterOptions(
        accessToken,
        idToken,
        lat,
        lon,
        missionType,
        debug,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.getTourParameterOptions']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get tour statistics, if available
     * @summary Get tour statistics, if available
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTourStats(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourStats>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTourStats(
        accessToken,
        idToken,
        missionId,
        tourId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.getTourStats']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get paginated list of recent tours
     * @summary Get paginated list of recent tours
     * @param {string} accessToken
     * @param {string} idToken
     * @param {PaginatedTourFilterBody} paginatedTourFilterBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getToursForLocation(
      accessToken: string,
      idToken: string,
      paginatedTourFilterBody: PaginatedTourFilterBody,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedParentResultTourMission>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getToursForLocation(
        accessToken,
        idToken,
        paginatedTourFilterBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.getToursForLocation']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get list of tours for the specified mission
     * @summary Get list of tours for the specified mission
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {boolean} includeFinished
     * @param {number} page
     * @param {number} pageSize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getToursForMission(
      accessToken: string,
      idToken: string,
      missionId: number,
      includeFinished: boolean,
      page: number,
      pageSize: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResultTour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getToursForMission(
        accessToken,
        idToken,
        missionId,
        includeFinished,
        page,
        pageSize,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.getToursForMission']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get list of available vehicle types
     * @summary Get list of available vehicle types
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVehicleTypes(
      accessToken: string,
      idToken: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VehicleType>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVehicleTypes(
        accessToken,
        idToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.getVehicleTypes']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get a list of vehicles with their status being in the specified service type
     * @summary Get a list of vehicles with their status being in the specified service type
     * @param {string} accessToken
     * @param {string} idToken
     * @param {ServiceVehiclesFilterSet} serviceVehiclesFilterSet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVehiclesAvailableForService(
      accessToken: string,
      idToken: string,
      serviceVehiclesFilterSet: ServiceVehiclesFilterSet,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceOverview>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVehiclesAvailableForService(
        accessToken,
        idToken,
        serviceVehiclesFilterSet,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.getVehiclesAvailableForService']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Refresh the GPS location of the step
     * @summary Refresh the GPS location of the step
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshStepLocation(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.refreshStepLocation(
        accessToken,
        idToken,
        missionId,
        tourId,
        stepId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.refreshStepLocation']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Request tour from mission
     * @summary Request tour from mission
     * @param {string} accessToken
     * @param {string} idToken
     * @param {TourParametersBody} tourParametersBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async requestTourFromMission(
      accessToken: string,
      idToken: string,
      tourParametersBody: TourParametersBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompiledTour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.requestTourFromMission(
        accessToken,
        idToken,
        tourParametersBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.requestTourFromMission']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Simulate tour
     * @summary Simulate tour based on a given mission. The tour is not persisted to the database. Do not interact with this tour, only use this for simulation purposes.
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {TourParametersBody} tourParametersBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async simulateTourFromMission(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourParametersBody: TourParametersBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompiledTour>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.simulateTourFromMission(
        accessToken,
        idToken,
        missionId,
        tourParametersBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.simulateTourFromMission']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Start working on the step
     * @summary Start working on the step
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async startStep(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TourResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.startStep(
        accessToken,
        idToken,
        missionId,
        tourId,
        stepId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceMissionsV2Api.startStep']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Trigger the specified step action
     * @summary Trigger the specified step action
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {TriggerActionBody} triggerActionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async triggerStepAction(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      triggerActionBody: TriggerActionBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TriggerEvent>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.triggerStepAction(
        accessToken,
        idToken,
        missionId,
        tourId,
        stepId,
        triggerActionBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.triggerStepAction']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Create or update a service hub
     * @summary Create or update a service hub
     * @param {string} accessToken
     * @param {string} idToken
     * @param {ServiceHub} serviceHub
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async upsertServiceHub(
      accessToken: string,
      idToken: string,
      serviceHub: ServiceHub,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceHub>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.upsertServiceHub(
        accessToken,
        idToken,
        serviceHub,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.upsertServiceHub']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Create or update a service parking zone
     * @summary Create or update a service parking zone
     * @param {string} accessToken
     * @param {string} idToken
     * @param {ServiceParkingZone} serviceParkingZone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async upsertServiceParkingZone(
      accessToken: string,
      idToken: string,
      serviceParkingZone: ServiceParkingZone,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceParkingZone>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.upsertServiceParkingZone(
        accessToken,
        idToken,
        serviceParkingZone,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.upsertServiceParkingZone']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Create or update a service phone
     * @summary Create or update a service phone
     * @param {string} accessToken
     * @param {string} idToken
     * @param {ServicePhone} servicePhone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async upsertServicePhone(
      accessToken: string,
      idToken: string,
      servicePhone: ServicePhone,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServicePhone>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.upsertServicePhone(
        accessToken,
        idToken,
        servicePhone,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.upsertServicePhone']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Create or update a service vehicle
     * @summary Create or update a service vehicle
     * @param {string} accessToken
     * @param {string} idToken
     * @param {ServiceVehicle} serviceVehicle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async upsertServiceVehicle(
      accessToken: string,
      idToken: string,
      serviceVehicle: ServiceVehicle,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceVehicle>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.upsertServiceVehicle(
        accessToken,
        idToken,
        serviceVehicle,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceMissionsV2Api.upsertServiceVehicle']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * ServiceMissionsV2Api - factory interface
 * @export
 */
export const ServiceMissionsV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ServiceMissionsV2ApiFp(configuration);
  return {
    /**
     * Adapt a tour by adding / removing / reordering jobs
     * @summary Adapt a tour by adding / removing / reordering jobs
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {File} body
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adaptTour(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      body: File,
      options?: any
    ): AxiosPromise<CompiledTour> {
      return localVarFp
        .adaptTour(accessToken, idToken, missionId, tourId, body, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Bulk-starts pre and at steps
     * @summary Bulk-starts all open pre- and at-steps of the job
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} jobId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bulkStartPreAndAtSteps(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      jobId: number,
      options?: any
    ): AxiosPromise<TourResponse> {
      return localVarFp
        .bulkStartPreAndAtSteps(accessToken, idToken, missionId, tourId, jobId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Check if step can be completed
     * @summary Check if step can be completed
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    completeChecks(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      options?: any
    ): AxiosPromise<CompleteChecksResponse> {
      return localVarFp
        .completeChecks(accessToken, idToken, missionId, tourId, stepId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Complete the specified step
     * @summary Complete the specified step
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {TransitionActionBody} transitionActionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    completeStep(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      transitionActionBody: TransitionActionBody,
      options?: any
    ): AxiosPromise<TourResponse> {
      return localVarFp
        .completeStep(
          accessToken,
          idToken,
          missionId,
          tourId,
          stepId,
          transitionActionBody,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Completes the tour
     * @summary Completes the tour
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {CompleteTourBody} completeTourBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    completeTour(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      completeTourBody: CompleteTourBody,
      options?: any
    ): AxiosPromise<TourResponse> {
      return localVarFp
        .completeTour(accessToken, idToken, missionId, tourId, completeTourBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create a new mission
     * @summary Create a new mission
     * @param {string} accessToken
     * @param {string} idToken
     * @param {MissionBody} missionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createMission(
      accessToken: string,
      idToken: string,
      missionBody: MissionBody,
      options?: any
    ): AxiosPromise<Mission> {
      return localVarFp
        .createMission(accessToken, idToken, missionBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Calculate estimated driving time between two coordinates
     * @summary Calculate estimated driving time between two coordinates
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} startLat
     * @param {number} startLon
     * @param {number} endLat
     * @param {number} endLon
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    estimatedDrivingTime(
      accessToken: string,
      idToken: string,
      startLat: number,
      startLon: number,
      endLat: number,
      endLon: number,
      options?: any
    ): AxiosPromise<EstimatedDrivingTime> {
      return localVarFp
        .estimatedDrivingTime(accessToken, idToken, startLat, startLon, endLat, endLon, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Extends the tour by adding the nearest service job
     * @summary Extends the tour by adding the nearest service job
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {ExtendByOneBody} extendByOneBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    extendByOneJob(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      extendByOneBody: ExtendByOneBody,
      options?: any
    ): AxiosPromise<CompiledTour> {
      return localVarFp
        .extendByOneJob(accessToken, idToken, missionId, tourId, extendByOneBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Fail the specified step
     * @summary Fail the specified step
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {FailStepBody} failStepBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    failStep(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      failStepBody: FailStepBody,
      options?: any
    ): AxiosPromise<TourResponse> {
      return localVarFp
        .failStep(accessToken, idToken, missionId, tourId, stepId, failStepBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Fail the tour
     * @summary Fail the tour
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {ReasonBody} reasonBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    failTour(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      reasonBody: ReasonBody,
      options?: any
    ): AxiosPromise<TourResponse> {
      return localVarFp
        .failTour(accessToken, idToken, missionId, tourId, reasonBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Forcefully fails / ends the specified job without trying any Jira transition
     * @summary Forcefully fails / ends the specified job without trying any Jira transition
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} jobId
     * @param {ReasonBody} reasonBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forceEndJob(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      jobId: number,
      reasonBody: ReasonBody,
      options?: any
    ): AxiosPromise<CompiledTour> {
      return localVarFp
        .forceEndJob(accessToken, idToken, missionId, tourId, jobId, reasonBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Forcefully fails / ends the specified tour without trying any Jira transitions
     * @summary Forcefully fails / ends the specified tour without trying any Jira transitions
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {ReasonBody} reasonBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    forceEndTour(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      reasonBody: ReasonBody,
      options?: any
    ): AxiosPromise<CompiledTour> {
      return localVarFp
        .forceEndTour(accessToken, idToken, missionId, tourId, reasonBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all open tours for the user
     * @summary Get all open tours for the user
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAllOpenTours(
      accessToken: string,
      idToken: string,
      options?: any
    ): AxiosPromise<Array<CompiledTour>> {
      return localVarFp
        .getAllOpenTours(accessToken, idToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get the available complete action options for the specified step
     * @summary Get the available complete action options for the specified step
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCompleteActionOptions(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      options?: any
    ): AxiosPromise<Array<TransitionActionField>> {
      return localVarFp
        .getCompleteActionOptions(accessToken, idToken, missionId, tourId, stepId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets mission detail with tour statistics
     * @summary Gets mission detail with tour statistics
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMissionWithTourStatistics(
      accessToken: string,
      idToken: string,
      missionId: number,
      options?: any
    ): AxiosPromise<MissionWithTourStats> {
      return localVarFp
        .getMissionWithTourStatistics(accessToken, idToken, missionId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets missions
     * @summary Gets missions
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {boolean} includeExpired
     * @param {boolean} includeDisabled
     * @param {MissionType} missionType
     * @param {number} page
     * @param {number} pageSize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMissions(
      accessToken: string,
      idToken: string,
      locationId: number,
      includeExpired: boolean,
      includeDisabled: boolean,
      missionType: MissionType,
      page: number,
      pageSize: number,
      options?: any
    ): AxiosPromise<MissionOverview> {
      return localVarFp
        .getMissions(
          accessToken,
          idToken,
          locationId,
          includeExpired,
          includeDisabled,
          missionType,
          page,
          pageSize,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets the highest prioritized mission of the specified type for the user. Use debug=true query for testing in OCXX.
     * @summary Gets the highest prioritized mission
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} lat
     * @param {number} lon
     * @param {MissionType} type
     * @param {boolean} [debug]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getNextMission(
      accessToken: string,
      idToken: string,
      lat: number,
      lon: number,
      type: MissionType,
      debug?: boolean,
      options?: any
    ): AxiosPromise<Mission> {
      return localVarFp
        .getNextMission(accessToken, idToken, lat, lon, type, debug, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get the open tour for the specified tour type (if there is one)
     * @summary Get the open tour for the specified tour type (if there is one)
     * @param {string} accessToken
     * @param {string} idToken
     * @param {MissionType} tourType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOpenTour(
      accessToken: string,
      idToken: string,
      tourType: MissionType,
      options?: any
    ): AxiosPromise<CompiledTour> {
      return localVarFp
        .getOpenTour(accessToken, idToken, tourType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get available parking zones for location
     * @summary Get available parking zones for location
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {boolean} [includeInactive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getParkingZones(
      accessToken: string,
      idToken: string,
      locationId: number,
      includeInactive?: boolean,
      options?: any
    ): AxiosPromise<Array<ServiceParkingZone>> {
      return localVarFp
        .getParkingZones(accessToken, idToken, locationId, includeInactive, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get list of service employees
     * @summary Get list of service employees
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {MissionType} missionType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceEmployees(
      accessToken: string,
      idToken: string,
      locationId: number,
      missionType: MissionType,
      options?: any
    ): AxiosPromise<Array<ServiceEmployee>> {
      return localVarFp
        .getServiceEmployees(accessToken, idToken, locationId, missionType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get available hubs for location
     * @summary Get available hubs for location
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {boolean} [includeInactive]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceHubs(
      accessToken: string,
      idToken: string,
      locationId: number,
      includeInactive?: boolean,
      options?: any
    ): AxiosPromise<Array<ServiceHub>> {
      return localVarFp
        .getServiceHubs(accessToken, idToken, locationId, includeInactive, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get available service phones for location
     * @summary Get available service phones for location
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {boolean} [includeDisabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServicePhones(
      accessToken: string,
      idToken: string,
      locationId: number,
      includeDisabled?: boolean,
      options?: any
    ): AxiosPromise<Array<ServicePhone>> {
      return localVarFp
        .getServicePhones(accessToken, idToken, locationId, includeDisabled, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get available service vehicles for location
     * @summary Get available service vehicles for location
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {boolean} [includeDisabled]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceVehicles(
      accessToken: string,
      idToken: string,
      locationId: number,
      includeDisabled?: boolean,
      options?: any
    ): AxiosPromise<Array<ServiceVehicle>> {
      return localVarFp
        .getServiceVehicles(accessToken, idToken, locationId, includeDisabled, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get tour detail
     * @summary Get tour detail
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTour(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      options?: any
    ): AxiosPromise<Tour> {
      return localVarFp
        .getTour(accessToken, idToken, missionId, tourId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get tour corridor
     * @summary Get tour corridor
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTourCorridor(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      options?: any
    ): AxiosPromise<TourCorridor> {
      return localVarFp
        .getTourCorridor(accessToken, idToken, missionId, tourId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get detailed tour logs
     * @summary Get detailed tour logs
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTourLogs(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      options?: any
    ): AxiosPromise<Array<GenericTourEvent>> {
      return localVarFp
        .getTourLogs(accessToken, idToken, missionId, tourId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get available hubs, service vehicles, service phones and service employees as a bundle
     * @summary Get available hubs, service vehicles, service phones and service employees as a bundle
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} lat
     * @param {number} lon
     * @param {MissionType} missionType
     * @param {boolean} [debug]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTourParameterOptions(
      accessToken: string,
      idToken: string,
      lat: number,
      lon: number,
      missionType: MissionType,
      debug?: boolean,
      options?: any
    ): AxiosPromise<TourParameterOptionsBundle> {
      return localVarFp
        .getTourParameterOptions(accessToken, idToken, lat, lon, missionType, debug, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get tour statistics, if available
     * @summary Get tour statistics, if available
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTourStats(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      options?: any
    ): AxiosPromise<TourStats> {
      return localVarFp
        .getTourStats(accessToken, idToken, missionId, tourId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get paginated list of recent tours
     * @summary Get paginated list of recent tours
     * @param {string} accessToken
     * @param {string} idToken
     * @param {PaginatedTourFilterBody} paginatedTourFilterBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToursForLocation(
      accessToken: string,
      idToken: string,
      paginatedTourFilterBody: PaginatedTourFilterBody,
      options?: any
    ): AxiosPromise<PaginatedParentResultTourMission> {
      return localVarFp
        .getToursForLocation(accessToken, idToken, paginatedTourFilterBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get list of tours for the specified mission
     * @summary Get list of tours for the specified mission
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {boolean} includeFinished
     * @param {number} page
     * @param {number} pageSize
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getToursForMission(
      accessToken: string,
      idToken: string,
      missionId: number,
      includeFinished: boolean,
      page: number,
      pageSize: number,
      options?: any
    ): AxiosPromise<PaginatedResultTour> {
      return localVarFp
        .getToursForMission(
          accessToken,
          idToken,
          missionId,
          includeFinished,
          page,
          pageSize,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get list of available vehicle types
     * @summary Get list of available vehicle types
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVehicleTypes(
      accessToken: string,
      idToken: string,
      options?: any
    ): AxiosPromise<Array<VehicleType>> {
      return localVarFp
        .getVehicleTypes(accessToken, idToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a list of vehicles with their status being in the specified service type
     * @summary Get a list of vehicles with their status being in the specified service type
     * @param {string} accessToken
     * @param {string} idToken
     * @param {ServiceVehiclesFilterSet} serviceVehiclesFilterSet
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVehiclesAvailableForService(
      accessToken: string,
      idToken: string,
      serviceVehiclesFilterSet: ServiceVehiclesFilterSet,
      options?: any
    ): AxiosPromise<ServiceOverview> {
      return localVarFp
        .getVehiclesAvailableForService(accessToken, idToken, serviceVehiclesFilterSet, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Refresh the GPS location of the step
     * @summary Refresh the GPS location of the step
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshStepLocation(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      options?: any
    ): AxiosPromise<TourResponse> {
      return localVarFp
        .refreshStepLocation(accessToken, idToken, missionId, tourId, stepId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Request tour from mission
     * @summary Request tour from mission
     * @param {string} accessToken
     * @param {string} idToken
     * @param {TourParametersBody} tourParametersBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    requestTourFromMission(
      accessToken: string,
      idToken: string,
      tourParametersBody: TourParametersBody,
      options?: any
    ): AxiosPromise<CompiledTour> {
      return localVarFp
        .requestTourFromMission(accessToken, idToken, tourParametersBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Simulate tour
     * @summary Simulate tour based on a given mission. The tour is not persisted to the database. Do not interact with this tour, only use this for simulation purposes.
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {TourParametersBody} tourParametersBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    simulateTourFromMission(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourParametersBody: TourParametersBody,
      options?: any
    ): AxiosPromise<CompiledTour> {
      return localVarFp
        .simulateTourFromMission(accessToken, idToken, missionId, tourParametersBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Start working on the step
     * @summary Start working on the step
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startStep(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      options?: any
    ): AxiosPromise<TourResponse> {
      return localVarFp
        .startStep(accessToken, idToken, missionId, tourId, stepId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Trigger the specified step action
     * @summary Trigger the specified step action
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} missionId
     * @param {number} tourId
     * @param {number} stepId
     * @param {TriggerActionBody} triggerActionBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    triggerStepAction(
      accessToken: string,
      idToken: string,
      missionId: number,
      tourId: number,
      stepId: number,
      triggerActionBody: TriggerActionBody,
      options?: any
    ): AxiosPromise<TriggerEvent> {
      return localVarFp
        .triggerStepAction(
          accessToken,
          idToken,
          missionId,
          tourId,
          stepId,
          triggerActionBody,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Create or update a service hub
     * @summary Create or update a service hub
     * @param {string} accessToken
     * @param {string} idToken
     * @param {ServiceHub} serviceHub
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertServiceHub(
      accessToken: string,
      idToken: string,
      serviceHub: ServiceHub,
      options?: any
    ): AxiosPromise<ServiceHub> {
      return localVarFp
        .upsertServiceHub(accessToken, idToken, serviceHub, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create or update a service parking zone
     * @summary Create or update a service parking zone
     * @param {string} accessToken
     * @param {string} idToken
     * @param {ServiceParkingZone} serviceParkingZone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertServiceParkingZone(
      accessToken: string,
      idToken: string,
      serviceParkingZone: ServiceParkingZone,
      options?: any
    ): AxiosPromise<ServiceParkingZone> {
      return localVarFp
        .upsertServiceParkingZone(accessToken, idToken, serviceParkingZone, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create or update a service phone
     * @summary Create or update a service phone
     * @param {string} accessToken
     * @param {string} idToken
     * @param {ServicePhone} servicePhone
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertServicePhone(
      accessToken: string,
      idToken: string,
      servicePhone: ServicePhone,
      options?: any
    ): AxiosPromise<ServicePhone> {
      return localVarFp
        .upsertServicePhone(accessToken, idToken, servicePhone, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Create or update a service vehicle
     * @summary Create or update a service vehicle
     * @param {string} accessToken
     * @param {string} idToken
     * @param {ServiceVehicle} serviceVehicle
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    upsertServiceVehicle(
      accessToken: string,
      idToken: string,
      serviceVehicle: ServiceVehicle,
      options?: any
    ): AxiosPromise<ServiceVehicle> {
      return localVarFp
        .upsertServiceVehicle(accessToken, idToken, serviceVehicle, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * ServiceMissionsV2Api - object-oriented interface
 * @export
 * @class ServiceMissionsV2Api
 * @extends {BaseAPI}
 */
export class ServiceMissionsV2Api extends BaseAPI {
  /**
   * Adapt a tour by adding / removing / reordering jobs
   * @summary Adapt a tour by adding / removing / reordering jobs
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {File} body
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public adaptTour(
    accessToken: string,
    idToken: string,
    missionId: number,
    tourId: number,
    body: File,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .adaptTour(accessToken, idToken, missionId, tourId, body, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Bulk-starts pre and at steps
   * @summary Bulk-starts all open pre- and at-steps of the job
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} jobId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public bulkStartPreAndAtSteps(
    accessToken: string,
    idToken: string,
    missionId: number,
    tourId: number,
    jobId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .bulkStartPreAndAtSteps(accessToken, idToken, missionId, tourId, jobId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Check if step can be completed
   * @summary Check if step can be completed
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} stepId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public completeChecks(
    accessToken: string,
    idToken: string,
    missionId: number,
    tourId: number,
    stepId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .completeChecks(accessToken, idToken, missionId, tourId, stepId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Complete the specified step
   * @summary Complete the specified step
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} stepId
   * @param {TransitionActionBody} transitionActionBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public completeStep(
    accessToken: string,
    idToken: string,
    missionId: number,
    tourId: number,
    stepId: number,
    transitionActionBody: TransitionActionBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .completeStep(accessToken, idToken, missionId, tourId, stepId, transitionActionBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Completes the tour
   * @summary Completes the tour
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {CompleteTourBody} completeTourBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public completeTour(
    accessToken: string,
    idToken: string,
    missionId: number,
    tourId: number,
    completeTourBody: CompleteTourBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .completeTour(accessToken, idToken, missionId, tourId, completeTourBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create a new mission
   * @summary Create a new mission
   * @param {string} accessToken
   * @param {string} idToken
   * @param {MissionBody} missionBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public createMission(
    accessToken: string,
    idToken: string,
    missionBody: MissionBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .createMission(accessToken, idToken, missionBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Calculate estimated driving time between two coordinates
   * @summary Calculate estimated driving time between two coordinates
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} startLat
   * @param {number} startLon
   * @param {number} endLat
   * @param {number} endLon
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public estimatedDrivingTime(
    accessToken: string,
    idToken: string,
    startLat: number,
    startLon: number,
    endLat: number,
    endLon: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .estimatedDrivingTime(accessToken, idToken, startLat, startLon, endLat, endLon, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Extends the tour by adding the nearest service job
   * @summary Extends the tour by adding the nearest service job
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {ExtendByOneBody} extendByOneBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public extendByOneJob(
    accessToken: string,
    idToken: string,
    missionId: number,
    tourId: number,
    extendByOneBody: ExtendByOneBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .extendByOneJob(accessToken, idToken, missionId, tourId, extendByOneBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Fail the specified step
   * @summary Fail the specified step
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} stepId
   * @param {FailStepBody} failStepBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public failStep(
    accessToken: string,
    idToken: string,
    missionId: number,
    tourId: number,
    stepId: number,
    failStepBody: FailStepBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .failStep(accessToken, idToken, missionId, tourId, stepId, failStepBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Fail the tour
   * @summary Fail the tour
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {ReasonBody} reasonBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public failTour(
    accessToken: string,
    idToken: string,
    missionId: number,
    tourId: number,
    reasonBody: ReasonBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .failTour(accessToken, idToken, missionId, tourId, reasonBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Forcefully fails / ends the specified job without trying any Jira transition
   * @summary Forcefully fails / ends the specified job without trying any Jira transition
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} jobId
   * @param {ReasonBody} reasonBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public forceEndJob(
    accessToken: string,
    idToken: string,
    missionId: number,
    tourId: number,
    jobId: number,
    reasonBody: ReasonBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .forceEndJob(accessToken, idToken, missionId, tourId, jobId, reasonBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Forcefully fails / ends the specified tour without trying any Jira transitions
   * @summary Forcefully fails / ends the specified tour without trying any Jira transitions
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {ReasonBody} reasonBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public forceEndTour(
    accessToken: string,
    idToken: string,
    missionId: number,
    tourId: number,
    reasonBody: ReasonBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .forceEndTour(accessToken, idToken, missionId, tourId, reasonBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all open tours for the user
   * @summary Get all open tours for the user
   * @param {string} accessToken
   * @param {string} idToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public getAllOpenTours(accessToken: string, idToken: string, options?: AxiosRequestConfig) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .getAllOpenTours(accessToken, idToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get the available complete action options for the specified step
   * @summary Get the available complete action options for the specified step
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} stepId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public getCompleteActionOptions(
    accessToken: string,
    idToken: string,
    missionId: number,
    tourId: number,
    stepId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .getCompleteActionOptions(accessToken, idToken, missionId, tourId, stepId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets mission detail with tour statistics
   * @summary Gets mission detail with tour statistics
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} missionId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public getMissionWithTourStatistics(
    accessToken: string,
    idToken: string,
    missionId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .getMissionWithTourStatistics(accessToken, idToken, missionId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets missions
   * @summary Gets missions
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} locationId
   * @param {boolean} includeExpired
   * @param {boolean} includeDisabled
   * @param {MissionType} missionType
   * @param {number} page
   * @param {number} pageSize
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public getMissions(
    accessToken: string,
    idToken: string,
    locationId: number,
    includeExpired: boolean,
    includeDisabled: boolean,
    missionType: MissionType,
    page: number,
    pageSize: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .getMissions(
        accessToken,
        idToken,
        locationId,
        includeExpired,
        includeDisabled,
        missionType,
        page,
        pageSize,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets the highest prioritized mission of the specified type for the user. Use debug=true query for testing in OCXX.
   * @summary Gets the highest prioritized mission
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} lat
   * @param {number} lon
   * @param {MissionType} type
   * @param {boolean} [debug]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public getNextMission(
    accessToken: string,
    idToken: string,
    lat: number,
    lon: number,
    type: MissionType,
    debug?: boolean,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .getNextMission(accessToken, idToken, lat, lon, type, debug, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get the open tour for the specified tour type (if there is one)
   * @summary Get the open tour for the specified tour type (if there is one)
   * @param {string} accessToken
   * @param {string} idToken
   * @param {MissionType} tourType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public getOpenTour(
    accessToken: string,
    idToken: string,
    tourType: MissionType,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .getOpenTour(accessToken, idToken, tourType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get available parking zones for location
   * @summary Get available parking zones for location
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} locationId
   * @param {boolean} [includeInactive]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public getParkingZones(
    accessToken: string,
    idToken: string,
    locationId: number,
    includeInactive?: boolean,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .getParkingZones(accessToken, idToken, locationId, includeInactive, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get list of service employees
   * @summary Get list of service employees
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} locationId
   * @param {MissionType} missionType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public getServiceEmployees(
    accessToken: string,
    idToken: string,
    locationId: number,
    missionType: MissionType,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .getServiceEmployees(accessToken, idToken, locationId, missionType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get available hubs for location
   * @summary Get available hubs for location
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} locationId
   * @param {boolean} [includeInactive]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public getServiceHubs(
    accessToken: string,
    idToken: string,
    locationId: number,
    includeInactive?: boolean,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .getServiceHubs(accessToken, idToken, locationId, includeInactive, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get available service phones for location
   * @summary Get available service phones for location
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} locationId
   * @param {boolean} [includeDisabled]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public getServicePhones(
    accessToken: string,
    idToken: string,
    locationId: number,
    includeDisabled?: boolean,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .getServicePhones(accessToken, idToken, locationId, includeDisabled, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get available service vehicles for location
   * @summary Get available service vehicles for location
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} locationId
   * @param {boolean} [includeDisabled]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public getServiceVehicles(
    accessToken: string,
    idToken: string,
    locationId: number,
    includeDisabled?: boolean,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .getServiceVehicles(accessToken, idToken, locationId, includeDisabled, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get tour detail
   * @summary Get tour detail
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public getTour(
    accessToken: string,
    idToken: string,
    missionId: number,
    tourId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .getTour(accessToken, idToken, missionId, tourId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get tour corridor
   * @summary Get tour corridor
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public getTourCorridor(
    accessToken: string,
    idToken: string,
    missionId: number,
    tourId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .getTourCorridor(accessToken, idToken, missionId, tourId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get detailed tour logs
   * @summary Get detailed tour logs
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public getTourLogs(
    accessToken: string,
    idToken: string,
    missionId: number,
    tourId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .getTourLogs(accessToken, idToken, missionId, tourId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get available hubs, service vehicles, service phones and service employees as a bundle
   * @summary Get available hubs, service vehicles, service phones and service employees as a bundle
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} lat
   * @param {number} lon
   * @param {MissionType} missionType
   * @param {boolean} [debug]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public getTourParameterOptions(
    accessToken: string,
    idToken: string,
    lat: number,
    lon: number,
    missionType: MissionType,
    debug?: boolean,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .getTourParameterOptions(accessToken, idToken, lat, lon, missionType, debug, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get tour statistics, if available
   * @summary Get tour statistics, if available
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public getTourStats(
    accessToken: string,
    idToken: string,
    missionId: number,
    tourId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .getTourStats(accessToken, idToken, missionId, tourId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get paginated list of recent tours
   * @summary Get paginated list of recent tours
   * @param {string} accessToken
   * @param {string} idToken
   * @param {PaginatedTourFilterBody} paginatedTourFilterBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public getToursForLocation(
    accessToken: string,
    idToken: string,
    paginatedTourFilterBody: PaginatedTourFilterBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .getToursForLocation(accessToken, idToken, paginatedTourFilterBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get list of tours for the specified mission
   * @summary Get list of tours for the specified mission
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} missionId
   * @param {boolean} includeFinished
   * @param {number} page
   * @param {number} pageSize
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public getToursForMission(
    accessToken: string,
    idToken: string,
    missionId: number,
    includeFinished: boolean,
    page: number,
    pageSize: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .getToursForMission(accessToken, idToken, missionId, includeFinished, page, pageSize, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get list of available vehicle types
   * @summary Get list of available vehicle types
   * @param {string} accessToken
   * @param {string} idToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public getVehicleTypes(accessToken: string, idToken: string, options?: AxiosRequestConfig) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .getVehicleTypes(accessToken, idToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a list of vehicles with their status being in the specified service type
   * @summary Get a list of vehicles with their status being in the specified service type
   * @param {string} accessToken
   * @param {string} idToken
   * @param {ServiceVehiclesFilterSet} serviceVehiclesFilterSet
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public getVehiclesAvailableForService(
    accessToken: string,
    idToken: string,
    serviceVehiclesFilterSet: ServiceVehiclesFilterSet,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .getVehiclesAvailableForService(accessToken, idToken, serviceVehiclesFilterSet, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Refresh the GPS location of the step
   * @summary Refresh the GPS location of the step
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} stepId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public refreshStepLocation(
    accessToken: string,
    idToken: string,
    missionId: number,
    tourId: number,
    stepId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .refreshStepLocation(accessToken, idToken, missionId, tourId, stepId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Request tour from mission
   * @summary Request tour from mission
   * @param {string} accessToken
   * @param {string} idToken
   * @param {TourParametersBody} tourParametersBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public requestTourFromMission(
    accessToken: string,
    idToken: string,
    tourParametersBody: TourParametersBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .requestTourFromMission(accessToken, idToken, tourParametersBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Simulate tour
   * @summary Simulate tour based on a given mission. The tour is not persisted to the database. Do not interact with this tour, only use this for simulation purposes.
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} missionId
   * @param {TourParametersBody} tourParametersBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public simulateTourFromMission(
    accessToken: string,
    idToken: string,
    missionId: number,
    tourParametersBody: TourParametersBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .simulateTourFromMission(accessToken, idToken, missionId, tourParametersBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Start working on the step
   * @summary Start working on the step
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} stepId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public startStep(
    accessToken: string,
    idToken: string,
    missionId: number,
    tourId: number,
    stepId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .startStep(accessToken, idToken, missionId, tourId, stepId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Trigger the specified step action
   * @summary Trigger the specified step action
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} missionId
   * @param {number} tourId
   * @param {number} stepId
   * @param {TriggerActionBody} triggerActionBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public triggerStepAction(
    accessToken: string,
    idToken: string,
    missionId: number,
    tourId: number,
    stepId: number,
    triggerActionBody: TriggerActionBody,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .triggerStepAction(
        accessToken,
        idToken,
        missionId,
        tourId,
        stepId,
        triggerActionBody,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create or update a service hub
   * @summary Create or update a service hub
   * @param {string} accessToken
   * @param {string} idToken
   * @param {ServiceHub} serviceHub
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public upsertServiceHub(
    accessToken: string,
    idToken: string,
    serviceHub: ServiceHub,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .upsertServiceHub(accessToken, idToken, serviceHub, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create or update a service parking zone
   * @summary Create or update a service parking zone
   * @param {string} accessToken
   * @param {string} idToken
   * @param {ServiceParkingZone} serviceParkingZone
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public upsertServiceParkingZone(
    accessToken: string,
    idToken: string,
    serviceParkingZone: ServiceParkingZone,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .upsertServiceParkingZone(accessToken, idToken, serviceParkingZone, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create or update a service phone
   * @summary Create or update a service phone
   * @param {string} accessToken
   * @param {string} idToken
   * @param {ServicePhone} servicePhone
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public upsertServicePhone(
    accessToken: string,
    idToken: string,
    servicePhone: ServicePhone,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .upsertServicePhone(accessToken, idToken, servicePhone, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Create or update a service vehicle
   * @summary Create or update a service vehicle
   * @param {string} accessToken
   * @param {string} idToken
   * @param {ServiceVehicle} serviceVehicle
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceMissionsV2Api
   */
  public upsertServiceVehicle(
    accessToken: string,
    idToken: string,
    serviceVehicle: ServiceVehicle,
    options?: AxiosRequestConfig
  ) {
    return ServiceMissionsV2ApiFp(this.configuration)
      .upsertServiceVehicle(accessToken, idToken, serviceVehicle, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * ServiceV2Api - axios parameter creator
 * @export
 */
export const ServiceV2ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Creates a comment for a service task by taskId
     * @summary Create comment on service task
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} taskId
     * @param {InputComment} inputComment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCommentOnServiceTask: async (
      accessToken: string,
      idToken: string,
      taskId: number,
      inputComment: InputComment,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('createCommentOnServiceTask', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('createCommentOnServiceTask', 'idToken', idToken);
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists('createCommentOnServiceTask', 'taskId', taskId);
      // verify required parameter 'inputComment' is not null or undefined
      assertParamExists('createCommentOnServiceTask', 'inputComment', inputComment);
      const localVarPath = `/v2/service/task/{taskId}/comment`.replace(
        `{${'taskId'}}`,
        encodeURIComponent(String(taskId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inputComment,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Create service asset (vehicle)
     * @param {string} accessToken
     * @param {string} idToken
     * @param {InputServiceAsset} inputServiceAsset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createServiceAsset: async (
      accessToken: string,
      idToken: string,
      inputServiceAsset: InputServiceAsset,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('createServiceAsset', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('createServiceAsset', 'idToken', idToken);
      // verify required parameter 'inputServiceAsset' is not null or undefined
      assertParamExists('createServiceAsset', 'inputServiceAsset', inputServiceAsset);
      const localVarPath = `/v2/service/asset`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inputServiceAsset,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Creates a new service task
     * @summary Create service task
     * @param {string} accessToken
     * @param {string} idToken
     * @param {InputServiceTask} inputServiceTask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createServiceTask: async (
      accessToken: string,
      idToken: string,
      inputServiceTask: InputServiceTask,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('createServiceTask', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('createServiceTask', 'idToken', idToken);
      // verify required parameter 'inputServiceTask' is not null or undefined
      assertParamExists('createServiceTask', 'inputServiceTask', inputServiceTask);
      const localVarPath = `/v2/service/task`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inputServiceTask,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Creates a new list of service tasks
     * @summary Create bulk service tasks
     * @param {string} accessToken
     * @param {string} idToken
     * @param {Array<InputServiceTask>} [inputServiceTask]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createServiceTasks: async (
      accessToken: string,
      idToken: string,
      inputServiceTask?: Array<InputServiceTask>,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('createServiceTasks', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('createServiceTasks', 'idToken', idToken);
      const localVarPath = `/v2/service/tasks/bulk-create`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inputServiceTask,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Gets all comments on a service task by taskId
     * @summary Get comments for service task
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommentOnServiceTask: async (
      accessToken: string,
      idToken: string,
      taskId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getCommentOnServiceTask', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getCommentOnServiceTask', 'idToken', idToken);
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists('getCommentOnServiceTask', 'taskId', taskId);
      const localVarPath = `/v2/service/task/{taskId}/comment`.replace(
        `{${'taskId'}}`,
        encodeURIComponent(String(taskId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get a specific service asset (vehicle) by the assets vin
     * @summary Get service asset (vehicle) by vin
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} assetVIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceAsset: async (
      accessToken: string,
      idToken: string,
      assetVIN: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServiceAsset', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getServiceAsset', 'idToken', idToken);
      // verify required parameter 'assetVIN' is not null or undefined
      assertParamExists('getServiceAsset', 'assetVIN', assetVIN);
      const localVarPath = `/v2/service/asset/{assetVIN}`.replace(
        `{${'assetVIN'}}`,
        encodeURIComponent(String(assetVIN))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get service assets (vehicles) in the specified business location (Badgermole businessLocationId)
     * @summary Get service assets (vehicles)
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} [businessLocationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceAssets: async (
      accessToken: string,
      idToken: string,
      businessLocationId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServiceAssets', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getServiceAssets', 'idToken', idToken);
      const localVarPath = `/v2/service/asset`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (businessLocationId !== undefined) {
        localVarQueryParameter['businessLocationId'] = businessLocationId;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Retrieve service assets (vehicles) filtered based on provided criteria.
     * @summary Get filtered service assets (vehicles)
     * @param {string} accessToken
     * @param {string} idToken
     * @param {ServiceAssetFilters} serviceAssetFilters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceAssetsFiltered: async (
      accessToken: string,
      idToken: string,
      serviceAssetFilters: ServiceAssetFilters,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServiceAssetsFiltered', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getServiceAssetsFiltered', 'idToken', idToken);
      // verify required parameter 'serviceAssetFilters' is not null or undefined
      assertParamExists('getServiceAssetsFiltered', 'serviceAssetFilters', serviceAssetFilters);
      const localVarPath = `/v2/service/assetsfiltered`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        serviceAssetFilters,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get a specific service location.
     * @summary Get a specific service location
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceLocation: async (
      accessToken: string,
      idToken: string,
      locationId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServiceLocation', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getServiceLocation', 'idToken', idToken);
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('getServiceLocation', 'locationId', locationId);
      const localVarPath = `/v2/service/business-location/{locationId}`.replace(
        `{${'locationId'}}`,
        encodeURIComponent(String(locationId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get service locations.
     * @summary Get service locations.
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceLocations: async (
      accessToken: string,
      idToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServiceLocations', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getServiceLocations', 'idToken', idToken);
      const localVarPath = `/v2/service/business-location`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Gets a service task by id
     * @summary Get service task
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceTask: async (
      accessToken: string,
      idToken: string,
      taskId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServiceTask', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getServiceTask', 'idToken', idToken);
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists('getServiceTask', 'taskId', taskId);
      const localVarPath = `/v2/service/task/{taskId}`.replace(
        `{${'taskId'}}`,
        encodeURIComponent(String(taskId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get all the logs for a service task by taskId
     * @summary Get logs for service task
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceTaskLogs: async (
      accessToken: string,
      idToken: string,
      taskId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServiceTaskLogs', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getServiceTaskLogs', 'idToken', idToken);
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists('getServiceTaskLogs', 'taskId', taskId);
      const localVarPath = `/v2/service/task/{taskId}/logs`.replace(
        `{${'taskId'}}`,
        encodeURIComponent(String(taskId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     *
     * @summary Get service tasks
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} [businessLocationId]
     * @param {AssignmentState} [taskStatus]
     * @param {AssignmentType} [taskType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceTasks: async (
      accessToken: string,
      idToken: string,
      businessLocationId?: number,
      taskStatus?: AssignmentState,
      taskType?: AssignmentType,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getServiceTasks', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getServiceTasks', 'idToken', idToken);
      const localVarPath = `/v2/service/task`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (businessLocationId !== undefined) {
        localVarQueryParameter['businessLocationId'] = businessLocationId;
      }

      if (taskStatus !== undefined) {
        localVarQueryParameter['taskStatus'] = taskStatus;
      }

      if (taskType !== undefined) {
        localVarQueryParameter['taskType'] = taskType;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Updates a service asset (vehicle) by its vin
     * @summary Update service asset (vehicle)
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} assetVIN
     * @param {InputServiceAsset} inputServiceAsset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateServiceAsset: async (
      accessToken: string,
      idToken: string,
      assetVIN: string,
      inputServiceAsset: InputServiceAsset,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('updateServiceAsset', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('updateServiceAsset', 'idToken', idToken);
      // verify required parameter 'assetVIN' is not null or undefined
      assertParamExists('updateServiceAsset', 'assetVIN', assetVIN);
      // verify required parameter 'inputServiceAsset' is not null or undefined
      assertParamExists('updateServiceAsset', 'inputServiceAsset', inputServiceAsset);
      const localVarPath = `/v2/service/asset/{assetVIN}`.replace(
        `{${'assetVIN'}}`,
        encodeURIComponent(String(assetVIN))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inputServiceAsset,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Updates service task by id
     * @summary Update service task
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} taskId
     * @param {InputServiceTask} inputServiceTask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateServiceTask: async (
      accessToken: string,
      idToken: string,
      taskId: number,
      inputServiceTask: InputServiceTask,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('updateServiceTask', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('updateServiceTask', 'idToken', idToken);
      // verify required parameter 'taskId' is not null or undefined
      assertParamExists('updateServiceTask', 'taskId', taskId);
      // verify required parameter 'inputServiceTask' is not null or undefined
      assertParamExists('updateServiceTask', 'inputServiceTask', inputServiceTask);
      const localVarPath = `/v2/service/task/{taskId}`.replace(
        `{${'taskId'}}`,
        encodeURIComponent(String(taskId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        inputServiceTask,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * ServiceV2Api - functional programming interface
 * @export
 */
export const ServiceV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ServiceV2ApiAxiosParamCreator(configuration);
  return {
    /**
     * Creates a comment for a service task by taskId
     * @summary Create comment on service task
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} taskId
     * @param {InputComment} inputComment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createCommentOnServiceTask(
      accessToken: string,
      idToken: string,
      taskId: number,
      inputComment: InputComment,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceTask>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createCommentOnServiceTask(
        accessToken,
        idToken,
        taskId,
        inputComment,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceV2Api.createCommentOnServiceTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Create service asset (vehicle)
     * @param {string} accessToken
     * @param {string} idToken
     * @param {InputServiceAsset} inputServiceAsset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createServiceAsset(
      accessToken: string,
      idToken: string,
      inputServiceAsset: InputServiceAsset,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceAsset>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createServiceAsset(
        accessToken,
        idToken,
        inputServiceAsset,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceV2Api.createServiceAsset']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Creates a new service task
     * @summary Create service task
     * @param {string} accessToken
     * @param {string} idToken
     * @param {InputServiceTask} inputServiceTask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createServiceTask(
      accessToken: string,
      idToken: string,
      inputServiceTask: InputServiceTask,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceTask>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createServiceTask(
        accessToken,
        idToken,
        inputServiceTask,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceV2Api.createServiceTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Creates a new list of service tasks
     * @summary Create bulk service tasks
     * @param {string} accessToken
     * @param {string} idToken
     * @param {Array<InputServiceTask>} [inputServiceTask]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createServiceTasks(
      accessToken: string,
      idToken: string,
      inputServiceTask?: Array<InputServiceTask>,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceTask>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createServiceTasks(
        accessToken,
        idToken,
        inputServiceTask,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceV2Api.createServiceTasks']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Gets all comments on a service task by taskId
     * @summary Get comments for service task
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCommentOnServiceTask(
      accessToken: string,
      idToken: string,
      taskId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Comment>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentOnServiceTask(
        accessToken,
        idToken,
        taskId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceV2Api.getCommentOnServiceTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get a specific service asset (vehicle) by the assets vin
     * @summary Get service asset (vehicle) by vin
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} assetVIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceAsset(
      accessToken: string,
      idToken: string,
      assetVIN: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceAsset>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceAsset(
        accessToken,
        idToken,
        assetVIN,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceV2Api.getServiceAsset']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get service assets (vehicles) in the specified business location (Badgermole businessLocationId)
     * @summary Get service assets (vehicles)
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} [businessLocationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceAssets(
      accessToken: string,
      idToken: string,
      businessLocationId?: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceAsset>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceAssets(
        accessToken,
        idToken,
        businessLocationId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceV2Api.getServiceAssets']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Retrieve service assets (vehicles) filtered based on provided criteria.
     * @summary Get filtered service assets (vehicles)
     * @param {string} accessToken
     * @param {string} idToken
     * @param {ServiceAssetFilters} serviceAssetFilters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceAssetsFiltered(
      accessToken: string,
      idToken: string,
      serviceAssetFilters: ServiceAssetFilters,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceAssetInfo>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceAssetsFiltered(
        accessToken,
        idToken,
        serviceAssetFilters,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceV2Api.getServiceAssetsFiltered']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get a specific service location.
     * @summary Get a specific service location
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceLocation(
      accessToken: string,
      idToken: string,
      locationId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceLocation>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceLocation(
        accessToken,
        idToken,
        locationId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceV2Api.getServiceLocation']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get service locations.
     * @summary Get service locations.
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceLocations(
      accessToken: string,
      idToken: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceLocation>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceLocations(
        accessToken,
        idToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['ServiceV2Api.getServiceLocations']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Gets a service task by id
     * @summary Get service task
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceTask(
      accessToken: string,
      idToken: string,
      taskId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceTask>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceTask(
        accessToken,
        idToken,
        taskId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceV2Api.getServiceTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get all the logs for a service task by taskId
     * @summary Get logs for service task
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceTaskLogs(
      accessToken: string,
      idToken: string,
      taskId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskLog>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceTaskLogs(
        accessToken,
        idToken,
        taskId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceV2Api.getServiceTaskLogs']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     *
     * @summary Get service tasks
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} [businessLocationId]
     * @param {AssignmentState} [taskStatus]
     * @param {AssignmentType} [taskType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getServiceTasks(
      accessToken: string,
      idToken: string,
      businessLocationId?: number,
      taskStatus?: AssignmentState,
      taskType?: AssignmentType,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ServiceTask>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getServiceTasks(
        accessToken,
        idToken,
        businessLocationId,
        taskStatus,
        taskType,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceV2Api.getServiceTasks']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Updates a service asset (vehicle) by its vin
     * @summary Update service asset (vehicle)
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} assetVIN
     * @param {InputServiceAsset} inputServiceAsset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateServiceAsset(
      accessToken: string,
      idToken: string,
      assetVIN: string,
      inputServiceAsset: InputServiceAsset,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceAsset>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateServiceAsset(
        accessToken,
        idToken,
        assetVIN,
        inputServiceAsset,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceV2Api.updateServiceAsset']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Updates service task by id
     * @summary Update service task
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} taskId
     * @param {InputServiceTask} inputServiceTask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateServiceTask(
      accessToken: string,
      idToken: string,
      taskId: number,
      inputServiceTask: InputServiceTask,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ServiceTask>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateServiceTask(
        accessToken,
        idToken,
        taskId,
        inputServiceTask,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['ServiceV2Api.updateServiceTask']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * ServiceV2Api - factory interface
 * @export
 */
export const ServiceV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ServiceV2ApiFp(configuration);
  return {
    /**
     * Creates a comment for a service task by taskId
     * @summary Create comment on service task
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} taskId
     * @param {InputComment} inputComment
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createCommentOnServiceTask(
      accessToken: string,
      idToken: string,
      taskId: number,
      inputComment: InputComment,
      options?: any
    ): AxiosPromise<ServiceTask> {
      return localVarFp
        .createCommentOnServiceTask(accessToken, idToken, taskId, inputComment, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Create service asset (vehicle)
     * @param {string} accessToken
     * @param {string} idToken
     * @param {InputServiceAsset} inputServiceAsset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createServiceAsset(
      accessToken: string,
      idToken: string,
      inputServiceAsset: InputServiceAsset,
      options?: any
    ): AxiosPromise<ServiceAsset> {
      return localVarFp
        .createServiceAsset(accessToken, idToken, inputServiceAsset, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Creates a new service task
     * @summary Create service task
     * @param {string} accessToken
     * @param {string} idToken
     * @param {InputServiceTask} inputServiceTask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createServiceTask(
      accessToken: string,
      idToken: string,
      inputServiceTask: InputServiceTask,
      options?: any
    ): AxiosPromise<ServiceTask> {
      return localVarFp
        .createServiceTask(accessToken, idToken, inputServiceTask, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Creates a new list of service tasks
     * @summary Create bulk service tasks
     * @param {string} accessToken
     * @param {string} idToken
     * @param {Array<InputServiceTask>} [inputServiceTask]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createServiceTasks(
      accessToken: string,
      idToken: string,
      inputServiceTask?: Array<InputServiceTask>,
      options?: any
    ): AxiosPromise<Array<ServiceTask>> {
      return localVarFp
        .createServiceTasks(accessToken, idToken, inputServiceTask, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets all comments on a service task by taskId
     * @summary Get comments for service task
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCommentOnServiceTask(
      accessToken: string,
      idToken: string,
      taskId: number,
      options?: any
    ): AxiosPromise<Array<Comment>> {
      return localVarFp
        .getCommentOnServiceTask(accessToken, idToken, taskId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a specific service asset (vehicle) by the assets vin
     * @summary Get service asset (vehicle) by vin
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} assetVIN
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceAsset(
      accessToken: string,
      idToken: string,
      assetVIN: string,
      options?: any
    ): AxiosPromise<ServiceAsset> {
      return localVarFp
        .getServiceAsset(accessToken, idToken, assetVIN, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get service assets (vehicles) in the specified business location (Badgermole businessLocationId)
     * @summary Get service assets (vehicles)
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} [businessLocationId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceAssets(
      accessToken: string,
      idToken: string,
      businessLocationId?: number,
      options?: any
    ): AxiosPromise<Array<ServiceAsset>> {
      return localVarFp
        .getServiceAssets(accessToken, idToken, businessLocationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Retrieve service assets (vehicles) filtered based on provided criteria.
     * @summary Get filtered service assets (vehicles)
     * @param {string} accessToken
     * @param {string} idToken
     * @param {ServiceAssetFilters} serviceAssetFilters
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceAssetsFiltered(
      accessToken: string,
      idToken: string,
      serviceAssetFilters: ServiceAssetFilters,
      options?: any
    ): AxiosPromise<Array<ServiceAssetInfo>> {
      return localVarFp
        .getServiceAssetsFiltered(accessToken, idToken, serviceAssetFilters, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get a specific service location.
     * @summary Get a specific service location
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceLocation(
      accessToken: string,
      idToken: string,
      locationId: number,
      options?: any
    ): AxiosPromise<ServiceLocation> {
      return localVarFp
        .getServiceLocation(accessToken, idToken, locationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get service locations.
     * @summary Get service locations.
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceLocations(
      accessToken: string,
      idToken: string,
      options?: any
    ): AxiosPromise<Array<ServiceLocation>> {
      return localVarFp
        .getServiceLocations(accessToken, idToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets a service task by id
     * @summary Get service task
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceTask(
      accessToken: string,
      idToken: string,
      taskId: number,
      options?: any
    ): AxiosPromise<ServiceTask> {
      return localVarFp
        .getServiceTask(accessToken, idToken, taskId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get all the logs for a service task by taskId
     * @summary Get logs for service task
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} taskId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceTaskLogs(
      accessToken: string,
      idToken: string,
      taskId: number,
      options?: any
    ): AxiosPromise<Array<TaskLog>> {
      return localVarFp
        .getServiceTaskLogs(accessToken, idToken, taskId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary Get service tasks
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} [businessLocationId]
     * @param {AssignmentState} [taskStatus]
     * @param {AssignmentType} [taskType]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getServiceTasks(
      accessToken: string,
      idToken: string,
      businessLocationId?: number,
      taskStatus?: AssignmentState,
      taskType?: AssignmentType,
      options?: any
    ): AxiosPromise<Array<ServiceTask>> {
      return localVarFp
        .getServiceTasks(accessToken, idToken, businessLocationId, taskStatus, taskType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates a service asset (vehicle) by its vin
     * @summary Update service asset (vehicle)
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} assetVIN
     * @param {InputServiceAsset} inputServiceAsset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateServiceAsset(
      accessToken: string,
      idToken: string,
      assetVIN: string,
      inputServiceAsset: InputServiceAsset,
      options?: any
    ): AxiosPromise<ServiceAsset> {
      return localVarFp
        .updateServiceAsset(accessToken, idToken, assetVIN, inputServiceAsset, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Updates service task by id
     * @summary Update service task
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} taskId
     * @param {InputServiceTask} inputServiceTask
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateServiceTask(
      accessToken: string,
      idToken: string,
      taskId: number,
      inputServiceTask: InputServiceTask,
      options?: any
    ): AxiosPromise<ServiceTask> {
      return localVarFp
        .updateServiceTask(accessToken, idToken, taskId, inputServiceTask, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * ServiceV2Api - object-oriented interface
 * @export
 * @class ServiceV2Api
 * @extends {BaseAPI}
 */
export class ServiceV2Api extends BaseAPI {
  /**
   * Creates a comment for a service task by taskId
   * @summary Create comment on service task
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} taskId
   * @param {InputComment} inputComment
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceV2Api
   */
  public createCommentOnServiceTask(
    accessToken: string,
    idToken: string,
    taskId: number,
    inputComment: InputComment,
    options?: AxiosRequestConfig
  ) {
    return ServiceV2ApiFp(this.configuration)
      .createCommentOnServiceTask(accessToken, idToken, taskId, inputComment, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Create service asset (vehicle)
   * @param {string} accessToken
   * @param {string} idToken
   * @param {InputServiceAsset} inputServiceAsset
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceV2Api
   */
  public createServiceAsset(
    accessToken: string,
    idToken: string,
    inputServiceAsset: InputServiceAsset,
    options?: AxiosRequestConfig
  ) {
    return ServiceV2ApiFp(this.configuration)
      .createServiceAsset(accessToken, idToken, inputServiceAsset, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Creates a new service task
   * @summary Create service task
   * @param {string} accessToken
   * @param {string} idToken
   * @param {InputServiceTask} inputServiceTask
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceV2Api
   */
  public createServiceTask(
    accessToken: string,
    idToken: string,
    inputServiceTask: InputServiceTask,
    options?: AxiosRequestConfig
  ) {
    return ServiceV2ApiFp(this.configuration)
      .createServiceTask(accessToken, idToken, inputServiceTask, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Creates a new list of service tasks
   * @summary Create bulk service tasks
   * @param {string} accessToken
   * @param {string} idToken
   * @param {Array<InputServiceTask>} [inputServiceTask]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceV2Api
   */
  public createServiceTasks(
    accessToken: string,
    idToken: string,
    inputServiceTask?: Array<InputServiceTask>,
    options?: AxiosRequestConfig
  ) {
    return ServiceV2ApiFp(this.configuration)
      .createServiceTasks(accessToken, idToken, inputServiceTask, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets all comments on a service task by taskId
   * @summary Get comments for service task
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} taskId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceV2Api
   */
  public getCommentOnServiceTask(
    accessToken: string,
    idToken: string,
    taskId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceV2ApiFp(this.configuration)
      .getCommentOnServiceTask(accessToken, idToken, taskId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a specific service asset (vehicle) by the assets vin
   * @summary Get service asset (vehicle) by vin
   * @param {string} accessToken
   * @param {string} idToken
   * @param {string} assetVIN
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceV2Api
   */
  public getServiceAsset(
    accessToken: string,
    idToken: string,
    assetVIN: string,
    options?: AxiosRequestConfig
  ) {
    return ServiceV2ApiFp(this.configuration)
      .getServiceAsset(accessToken, idToken, assetVIN, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get service assets (vehicles) in the specified business location (Badgermole businessLocationId)
   * @summary Get service assets (vehicles)
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} [businessLocationId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceV2Api
   */
  public getServiceAssets(
    accessToken: string,
    idToken: string,
    businessLocationId?: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceV2ApiFp(this.configuration)
      .getServiceAssets(accessToken, idToken, businessLocationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Retrieve service assets (vehicles) filtered based on provided criteria.
   * @summary Get filtered service assets (vehicles)
   * @param {string} accessToken
   * @param {string} idToken
   * @param {ServiceAssetFilters} serviceAssetFilters
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceV2Api
   */
  public getServiceAssetsFiltered(
    accessToken: string,
    idToken: string,
    serviceAssetFilters: ServiceAssetFilters,
    options?: AxiosRequestConfig
  ) {
    return ServiceV2ApiFp(this.configuration)
      .getServiceAssetsFiltered(accessToken, idToken, serviceAssetFilters, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get a specific service location.
   * @summary Get a specific service location
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} locationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceV2Api
   */
  public getServiceLocation(
    accessToken: string,
    idToken: string,
    locationId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceV2ApiFp(this.configuration)
      .getServiceLocation(accessToken, idToken, locationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get service locations.
   * @summary Get service locations.
   * @param {string} accessToken
   * @param {string} idToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceV2Api
   */
  public getServiceLocations(accessToken: string, idToken: string, options?: AxiosRequestConfig) {
    return ServiceV2ApiFp(this.configuration)
      .getServiceLocations(accessToken, idToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets a service task by id
   * @summary Get service task
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} taskId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceV2Api
   */
  public getServiceTask(
    accessToken: string,
    idToken: string,
    taskId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceV2ApiFp(this.configuration)
      .getServiceTask(accessToken, idToken, taskId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get all the logs for a service task by taskId
   * @summary Get logs for service task
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} taskId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceV2Api
   */
  public getServiceTaskLogs(
    accessToken: string,
    idToken: string,
    taskId: number,
    options?: AxiosRequestConfig
  ) {
    return ServiceV2ApiFp(this.configuration)
      .getServiceTaskLogs(accessToken, idToken, taskId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get service tasks
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} [businessLocationId]
   * @param {AssignmentState} [taskStatus]
   * @param {AssignmentType} [taskType]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceV2Api
   */
  public getServiceTasks(
    accessToken: string,
    idToken: string,
    businessLocationId?: number,
    taskStatus?: AssignmentState,
    taskType?: AssignmentType,
    options?: AxiosRequestConfig
  ) {
    return ServiceV2ApiFp(this.configuration)
      .getServiceTasks(accessToken, idToken, businessLocationId, taskStatus, taskType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates a service asset (vehicle) by its vin
   * @summary Update service asset (vehicle)
   * @param {string} accessToken
   * @param {string} idToken
   * @param {string} assetVIN
   * @param {InputServiceAsset} inputServiceAsset
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceV2Api
   */
  public updateServiceAsset(
    accessToken: string,
    idToken: string,
    assetVIN: string,
    inputServiceAsset: InputServiceAsset,
    options?: AxiosRequestConfig
  ) {
    return ServiceV2ApiFp(this.configuration)
      .updateServiceAsset(accessToken, idToken, assetVIN, inputServiceAsset, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Updates service task by id
   * @summary Update service task
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} taskId
   * @param {InputServiceTask} inputServiceTask
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ServiceV2Api
   */
  public updateServiceTask(
    accessToken: string,
    idToken: string,
    taskId: number,
    inputServiceTask: InputServiceTask,
    options?: AxiosRequestConfig
  ) {
    return ServiceV2ApiFp(this.configuration)
      .updateServiceTask(accessToken, idToken, taskId, inputServiceTask, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * VehicleInteractionApi - axios parameter creator
 * @export
 */
export const VehicleInteractionApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Disables the vehicle alarm
     * @summary Disables the vehicle alarm
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1disableAlarm: async (vin: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'vin' is not null or undefined
      assertParamExists('v1disableAlarm', 'vin', vin);
      const localVarPath = `/vehicles/{vin}/alarm/disable`.replace(
        `{${'vin'}}`,
        encodeURIComponent(String(vin))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Enables the vehicle alarm
     * @summary Enables the vehicle alarm
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1enableAlarm: async (vin: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'vin' is not null or undefined
      assertParamExists('v1enableAlarm', 'vin', vin);
      const localVarPath = `/vehicles/{vin}/alarm/enable`.replace(
        `{${'vin'}}`,
        encodeURIComponent(String(vin))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * VehicleInteractionApi - functional programming interface
 * @export
 */
export const VehicleInteractionApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = VehicleInteractionApiAxiosParamCreator(configuration);
  return {
    /**
     * Disables the vehicle alarm
     * @summary Disables the vehicle alarm
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1disableAlarm(
      vin: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1disableAlarm(vin, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehicleInteractionApi.v1disableAlarm']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Enables the vehicle alarm
     * @summary Enables the vehicle alarm
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1enableAlarm(
      vin: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1enableAlarm(vin, options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehicleInteractionApi.v1enableAlarm']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * VehicleInteractionApi - factory interface
 * @export
 */
export const VehicleInteractionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = VehicleInteractionApiFp(configuration);
  return {
    /**
     * Disables the vehicle alarm
     * @summary Disables the vehicle alarm
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1disableAlarm(vin: string, options?: any): AxiosPromise<void> {
      return localVarFp.v1disableAlarm(vin, options).then((request) => request(axios, basePath));
    },
    /**
     * Enables the vehicle alarm
     * @summary Enables the vehicle alarm
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1enableAlarm(vin: string, options?: any): AxiosPromise<void> {
      return localVarFp.v1enableAlarm(vin, options).then((request) => request(axios, basePath));
    }
  };
};

/**
 * VehicleInteractionApi - object-oriented interface
 * @export
 * @class VehicleInteractionApi
 * @extends {BaseAPI}
 */
export class VehicleInteractionApi extends BaseAPI {
  /**
   * Disables the vehicle alarm
   * @summary Disables the vehicle alarm
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleInteractionApi
   */
  public v1disableAlarm(vin: string, options?: AxiosRequestConfig) {
    return VehicleInteractionApiFp(this.configuration)
      .v1disableAlarm(vin, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enables the vehicle alarm
   * @summary Enables the vehicle alarm
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleInteractionApi
   */
  public v1enableAlarm(vin: string, options?: AxiosRequestConfig) {
    return VehicleInteractionApiFp(this.configuration)
      .v1enableAlarm(vin, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * VehicleInteractionV2Api - axios parameter creator
 * @export
 */
export const VehicleInteractionV2ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Disables the vehicle alarm
     * @summary Disables the vehicle alarm
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disableAlarm: async (
      accessToken: string,
      idToken: string,
      vin: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('disableAlarm', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('disableAlarm', 'idToken', idToken);
      // verify required parameter 'vin' is not null or undefined
      assertParamExists('disableAlarm', 'vin', vin);
      const localVarPath = `/v2/vehicles/{vin}/alarm/disable`.replace(
        `{${'vin'}}`,
        encodeURIComponent(String(vin))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Enables the vehicle alarm
     * @summary Enables the vehicle alarm
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    enableAlarm: async (
      accessToken: string,
      idToken: string,
      vin: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('enableAlarm', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('enableAlarm', 'idToken', idToken);
      // verify required parameter 'vin' is not null or undefined
      assertParamExists('enableAlarm', 'vin', vin);
      const localVarPath = `/v2/vehicles/{vin}/alarm/enable`.replace(
        `{${'vin'}}`,
        encodeURIComponent(String(vin))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * VehicleInteractionV2Api - functional programming interface
 * @export
 */
export const VehicleInteractionV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = VehicleInteractionV2ApiAxiosParamCreator(configuration);
  return {
    /**
     * Disables the vehicle alarm
     * @summary Disables the vehicle alarm
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async disableAlarm(
      accessToken: string,
      idToken: string,
      vin: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.disableAlarm(
        accessToken,
        idToken,
        vin,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehicleInteractionV2Api.disableAlarm']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Enables the vehicle alarm
     * @summary Enables the vehicle alarm
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async enableAlarm(
      accessToken: string,
      idToken: string,
      vin: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.enableAlarm(
        accessToken,
        idToken,
        vin,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehicleInteractionV2Api.enableAlarm']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * VehicleInteractionV2Api - factory interface
 * @export
 */
export const VehicleInteractionV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = VehicleInteractionV2ApiFp(configuration);
  return {
    /**
     * Disables the vehicle alarm
     * @summary Disables the vehicle alarm
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    disableAlarm(
      accessToken: string,
      idToken: string,
      vin: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .disableAlarm(accessToken, idToken, vin, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Enables the vehicle alarm
     * @summary Enables the vehicle alarm
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} vin
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    enableAlarm(
      accessToken: string,
      idToken: string,
      vin: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .enableAlarm(accessToken, idToken, vin, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * VehicleInteractionV2Api - object-oriented interface
 * @export
 * @class VehicleInteractionV2Api
 * @extends {BaseAPI}
 */
export class VehicleInteractionV2Api extends BaseAPI {
  /**
   * Disables the vehicle alarm
   * @summary Disables the vehicle alarm
   * @param {string} accessToken
   * @param {string} idToken
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleInteractionV2Api
   */
  public disableAlarm(
    accessToken: string,
    idToken: string,
    vin: string,
    options?: AxiosRequestConfig
  ) {
    return VehicleInteractionV2ApiFp(this.configuration)
      .disableAlarm(accessToken, idToken, vin, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Enables the vehicle alarm
   * @summary Enables the vehicle alarm
   * @param {string} accessToken
   * @param {string} idToken
   * @param {string} vin
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleInteractionV2Api
   */
  public enableAlarm(
    accessToken: string,
    idToken: string,
    vin: string,
    options?: AxiosRequestConfig
  ) {
    return VehicleInteractionV2ApiFp(this.configuration)
      .enableAlarm(accessToken, idToken, vin, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * VehicleReportsApi - axios parameter creator
 * @export
 */
export const VehicleReportsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Create a vehicle report
     * @summary Create a vehicle report
     * @param {VehicleReportBody} vehicleReportBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1createVehicleReport: async (
      vehicleReportBody: VehicleReportBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'vehicleReportBody' is not null or undefined
      assertParamExists('v1createVehicleReport', 'vehicleReportBody', vehicleReportBody);
      const localVarPath = `/vehicle-reports`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        vehicleReportBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Gets all vehicle reports
     * @summary Gets all vehicle reports
     * @param {number} page
     * @param {number} pageSize
     * @param {number} [locationId]
     * @param {string} [start]
     * @param {string} [end]
     * @param {string} [reporter]
     * @param {string} [vin]
     * @param {string} [licencePlate]
     * @param {string} [description]
     * @param {string} [tags]
     * @param {boolean} [showResolved]
     * @param {number} [reportedDuringTourId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getVehicleReports: async (
      page: number,
      pageSize: number,
      locationId?: number,
      start?: string,
      end?: string,
      reporter?: string,
      vin?: string,
      licencePlate?: string,
      description?: string,
      tags?: string,
      showResolved?: boolean,
      reportedDuringTourId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists('v1getVehicleReports', 'page', page);
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('v1getVehicleReports', 'pageSize', pageSize);
      const localVarPath = `/vehicle-reports`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] =
          (start as any) instanceof Date ? (start as any).toISOString() : start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] =
          (end as any) instanceof Date ? (end as any).toISOString() : end;
      }

      if (reporter !== undefined) {
        localVarQueryParameter['reporter'] = reporter;
      }

      if (vin !== undefined) {
        localVarQueryParameter['vin'] = vin;
      }

      if (licencePlate !== undefined) {
        localVarQueryParameter['licencePlate'] = licencePlate;
      }

      if (description !== undefined) {
        localVarQueryParameter['description'] = description;
      }

      if (tags !== undefined) {
        localVarQueryParameter['tags'] = tags;
      }

      if (showResolved !== undefined) {
        localVarQueryParameter['showResolved'] = showResolved;
      }

      if (reportedDuringTourId !== undefined) {
        localVarQueryParameter['reportedDuringTourId'] = reportedDuringTourId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Marks a vehicle report as being resolved by the currently logged in user
     * @summary Marks a vehicle report as being resolved by the currently logged in user
     * @param {number} vehicleReportId
     * @param {VehicleReportResolveBody} vehicleReportResolveBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1resolveVehicleReport: async (
      vehicleReportId: number,
      vehicleReportResolveBody: VehicleReportResolveBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'vehicleReportId' is not null or undefined
      assertParamExists('v1resolveVehicleReport', 'vehicleReportId', vehicleReportId);
      // verify required parameter 'vehicleReportResolveBody' is not null or undefined
      assertParamExists(
        'v1resolveVehicleReport',
        'vehicleReportResolveBody',
        vehicleReportResolveBody
      );
      const localVarPath = `/vehicle-reports/{vehicleReportId}/resolve`.replace(
        `{${'vehicleReportId'}}`,
        encodeURIComponent(String(vehicleReportId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        vehicleReportResolveBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * VehicleReportsApi - functional programming interface
 * @export
 */
export const VehicleReportsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = VehicleReportsApiAxiosParamCreator(configuration);
  return {
    /**
     * Create a vehicle report
     * @summary Create a vehicle report
     * @param {VehicleReportBody} vehicleReportBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1createVehicleReport(
      vehicleReportBody: VehicleReportBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersistedVehicleReport>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1createVehicleReport(
        vehicleReportBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehicleReportsApi.v1createVehicleReport']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Gets all vehicle reports
     * @summary Gets all vehicle reports
     * @param {number} page
     * @param {number} pageSize
     * @param {number} [locationId]
     * @param {string} [start]
     * @param {string} [end]
     * @param {string} [reporter]
     * @param {string} [vin]
     * @param {string} [licencePlate]
     * @param {string} [description]
     * @param {string} [tags]
     * @param {boolean} [showResolved]
     * @param {number} [reportedDuringTourId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getVehicleReports(
      page: number,
      pageSize: number,
      locationId?: number,
      start?: string,
      end?: string,
      reporter?: string,
      vin?: string,
      licencePlate?: string,
      description?: string,
      tags?: string,
      showResolved?: boolean,
      reportedDuringTourId?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedResultPersistedVehicleReport>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1getVehicleReports(
        page,
        pageSize,
        locationId,
        start,
        end,
        reporter,
        vin,
        licencePlate,
        description,
        tags,
        showResolved,
        reportedDuringTourId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehicleReportsApi.v1getVehicleReports']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Marks a vehicle report as being resolved by the currently logged in user
     * @summary Marks a vehicle report as being resolved by the currently logged in user
     * @param {number} vehicleReportId
     * @param {VehicleReportResolveBody} vehicleReportResolveBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1resolveVehicleReport(
      vehicleReportId: number,
      vehicleReportResolveBody: VehicleReportResolveBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersistedVehicleReport>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1resolveVehicleReport(
        vehicleReportId,
        vehicleReportResolveBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehicleReportsApi.v1resolveVehicleReport']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * VehicleReportsApi - factory interface
 * @export
 */
export const VehicleReportsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = VehicleReportsApiFp(configuration);
  return {
    /**
     * Create a vehicle report
     * @summary Create a vehicle report
     * @param {VehicleReportBody} vehicleReportBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1createVehicleReport(
      vehicleReportBody: VehicleReportBody,
      options?: any
    ): AxiosPromise<PersistedVehicleReport> {
      return localVarFp
        .v1createVehicleReport(vehicleReportBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets all vehicle reports
     * @summary Gets all vehicle reports
     * @param {number} page
     * @param {number} pageSize
     * @param {number} [locationId]
     * @param {string} [start]
     * @param {string} [end]
     * @param {string} [reporter]
     * @param {string} [vin]
     * @param {string} [licencePlate]
     * @param {string} [description]
     * @param {string} [tags]
     * @param {boolean} [showResolved]
     * @param {number} [reportedDuringTourId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getVehicleReports(
      page: number,
      pageSize: number,
      locationId?: number,
      start?: string,
      end?: string,
      reporter?: string,
      vin?: string,
      licencePlate?: string,
      description?: string,
      tags?: string,
      showResolved?: boolean,
      reportedDuringTourId?: number,
      options?: any
    ): AxiosPromise<PaginatedResultPersistedVehicleReport> {
      return localVarFp
        .v1getVehicleReports(
          page,
          pageSize,
          locationId,
          start,
          end,
          reporter,
          vin,
          licencePlate,
          description,
          tags,
          showResolved,
          reportedDuringTourId,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Marks a vehicle report as being resolved by the currently logged in user
     * @summary Marks a vehicle report as being resolved by the currently logged in user
     * @param {number} vehicleReportId
     * @param {VehicleReportResolveBody} vehicleReportResolveBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1resolveVehicleReport(
      vehicleReportId: number,
      vehicleReportResolveBody: VehicleReportResolveBody,
      options?: any
    ): AxiosPromise<PersistedVehicleReport> {
      return localVarFp
        .v1resolveVehicleReport(vehicleReportId, vehicleReportResolveBody, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * VehicleReportsApi - object-oriented interface
 * @export
 * @class VehicleReportsApi
 * @extends {BaseAPI}
 */
export class VehicleReportsApi extends BaseAPI {
  /**
   * Create a vehicle report
   * @summary Create a vehicle report
   * @param {VehicleReportBody} vehicleReportBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleReportsApi
   */
  public v1createVehicleReport(vehicleReportBody: VehicleReportBody, options?: AxiosRequestConfig) {
    return VehicleReportsApiFp(this.configuration)
      .v1createVehicleReport(vehicleReportBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets all vehicle reports
   * @summary Gets all vehicle reports
   * @param {number} page
   * @param {number} pageSize
   * @param {number} [locationId]
   * @param {string} [start]
   * @param {string} [end]
   * @param {string} [reporter]
   * @param {string} [vin]
   * @param {string} [licencePlate]
   * @param {string} [description]
   * @param {string} [tags]
   * @param {boolean} [showResolved]
   * @param {number} [reportedDuringTourId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleReportsApi
   */
  public v1getVehicleReports(
    page: number,
    pageSize: number,
    locationId?: number,
    start?: string,
    end?: string,
    reporter?: string,
    vin?: string,
    licencePlate?: string,
    description?: string,
    tags?: string,
    showResolved?: boolean,
    reportedDuringTourId?: number,
    options?: AxiosRequestConfig
  ) {
    return VehicleReportsApiFp(this.configuration)
      .v1getVehicleReports(
        page,
        pageSize,
        locationId,
        start,
        end,
        reporter,
        vin,
        licencePlate,
        description,
        tags,
        showResolved,
        reportedDuringTourId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Marks a vehicle report as being resolved by the currently logged in user
   * @summary Marks a vehicle report as being resolved by the currently logged in user
   * @param {number} vehicleReportId
   * @param {VehicleReportResolveBody} vehicleReportResolveBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleReportsApi
   */
  public v1resolveVehicleReport(
    vehicleReportId: number,
    vehicleReportResolveBody: VehicleReportResolveBody,
    options?: AxiosRequestConfig
  ) {
    return VehicleReportsApiFp(this.configuration)
      .v1resolveVehicleReport(vehicleReportId, vehicleReportResolveBody, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * VehicleReportsV2Api - axios parameter creator
 * @export
 */
export const VehicleReportsV2ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Create a vehicle report
     * @summary Create a vehicle report
     * @param {string} accessToken
     * @param {string} idToken
     * @param {VehicleReportBody} vehicleReportBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createVehicleReport: async (
      accessToken: string,
      idToken: string,
      vehicleReportBody: VehicleReportBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('createVehicleReport', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('createVehicleReport', 'idToken', idToken);
      // verify required parameter 'vehicleReportBody' is not null or undefined
      assertParamExists('createVehicleReport', 'vehicleReportBody', vehicleReportBody);
      const localVarPath = `/v2/vehicles/reports`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        vehicleReportBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Gets all vehicle reports
     * @summary Gets all vehicle reports
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} page
     * @param {number} pageSize
     * @param {number} [locationId]
     * @param {string} [start]
     * @param {string} [end]
     * @param {string} [reporter]
     * @param {string} [vin]
     * @param {string} [licencePlate]
     * @param {string} [description]
     * @param {string} [tags]
     * @param {boolean} [showResolved]
     * @param {number} [reportedDuringTourId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVehicleReports: async (
      accessToken: string,
      idToken: string,
      page: number,
      pageSize: number,
      locationId?: number,
      start?: string,
      end?: string,
      reporter?: string,
      vin?: string,
      licencePlate?: string,
      description?: string,
      tags?: string,
      showResolved?: boolean,
      reportedDuringTourId?: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getVehicleReports', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getVehicleReports', 'idToken', idToken);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('getVehicleReports', 'page', page);
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('getVehicleReports', 'pageSize', pageSize);
      const localVarPath = `/v2/vehicles/reports`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (start !== undefined) {
        localVarQueryParameter['start'] =
          (start as any) instanceof Date ? (start as any).toISOString() : start;
      }

      if (end !== undefined) {
        localVarQueryParameter['end'] =
          (end as any) instanceof Date ? (end as any).toISOString() : end;
      }

      if (reporter !== undefined) {
        localVarQueryParameter['reporter'] = reporter;
      }

      if (vin !== undefined) {
        localVarQueryParameter['vin'] = vin;
      }

      if (licencePlate !== undefined) {
        localVarQueryParameter['licencePlate'] = licencePlate;
      }

      if (description !== undefined) {
        localVarQueryParameter['description'] = description;
      }

      if (tags !== undefined) {
        localVarQueryParameter['tags'] = tags;
      }

      if (showResolved !== undefined) {
        localVarQueryParameter['showResolved'] = showResolved;
      }

      if (reportedDuringTourId !== undefined) {
        localVarQueryParameter['reportedDuringTourId'] = reportedDuringTourId;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Marks a vehicle report as being resolved by the currently logged in user
     * @summary Marks a vehicle report as being resolved by the currently logged in user
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} vehicleReportId
     * @param {VehicleReportResolveBody} vehicleReportResolveBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resolveVehicleReport: async (
      accessToken: string,
      idToken: string,
      vehicleReportId: number,
      vehicleReportResolveBody: VehicleReportResolveBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('resolveVehicleReport', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('resolveVehicleReport', 'idToken', idToken);
      // verify required parameter 'vehicleReportId' is not null or undefined
      assertParamExists('resolveVehicleReport', 'vehicleReportId', vehicleReportId);
      // verify required parameter 'vehicleReportResolveBody' is not null or undefined
      assertParamExists(
        'resolveVehicleReport',
        'vehicleReportResolveBody',
        vehicleReportResolveBody
      );
      const localVarPath = `/v2/vehicles/reports/{vehicleReportId}/resolve`.replace(
        `{${'vehicleReportId'}}`,
        encodeURIComponent(String(vehicleReportId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        vehicleReportResolveBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * VehicleReportsV2Api - functional programming interface
 * @export
 */
export const VehicleReportsV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = VehicleReportsV2ApiAxiosParamCreator(configuration);
  return {
    /**
     * Create a vehicle report
     * @summary Create a vehicle report
     * @param {string} accessToken
     * @param {string} idToken
     * @param {VehicleReportBody} vehicleReportBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createVehicleReport(
      accessToken: string,
      idToken: string,
      vehicleReportBody: VehicleReportBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersistedVehicleReport>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createVehicleReport(
        accessToken,
        idToken,
        vehicleReportBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehicleReportsV2Api.createVehicleReport']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Gets all vehicle reports
     * @summary Gets all vehicle reports
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} page
     * @param {number} pageSize
     * @param {number} [locationId]
     * @param {string} [start]
     * @param {string} [end]
     * @param {string} [reporter]
     * @param {string} [vin]
     * @param {string} [licencePlate]
     * @param {string} [description]
     * @param {string} [tags]
     * @param {boolean} [showResolved]
     * @param {number} [reportedDuringTourId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVehicleReports(
      accessToken: string,
      idToken: string,
      page: number,
      pageSize: number,
      locationId?: number,
      start?: string,
      end?: string,
      reporter?: string,
      vin?: string,
      licencePlate?: string,
      description?: string,
      tags?: string,
      showResolved?: boolean,
      reportedDuringTourId?: number,
      options?: AxiosRequestConfig
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string
      ) => AxiosPromise<PaginatedResultPersistedVehicleReport>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVehicleReports(
        accessToken,
        idToken,
        page,
        pageSize,
        locationId,
        start,
        end,
        reporter,
        vin,
        licencePlate,
        description,
        tags,
        showResolved,
        reportedDuringTourId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehicleReportsV2Api.getVehicleReports']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Marks a vehicle report as being resolved by the currently logged in user
     * @summary Marks a vehicle report as being resolved by the currently logged in user
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} vehicleReportId
     * @param {VehicleReportResolveBody} vehicleReportResolveBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resolveVehicleReport(
      accessToken: string,
      idToken: string,
      vehicleReportId: number,
      vehicleReportResolveBody: VehicleReportResolveBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersistedVehicleReport>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resolveVehicleReport(
        accessToken,
        idToken,
        vehicleReportId,
        vehicleReportResolveBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehicleReportsV2Api.resolveVehicleReport']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * VehicleReportsV2Api - factory interface
 * @export
 */
export const VehicleReportsV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = VehicleReportsV2ApiFp(configuration);
  return {
    /**
     * Create a vehicle report
     * @summary Create a vehicle report
     * @param {string} accessToken
     * @param {string} idToken
     * @param {VehicleReportBody} vehicleReportBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createVehicleReport(
      accessToken: string,
      idToken: string,
      vehicleReportBody: VehicleReportBody,
      options?: any
    ): AxiosPromise<PersistedVehicleReport> {
      return localVarFp
        .createVehicleReport(accessToken, idToken, vehicleReportBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Gets all vehicle reports
     * @summary Gets all vehicle reports
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} page
     * @param {number} pageSize
     * @param {number} [locationId]
     * @param {string} [start]
     * @param {string} [end]
     * @param {string} [reporter]
     * @param {string} [vin]
     * @param {string} [licencePlate]
     * @param {string} [description]
     * @param {string} [tags]
     * @param {boolean} [showResolved]
     * @param {number} [reportedDuringTourId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVehicleReports(
      accessToken: string,
      idToken: string,
      page: number,
      pageSize: number,
      locationId?: number,
      start?: string,
      end?: string,
      reporter?: string,
      vin?: string,
      licencePlate?: string,
      description?: string,
      tags?: string,
      showResolved?: boolean,
      reportedDuringTourId?: number,
      options?: any
    ): AxiosPromise<PaginatedResultPersistedVehicleReport> {
      return localVarFp
        .getVehicleReports(
          accessToken,
          idToken,
          page,
          pageSize,
          locationId,
          start,
          end,
          reporter,
          vin,
          licencePlate,
          description,
          tags,
          showResolved,
          reportedDuringTourId,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Marks a vehicle report as being resolved by the currently logged in user
     * @summary Marks a vehicle report as being resolved by the currently logged in user
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} vehicleReportId
     * @param {VehicleReportResolveBody} vehicleReportResolveBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resolveVehicleReport(
      accessToken: string,
      idToken: string,
      vehicleReportId: number,
      vehicleReportResolveBody: VehicleReportResolveBody,
      options?: any
    ): AxiosPromise<PersistedVehicleReport> {
      return localVarFp
        .resolveVehicleReport(
          accessToken,
          idToken,
          vehicleReportId,
          vehicleReportResolveBody,
          options
        )
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * VehicleReportsV2Api - object-oriented interface
 * @export
 * @class VehicleReportsV2Api
 * @extends {BaseAPI}
 */
export class VehicleReportsV2Api extends BaseAPI {
  /**
   * Create a vehicle report
   * @summary Create a vehicle report
   * @param {string} accessToken
   * @param {string} idToken
   * @param {VehicleReportBody} vehicleReportBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleReportsV2Api
   */
  public createVehicleReport(
    accessToken: string,
    idToken: string,
    vehicleReportBody: VehicleReportBody,
    options?: AxiosRequestConfig
  ) {
    return VehicleReportsV2ApiFp(this.configuration)
      .createVehicleReport(accessToken, idToken, vehicleReportBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Gets all vehicle reports
   * @summary Gets all vehicle reports
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} page
   * @param {number} pageSize
   * @param {number} [locationId]
   * @param {string} [start]
   * @param {string} [end]
   * @param {string} [reporter]
   * @param {string} [vin]
   * @param {string} [licencePlate]
   * @param {string} [description]
   * @param {string} [tags]
   * @param {boolean} [showResolved]
   * @param {number} [reportedDuringTourId]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleReportsV2Api
   */
  public getVehicleReports(
    accessToken: string,
    idToken: string,
    page: number,
    pageSize: number,
    locationId?: number,
    start?: string,
    end?: string,
    reporter?: string,
    vin?: string,
    licencePlate?: string,
    description?: string,
    tags?: string,
    showResolved?: boolean,
    reportedDuringTourId?: number,
    options?: AxiosRequestConfig
  ) {
    return VehicleReportsV2ApiFp(this.configuration)
      .getVehicleReports(
        accessToken,
        idToken,
        page,
        pageSize,
        locationId,
        start,
        end,
        reporter,
        vin,
        licencePlate,
        description,
        tags,
        showResolved,
        reportedDuringTourId,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Marks a vehicle report as being resolved by the currently logged in user
   * @summary Marks a vehicle report as being resolved by the currently logged in user
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} vehicleReportId
   * @param {VehicleReportResolveBody} vehicleReportResolveBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehicleReportsV2Api
   */
  public resolveVehicleReport(
    accessToken: string,
    idToken: string,
    vehicleReportId: number,
    vehicleReportResolveBody: VehicleReportResolveBody,
    options?: AxiosRequestConfig
  ) {
    return VehicleReportsV2ApiFp(this.configuration)
      .resolveVehicleReport(
        accessToken,
        idToken,
        vehicleReportId,
        vehicleReportResolveBody,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * VehiclesApi - axios parameter creator
 * @export
 */
export const VehiclesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get active vehicles in the specified location (WunderFleet locationId)
     * @summary Get active vehicles
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getActiveVehicles: async (
      locationId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('v1getActiveVehicles', 'locationId', locationId);
      const localVarPath = `/active-vehicles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get available vehicles
     * @summary Get available vehicles
     * @param {string} projectKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getAvailableVehicles: async (
      projectKey: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'projectKey' is not null or undefined
      assertParamExists('v1getAvailableVehicles', 'projectKey', projectKey);
      const localVarPath = `/vehicles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      if (projectKey !== undefined) {
        localVarQueryParameter['projectKey'] = projectKey;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * This uses a WunderFleet workaround to attach the currently visible damage description to the vehicle object
     * @summary Get vehicle by vehicleId including damage description (uncached)
     * @param {number} vehicleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getVehicleByIdIncludingDamageDescription: async (
      vehicleId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'vehicleId' is not null or undefined
      assertParamExists('v1getVehicleByIdIncludingDamageDescription', 'vehicleId', vehicleId);
      const localVarPath = `/vehicles/{vehicleId}/include-damages`.replace(
        `{${'vehicleId'}}`,
        encodeURIComponent(String(vehicleId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get vehicles nearby the users position
     * @summary Get vehicles nearby the users position
     * @param {number} lat
     * @param {number} lon
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getVehiclesNearby: async (
      lat: number,
      lon: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'lat' is not null or undefined
      assertParamExists('v1getVehiclesNearby', 'lat', lat);
      // verify required parameter 'lon' is not null or undefined
      assertParamExists('v1getVehiclesNearby', 'lon', lon);
      const localVarPath = `/vehicles/nearby`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      if (lat !== undefined) {
        localVarQueryParameter['lat'] = lat;
      }

      if (lon !== undefined) {
        localVarQueryParameter['lon'] = lon;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Search for vehicles by licence plate
     * @summary Search for vehicles by licence plate
     * @param {string} licencePlate Licence plate of the vehicle to search for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1searchForVehicles: async (
      licencePlate: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'licencePlate' is not null or undefined
      assertParamExists('v1searchForVehicles', 'licencePlate', licencePlate);
      const localVarPath = `/vehicles/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      if (licencePlate !== undefined) {
        localVarQueryParameter['licence-plate'] = licencePlate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * VehiclesApi - functional programming interface
 * @export
 */
export const VehiclesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = VehiclesApiAxiosParamCreator(configuration);
  return {
    /**
     * Get active vehicles in the specified location (WunderFleet locationId)
     * @summary Get active vehicles
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getActiveVehicles(
      locationId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Vehicle>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1getActiveVehicles(
        locationId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['VehiclesApi.v1getActiveVehicles']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get available vehicles
     * @summary Get available vehicles
     * @param {string} projectKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getAvailableVehicles(
      projectKey: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Vehicle>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1getAvailableVehicles(
        projectKey,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehiclesApi.v1getAvailableVehicles']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * This uses a WunderFleet workaround to attach the currently visible damage description to the vehicle object
     * @summary Get vehicle by vehicleId including damage description (uncached)
     * @param {number} vehicleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getVehicleByIdIncludingDamageDescription(
      vehicleId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Vehicle>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.v1getVehicleByIdIncludingDamageDescription(
          vehicleId,
          options
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehiclesApi.v1getVehicleByIdIncludingDamageDescription']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get vehicles nearby the users position
     * @summary Get vehicles nearby the users position
     * @param {number} lat
     * @param {number} lon
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getVehiclesNearby(
      lat: number,
      lon: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Vehicle>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1getVehiclesNearby(
        lat,
        lon,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['VehiclesApi.v1getVehiclesNearby']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Search for vehicles by licence plate
     * @summary Search for vehicles by licence plate
     * @param {string} licencePlate Licence plate of the vehicle to search for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1searchForVehicles(
      licencePlate: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Vehicle>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1searchForVehicles(
        licencePlate,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['VehiclesApi.v1searchForVehicles']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * VehiclesApi - factory interface
 * @export
 */
export const VehiclesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = VehiclesApiFp(configuration);
  return {
    /**
     * Get active vehicles in the specified location (WunderFleet locationId)
     * @summary Get active vehicles
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getActiveVehicles(locationId: number, options?: any): AxiosPromise<Array<Vehicle>> {
      return localVarFp
        .v1getActiveVehicles(locationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get available vehicles
     * @summary Get available vehicles
     * @param {string} projectKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getAvailableVehicles(projectKey: string, options?: any): AxiosPromise<Array<Vehicle>> {
      return localVarFp
        .v1getAvailableVehicles(projectKey, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This uses a WunderFleet workaround to attach the currently visible damage description to the vehicle object
     * @summary Get vehicle by vehicleId including damage description (uncached)
     * @param {number} vehicleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getVehicleByIdIncludingDamageDescription(
      vehicleId: number,
      options?: any
    ): AxiosPromise<Vehicle> {
      return localVarFp
        .v1getVehicleByIdIncludingDamageDescription(vehicleId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get vehicles nearby the users position
     * @summary Get vehicles nearby the users position
     * @param {number} lat
     * @param {number} lon
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getVehiclesNearby(lat: number, lon: number, options?: any): AxiosPromise<Array<Vehicle>> {
      return localVarFp
        .v1getVehiclesNearby(lat, lon, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Search for vehicles by licence plate
     * @summary Search for vehicles by licence plate
     * @param {string} licencePlate Licence plate of the vehicle to search for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1searchForVehicles(licencePlate: string, options?: any): AxiosPromise<Array<Vehicle>> {
      return localVarFp
        .v1searchForVehicles(licencePlate, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * VehiclesApi - object-oriented interface
 * @export
 * @class VehiclesApi
 * @extends {BaseAPI}
 */
export class VehiclesApi extends BaseAPI {
  /**
   * Get active vehicles in the specified location (WunderFleet locationId)
   * @summary Get active vehicles
   * @param {number} locationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesApi
   */
  public v1getActiveVehicles(locationId: number, options?: AxiosRequestConfig) {
    return VehiclesApiFp(this.configuration)
      .v1getActiveVehicles(locationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get available vehicles
   * @summary Get available vehicles
   * @param {string} projectKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesApi
   */
  public v1getAvailableVehicles(projectKey: string, options?: AxiosRequestConfig) {
    return VehiclesApiFp(this.configuration)
      .v1getAvailableVehicles(projectKey, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This uses a WunderFleet workaround to attach the currently visible damage description to the vehicle object
   * @summary Get vehicle by vehicleId including damage description (uncached)
   * @param {number} vehicleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesApi
   */
  public v1getVehicleByIdIncludingDamageDescription(
    vehicleId: number,
    options?: AxiosRequestConfig
  ) {
    return VehiclesApiFp(this.configuration)
      .v1getVehicleByIdIncludingDamageDescription(vehicleId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get vehicles nearby the users position
   * @summary Get vehicles nearby the users position
   * @param {number} lat
   * @param {number} lon
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesApi
   */
  public v1getVehiclesNearby(lat: number, lon: number, options?: AxiosRequestConfig) {
    return VehiclesApiFp(this.configuration)
      .v1getVehiclesNearby(lat, lon, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Search for vehicles by licence plate
   * @summary Search for vehicles by licence plate
   * @param {string} licencePlate Licence plate of the vehicle to search for
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesApi
   */
  public v1searchForVehicles(licencePlate: string, options?: AxiosRequestConfig) {
    return VehiclesApiFp(this.configuration)
      .v1searchForVehicles(licencePlate, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * VehiclesV2Api - axios parameter creator
 * @export
 */
export const VehiclesV2ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get active vehicles in the specified location (WunderFleet locationId)
     * @summary Get active vehicles
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActiveVehicles: async (
      accessToken: string,
      idToken: string,
      locationId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getActiveVehicles', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getActiveVehicles', 'idToken', idToken);
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('getActiveVehicles', 'locationId', locationId);
      const localVarPath = `/v2/vehicles/active-vehicles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get available vehicles
     * @summary Get available vehicles
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} projectKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableVehicles: async (
      accessToken: string,
      idToken: string,
      projectKey: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getAvailableVehicles', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getAvailableVehicles', 'idToken', idToken);
      // verify required parameter 'projectKey' is not null or undefined
      assertParamExists('getAvailableVehicles', 'projectKey', projectKey);
      const localVarPath = `/v2/vehicles`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (projectKey !== undefined) {
        localVarQueryParameter['projectKey'] = projectKey;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Provides a statistical overview of vehicles for a given location
     * @summary Get vehicles statistics for location id
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFleetStatus: async (
      accessToken: string,
      idToken: string,
      locationId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getFleetStatus', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getFleetStatus', 'idToken', idToken);
      // verify required parameter 'locationId' is not null or undefined
      assertParamExists('getFleetStatus', 'locationId', locationId);
      const localVarPath = `/v2/vehicles/status`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (locationId !== undefined) {
        localVarQueryParameter['locationId'] = locationId;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * This uses a WunderFleet workaround to attach the currently visible damage description to the vehicle object
     * @summary Get vehicle by vehicleId including damage description (uncached)
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} vehicleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVehicleByIdIncludingDamageDescription: async (
      accessToken: string,
      idToken: string,
      vehicleId: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getVehicleByIdIncludingDamageDescription', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getVehicleByIdIncludingDamageDescription', 'idToken', idToken);
      // verify required parameter 'vehicleId' is not null or undefined
      assertParamExists('getVehicleByIdIncludingDamageDescription', 'vehicleId', vehicleId);
      const localVarPath = `/v2/vehicles/{vehicleId}/include-damages`.replace(
        `{${'vehicleId'}}`,
        encodeURIComponent(String(vehicleId))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get vehicles nearby the users position
     * @summary Get vehicles nearby the users position
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} lat
     * @param {number} lon
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVehiclesNearby: async (
      accessToken: string,
      idToken: string,
      lat: number,
      lon: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getVehiclesNearby', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getVehiclesNearby', 'idToken', idToken);
      // verify required parameter 'lat' is not null or undefined
      assertParamExists('getVehiclesNearby', 'lat', lat);
      // verify required parameter 'lon' is not null or undefined
      assertParamExists('getVehiclesNearby', 'lon', lon);
      const localVarPath = `/v2/vehicles/nearby`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (lat !== undefined) {
        localVarQueryParameter['lat'] = lat;
      }

      if (lon !== undefined) {
        localVarQueryParameter['lon'] = lon;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Search for vehicles by licence plate
     * @summary Search for vehicles by licence plate
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} licencePlate Licence plate of the vehicle to search for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchForVehicles: async (
      accessToken: string,
      idToken: string,
      licencePlate: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('searchForVehicles', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('searchForVehicles', 'idToken', idToken);
      // verify required parameter 'licencePlate' is not null or undefined
      assertParamExists('searchForVehicles', 'licencePlate', licencePlate);
      const localVarPath = `/v2/vehicles/search`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (licencePlate !== undefined) {
        localVarQueryParameter['licence-plate'] = licencePlate;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * VehiclesV2Api - functional programming interface
 * @export
 */
export const VehiclesV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = VehiclesV2ApiAxiosParamCreator(configuration);
  return {
    /**
     * Get active vehicles in the specified location (WunderFleet locationId)
     * @summary Get active vehicles
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getActiveVehicles(
      accessToken: string,
      idToken: string,
      locationId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Vehicle>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getActiveVehicles(
        accessToken,
        idToken,
        locationId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['VehiclesV2Api.getActiveVehicles']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get available vehicles
     * @summary Get available vehicles
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} projectKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAvailableVehicles(
      accessToken: string,
      idToken: string,
      projectKey: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Vehicle>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAvailableVehicles(
        accessToken,
        idToken,
        projectKey,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehiclesV2Api.getAvailableVehicles']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Provides a statistical overview of vehicles for a given location
     * @summary Get vehicles statistics for location id
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getFleetStatus(
      accessToken: string,
      idToken: string,
      locationId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StatisticsOverview>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getFleetStatus(
        accessToken,
        idToken,
        locationId,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['VehiclesV2Api.getFleetStatus']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * This uses a WunderFleet workaround to attach the currently visible damage description to the vehicle object
     * @summary Get vehicle by vehicleId including damage description (uncached)
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} vehicleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVehicleByIdIncludingDamageDescription(
      accessToken: string,
      idToken: string,
      vehicleId: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Vehicle>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getVehicleByIdIncludingDamageDescription(
          accessToken,
          idToken,
          vehicleId,
          options
        );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['VehiclesV2Api.getVehicleByIdIncludingDamageDescription']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get vehicles nearby the users position
     * @summary Get vehicles nearby the users position
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} lat
     * @param {number} lon
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVehiclesNearby(
      accessToken: string,
      idToken: string,
      lat: number,
      lon: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Vehicle>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVehiclesNearby(
        accessToken,
        idToken,
        lat,
        lon,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['VehiclesV2Api.getVehiclesNearby']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Search for vehicles by licence plate
     * @summary Search for vehicles by licence plate
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} licencePlate Licence plate of the vehicle to search for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchForVehicles(
      accessToken: string,
      idToken: string,
      licencePlate: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Vehicle>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchForVehicles(
        accessToken,
        idToken,
        licencePlate,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['VehiclesV2Api.searchForVehicles']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * VehiclesV2Api - factory interface
 * @export
 */
export const VehiclesV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = VehiclesV2ApiFp(configuration);
  return {
    /**
     * Get active vehicles in the specified location (WunderFleet locationId)
     * @summary Get active vehicles
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActiveVehicles(
      accessToken: string,
      idToken: string,
      locationId: number,
      options?: any
    ): AxiosPromise<Array<Vehicle>> {
      return localVarFp
        .getActiveVehicles(accessToken, idToken, locationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get available vehicles
     * @summary Get available vehicles
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} projectKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAvailableVehicles(
      accessToken: string,
      idToken: string,
      projectKey: string,
      options?: any
    ): AxiosPromise<Array<Vehicle>> {
      return localVarFp
        .getAvailableVehicles(accessToken, idToken, projectKey, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Provides a statistical overview of vehicles for a given location
     * @summary Get vehicles statistics for location id
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} locationId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getFleetStatus(
      accessToken: string,
      idToken: string,
      locationId: number,
      options?: any
    ): AxiosPromise<StatisticsOverview> {
      return localVarFp
        .getFleetStatus(accessToken, idToken, locationId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This uses a WunderFleet workaround to attach the currently visible damage description to the vehicle object
     * @summary Get vehicle by vehicleId including damage description (uncached)
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} vehicleId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVehicleByIdIncludingDamageDescription(
      accessToken: string,
      idToken: string,
      vehicleId: number,
      options?: any
    ): AxiosPromise<Vehicle> {
      return localVarFp
        .getVehicleByIdIncludingDamageDescription(accessToken, idToken, vehicleId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get vehicles nearby the users position
     * @summary Get vehicles nearby the users position
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} lat
     * @param {number} lon
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVehiclesNearby(
      accessToken: string,
      idToken: string,
      lat: number,
      lon: number,
      options?: any
    ): AxiosPromise<Array<Vehicle>> {
      return localVarFp
        .getVehiclesNearby(accessToken, idToken, lat, lon, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Search for vehicles by licence plate
     * @summary Search for vehicles by licence plate
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} licencePlate Licence plate of the vehicle to search for
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchForVehicles(
      accessToken: string,
      idToken: string,
      licencePlate: string,
      options?: any
    ): AxiosPromise<Array<Vehicle>> {
      return localVarFp
        .searchForVehicles(accessToken, idToken, licencePlate, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * VehiclesV2Api - object-oriented interface
 * @export
 * @class VehiclesV2Api
 * @extends {BaseAPI}
 */
export class VehiclesV2Api extends BaseAPI {
  /**
   * Get active vehicles in the specified location (WunderFleet locationId)
   * @summary Get active vehicles
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} locationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesV2Api
   */
  public getActiveVehicles(
    accessToken: string,
    idToken: string,
    locationId: number,
    options?: AxiosRequestConfig
  ) {
    return VehiclesV2ApiFp(this.configuration)
      .getActiveVehicles(accessToken, idToken, locationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get available vehicles
   * @summary Get available vehicles
   * @param {string} accessToken
   * @param {string} idToken
   * @param {string} projectKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesV2Api
   */
  public getAvailableVehicles(
    accessToken: string,
    idToken: string,
    projectKey: string,
    options?: AxiosRequestConfig
  ) {
    return VehiclesV2ApiFp(this.configuration)
      .getAvailableVehicles(accessToken, idToken, projectKey, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Provides a statistical overview of vehicles for a given location
   * @summary Get vehicles statistics for location id
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} locationId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesV2Api
   */
  public getFleetStatus(
    accessToken: string,
    idToken: string,
    locationId: number,
    options?: AxiosRequestConfig
  ) {
    return VehiclesV2ApiFp(this.configuration)
      .getFleetStatus(accessToken, idToken, locationId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This uses a WunderFleet workaround to attach the currently visible damage description to the vehicle object
   * @summary Get vehicle by vehicleId including damage description (uncached)
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} vehicleId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesV2Api
   */
  public getVehicleByIdIncludingDamageDescription(
    accessToken: string,
    idToken: string,
    vehicleId: number,
    options?: AxiosRequestConfig
  ) {
    return VehiclesV2ApiFp(this.configuration)
      .getVehicleByIdIncludingDamageDescription(accessToken, idToken, vehicleId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get vehicles nearby the users position
   * @summary Get vehicles nearby the users position
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} lat
   * @param {number} lon
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesV2Api
   */
  public getVehiclesNearby(
    accessToken: string,
    idToken: string,
    lat: number,
    lon: number,
    options?: AxiosRequestConfig
  ) {
    return VehiclesV2ApiFp(this.configuration)
      .getVehiclesNearby(accessToken, idToken, lat, lon, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Search for vehicles by licence plate
   * @summary Search for vehicles by licence plate
   * @param {string} accessToken
   * @param {string} idToken
   * @param {string} licencePlate Licence plate of the vehicle to search for
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VehiclesV2Api
   */
  public searchForVehicles(
    accessToken: string,
    idToken: string,
    licencePlate: string,
    options?: AxiosRequestConfig
  ) {
    return VehiclesV2ApiFp(this.configuration)
      .searchForVehicles(accessToken, idToken, licencePlate, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * WorkshopApi - axios parameter creator
 * @export
 */
export const WorkshopApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Add a todo item on the repair ticket
     * @summary Add a todo item on the repair ticket
     * @param {string} repairTicketIssueKey
     * @param {ProblemListTodoBody} problemListTodoBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1addRepairTicketTodo: async (
      repairTicketIssueKey: string,
      problemListTodoBody: ProblemListTodoBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'repairTicketIssueKey' is not null or undefined
      assertParamExists('v1addRepairTicketTodo', 'repairTicketIssueKey', repairTicketIssueKey);
      // verify required parameter 'problemListTodoBody' is not null or undefined
      assertParamExists('v1addRepairTicketTodo', 'problemListTodoBody', problemListTodoBody);
      const localVarPath = `/workshop/repair-list/{repairTicketIssueKey}/todos`.replace(
        `{${'repairTicketIssueKey'}}`,
        encodeURIComponent(String(repairTicketIssueKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        problemListTodoBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Complete work on the repair ticket successfully
     * @summary Complete work on the repair ticket successfully
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1completeRepairTicket: async (
      repairTicketIssueKey: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'repairTicketIssueKey' is not null or undefined
      assertParamExists('v1completeRepairTicket', 'repairTicketIssueKey', repairTicketIssueKey);
      const localVarPath = `/workshop/repair-list/{repairTicketIssueKey}/complete`.replace(
        `{${'repairTicketIssueKey'}}`,
        encodeURIComponent(String(repairTicketIssueKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Fail work on the repair ticket
     * @summary Fail work on the repair ticket
     * @param {string} repairTicketIssueKey
     * @param {RepairTicketFailBody} repairTicketFailBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1failRepairTicket: async (
      repairTicketIssueKey: string,
      repairTicketFailBody: RepairTicketFailBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'repairTicketIssueKey' is not null or undefined
      assertParamExists('v1failRepairTicket', 'repairTicketIssueKey', repairTicketIssueKey);
      // verify required parameter 'repairTicketFailBody' is not null or undefined
      assertParamExists('v1failRepairTicket', 'repairTicketFailBody', repairTicketFailBody);
      const localVarPath = `/workshop/repair-list/{repairTicketIssueKey}/fail`.replace(
        `{${'repairTicketIssueKey'}}`,
        encodeURIComponent(String(repairTicketIssueKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        repairTicketFailBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get list of open repair tickets for the specified workshop
     * @summary Get list of open repair tickets for the specified workshop
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getListOfOpenRepairTickets: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/workshop/repair-list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get logs for external workshop
     * @summary Get logs for external workshop
     * @param {number} page
     * @param {number} pageSize
     * @param {string} [workshopIdentifier]
     * @param {string} [issueKey]
     * @param {string} [vin]
     * @param {string} [username]
     * @param {string} [dateFrom]
     * @param {string} [dateTo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getLogsForExternalWorkshop: async (
      page: number,
      pageSize: number,
      workshopIdentifier?: string,
      issueKey?: string,
      vin?: string,
      username?: string,
      dateFrom?: string,
      dateTo?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'page' is not null or undefined
      assertParamExists('v1getLogsForExternalWorkshop', 'page', page);
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('v1getLogsForExternalWorkshop', 'pageSize', pageSize);
      const localVarPath = `/workshop/logs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (workshopIdentifier !== undefined) {
        localVarQueryParameter['workshopIdentifier'] = workshopIdentifier;
      }

      if (issueKey !== undefined) {
        localVarQueryParameter['issueKey'] = issueKey;
      }

      if (vin !== undefined) {
        localVarQueryParameter['vin'] = vin;
      }

      if (username !== undefined) {
        localVarQueryParameter['username'] = username;
      }

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] =
          (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString() : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['dateTo'] =
          (dateTo as any) instanceof Date ? (dateTo as any).toISOString() : dateTo;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get open repair ticket for the given issue key
     * @summary Get open repair ticket for the given issue key
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getOpenRepairTicket: async (
      repairTicketIssueKey: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'repairTicketIssueKey' is not null or undefined
      assertParamExists('v1getOpenRepairTicket', 'repairTicketIssueKey', repairTicketIssueKey);
      const localVarPath = `/workshop/repair-list/{repairTicketIssueKey}`.replace(
        `{${'repairTicketIssueKey'}}`,
        encodeURIComponent(String(repairTicketIssueKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get the list of available problems to use in a repair ticket todo item
     * @summary Get the list of available problems to use in a repair ticket todo item
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getRepairTicketTodoProblemList: async (
      repairTicketIssueKey: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'repairTicketIssueKey' is not null or undefined
      assertParamExists(
        'v1getRepairTicketTodoProblemList',
        'repairTicketIssueKey',
        repairTicketIssueKey
      );
      const localVarPath = `/workshop/repair-list/{repairTicketIssueKey}/problem-list`.replace(
        `{${'repairTicketIssueKey'}}`,
        encodeURIComponent(String(repairTicketIssueKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get live vehicle status for a repair ticket
     * @summary Get live vehicle status for a repair ticket
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1liveStatusRepairTicket: async (
      repairTicketIssueKey: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'repairTicketIssueKey' is not null or undefined
      assertParamExists('v1liveStatusRepairTicket', 'repairTicketIssueKey', repairTicketIssueKey);
      const localVarPath = `/workshop/repair-list/{repairTicketIssueKey}/live-data`.replace(
        `{${'repairTicketIssueKey'}}`,
        encodeURIComponent(String(repairTicketIssueKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Perform a hardware interaction (e.g. lock, unlock) with the vehicle connected to the repair ticket
     * @summary Perform a hardware interaction (e.g. lock, unlock) with the vehicle connected to the repair ticket
     * @param {string} repairTicketIssueKey
     * @param {TriggerActionBody1} triggerActionBody1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1performTriggerAction: async (
      repairTicketIssueKey: string,
      triggerActionBody1: TriggerActionBody1,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'repairTicketIssueKey' is not null or undefined
      assertParamExists('v1performTriggerAction', 'repairTicketIssueKey', repairTicketIssueKey);
      // verify required parameter 'triggerActionBody1' is not null or undefined
      assertParamExists('v1performTriggerAction', 'triggerActionBody1', triggerActionBody1);
      const localVarPath = `/workshop/repair-list/{repairTicketIssueKey}/trigger-action`.replace(
        `{${'repairTicketIssueKey'}}`,
        encodeURIComponent(String(repairTicketIssueKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        triggerActionBody1,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Resolve a todo item on the repair ticket
     * @summary Resolve a todo item on the repair ticket
     * @param {string} repairTicketIssueKey
     * @param {number} repairTodoId
     * @param {ProblemListTodoResolveBody} problemListTodoResolveBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1resolveRepairTicketTodo: async (
      repairTicketIssueKey: string,
      repairTodoId: number,
      problemListTodoResolveBody: ProblemListTodoResolveBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'repairTicketIssueKey' is not null or undefined
      assertParamExists('v1resolveRepairTicketTodo', 'repairTicketIssueKey', repairTicketIssueKey);
      // verify required parameter 'repairTodoId' is not null or undefined
      assertParamExists('v1resolveRepairTicketTodo', 'repairTodoId', repairTodoId);
      // verify required parameter 'problemListTodoResolveBody' is not null or undefined
      assertParamExists(
        'v1resolveRepairTicketTodo',
        'problemListTodoResolveBody',
        problemListTodoResolveBody
      );
      const localVarPath =
        `/workshop/repair-list/{repairTicketIssueKey}/todos/{repairTodoId}/resolve`
          .replace(`{${'repairTicketIssueKey'}}`, encodeURIComponent(String(repairTicketIssueKey)))
          .replace(`{${'repairTodoId'}}`, encodeURIComponent(String(repairTodoId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      // authentication apiKeyAuth required
      await setApiKeyToObject(localVarHeaderParameter, 'JiraAuthentication', configuration);

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        problemListTodoResolveBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * WorkshopApi - functional programming interface
 * @export
 */
export const WorkshopApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WorkshopApiAxiosParamCreator(configuration);
  return {
    /**
     * Add a todo item on the repair ticket
     * @summary Add a todo item on the repair ticket
     * @param {string} repairTicketIssueKey
     * @param {ProblemListTodoBody} problemListTodoBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1addRepairTicketTodo(
      repairTicketIssueKey: string,
      problemListTodoBody: ProblemListTodoBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1addRepairTicketTodo(
        repairTicketIssueKey,
        problemListTodoBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopApi.v1addRepairTicketTodo']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Complete work on the repair ticket successfully
     * @summary Complete work on the repair ticket successfully
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1completeRepairTicket(
      repairTicketIssueKey: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1completeRepairTicket(
        repairTicketIssueKey,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopApi.v1completeRepairTicket']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Fail work on the repair ticket
     * @summary Fail work on the repair ticket
     * @param {string} repairTicketIssueKey
     * @param {RepairTicketFailBody} repairTicketFailBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1failRepairTicket(
      repairTicketIssueKey: string,
      repairTicketFailBody: RepairTicketFailBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1failRepairTicket(
        repairTicketIssueKey,
        repairTicketFailBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['WorkshopApi.v1failRepairTicket']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get list of open repair tickets for the specified workshop
     * @summary Get list of open repair tickets for the specified workshop
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getListOfOpenRepairTickets(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkshopTicket>>> {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.v1getListOfOpenRepairTickets(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopApi.v1getListOfOpenRepairTickets']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get logs for external workshop
     * @summary Get logs for external workshop
     * @param {number} page
     * @param {number} pageSize
     * @param {string} [workshopIdentifier]
     * @param {string} [issueKey]
     * @param {string} [vin]
     * @param {string} [username]
     * @param {string} [dateFrom]
     * @param {string} [dateTo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getLogsForExternalWorkshop(
      page: number,
      pageSize: number,
      workshopIdentifier?: string,
      issueKey?: string,
      vin?: string,
      username?: string,
      dateFrom?: string,
      dateTo?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResultWorkshopEvent>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1getLogsForExternalWorkshop(
        page,
        pageSize,
        workshopIdentifier,
        issueKey,
        vin,
        username,
        dateFrom,
        dateTo,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopApi.v1getLogsForExternalWorkshop']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get open repair ticket for the given issue key
     * @summary Get open repair ticket for the given issue key
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getOpenRepairTicket(
      repairTicketIssueKey: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkshopTicket>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1getOpenRepairTicket(
        repairTicketIssueKey,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopApi.v1getOpenRepairTicket']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get the list of available problems to use in a repair ticket todo item
     * @summary Get the list of available problems to use in a repair ticket todo item
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1getRepairTicketTodoProblemList(
      repairTicketIssueKey: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvailableProblem>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1getRepairTicketTodoProblemList(
        repairTicketIssueKey,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopApi.v1getRepairTicketTodoProblemList']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get live vehicle status for a repair ticket
     * @summary Get live vehicle status for a repair ticket
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1liveStatusRepairTicket(
      repairTicketIssueKey: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LiveData>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1liveStatusRepairTicket(
        repairTicketIssueKey,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopApi.v1liveStatusRepairTicket']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Perform a hardware interaction (e.g. lock, unlock) with the vehicle connected to the repair ticket
     * @summary Perform a hardware interaction (e.g. lock, unlock) with the vehicle connected to the repair ticket
     * @param {string} repairTicketIssueKey
     * @param {TriggerActionBody1} triggerActionBody1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1performTriggerAction(
      repairTicketIssueKey: string,
      triggerActionBody1: TriggerActionBody1,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1performTriggerAction(
        repairTicketIssueKey,
        triggerActionBody1,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopApi.v1performTriggerAction']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Resolve a todo item on the repair ticket
     * @summary Resolve a todo item on the repair ticket
     * @param {string} repairTicketIssueKey
     * @param {number} repairTodoId
     * @param {ProblemListTodoResolveBody} problemListTodoResolveBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async v1resolveRepairTicketTodo(
      repairTicketIssueKey: string,
      repairTodoId: number,
      problemListTodoResolveBody: ProblemListTodoResolveBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.v1resolveRepairTicketTodo(
        repairTicketIssueKey,
        repairTodoId,
        problemListTodoResolveBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopApi.v1resolveRepairTicketTodo']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * WorkshopApi - factory interface
 * @export
 */
export const WorkshopApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = WorkshopApiFp(configuration);
  return {
    /**
     * Add a todo item on the repair ticket
     * @summary Add a todo item on the repair ticket
     * @param {string} repairTicketIssueKey
     * @param {ProblemListTodoBody} problemListTodoBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1addRepairTicketTodo(
      repairTicketIssueKey: string,
      problemListTodoBody: ProblemListTodoBody,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .v1addRepairTicketTodo(repairTicketIssueKey, problemListTodoBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Complete work on the repair ticket successfully
     * @summary Complete work on the repair ticket successfully
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1completeRepairTicket(repairTicketIssueKey: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .v1completeRepairTicket(repairTicketIssueKey, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Fail work on the repair ticket
     * @summary Fail work on the repair ticket
     * @param {string} repairTicketIssueKey
     * @param {RepairTicketFailBody} repairTicketFailBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1failRepairTicket(
      repairTicketIssueKey: string,
      repairTicketFailBody: RepairTicketFailBody,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .v1failRepairTicket(repairTicketIssueKey, repairTicketFailBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get list of open repair tickets for the specified workshop
     * @summary Get list of open repair tickets for the specified workshop
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getListOfOpenRepairTickets(options?: any): AxiosPromise<Array<WorkshopTicket>> {
      return localVarFp
        .v1getListOfOpenRepairTickets(options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get logs for external workshop
     * @summary Get logs for external workshop
     * @param {number} page
     * @param {number} pageSize
     * @param {string} [workshopIdentifier]
     * @param {string} [issueKey]
     * @param {string} [vin]
     * @param {string} [username]
     * @param {string} [dateFrom]
     * @param {string} [dateTo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getLogsForExternalWorkshop(
      page: number,
      pageSize: number,
      workshopIdentifier?: string,
      issueKey?: string,
      vin?: string,
      username?: string,
      dateFrom?: string,
      dateTo?: string,
      options?: any
    ): AxiosPromise<PaginatedResultWorkshopEvent> {
      return localVarFp
        .v1getLogsForExternalWorkshop(
          page,
          pageSize,
          workshopIdentifier,
          issueKey,
          vin,
          username,
          dateFrom,
          dateTo,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get open repair ticket for the given issue key
     * @summary Get open repair ticket for the given issue key
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getOpenRepairTicket(
      repairTicketIssueKey: string,
      options?: any
    ): AxiosPromise<WorkshopTicket> {
      return localVarFp
        .v1getOpenRepairTicket(repairTicketIssueKey, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get the list of available problems to use in a repair ticket todo item
     * @summary Get the list of available problems to use in a repair ticket todo item
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1getRepairTicketTodoProblemList(
      repairTicketIssueKey: string,
      options?: any
    ): AxiosPromise<Array<AvailableProblem>> {
      return localVarFp
        .v1getRepairTicketTodoProblemList(repairTicketIssueKey, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get live vehicle status for a repair ticket
     * @summary Get live vehicle status for a repair ticket
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1liveStatusRepairTicket(repairTicketIssueKey: string, options?: any): AxiosPromise<LiveData> {
      return localVarFp
        .v1liveStatusRepairTicket(repairTicketIssueKey, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Perform a hardware interaction (e.g. lock, unlock) with the vehicle connected to the repair ticket
     * @summary Perform a hardware interaction (e.g. lock, unlock) with the vehicle connected to the repair ticket
     * @param {string} repairTicketIssueKey
     * @param {TriggerActionBody1} triggerActionBody1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1performTriggerAction(
      repairTicketIssueKey: string,
      triggerActionBody1: TriggerActionBody1,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .v1performTriggerAction(repairTicketIssueKey, triggerActionBody1, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Resolve a todo item on the repair ticket
     * @summary Resolve a todo item on the repair ticket
     * @param {string} repairTicketIssueKey
     * @param {number} repairTodoId
     * @param {ProblemListTodoResolveBody} problemListTodoResolveBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    v1resolveRepairTicketTodo(
      repairTicketIssueKey: string,
      repairTodoId: number,
      problemListTodoResolveBody: ProblemListTodoResolveBody,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .v1resolveRepairTicketTodo(
          repairTicketIssueKey,
          repairTodoId,
          problemListTodoResolveBody,
          options
        )
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * WorkshopApi - object-oriented interface
 * @export
 * @class WorkshopApi
 * @extends {BaseAPI}
 */
export class WorkshopApi extends BaseAPI {
  /**
   * Add a todo item on the repair ticket
   * @summary Add a todo item on the repair ticket
   * @param {string} repairTicketIssueKey
   * @param {ProblemListTodoBody} problemListTodoBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public v1addRepairTicketTodo(
    repairTicketIssueKey: string,
    problemListTodoBody: ProblemListTodoBody,
    options?: AxiosRequestConfig
  ) {
    return WorkshopApiFp(this.configuration)
      .v1addRepairTicketTodo(repairTicketIssueKey, problemListTodoBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Complete work on the repair ticket successfully
   * @summary Complete work on the repair ticket successfully
   * @param {string} repairTicketIssueKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public v1completeRepairTicket(repairTicketIssueKey: string, options?: AxiosRequestConfig) {
    return WorkshopApiFp(this.configuration)
      .v1completeRepairTicket(repairTicketIssueKey, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Fail work on the repair ticket
   * @summary Fail work on the repair ticket
   * @param {string} repairTicketIssueKey
   * @param {RepairTicketFailBody} repairTicketFailBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public v1failRepairTicket(
    repairTicketIssueKey: string,
    repairTicketFailBody: RepairTicketFailBody,
    options?: AxiosRequestConfig
  ) {
    return WorkshopApiFp(this.configuration)
      .v1failRepairTicket(repairTicketIssueKey, repairTicketFailBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get list of open repair tickets for the specified workshop
   * @summary Get list of open repair tickets for the specified workshop
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public v1getListOfOpenRepairTickets(options?: AxiosRequestConfig) {
    return WorkshopApiFp(this.configuration)
      .v1getListOfOpenRepairTickets(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get logs for external workshop
   * @summary Get logs for external workshop
   * @param {number} page
   * @param {number} pageSize
   * @param {string} [workshopIdentifier]
   * @param {string} [issueKey]
   * @param {string} [vin]
   * @param {string} [username]
   * @param {string} [dateFrom]
   * @param {string} [dateTo]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public v1getLogsForExternalWorkshop(
    page: number,
    pageSize: number,
    workshopIdentifier?: string,
    issueKey?: string,
    vin?: string,
    username?: string,
    dateFrom?: string,
    dateTo?: string,
    options?: AxiosRequestConfig
  ) {
    return WorkshopApiFp(this.configuration)
      .v1getLogsForExternalWorkshop(
        page,
        pageSize,
        workshopIdentifier,
        issueKey,
        vin,
        username,
        dateFrom,
        dateTo,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get open repair ticket for the given issue key
   * @summary Get open repair ticket for the given issue key
   * @param {string} repairTicketIssueKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public v1getOpenRepairTicket(repairTicketIssueKey: string, options?: AxiosRequestConfig) {
    return WorkshopApiFp(this.configuration)
      .v1getOpenRepairTicket(repairTicketIssueKey, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get the list of available problems to use in a repair ticket todo item
   * @summary Get the list of available problems to use in a repair ticket todo item
   * @param {string} repairTicketIssueKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public v1getRepairTicketTodoProblemList(
    repairTicketIssueKey: string,
    options?: AxiosRequestConfig
  ) {
    return WorkshopApiFp(this.configuration)
      .v1getRepairTicketTodoProblemList(repairTicketIssueKey, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get live vehicle status for a repair ticket
   * @summary Get live vehicle status for a repair ticket
   * @param {string} repairTicketIssueKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public v1liveStatusRepairTicket(repairTicketIssueKey: string, options?: AxiosRequestConfig) {
    return WorkshopApiFp(this.configuration)
      .v1liveStatusRepairTicket(repairTicketIssueKey, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Perform a hardware interaction (e.g. lock, unlock) with the vehicle connected to the repair ticket
   * @summary Perform a hardware interaction (e.g. lock, unlock) with the vehicle connected to the repair ticket
   * @param {string} repairTicketIssueKey
   * @param {TriggerActionBody1} triggerActionBody1
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public v1performTriggerAction(
    repairTicketIssueKey: string,
    triggerActionBody1: TriggerActionBody1,
    options?: AxiosRequestConfig
  ) {
    return WorkshopApiFp(this.configuration)
      .v1performTriggerAction(repairTicketIssueKey, triggerActionBody1, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Resolve a todo item on the repair ticket
   * @summary Resolve a todo item on the repair ticket
   * @param {string} repairTicketIssueKey
   * @param {number} repairTodoId
   * @param {ProblemListTodoResolveBody} problemListTodoResolveBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopApi
   */
  public v1resolveRepairTicketTodo(
    repairTicketIssueKey: string,
    repairTodoId: number,
    problemListTodoResolveBody: ProblemListTodoResolveBody,
    options?: AxiosRequestConfig
  ) {
    return WorkshopApiFp(this.configuration)
      .v1resolveRepairTicketTodo(
        repairTicketIssueKey,
        repairTodoId,
        problemListTodoResolveBody,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * WorkshopV2Api - axios parameter creator
 * @export
 */
export const WorkshopV2ApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Add a todo item on the repair ticket
     * @summary Add a todo item on the repair ticket
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} repairTicketIssueKey
     * @param {ProblemListTodoBody} problemListTodoBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addRepairTicketTodo: async (
      accessToken: string,
      idToken: string,
      repairTicketIssueKey: string,
      problemListTodoBody: ProblemListTodoBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('addRepairTicketTodo', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('addRepairTicketTodo', 'idToken', idToken);
      // verify required parameter 'repairTicketIssueKey' is not null or undefined
      assertParamExists('addRepairTicketTodo', 'repairTicketIssueKey', repairTicketIssueKey);
      // verify required parameter 'problemListTodoBody' is not null or undefined
      assertParamExists('addRepairTicketTodo', 'problemListTodoBody', problemListTodoBody);
      const localVarPath = `/v2/workshop/repair-list/{repairTicketIssueKey}/todos`.replace(
        `{${'repairTicketIssueKey'}}`,
        encodeURIComponent(String(repairTicketIssueKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        problemListTodoBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Complete work on the repair ticket successfully
     * @summary Complete work on the repair ticket successfully
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    completeRepairTicket: async (
      accessToken: string,
      idToken: string,
      repairTicketIssueKey: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('completeRepairTicket', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('completeRepairTicket', 'idToken', idToken);
      // verify required parameter 'repairTicketIssueKey' is not null or undefined
      assertParamExists('completeRepairTicket', 'repairTicketIssueKey', repairTicketIssueKey);
      const localVarPath = `/v2/workshop/repair-list/{repairTicketIssueKey}/complete`.replace(
        `{${'repairTicketIssueKey'}}`,
        encodeURIComponent(String(repairTicketIssueKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Fail work on the repair ticket
     * @summary Fail work on the repair ticket
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} repairTicketIssueKey
     * @param {RepairTicketFailBody} repairTicketFailBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    failRepairTicket: async (
      accessToken: string,
      idToken: string,
      repairTicketIssueKey: string,
      repairTicketFailBody: RepairTicketFailBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('failRepairTicket', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('failRepairTicket', 'idToken', idToken);
      // verify required parameter 'repairTicketIssueKey' is not null or undefined
      assertParamExists('failRepairTicket', 'repairTicketIssueKey', repairTicketIssueKey);
      // verify required parameter 'repairTicketFailBody' is not null or undefined
      assertParamExists('failRepairTicket', 'repairTicketFailBody', repairTicketFailBody);
      const localVarPath = `/v2/workshop/repair-list/{repairTicketIssueKey}/fail`.replace(
        `{${'repairTicketIssueKey'}}`,
        encodeURIComponent(String(repairTicketIssueKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        repairTicketFailBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get list of open repair tickets for the specified workshop
     * @summary Get list of open repair tickets for the specified workshop
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListOfOpenRepairTickets: async (
      accessToken: string,
      idToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getListOfOpenRepairTickets', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getListOfOpenRepairTickets', 'idToken', idToken);
      const localVarPath = `/v2/workshop/repair-list`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get logs for external workshop
     * @summary Get logs for external workshop
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} page
     * @param {number} pageSize
     * @param {string} [workshopIdentifier]
     * @param {string} [issueKey]
     * @param {string} [vin]
     * @param {string} [username]
     * @param {string} [dateFrom]
     * @param {string} [dateTo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLogsForExternalWorkshop: async (
      accessToken: string,
      idToken: string,
      page: number,
      pageSize: number,
      workshopIdentifier?: string,
      issueKey?: string,
      vin?: string,
      username?: string,
      dateFrom?: string,
      dateTo?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getLogsForExternalWorkshop', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getLogsForExternalWorkshop', 'idToken', idToken);
      // verify required parameter 'page' is not null or undefined
      assertParamExists('getLogsForExternalWorkshop', 'page', page);
      // verify required parameter 'pageSize' is not null or undefined
      assertParamExists('getLogsForExternalWorkshop', 'pageSize', pageSize);
      const localVarPath = `/v2/workshop/logs`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (page !== undefined) {
        localVarQueryParameter['page'] = page;
      }

      if (pageSize !== undefined) {
        localVarQueryParameter['pageSize'] = pageSize;
      }

      if (workshopIdentifier !== undefined) {
        localVarQueryParameter['workshopIdentifier'] = workshopIdentifier;
      }

      if (issueKey !== undefined) {
        localVarQueryParameter['issueKey'] = issueKey;
      }

      if (vin !== undefined) {
        localVarQueryParameter['vin'] = vin;
      }

      if (username !== undefined) {
        localVarQueryParameter['username'] = username;
      }

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] =
          (dateFrom as any) instanceof Date ? (dateFrom as any).toISOString() : dateFrom;
      }

      if (dateTo !== undefined) {
        localVarQueryParameter['dateTo'] =
          (dateTo as any) instanceof Date ? (dateTo as any).toISOString() : dateTo;
      }

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get open repair ticket for the given issue key
     * @summary Get open repair ticket for the given issue key
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOpenRepairTicket: async (
      accessToken: string,
      idToken: string,
      repairTicketIssueKey: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getOpenRepairTicket', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getOpenRepairTicket', 'idToken', idToken);
      // verify required parameter 'repairTicketIssueKey' is not null or undefined
      assertParamExists('getOpenRepairTicket', 'repairTicketIssueKey', repairTicketIssueKey);
      const localVarPath = `/v2/workshop/repair-list/{repairTicketIssueKey}`.replace(
        `{${'repairTicketIssueKey'}}`,
        encodeURIComponent(String(repairTicketIssueKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get the list of available problems to use in a repair ticket todo item
     * @summary Get the list of available problems to use in a repair ticket todo item
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRepairTicketTodoProblemList: async (
      accessToken: string,
      idToken: string,
      repairTicketIssueKey: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('getRepairTicketTodoProblemList', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('getRepairTicketTodoProblemList', 'idToken', idToken);
      // verify required parameter 'repairTicketIssueKey' is not null or undefined
      assertParamExists(
        'getRepairTicketTodoProblemList',
        'repairTicketIssueKey',
        repairTicketIssueKey
      );
      const localVarPath = `/v2/workshop/repair-list/{repairTicketIssueKey}/problem-list`.replace(
        `{${'repairTicketIssueKey'}}`,
        encodeURIComponent(String(repairTicketIssueKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get live vehicle status for a repair ticket
     * @summary Get live vehicle status for a repair ticket
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    liveStatusRepairTicket: async (
      accessToken: string,
      idToken: string,
      repairTicketIssueKey: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('liveStatusRepairTicket', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('liveStatusRepairTicket', 'idToken', idToken);
      // verify required parameter 'repairTicketIssueKey' is not null or undefined
      assertParamExists('liveStatusRepairTicket', 'repairTicketIssueKey', repairTicketIssueKey);
      const localVarPath = `/v2/workshop/repair-list/{repairTicketIssueKey}/live-data`.replace(
        `{${'repairTicketIssueKey'}}`,
        encodeURIComponent(String(repairTicketIssueKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Perform a hardware interaction (e.g. lock, unlock) with the vehicle connected to the repair ticket
     * @summary Perform a hardware interaction (e.g. lock, unlock) with the vehicle connected to the repair ticket
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} repairTicketIssueKey
     * @param {TriggerActionBody1} triggerActionBody1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    performTriggerAction: async (
      accessToken: string,
      idToken: string,
      repairTicketIssueKey: string,
      triggerActionBody1: TriggerActionBody1,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('performTriggerAction', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('performTriggerAction', 'idToken', idToken);
      // verify required parameter 'repairTicketIssueKey' is not null or undefined
      assertParamExists('performTriggerAction', 'repairTicketIssueKey', repairTicketIssueKey);
      // verify required parameter 'triggerActionBody1' is not null or undefined
      assertParamExists('performTriggerAction', 'triggerActionBody1', triggerActionBody1);
      const localVarPath = `/v2/workshop/repair-list/{repairTicketIssueKey}/trigger-action`.replace(
        `{${'repairTicketIssueKey'}}`,
        encodeURIComponent(String(repairTicketIssueKey))
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        triggerActionBody1,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Resolve a todo item on the repair ticket
     * @summary Resolve a todo item on the repair ticket
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} repairTicketIssueKey
     * @param {number} repairTodoId
     * @param {ProblemListTodoResolveBody} problemListTodoResolveBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resolveRepairTicketTodo: async (
      accessToken: string,
      idToken: string,
      repairTicketIssueKey: string,
      repairTodoId: number,
      problemListTodoResolveBody: ProblemListTodoResolveBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('resolveRepairTicketTodo', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('resolveRepairTicketTodo', 'idToken', idToken);
      // verify required parameter 'repairTicketIssueKey' is not null or undefined
      assertParamExists('resolveRepairTicketTodo', 'repairTicketIssueKey', repairTicketIssueKey);
      // verify required parameter 'repairTodoId' is not null or undefined
      assertParamExists('resolveRepairTicketTodo', 'repairTodoId', repairTodoId);
      // verify required parameter 'problemListTodoResolveBody' is not null or undefined
      assertParamExists(
        'resolveRepairTicketTodo',
        'problemListTodoResolveBody',
        problemListTodoResolveBody
      );
      const localVarPath =
        `/v2/workshop/repair-list/{repairTicketIssueKey}/todos/{repairTodoId}/resolve`
          .replace(`{${'repairTicketIssueKey'}}`, encodeURIComponent(String(repairTicketIssueKey)))
          .replace(`{${'repairTodoId'}}`, encodeURIComponent(String(repairTodoId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        problemListTodoResolveBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * WorkshopV2Api - functional programming interface
 * @export
 */
export const WorkshopV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WorkshopV2ApiAxiosParamCreator(configuration);
  return {
    /**
     * Add a todo item on the repair ticket
     * @summary Add a todo item on the repair ticket
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} repairTicketIssueKey
     * @param {ProblemListTodoBody} problemListTodoBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addRepairTicketTodo(
      accessToken: string,
      idToken: string,
      repairTicketIssueKey: string,
      problemListTodoBody: ProblemListTodoBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addRepairTicketTodo(
        accessToken,
        idToken,
        repairTicketIssueKey,
        problemListTodoBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopV2Api.addRepairTicketTodo']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Complete work on the repair ticket successfully
     * @summary Complete work on the repair ticket successfully
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async completeRepairTicket(
      accessToken: string,
      idToken: string,
      repairTicketIssueKey: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.completeRepairTicket(
        accessToken,
        idToken,
        repairTicketIssueKey,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopV2Api.completeRepairTicket']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Fail work on the repair ticket
     * @summary Fail work on the repair ticket
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} repairTicketIssueKey
     * @param {RepairTicketFailBody} repairTicketFailBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async failRepairTicket(
      accessToken: string,
      idToken: string,
      repairTicketIssueKey: string,
      repairTicketFailBody: RepairTicketFailBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.failRepairTicket(
        accessToken,
        idToken,
        repairTicketIssueKey,
        repairTicketFailBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath = operationServerMap['WorkshopV2Api.failRepairTicket']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get list of open repair tickets for the specified workshop
     * @summary Get list of open repair tickets for the specified workshop
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getListOfOpenRepairTickets(
      accessToken: string,
      idToken: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkshopTicket>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getListOfOpenRepairTickets(
        accessToken,
        idToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopV2Api.getListOfOpenRepairTickets']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get logs for external workshop
     * @summary Get logs for external workshop
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} page
     * @param {number} pageSize
     * @param {string} [workshopIdentifier]
     * @param {string} [issueKey]
     * @param {string} [vin]
     * @param {string} [username]
     * @param {string} [dateFrom]
     * @param {string} [dateTo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLogsForExternalWorkshop(
      accessToken: string,
      idToken: string,
      page: number,
      pageSize: number,
      workshopIdentifier?: string,
      issueKey?: string,
      vin?: string,
      username?: string,
      dateFrom?: string,
      dateTo?: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<PaginatedResultWorkshopEvent>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLogsForExternalWorkshop(
        accessToken,
        idToken,
        page,
        pageSize,
        workshopIdentifier,
        issueKey,
        vin,
        username,
        dateFrom,
        dateTo,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopV2Api.getLogsForExternalWorkshop']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get open repair ticket for the given issue key
     * @summary Get open repair ticket for the given issue key
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOpenRepairTicket(
      accessToken: string,
      idToken: string,
      repairTicketIssueKey: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkshopTicket>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOpenRepairTicket(
        accessToken,
        idToken,
        repairTicketIssueKey,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopV2Api.getOpenRepairTicket']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get the list of available problems to use in a repair ticket todo item
     * @summary Get the list of available problems to use in a repair ticket todo item
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getRepairTicketTodoProblemList(
      accessToken: string,
      idToken: string,
      repairTicketIssueKey: string,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AvailableProblem>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getRepairTicketTodoProblemList(
        accessToken,
        idToken,
        repairTicketIssueKey,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopV2Api.getRepairTicketTodoProblemList']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get live vehicle status for a repair ticket
     * @summary Get live vehicle status for a repair ticket
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async liveStatusRepairTicket(
      accessToken: string,
      idToken: string,
      repairTicketIssueKey: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LiveData>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.liveStatusRepairTicket(
        accessToken,
        idToken,
        repairTicketIssueKey,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopV2Api.liveStatusRepairTicket']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Perform a hardware interaction (e.g. lock, unlock) with the vehicle connected to the repair ticket
     * @summary Perform a hardware interaction (e.g. lock, unlock) with the vehicle connected to the repair ticket
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} repairTicketIssueKey
     * @param {TriggerActionBody1} triggerActionBody1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async performTriggerAction(
      accessToken: string,
      idToken: string,
      repairTicketIssueKey: string,
      triggerActionBody1: TriggerActionBody1,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.performTriggerAction(
        accessToken,
        idToken,
        repairTicketIssueKey,
        triggerActionBody1,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopV2Api.performTriggerAction']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Resolve a todo item on the repair ticket
     * @summary Resolve a todo item on the repair ticket
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} repairTicketIssueKey
     * @param {number} repairTodoId
     * @param {ProblemListTodoResolveBody} problemListTodoResolveBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async resolveRepairTicketTodo(
      accessToken: string,
      idToken: string,
      repairTicketIssueKey: string,
      repairTodoId: number,
      problemListTodoResolveBody: ProblemListTodoResolveBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.resolveRepairTicketTodo(
        accessToken,
        idToken,
        repairTicketIssueKey,
        repairTodoId,
        problemListTodoResolveBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WorkshopV2Api.resolveRepairTicketTodo']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * WorkshopV2Api - factory interface
 * @export
 */
export const WorkshopV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = WorkshopV2ApiFp(configuration);
  return {
    /**
     * Add a todo item on the repair ticket
     * @summary Add a todo item on the repair ticket
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} repairTicketIssueKey
     * @param {ProblemListTodoBody} problemListTodoBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addRepairTicketTodo(
      accessToken: string,
      idToken: string,
      repairTicketIssueKey: string,
      problemListTodoBody: ProblemListTodoBody,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .addRepairTicketTodo(
          accessToken,
          idToken,
          repairTicketIssueKey,
          problemListTodoBody,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Complete work on the repair ticket successfully
     * @summary Complete work on the repair ticket successfully
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    completeRepairTicket(
      accessToken: string,
      idToken: string,
      repairTicketIssueKey: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .completeRepairTicket(accessToken, idToken, repairTicketIssueKey, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Fail work on the repair ticket
     * @summary Fail work on the repair ticket
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} repairTicketIssueKey
     * @param {RepairTicketFailBody} repairTicketFailBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    failRepairTicket(
      accessToken: string,
      idToken: string,
      repairTicketIssueKey: string,
      repairTicketFailBody: RepairTicketFailBody,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .failRepairTicket(accessToken, idToken, repairTicketIssueKey, repairTicketFailBody, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get list of open repair tickets for the specified workshop
     * @summary Get list of open repair tickets for the specified workshop
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getListOfOpenRepairTickets(
      accessToken: string,
      idToken: string,
      options?: any
    ): AxiosPromise<Array<WorkshopTicket>> {
      return localVarFp
        .getListOfOpenRepairTickets(accessToken, idToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get logs for external workshop
     * @summary Get logs for external workshop
     * @param {string} accessToken
     * @param {string} idToken
     * @param {number} page
     * @param {number} pageSize
     * @param {string} [workshopIdentifier]
     * @param {string} [issueKey]
     * @param {string} [vin]
     * @param {string} [username]
     * @param {string} [dateFrom]
     * @param {string} [dateTo]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLogsForExternalWorkshop(
      accessToken: string,
      idToken: string,
      page: number,
      pageSize: number,
      workshopIdentifier?: string,
      issueKey?: string,
      vin?: string,
      username?: string,
      dateFrom?: string,
      dateTo?: string,
      options?: any
    ): AxiosPromise<PaginatedResultWorkshopEvent> {
      return localVarFp
        .getLogsForExternalWorkshop(
          accessToken,
          idToken,
          page,
          pageSize,
          workshopIdentifier,
          issueKey,
          vin,
          username,
          dateFrom,
          dateTo,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get open repair ticket for the given issue key
     * @summary Get open repair ticket for the given issue key
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOpenRepairTicket(
      accessToken: string,
      idToken: string,
      repairTicketIssueKey: string,
      options?: any
    ): AxiosPromise<WorkshopTicket> {
      return localVarFp
        .getOpenRepairTicket(accessToken, idToken, repairTicketIssueKey, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get the list of available problems to use in a repair ticket todo item
     * @summary Get the list of available problems to use in a repair ticket todo item
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getRepairTicketTodoProblemList(
      accessToken: string,
      idToken: string,
      repairTicketIssueKey: string,
      options?: any
    ): AxiosPromise<Array<AvailableProblem>> {
      return localVarFp
        .getRepairTicketTodoProblemList(accessToken, idToken, repairTicketIssueKey, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get live vehicle status for a repair ticket
     * @summary Get live vehicle status for a repair ticket
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} repairTicketIssueKey
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    liveStatusRepairTicket(
      accessToken: string,
      idToken: string,
      repairTicketIssueKey: string,
      options?: any
    ): AxiosPromise<LiveData> {
      return localVarFp
        .liveStatusRepairTicket(accessToken, idToken, repairTicketIssueKey, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Perform a hardware interaction (e.g. lock, unlock) with the vehicle connected to the repair ticket
     * @summary Perform a hardware interaction (e.g. lock, unlock) with the vehicle connected to the repair ticket
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} repairTicketIssueKey
     * @param {TriggerActionBody1} triggerActionBody1
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    performTriggerAction(
      accessToken: string,
      idToken: string,
      repairTicketIssueKey: string,
      triggerActionBody1: TriggerActionBody1,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .performTriggerAction(
          accessToken,
          idToken,
          repairTicketIssueKey,
          triggerActionBody1,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Resolve a todo item on the repair ticket
     * @summary Resolve a todo item on the repair ticket
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} repairTicketIssueKey
     * @param {number} repairTodoId
     * @param {ProblemListTodoResolveBody} problemListTodoResolveBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    resolveRepairTicketTodo(
      accessToken: string,
      idToken: string,
      repairTicketIssueKey: string,
      repairTodoId: number,
      problemListTodoResolveBody: ProblemListTodoResolveBody,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .resolveRepairTicketTodo(
          accessToken,
          idToken,
          repairTicketIssueKey,
          repairTodoId,
          problemListTodoResolveBody,
          options
        )
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * WorkshopV2Api - object-oriented interface
 * @export
 * @class WorkshopV2Api
 * @extends {BaseAPI}
 */
export class WorkshopV2Api extends BaseAPI {
  /**
   * Add a todo item on the repair ticket
   * @summary Add a todo item on the repair ticket
   * @param {string} accessToken
   * @param {string} idToken
   * @param {string} repairTicketIssueKey
   * @param {ProblemListTodoBody} problemListTodoBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopV2Api
   */
  public addRepairTicketTodo(
    accessToken: string,
    idToken: string,
    repairTicketIssueKey: string,
    problemListTodoBody: ProblemListTodoBody,
    options?: AxiosRequestConfig
  ) {
    return WorkshopV2ApiFp(this.configuration)
      .addRepairTicketTodo(accessToken, idToken, repairTicketIssueKey, problemListTodoBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Complete work on the repair ticket successfully
   * @summary Complete work on the repair ticket successfully
   * @param {string} accessToken
   * @param {string} idToken
   * @param {string} repairTicketIssueKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopV2Api
   */
  public completeRepairTicket(
    accessToken: string,
    idToken: string,
    repairTicketIssueKey: string,
    options?: AxiosRequestConfig
  ) {
    return WorkshopV2ApiFp(this.configuration)
      .completeRepairTicket(accessToken, idToken, repairTicketIssueKey, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Fail work on the repair ticket
   * @summary Fail work on the repair ticket
   * @param {string} accessToken
   * @param {string} idToken
   * @param {string} repairTicketIssueKey
   * @param {RepairTicketFailBody} repairTicketFailBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopV2Api
   */
  public failRepairTicket(
    accessToken: string,
    idToken: string,
    repairTicketIssueKey: string,
    repairTicketFailBody: RepairTicketFailBody,
    options?: AxiosRequestConfig
  ) {
    return WorkshopV2ApiFp(this.configuration)
      .failRepairTicket(accessToken, idToken, repairTicketIssueKey, repairTicketFailBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get list of open repair tickets for the specified workshop
   * @summary Get list of open repair tickets for the specified workshop
   * @param {string} accessToken
   * @param {string} idToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopV2Api
   */
  public getListOfOpenRepairTickets(
    accessToken: string,
    idToken: string,
    options?: AxiosRequestConfig
  ) {
    return WorkshopV2ApiFp(this.configuration)
      .getListOfOpenRepairTickets(accessToken, idToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get logs for external workshop
   * @summary Get logs for external workshop
   * @param {string} accessToken
   * @param {string} idToken
   * @param {number} page
   * @param {number} pageSize
   * @param {string} [workshopIdentifier]
   * @param {string} [issueKey]
   * @param {string} [vin]
   * @param {string} [username]
   * @param {string} [dateFrom]
   * @param {string} [dateTo]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopV2Api
   */
  public getLogsForExternalWorkshop(
    accessToken: string,
    idToken: string,
    page: number,
    pageSize: number,
    workshopIdentifier?: string,
    issueKey?: string,
    vin?: string,
    username?: string,
    dateFrom?: string,
    dateTo?: string,
    options?: AxiosRequestConfig
  ) {
    return WorkshopV2ApiFp(this.configuration)
      .getLogsForExternalWorkshop(
        accessToken,
        idToken,
        page,
        pageSize,
        workshopIdentifier,
        issueKey,
        vin,
        username,
        dateFrom,
        dateTo,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get open repair ticket for the given issue key
   * @summary Get open repair ticket for the given issue key
   * @param {string} accessToken
   * @param {string} idToken
   * @param {string} repairTicketIssueKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopV2Api
   */
  public getOpenRepairTicket(
    accessToken: string,
    idToken: string,
    repairTicketIssueKey: string,
    options?: AxiosRequestConfig
  ) {
    return WorkshopV2ApiFp(this.configuration)
      .getOpenRepairTicket(accessToken, idToken, repairTicketIssueKey, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get the list of available problems to use in a repair ticket todo item
   * @summary Get the list of available problems to use in a repair ticket todo item
   * @param {string} accessToken
   * @param {string} idToken
   * @param {string} repairTicketIssueKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopV2Api
   */
  public getRepairTicketTodoProblemList(
    accessToken: string,
    idToken: string,
    repairTicketIssueKey: string,
    options?: AxiosRequestConfig
  ) {
    return WorkshopV2ApiFp(this.configuration)
      .getRepairTicketTodoProblemList(accessToken, idToken, repairTicketIssueKey, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get live vehicle status for a repair ticket
   * @summary Get live vehicle status for a repair ticket
   * @param {string} accessToken
   * @param {string} idToken
   * @param {string} repairTicketIssueKey
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopV2Api
   */
  public liveStatusRepairTicket(
    accessToken: string,
    idToken: string,
    repairTicketIssueKey: string,
    options?: AxiosRequestConfig
  ) {
    return WorkshopV2ApiFp(this.configuration)
      .liveStatusRepairTicket(accessToken, idToken, repairTicketIssueKey, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Perform a hardware interaction (e.g. lock, unlock) with the vehicle connected to the repair ticket
   * @summary Perform a hardware interaction (e.g. lock, unlock) with the vehicle connected to the repair ticket
   * @param {string} accessToken
   * @param {string} idToken
   * @param {string} repairTicketIssueKey
   * @param {TriggerActionBody1} triggerActionBody1
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopV2Api
   */
  public performTriggerAction(
    accessToken: string,
    idToken: string,
    repairTicketIssueKey: string,
    triggerActionBody1: TriggerActionBody1,
    options?: AxiosRequestConfig
  ) {
    return WorkshopV2ApiFp(this.configuration)
      .performTriggerAction(accessToken, idToken, repairTicketIssueKey, triggerActionBody1, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Resolve a todo item on the repair ticket
   * @summary Resolve a todo item on the repair ticket
   * @param {string} accessToken
   * @param {string} idToken
   * @param {string} repairTicketIssueKey
   * @param {number} repairTodoId
   * @param {ProblemListTodoResolveBody} problemListTodoResolveBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkshopV2Api
   */
  public resolveRepairTicketTodo(
    accessToken: string,
    idToken: string,
    repairTicketIssueKey: string,
    repairTodoId: number,
    problemListTodoResolveBody: ProblemListTodoResolveBody,
    options?: AxiosRequestConfig
  ) {
    return WorkshopV2ApiFp(this.configuration)
      .resolveRepairTicketTodo(
        accessToken,
        idToken,
        repairTicketIssueKey,
        repairTodoId,
        problemListTodoResolveBody,
        options
      )
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * WunderBusinessLocationsApi - axios parameter creator
 * @export
 */
export const WunderBusinessLocationsApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * Get all emmy business locations including territories and no-parking zones
     * @summary Get all emmy business locations including territories and no-parking zones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessLocationsDescription: async (
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      const localVarPath = `/business-locations`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * WunderBusinessLocationsApi - functional programming interface
 * @export
 */
export const WunderBusinessLocationsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WunderBusinessLocationsApiAxiosParamCreator(configuration);
  return {
    /**
     * Get all emmy business locations including territories and no-parking zones
     * @summary Get all emmy business locations including territories and no-parking zones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessLocationsDescription(
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BusinessLocation>>
    > {
      const localVarAxiosArgs =
        await localVarAxiosParamCreator.getBusinessLocationsDescription(options);
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WunderBusinessLocationsApi.getBusinessLocationsDescription']?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * WunderBusinessLocationsApi - factory interface
 * @export
 */
export const WunderBusinessLocationsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = WunderBusinessLocationsApiFp(configuration);
  return {
    /**
     * Get all emmy business locations including territories and no-parking zones
     * @summary Get all emmy business locations including territories and no-parking zones
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessLocationsDescription(options?: any): AxiosPromise<Array<BusinessLocation>> {
      return localVarFp
        .getBusinessLocationsDescription(options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * WunderBusinessLocationsApi - object-oriented interface
 * @export
 * @class WunderBusinessLocationsApi
 * @extends {BaseAPI}
 */
export class WunderBusinessLocationsApi extends BaseAPI {
  /**
   * Get all emmy business locations including territories and no-parking zones
   * @summary Get all emmy business locations including territories and no-parking zones
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WunderBusinessLocationsApi
   */
  public getBusinessLocationsDescription(options?: AxiosRequestConfig) {
    return WunderBusinessLocationsApiFp(this.configuration)
      .getBusinessLocationsDescription(options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * WunderFleetBulkActionsV2Api - axios parameter creator
 * @export
 */
export const WunderFleetBulkActionsV2ApiAxiosParamCreator = function (
  configuration?: Configuration
) {
  return {
    /**
     * Aborts the previously submitted bulk create
     * @summary Aborts the previously submitted bulk create
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wunderBulkEditVehiclesAbort: async (
      accessToken: string,
      idToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('wunderBulkEditVehiclesAbort', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('wunderBulkEditVehiclesAbort', 'idToken', idToken);
      const localVarPath = `/v2/wunder/vehicles/bulk-edit/abort`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Clear status report of previously submitted bulk edit, download report
     * @summary Clear status report of previously submitted bulk edit, download report
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wunderBulkEditVehiclesComplete: async (
      accessToken: string,
      idToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('wunderBulkEditVehiclesComplete', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('wunderBulkEditVehiclesComplete', 'idToken', idToken);
      const localVarPath = `/v2/wunder/vehicles/bulk-edit/complete`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Starts bulk editing of previously validated assets file
     * @summary Starts bulk editing of previously validated assets file
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} securityToken
     * @param {BulkEditStartBody} bulkEditStartBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wunderBulkEditVehiclesStart: async (
      accessToken: string,
      idToken: string,
      securityToken: string,
      bulkEditStartBody: BulkEditStartBody,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('wunderBulkEditVehiclesStart', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('wunderBulkEditVehiclesStart', 'idToken', idToken);
      // verify required parameter 'securityToken' is not null or undefined
      assertParamExists('wunderBulkEditVehiclesStart', 'securityToken', securityToken);
      // verify required parameter 'bulkEditStartBody' is not null or undefined
      assertParamExists('wunderBulkEditVehiclesStart', 'bulkEditStartBody', bulkEditStartBody);
      const localVarPath = `/v2/wunder/vehicles/bulk-edit/start`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      if (securityToken != null) {
        localVarHeaderParameter['securityToken'] = String(securityToken);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = serializeDataIfNeeded(
        bulkEditStartBody,
        localVarRequestOptions,
        configuration
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Get status report of previously submitted bulk edit
     * @summary Get status report of previously submitted bulk edit
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wunderBulkEditVehiclesStatus: async (
      accessToken: string,
      idToken: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('wunderBulkEditVehiclesStatus', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('wunderBulkEditVehiclesStatus', 'idToken', idToken);
      const localVarPath = `/v2/wunder/vehicles/bulk-edit/status`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    },
    /**
     * Validates an Excel file to be used for editing multiple WunderFleet assets
     * @summary Validates an Excel file to be used for editing multiple WunderFleet assets
     * @param {string} accessToken
     * @param {string} idToken
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wunderBulkEditVehiclesValidateFile: async (
      accessToken: string,
      idToken: string,
      file: File,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'accessToken' is not null or undefined
      assertParamExists('wunderBulkEditVehiclesValidateFile', 'accessToken', accessToken);
      // verify required parameter 'idToken' is not null or undefined
      assertParamExists('wunderBulkEditVehiclesValidateFile', 'idToken', idToken);
      // verify required parameter 'file' is not null or undefined
      assertParamExists('wunderBulkEditVehiclesValidateFile', 'file', file);
      const localVarPath = `/v2/wunder/vehicles/bulk-edit/validate-file`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

      if (accessToken != null) {
        localVarHeaderParameter['AccessToken'] = String(accessToken);
      }

      if (idToken != null) {
        localVarHeaderParameter['IdToken'] = String(idToken);
      }

      if (file !== undefined) {
        localVarFormParams.append('file', file as any);
      }

      localVarHeaderParameter['Content-Type'] = 'multipart/form-data';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers
      };
      localVarRequestOptions.data = localVarFormParams;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions
      };
    }
  };
};

/**
 * WunderFleetBulkActionsV2Api - functional programming interface
 * @export
 */
export const WunderFleetBulkActionsV2ApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WunderFleetBulkActionsV2ApiAxiosParamCreator(configuration);
  return {
    /**
     * Aborts the previously submitted bulk create
     * @summary Aborts the previously submitted bulk create
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async wunderBulkEditVehiclesAbort(
      accessToken: string,
      idToken: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.wunderBulkEditVehiclesAbort(
        accessToken,
        idToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WunderFleetBulkActionsV2Api.wunderBulkEditVehiclesAbort']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Clear status report of previously submitted bulk edit, download report
     * @summary Clear status report of previously submitted bulk edit, download report
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async wunderBulkEditVehiclesComplete(
      accessToken: string,
      idToken: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.wunderBulkEditVehiclesComplete(
        accessToken,
        idToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WunderFleetBulkActionsV2Api.wunderBulkEditVehiclesComplete']?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Starts bulk editing of previously validated assets file
     * @summary Starts bulk editing of previously validated assets file
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} securityToken
     * @param {BulkEditStartBody} bulkEditStartBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async wunderBulkEditVehiclesStart(
      accessToken: string,
      idToken: string,
      securityToken: string,
      bulkEditStartBody: BulkEditStartBody,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.wunderBulkEditVehiclesStart(
        accessToken,
        idToken,
        securityToken,
        bulkEditStartBody,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WunderFleetBulkActionsV2Api.wunderBulkEditVehiclesStart']?.[index]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Get status report of previously submitted bulk edit
     * @summary Get status report of previously submitted bulk edit
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async wunderBulkEditVehiclesStatus(
      accessToken: string,
      idToken: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WunderBulkEditStatus>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.wunderBulkEditVehiclesStatus(
        accessToken,
        idToken,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WunderFleetBulkActionsV2Api.wunderBulkEditVehiclesStatus']?.[index]
          ?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    },
    /**
     * Validates an Excel file to be used for editing multiple WunderFleet assets
     * @summary Validates an Excel file to be used for editing multiple WunderFleet assets
     * @param {string} accessToken
     * @param {string} idToken
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async wunderBulkEditVehiclesValidateFile(
      accessToken: string,
      idToken: string,
      file: File,
      options?: AxiosRequestConfig
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<BulkEditAssetsFileValidation>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.wunderBulkEditVehiclesValidateFile(
        accessToken,
        idToken,
        file,
        options
      );
      const index = configuration?.serverIndex ?? 0;
      const operationBasePath =
        operationServerMap['WunderFleetBulkActionsV2Api.wunderBulkEditVehiclesValidateFile']?.[
          index
        ]?.url;
      return (axios, basePath) =>
        createRequestFunction(
          localVarAxiosArgs,
          globalAxios,
          BASE_PATH,
          configuration
        )(axios, operationBasePath || basePath);
    }
  };
};

/**
 * WunderFleetBulkActionsV2Api - factory interface
 * @export
 */
export const WunderFleetBulkActionsV2ApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = WunderFleetBulkActionsV2ApiFp(configuration);
  return {
    /**
     * Aborts the previously submitted bulk create
     * @summary Aborts the previously submitted bulk create
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wunderBulkEditVehiclesAbort(
      accessToken: string,
      idToken: string,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .wunderBulkEditVehiclesAbort(accessToken, idToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Clear status report of previously submitted bulk edit, download report
     * @summary Clear status report of previously submitted bulk edit, download report
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wunderBulkEditVehiclesComplete(
      accessToken: string,
      idToken: string,
      options?: any
    ): AxiosPromise<File> {
      return localVarFp
        .wunderBulkEditVehiclesComplete(accessToken, idToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Starts bulk editing of previously validated assets file
     * @summary Starts bulk editing of previously validated assets file
     * @param {string} accessToken
     * @param {string} idToken
     * @param {string} securityToken
     * @param {BulkEditStartBody} bulkEditStartBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wunderBulkEditVehiclesStart(
      accessToken: string,
      idToken: string,
      securityToken: string,
      bulkEditStartBody: BulkEditStartBody,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .wunderBulkEditVehiclesStart(
          accessToken,
          idToken,
          securityToken,
          bulkEditStartBody,
          options
        )
        .then((request) => request(axios, basePath));
    },
    /**
     * Get status report of previously submitted bulk edit
     * @summary Get status report of previously submitted bulk edit
     * @param {string} accessToken
     * @param {string} idToken
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wunderBulkEditVehiclesStatus(
      accessToken: string,
      idToken: string,
      options?: any
    ): AxiosPromise<WunderBulkEditStatus> {
      return localVarFp
        .wunderBulkEditVehiclesStatus(accessToken, idToken, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Validates an Excel file to be used for editing multiple WunderFleet assets
     * @summary Validates an Excel file to be used for editing multiple WunderFleet assets
     * @param {string} accessToken
     * @param {string} idToken
     * @param {File} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    wunderBulkEditVehiclesValidateFile(
      accessToken: string,
      idToken: string,
      file: File,
      options?: any
    ): AxiosPromise<BulkEditAssetsFileValidation> {
      return localVarFp
        .wunderBulkEditVehiclesValidateFile(accessToken, idToken, file, options)
        .then((request) => request(axios, basePath));
    }
  };
};

/**
 * WunderFleetBulkActionsV2Api - object-oriented interface
 * @export
 * @class WunderFleetBulkActionsV2Api
 * @extends {BaseAPI}
 */
export class WunderFleetBulkActionsV2Api extends BaseAPI {
  /**
   * Aborts the previously submitted bulk create
   * @summary Aborts the previously submitted bulk create
   * @param {string} accessToken
   * @param {string} idToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WunderFleetBulkActionsV2Api
   */
  public wunderBulkEditVehiclesAbort(
    accessToken: string,
    idToken: string,
    options?: AxiosRequestConfig
  ) {
    return WunderFleetBulkActionsV2ApiFp(this.configuration)
      .wunderBulkEditVehiclesAbort(accessToken, idToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Clear status report of previously submitted bulk edit, download report
   * @summary Clear status report of previously submitted bulk edit, download report
   * @param {string} accessToken
   * @param {string} idToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WunderFleetBulkActionsV2Api
   */
  public wunderBulkEditVehiclesComplete(
    accessToken: string,
    idToken: string,
    options?: AxiosRequestConfig
  ) {
    return WunderFleetBulkActionsV2ApiFp(this.configuration)
      .wunderBulkEditVehiclesComplete(accessToken, idToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Starts bulk editing of previously validated assets file
   * @summary Starts bulk editing of previously validated assets file
   * @param {string} accessToken
   * @param {string} idToken
   * @param {string} securityToken
   * @param {BulkEditStartBody} bulkEditStartBody
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WunderFleetBulkActionsV2Api
   */
  public wunderBulkEditVehiclesStart(
    accessToken: string,
    idToken: string,
    securityToken: string,
    bulkEditStartBody: BulkEditStartBody,
    options?: AxiosRequestConfig
  ) {
    return WunderFleetBulkActionsV2ApiFp(this.configuration)
      .wunderBulkEditVehiclesStart(accessToken, idToken, securityToken, bulkEditStartBody, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get status report of previously submitted bulk edit
   * @summary Get status report of previously submitted bulk edit
   * @param {string} accessToken
   * @param {string} idToken
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WunderFleetBulkActionsV2Api
   */
  public wunderBulkEditVehiclesStatus(
    accessToken: string,
    idToken: string,
    options?: AxiosRequestConfig
  ) {
    return WunderFleetBulkActionsV2ApiFp(this.configuration)
      .wunderBulkEditVehiclesStatus(accessToken, idToken, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Validates an Excel file to be used for editing multiple WunderFleet assets
   * @summary Validates an Excel file to be used for editing multiple WunderFleet assets
   * @param {string} accessToken
   * @param {string} idToken
   * @param {File} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WunderFleetBulkActionsV2Api
   */
  public wunderBulkEditVehiclesValidateFile(
    accessToken: string,
    idToken: string,
    file: File,
    options?: AxiosRequestConfig
  ) {
    return WunderFleetBulkActionsV2ApiFp(this.configuration)
      .wunderBulkEditVehiclesValidateFile(accessToken, idToken, file, options)
      .then((request) => request(this.axios, this.basePath));
  }
}
