import L from 'leaflet';

export enum MarkerState {
  ACTIVE = 'active',
  AVAILABLE = 'available',
  FAILED = 'failed',
  COMPLETED = 'completed'
}

export class IconUtils {
  static buildIconForRelocate = (state: MarkerState): L.Icon => {
    const iconUrl =
      state === MarkerState.AVAILABLE
        ? 'marker-add.png'
        : state === MarkerState.FAILED
        ? 'end.png'
        : state === MarkerState.COMPLETED
        ? 'start.png'
        : 'marker-default.png';
    return L.icon({
      iconUrl: `/marker-icons/${iconUrl}`,
      iconSize: [26, 38],
      iconAnchor: [13, 38],
      popupAnchor: [0, -38]
    });
  };
}
