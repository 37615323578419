import { QUERY_KEYS } from '@/config/QueryKeys';
import { getWorkshopTickets } from '@badgermoleV2/workshop';
import { useQuery } from '@tanstack/react-query';

export function useRepairTickets() {
  const { data: repairTickets = [], isFetching: repairTicketsLoading } = useQuery({
    queryKey: [QUERY_KEYS.workshop.repairTickets],
    queryFn: async ({ signal }) => await getWorkshopTickets({signal})
  });

  return {
    repairTickets,
    repairTicketsLoading
  };
}
