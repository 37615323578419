import { ROUTES } from '@/App/routes/routes';
import { TourFooter } from '@/components/Tour';
import { useStepInteraction } from '@/hooks/Tour/General/useStepInteraction';
import { useTourInteraction } from '@/hooks/Tour/General/useTourInteraction';
import { useOpenTour } from '@/hooks/Tour/useOpenTour';
import { TourUtils } from '@/utils/TourUtils';
import { MissionType } from '@badgermoleV2/api';
import { Box, Button, LinearProgress, Paper, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

type BeforeYouGoScreenProps = {
  readonly requestTourLoading: boolean;
  readonly batteryCapacity: number;
};

export const BeforeYouGoScreen = ({
  requestTourLoading,
  batteryCapacity
}: BeforeYouGoScreenProps) => {
  const navigate = useNavigate();
  const [t] = useTranslation();
  const { openTour: compiledTour } = useOpenTour(MissionType.Swap);
  const { startStepAsync, isStartStepPending, isStartStepSuccess } = useStepInteraction();
  const { reloadTour } = useTourInteraction();

  const handleForceReloadTour = (): void => {
    if (!compiledTour) return;
    reloadTour(compiledTour.tour.tourType);
  };

  const showAndStartFirstStep = async () => {
    if (!compiledTour) {
      navigate(`${ROUTES.OVERVIEW}`);
      return;
    }

    const driveToStep = TourUtils.getFirstDriveToStep(compiledTour!);
    await startStepAsync({ tour: compiledTour, step: driveToStep });
  };

  if (isStartStepSuccess) {
    navigate(ROUTES.SERVICE.ROOT + ROUTES.TOUR.RUN);
  }

  return (
    <>
      <Paper sx={{ margin: '1rem 1rem 8rem 1rem', padding: '.5rem' }}>
        <Typography variant="h5">{t('service-tour:beforeYouGoList.title')}</Typography>
        <Typography variant="subtitle1">{t('service-tour:beforeYouGoList.subtitle')}</Typography>
        <ul>
          {TourUtils.beforeYouGoList.map((item) =>
            item === 'CapacityTimes2Batteries' ? (
              <li key={`beforeYouGoListItem${item}`}>
                {`${batteryCapacity * 2} ` + t(`service-tour:beforeYouGoList.${item}`)}
              </li>
            ) : (
              <li key={`beforeYouGoListItem${item}`}>
                {t(`service-tour:beforeYouGoList.${item}`)}
              </li>
            )
          )}
        </ul>
        <Box>
          <Button
            data-testid={'showFirstVehicle'}
            variant="contained"
            color={'success'}
            fullWidth
            disabled={isStartStepPending || requestTourLoading}
            onClick={showAndStartFirstStep}
          >
            {t('common:showFirstVehicle')}
          </Button>
          {(isStartStepPending || requestTourLoading) && <LinearProgress />}
        </Box>
      </Paper>
      <TourFooter reloadAction={handleForceReloadTour} />
    </>
  );
};
