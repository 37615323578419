import { GenericSelect } from '@/components/common/GenericSelect';
import type { AvailableProblem, WorkshopTicket } from '@badgermoleV2/api';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField
} from '@mui/material';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

type SimpleCreateWorkshopTodoDialogProps = {
  workshopTicket: WorkshopTicket;
  availableProblemList: AvailableProblem[];
  onCreate: (content: AvailableProblem, comment: string | undefined) => void;
  onClose: () => void;
};

export const SimpleCreateWorkshopTodoDialog = ({
  workshopTicket,
  availableProblemList,
  onCreate,
  onClose
}: SimpleCreateWorkshopTodoDialogProps) => {
  const [t] = useTranslation();
  const [comment, setComment] = useState<string>();
  const [filter, setFilter] = useState<string>();
  const [selectedProblem, setSelectedProblem] = useState<AvailableProblem>();
  const filteredProblemList = useMemo(() => {
    if (filter) {
      const filtered = availableProblemList.filter((p) => p.name.includes(filter));
      if (filtered.length === 1) {
        setSelectedProblem(filtered[0]);
      }
      return filtered;
    } else {
      return availableProblemList;
    }
  }, [filter, availableProblemList]);
  return (
    <Dialog open={true} onClose={() => {}} onBackdropClick={() => {}} fullWidth={true}>
      <DialogTitle>
        {t('external-workshop:repairTicket:createNew:title')} {workshopTicket.jiraIssue.issueKey}
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={2}>
          <TextField
            sx={{ marginTop: '32px' }}
            onChange={(e) => {
              setFilter(e.target.value as string);
            }}
            placeholder={t('external-workshop:repairTicket:createNew:newFilter')}
            required={true}
            fullWidth
          />
          <GenericSelect
            disabled={false}
            required={true}
            labelForItem={(h: AvailableProblem) => h.name}
            getValueFromItem={(h: AvailableProblem | undefined) => (h ? h.name : '')}
            items={filteredProblemList}
            onSelectItem={setSelectedProblem}
            selectedItem={selectedProblem}
            title={
              t('external-workshop:repairTicket:createNew:selectTodo') +
              ` (${filteredProblemList.length} ${t(
                'external-workshop:repairTicket:createNew:available'
              )})`
            }
          />
          <TextField
            sx={{ marginTop: '32px' }}
            onChange={(e) => {
              setComment(e.target.value as string);
            }}
            placeholder={t('external-workshop:repairTicket:addComment')}
            required={true}
            fullWidth
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" variant="outlined">
          {t('common:buttons:close')}
        </Button>
        <Button
          disabled={!selectedProblem}
          variant="contained"
          onClick={() => {
            if (selectedProblem) {
              onCreate(selectedProblem, comment);
            }
          }}
          color="primary"
        >
          {t('common:buttons:create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
