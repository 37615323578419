import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import type { ProblemListTodo, WorkshopTicket } from '@badgermoleV2/api';
import { WorkshopTicketTodoRow } from './WorkshopTicketTodoRow';

type WorkshopTicketTodoListProps = {
  readonly workshopTicket: WorkshopTicket;
  readonly changeTodoStatus: (todo: ProblemListTodo, status: string) => Promise<void>;
  readonly changeTodoComment: (todo: ProblemListTodo, comment: string) => Promise<void>;
};

export const WorkshopTicketTodoList = ({
  workshopTicket,
  changeTodoStatus,
  changeTodoComment
}: WorkshopTicketTodoListProps) => {
  const [t] = useTranslation();

  return (
    <TableContainer component={Paper}>
      <Table stickyHeader>
        <TableHead>
          <TableRow key={`TodoId`}>
            <TableCell key={`TodoContent`} align="left">
              {t('external-workshop:todoTable:content')}
            </TableCell>
            <TableCell key={`TodoComment`} align="left">
              {t('external-workshop:todoTable:comment')}
            </TableCell>
            <TableCell key={`TodoStatus`} align="left">
              {t('external-workshop:todoTable:status')}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(workshopTicket.todos ?? []).map((todo) => (
            <WorkshopTicketTodoRow
              key={`WorkshopTicketTodoRow${todo.todoId}`}
              todo={todo}
              changeTodoStatus={changeTodoStatus}
              changeTodoComment={changeTodoComment}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
