import { format, parse } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export class DateTimeUtils {
  static formatToLocale = (date: string): string => {
    try {
      const timeUTC = parse(date, "yyyy-MM-dd'T'HH:mm:ssX", new Date()); // gives UTC date
      const timeLocale = utcToZonedTime(timeUTC, 'Europe/Berlin'); // gives locale date
      return format(timeLocale, 'dd.MM.yyyy, HH:mm');
    } catch {
      console.debug(date);
      return 'dd.MM.yyyy, HH:mm';
    }
  };

  static formatDateToLocale = (date: Date): string => {
    return DateTimeUtils.formatToLocale(DateTimeUtils.formatForBadgermole(date));
  };

  static formatToLocaleDate = (date: string): Date => {
    const timeUTC = parse(date, "yyyy-MM-dd'T'HH:mm:ssX", new Date()); // gives UTC date
    const timeLocale = utcToZonedTime(timeUTC, 'Europe/Berlin'); // gives locale date
    return timeLocale;
  };

  static currentUtcDate = (): Date => {
    const timeLocale = utcToZonedTime(new Date(), 'Europe/Berlin'); // gives locale date
    return timeLocale;
  };

  static currentDateWithSeconds = (): string => {
    const timeLocale = format(new Date(), "dd.MM.yyyy', 'HH:mm:ss"); // gives locale date
    return timeLocale;
  };

  static formatToLocaleWithSeconds = (date: string): string => {
    const timeUTC = parse(date, "yyyy-MM-dd'T'HH:mm:ssX", new Date()); // gives UTC date
    const timeLocale = utcToZonedTime(timeUTC, 'Europe/Berlin'); // gives locale date
    return format(timeLocale, "dd.MM.yyyy', 'HH:mm:ss");
  };

  static formatForBadgermole = (date: Date): string => {
    return format(date, "yyyy-MM-dd'T'HH:mm:ssX");
  };

  static parseToDate = (date: string) => {
    return parse(date, "yyyy-MM-dd'T'HH:mm:ssX", new Date());
  };

  static formatSringWithMilliSecondsToLocaleWithSeconds = (date: string): string => {
    const timeUTC = parse(date, "yyyy-MM-dd'T'HH:mm:ss.SSSSSSX", new Date()); // gives UTC date
    const timeLocale = utcToZonedTime(timeUTC, 'Europe/Berlin'); // gives locale date
    return format(timeLocale, "dd.MM.yyyy' 'HH:mm:ss");
  };
}
