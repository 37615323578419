import { ROUTES } from '@/App/routes/routes';
import { DataLoadingDialog } from '@/components/Dialogs/DataLoadingDialog';
import { BusinessMap } from '@/components/Map/BusinessMap';
import { DisplayActiveVehicles } from '@/components/Map/DisplayActiveVehicles';
import { DisplayAvailableVehicles } from '@/components/Map/DisplayAvailableVehicles';
import { DisplayUserPosition } from '@/components/Map/DisplayUserPosition';
import { TourFooter } from '@/components/Tour';
import { useTourInteraction } from '@/hooks/Tour/General/useTourInteraction';
import { useRelocateTour } from '@/hooks/Tour/Relocate/useRelocateTour';
import { useTitle } from '@/hooks/useTitle';
import { AvailableRelocateVehicles } from '@/screens/TourScreens/Relocate/AvailableRelocateVehicles';
import { useStore } from '@/store';
import { MarkerState } from '@/utils/IconUtils';
import { ArrowDropDown, ArrowRight } from '@mui/icons-material';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { Alert, Box, Button, FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { ActiveRelocateVehicles } from './ActiveRelocateVehicles';

export const RelocateScreen = () => {
  useTitle({ title: 'relocate:title' });
  const [t] = useTranslation('relocate');
  const navigate = useNavigate();
  const {
    relocateTour,
    activeVehicles,
    availableVehicles,
    failedVehicles,
    finishedVehicles,
    completedVehicles,
    relocateActionLoading,
    adaptTour,
    fetchRelocateVehiclesLoading,
    disableAlarm,
    removeVehicleFromTour,
    enableAlarm
  } = useRelocateTour();
  const [openFinishedList, setOpenFinishedList] = useState(false);

  const [mapViewEnabled, setMapViewEnabled] = useStore((s) => [
    s.relocateMapViewEnabled,
    s.setRelocateMapViewEnabled
  ]);

  const { completeTourAsync, isCompleteTourPending } = useTourInteraction();

  const handleComplete = async () => {
    enableAlarm();
    await completeTourAsync({
      compiledTour: relocateTour!,
      vehicleMileage: relocateTour!.tour.tourParameters.serviceVehicle.mileage
    });
    navigate(ROUTES.OVERVIEW);
  };

  const MapViewToggle = () => (
    <FormControlLabel
      label={t('relocate:enableMapView')}
      sx={{ alignSelf: 'center' }}
      control={
        <FormControlLabel
          label={t('relocate:enableListView')}
          labelPlacement="start"
          control={
            <Switch
              sx={{ marginRight: '1em' }}
              checked={mapViewEnabled}
              onChange={() => setMapViewEnabled(!mapViewEnabled)}
            />
          }
        />
      }
    />
  );

  if (!relocateTour || isCompleteTourPending)
    return <DataLoadingDialog open message={t('common:loading:tourGenerate')} />;

  if (mapViewEnabled)
    return (
      <Box sx={{ margin: '.25rem' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            sx={{ width: 'fit-content' }}
            onClick={handleComplete}
            data-testid={'endRelocateButton'}
          >
            {t('relocate:endRelocateMode')}
          </Button>
          <Button
            disabled={activeVehicles.length < 1}
            size="small"
            variant="contained"
            color="primary"
            onClick={() => disableAlarm()}
          >
            {t('alarmOffShort')}
            <NotificationsOffIcon />
          </Button>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <MapViewToggle />
        </Box>
        <BusinessMap style={{ width: '100%', height: '70vh' }}>
          <DisplayUserPosition />
          <DisplayAvailableVehicles
            interact={(vehicle) => adaptTour([vehicle])}
            vehicles={availableVehicles}
            relocateActionLoading={relocateActionLoading}
          />
          <DisplayActiveVehicles
            state={MarkerState.ACTIVE}
            compiledTour={relocateTour}
            jobs={activeVehicles}
            removeVehicleFromTour={removeVehicleFromTour}
          />
          <DisplayActiveVehicles
            state={MarkerState.FAILED}
            compiledTour={relocateTour}
            jobs={failedVehicles}
            removeVehicleFromTour={removeVehicleFromTour}
          />
          <DisplayActiveVehicles
            state={MarkerState.COMPLETED}
            compiledTour={relocateTour}
            jobs={completedVehicles}
            removeVehicleFromTour={removeVehicleFromTour}
          />
        </BusinessMap>
        <TourFooter
          reloadAction={() => {
            window.location.reload();
          }}
        />
      </Box>
    );

  return (
    <Stack spacing={1} sx={{ margin: '.25rem', paddingBottom: '3rem' }}>
      <Button
        variant="contained"
        color="secondary"
        size="small"
        sx={{ alignSelf: 'center', width: 'fit-content' }}
        onClick={handleComplete}
        data-testid={'endRelocateButton'}
      >
        {t('relocate:endRelocateMode')}
      </Button>
      <MapViewToggle />
      <Box sx={{ fontWeight: 'bold', fontSize: 'x-large' }}>
        {t('relocate:currentRelocateVehicle')}
      </Box>
      {activeVehicles.length !== 0 ? (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Button size="small" variant="contained" color="primary" onClick={() => disableAlarm()}>
            {t('alarmOff')}
            <NotificationsOffIcon />
          </Button>
          <ActiveRelocateVehicles
            vehicles={activeVehicles}
            open
            compiledTour={relocateTour}
            removeVehicleFromTour={removeVehicleFromTour}
          />
        </Box>
      ) : (
        <Alert severity={'warning'}>{t('relocate:noRelocateVehiclesSelected')}</Alert>
      )}
      <Typography
        onClick={() => setOpenFinishedList((prev) => !prev)}
        sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold', fontSize: 'x-large' }}
      >
        {t('relocate:relocatedVehicles')}
        {openFinishedList ? <ArrowDropDown /> : <ArrowRight />}
      </Typography>
      {finishedVehicles.length !== 0 ? (
        <ActiveRelocateVehicles
          compiledTour={relocateTour}
          open={openFinishedList}
          vehicles={finishedVehicles}
          removeVehicleFromTour={removeVehicleFromTour}
        />
      ) : (
        <Alert severity={'warning'}>{t('relocate:noRelocateVehiclesFinished')}</Alert>
      )}
      <Typography sx={{ fontWeight: 'bold', fontSize: 'x-large' }}>
        {t('relocate:availableRelocateVehicles')}
      </Typography>
      <AvailableRelocateVehicles
        vehicles={availableVehicles}
        adaptTour={(vehicles) => {
          adaptTour(vehicles);
        }}
        adaptTourLoading={relocateActionLoading}
        fetchRelocateVehiclesLoading={fetchRelocateVehiclesLoading}
      />
      <TourFooter
        reloadAction={() => {
          window.location.reload();
        }}
      />
    </Stack>
  );
};
