import { useVehicleTypes } from '@/hooks/Cache/useVehicleTypes';
import { useRepairTickets } from '@/hooks/Workshop/useRepairTickets';
import { useTitle } from '@/hooks/useTitle';
import { Box, Button, Grid, List, ListItem, Paper, TextField } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkshopTicketCard } from './WorkshopTicketCard';

export const WorkshopScreen = () => {
  const { repairTickets, repairTicketsLoading } = useRepairTickets();
  const { t } = useTranslation();
  useTitle({ title: `external-workshop:title` });

  const [licensePlateSearch, setLicensePlateSearch] = useState('');
  const [cloudboxxSearch, setCloudboxxSearch] = useState('');
  const [vinSearch, setVinSearch] = useState('');

  const filteredRepairTickets = repairTickets.filter(
    (repairTicket) =>
      repairTicket.vehicle.licencePlate?.toLowerCase().includes(licensePlateSearch.toLowerCase()) &&
      repairTicket.vehicle.hardwareId
        ?.toLocaleLowerCase()
        .includes(cloudboxxSearch.toLowerCase()) &&
      repairTicket.jiraIssue.vin?.toLocaleLowerCase().includes(vinSearch.toLowerCase())
  );

  const { vehicleTypes, isLoading: vehicleTypesLoading } = useVehicleTypes();

  return (
    <Grid container>
      <Grid item xs={0} lg={3} />
      <Grid item xs={12} lg={6}>
        <Paper elevation={5} sx={{ padding: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={6} lg={3}>
              <TextField
                size="small"
                label={t(`external-workshop:repairTicket:licensePlate`)}
                value={licensePlateSearch}
                onChange={(e) => setLicensePlateSearch(e.target.value)}
                data-testid={'searchLicensePlate'}
              />
            </Grid>

            <Grid item xs={6} lg={3}>
              <TextField
                size="small"
                label={t(`external-workshop:repairTicket:cloudboxxId`)}
                value={cloudboxxSearch}
                onChange={(e) => setCloudboxxSearch(e.target.value)}
                data-testid={'searchCloudboxxId'}
              />
            </Grid>

            <Grid item xs={6} lg={3}>
              <TextField
                size="small"
                label={t(`external-workshop:repairTicket:vin`)}
                value={vinSearch}
                onChange={(e) => setVinSearch(e.target.value)}
                data-testid={'searchVin'}
              />
            </Grid>

            <Grid item xs={6} lg={3}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  setLicensePlateSearch(''), setCloudboxxSearch(''), setVinSearch('');
                }}
                data-testid={'clearSearch'}
              >
                {t(`common:buttons:clear`)}
              </Button>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs={0} lg={3} />
      <Grid item xs={0} lg={3} />
      <Grid item xs={12} lg={6}>
        <List>
          {filteredRepairTickets.length === 0 && !(repairTicketsLoading || vehicleTypesLoading) ? (
            <Box data-testid={'notAvailable'}>
              {t(`external-workshop:repairTicket:notAvailable`)}
            </Box>
          ) : (
            filteredRepairTickets.map((repairTicket) => (
              <ListItem key={`WorkshopTicketListItem${repairTicket.jiraIssue.issueKey}`}>
                <WorkshopTicketCard
                  key={`RepairTicket${repairTicket.jiraIssue.id}`}
                  vehicleTypes={vehicleTypes}
                  iconSrc={`/${
                    vehicleTypes
                      .find((vt) => vt.vehicleTypeId === repairTicket.vehicle.vehicleTypeId)
                      ?.model.split(' ')[0] ?? 'legacy'
                  }.png`}
                  workshopTicket={repairTicket}
                  showSelectButton
                />
              </ListItem>
            ))
          )}
        </List>
      </Grid>
      <Grid item xs={0} lg={3} />
    </Grid>
  );
};
